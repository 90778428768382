// Based on solution founded on StackOverflow

import Decimal from 'decimal.js';

// https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
const roundNumberWithAbbreviation = (number: number, decPlaces = 0): string => {
  let finalValue = '';

  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = ['k', 'M', 'B', 'T'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to '1000', '1000000', etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      finalValue += number + abbrev[i];
      // We are done... stop
      break;
    }
  }

  if (!finalValue) {
    finalValue = (Math.round(number * decPlaces) / decPlaces).toString();
  }

  return finalValue;
};

const roundNumberToTwo = (number: number): number => Math.round((number + Number.EPSILON) * 100) / 100;

const formatNumber = (number: number, fraction = false): string =>
  number.toLocaleString(undefined, { maximumFractionDigits: fraction ? 2 : 0, minimumFractionDigits: fraction ? 2 : 0, useGrouping: false });

const formatWeightWithUnit = (kilos: number, forceDecimals?: boolean, forceKilos?: boolean): string => {
  if (kilos < 100 || forceKilos) {
    return `${formatNumber(roundNumberToTwo(kilos), true)} kg`;
  }

  return `${formatNumber(roundNumberToTwo(kilos / 1000), forceDecimals || kilos < 1000)} t`;
};

const formatWeightWithSeparateUnit = (
  kilos: number,
  forceDecimals?: boolean,
  forceKilos?: boolean,
): { weight: string; unit: 'tonnes' | 'kilograms' } => {
  if (kilos < 1000 || forceKilos) {
    return { weight: formatNumber(roundNumberToTwo(kilos), true), unit: 'kilograms' };
  }

  return { weight: formatNumber(roundNumberToTwo(kilos / 1000), forceDecimals || kilos < 1000), unit: 'tonnes' };
};

const formatWeight = (kilos: number): number => {
  if (kilos < 1000) {
    return roundNumberToTwo(kilos);
  }

  if (kilos < 1000 * 1000) {
    return roundNumberToTwo(kilos / 1000);
  }

  return Math.round(kilos / 1000);
};

// Maciek: this is a clever trick I found on stack overflow. It will correct floating point errors nicely. Has worked for any weird combination I threw at it
// @ts-ignore
const fixFloat = (val: number): number => Number(parseFloat(val).toPrecision(15));

const convertKilosToTonnes = (kilos: number) => {
  return new Decimal(kilos).div(1000).toNumber();
};

const convertGramsToTonnes = (grams: number) => {
  return new Decimal(grams).div(1000000).toNumber();
};

const getPercentage = (percentageAmount: number, totalAmount: number): number => {
  if (isNaN(percentageAmount) || isNaN(totalAmount)) {
    return 0;
  }

  if (!percentageAmount || !totalAmount) {
    return 0;
  }

  return (percentageAmount / totalAmount) * 100;
};

const formatDecimal = (number: number, symbolsAfterDecimal = 3): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: symbolsAfterDecimal,
    useGrouping: false,
  });

  return formatter.format(number);
};

export {
  convertGramsToTonnes,
  convertKilosToTonnes,
  fixFloat,
  formatDecimal,
  formatNumber,
  formatWeight,
  formatWeightWithSeparateUnit,
  formatWeightWithUnit,
  getPercentage,
  roundNumberToTwo,
  roundNumberWithAbbreviation,
};
