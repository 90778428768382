import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createAllUsersApi from '@api/allUsers';
import { AppThunk, RootState } from '@store';
import { appContextSelector } from '@store/app/appSlice';

interface State {
  loading: boolean;
  items: (CustomerUser | FakeUser)[];
  roles?: Roles;
}

const initialState: State = {
  loading: false,
  items: [],
};

const slice = createSlice({
  name: 'allUsers',
  initialState,
  reducers: {
    setAllUsers: (state: State, action: PayloadAction<(CustomerUser | FakeUser)[]>): State => {
      state.items = action.payload;

      return state;
    },
    setRoles: (state: State, action: PayloadAction<Roles>): State => {
      state.roles = action.payload;

      return state;
    },
    setLoading: (state: State, action: PayloadAction<boolean>): State => {
      state.loading = action.payload;

      return state;
    },
  },
});

const { setAllUsers, setLoading, setRoles } = slice.actions;

export default slice.reducer;

export const getAllUsers =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const allUsersApi = createAllUsersApi(context);

    if (context === 'connect') {
      return;
    }

    dispatch(setLoading(true));

    try {
      const users = await allUsersApi.getAllUsers(currentAccount?.customerId ?? '');

      dispatch(setLoading(false));
      dispatch(setAllUsers(users));
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      dispatch(setLoading(false));
    }
  };

export const addFakeUsers =
  (users: FakeUser[]): AppThunk =>
  (dispatch, getState): void => {
    const { items } = getState().allUsers;

    const newUsers = users.filter(user => !items.find(item => item.userId === user.userId));

    dispatch(setAllUsers([...newUsers, ...items]));
  };

export const getRoles =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const allUsersApi = createAllUsersApi(context);

    dispatch(setLoading(true));

    try {
      let roles;
      if (context === 'connect') {
        roles = await allUsersApi.getRoles(currentAccount?.partnershipId ?? '');
      } else {
        roles = await allUsersApi.getRoles(currentAccount?.customerId ?? '');
      }

      dispatch(setLoading(false));
      dispatch(setRoles(roles));
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      dispatch(setLoading(false));
    }
  };

export const sendInvite =
  (invitePayload: InvitePayload, onSuccess: CallableFunction, onFailure: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const { roles } = getState().allUsers;
    const context = appContextSelector(getState());
    const allUsersApi = createAllUsersApi(context);

    dispatch(setLoading(true));

    const { email, customerRoleId } = invitePayload;

    const payload = {
      profile: {
        name: '',
        preferredName: '',
      },
      email,
      customerRoleId,
    };

    let data;

    try {
      if (context === 'connect') {
        data = await allUsersApi.createUserInvite(currentAccount?.partnershipId ?? '', payload);
      } else {
        data = await allUsersApi.createUserInvite(currentAccount?.customerId ?? '', payload);
      }

      dispatch(
        addFakeUsers([
          {
            userAccount: { email },
            role: { id: customerRoleId, name: roles?.roles.find(role => role.id === customerRoleId)?.name ?? '' },
            status: data.status,
            userId: data.userId,
          },
        ]),
      );
      dispatch(setLoading(false));
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      dispatch(setLoading(false));
      onFailure();
    }
  };

export const updateUser =
  (payload: UserPayload, userId: string, onSuccess: CallableFunction, onFailure: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const allUsersApi = createAllUsersApi(context);

    dispatch(setLoading(true));
    try {
      if (context === 'connect') {
        await allUsersApi.updateUser(currentAccount?.partnershipId ?? '', payload, userId);
      } else {
        await allUsersApi.updateUser(currentAccount?.customerId ?? '', payload, userId);
      }

      onSuccess();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      dispatch(setLoading(false));
      onFailure();
    }
  };

export const deleteUser =
  (userId: string, onSuccess: CallableFunction, onError: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const { items } = getState().allUsers;
    const context = appContextSelector(getState());
    const allUsersApi = createAllUsersApi(context);

    dispatch(setLoading(true));
    try {
      if (context === 'connect') {
        await allUsersApi.deleteUser(currentAccount?.partnershipId ?? '', userId);
      } else {
        await allUsersApi.deleteUser(currentAccount?.customerId ?? '', userId);
      }

      dispatch(setAllUsers(items.filter(item => item.userId !== userId)));

      dispatch(setLoading(false));
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      dispatch(setLoading(false));
      onError();
    }
  };

export const sendInviteBatch =
  (invites: InvitePayload[], onSuccess: CallableFunction, onFailure: CallableFunction, onFinish: CallableFunction): AppThunk =>
  async (dispatch): Promise<void> => {
    invites.forEach(invite => {
      dispatch(sendInvite(invite, onSuccess, onFailure));
    });

    onFinish();
  };

export const allRolesSelector = (state: RootState): Role[] | undefined => state.allUsers.roles?.roles;
