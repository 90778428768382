import { defineStyleConfig } from '@chakra-ui/react';

const Tabs = defineStyleConfig({
  variants: {
    line: {
      tab: {
        color: 'secondary.500',
        fontWeight: 500,
        _selected: {
          color: 'secondary.700',
          borderColor: 'primary.400',
        },
      },
    },
  },
});

export default Tabs;
