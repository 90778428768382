import { JSX } from 'react';
import { ChartLine, Cloud, Cube, Files, Gavel, Leaf, Question, Receipt, Slideshow } from '@icons';

export const mapToReactIcon = (iconName: string): JSX.Element => {
  switch (iconName) {
    case 'dashboard':
      return <Slideshow weight='bold' />;
    case 'emissions':
    case 'addressEmissions':
      return <Cloud weight='bold' />;
    case 'orders':
      return <Receipt weight='bold' />;
    case 'analytics':
      return <ChartLine weight='bold' />;
    case 'projects':
      return <Leaf weight='bold' />;
    case 'faq':
      return <Question weight='bold' />;
    case 'extras':
      return <Cube weight='bold' />;
    case 'licenses':
      return <Gavel weight='bold' />;
    case 'files':
      return <Files weight='bold' />;
    default:
      return <></>;
  }
};
