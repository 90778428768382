import { usePartnershipSettings } from '@api/partnership/queries';

const kebabize = (str: string) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

const CUSTOM_FONT_FAMILY = import.meta.env.REACT_APP_FONT_FAMILY;
const customFonts = {
  airnz: "'airnz', 'Helvetica Neue', Helvetica, Arial, sans-serif",
};

export const usePartnershipStylesOverride = () => {
  const { data } = usePartnershipSettings();
  const colors = data?.colors ?? {};
  const baseFontSize = data?.styles?.fontSize ?? 16;
  const buttonRadius = data?.styles?.buttonBorderRadius;
  let fontFamily: string | undefined;
  if (CUSTOM_FONT_FAMILY) {
    fontFamily = customFonts[CUSTOM_FONT_FAMILY];
  }

  const cssVars = [];
  for (const key of Object.keys(colors) as Array<keyof typeof colors>) {
    const value = colors[key];
    if (!value) {
      continue;
    }
    cssVars.push(`--${kebabize(key)}: ${value.startsWith('#') ? value : `#${value}`};`);
  }

  if (buttonRadius) {
    cssVars.push('--chooose-ui-radii-sm', `${buttonRadius}px`);
  }

  return {
    baseFontSize: baseFontSize ? `${baseFontSize}px` : undefined,
    fontFamily,
    varsOverrides: cssVars.join('\n'),
  };
};
