import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '@styles';

interface UseBreakpoint {
  isSmallMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isMobilePortrait: boolean;
  isMobileLandscape: boolean;
  isTabletAndSmallDesktop: boolean;
  isSmallDesktop: boolean;
  isSmallDesktopAndDown: boolean;
  isDesktop: boolean;
  isMediumDesktop: boolean;
  isBigDesktop: boolean;
  isSmallDesktopAndAbove: boolean;
  isExtraLargeDesktop: boolean;
}

export const useBreakpoint = (): UseBreakpoint => {
  const isSmallMobile = useMediaQuery({ maxWidth: parseInt(breakpoints.sm, 10) - 1 });
  const isMobile = useMediaQuery({ maxWidth: parseInt(breakpoints.md, 10) - 1 });
  const isTablet = useMediaQuery({ maxWidth: parseInt(breakpoints.lg, 10) - 1 });
  const isMobilePortrait = useMediaQuery({ maxWidth: parseInt(breakpoints.md, 10) - 1, orientation: 'portrait' });
  const isMobileLandscape = useMediaQuery({ maxWidth: parseInt(breakpoints.md, 10) - 1, orientation: 'landscape' });
  const isTabletAndSmallDesktop = useMediaQuery({ minWidth: parseInt(breakpoints.md, 10), maxWidth: parseInt(breakpoints.xl, 10) - 1 });
  const isSmallDesktopAndDown = useMediaQuery({ maxWidth: parseInt(breakpoints.lg, 10) - 1 });
  const isSmallDesktop = useMediaQuery({ minWidth: parseInt(breakpoints.lg, 10), maxWidth: parseInt(breakpoints.xl, 10) - 1 });
  const isSmallDesktopAndAbove = useMediaQuery({ minWidth: parseInt(breakpoints.lg, 10) });
  const isDesktop = useMediaQuery({ minWidth: parseInt(breakpoints.xl, 10) });
  const isMediumDesktop = useMediaQuery({ minWidth: parseInt(breakpoints.xxl, 10) });
  const isBigDesktop = useMediaQuery({ minWidth: parseInt(breakpoints.xxxl, 10) });
  const isExtraLargeDesktop = useMediaQuery({ minWidth: parseInt(breakpoints['4xl'], 10) });

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isMobilePortrait,
    isMobileLandscape,
    isTabletAndSmallDesktop,
    isSmallDesktop,
    isSmallDesktopAndDown,
    isDesktop,
    isMediumDesktop,
    isBigDesktop,
    isSmallDesktopAndAbove,
    isExtraLargeDesktop,
  };
};
