import client from './client';

export const getTermsAndConditions = async (pageName: string): Promise<SanityTermsAndConditions> => {
  const query = `*[slug.current=="${pageName}"][0]{
    title,
    _updatedAt,
    content,
    supply1,
    supply2,
    supply3,
    canAlwaysChangeSupply,
    hasToInformAboutChangeOfSupply,
    cannotChangeSupplyWithoutPartnersConsent,
    refundNoText,
    refundYesText,
  }`;
  const data = await client.fetch<SanityTermsAndConditions>(query);

  return data;
};

export const getPartnershipTerms = async (partnershipId: string): Promise<SanityTermsAndConditions[]> => {
  const query = `*[partnershipId == '${partnershipId}']{
    title,
    slug { 
      current
    },
    _updatedAt,
    override,
    content
  }`;

  const data = await client.fetch<SanityTermsAndConditions[]>(query);

  return data;
};
