import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'control', 'label']);
const Radio = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      cursor: 'pointer',
    },
    control: {
      border: '2px solid',
      borderColor: 'gray.300',
      '&[data-checked]': {
        borderColor: 'primary.400',
        bg: 'primary.400',
        '&:hover': {
          bg: 'primary.400',
          borderColor: 'primary.400',
        },
      },
    },
    label: {
      ml: '14px',
      color: 'secondary.700',
      fontFamily: 'body',
      fontSize: 'md',
    },
  },
  defaultProps: {
    size: 'lg',
  },
});

export default Radio;
