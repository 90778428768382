import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';

export interface PartnerSettings extends SettingsResponse {
  logoUrl: string;
  lightLogoUrl: string;
  faviconUrl: string;
  disableSignupLink?: boolean;
}

interface State {
  settings: PartnerSettings | null;
  stashedSettings: PartnerSettings | null;
}

const initialState: State = {
  settings: null,
  stashedSettings: null,
};

const slice = createSlice({
  name: 'partnerSettings',
  initialState,
  reducers: {
    setPartnerSettings: (state: State, action: PayloadAction<PartnerSettings>): State => {
      state.settings = action.payload;

      return state;
    },
  },
});

const { setPartnerSettings } = slice.actions;

export { setPartnerSettings };

export default slice.reducer;

export const partnerSettingsSelector = createSelector(
  (state: RootState) => state.partnerSettings,
  state => {
    return state.settings;
  },
);
