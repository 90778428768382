import { defineStyleConfig } from '@chakra-ui/react';

const Tag = defineStyleConfig({
  variants: {
    subtle: {
      container: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '15px',
        padding: '4px 12px',
      },
      label: {
        fontWeight: 400,
        textTransform: 'capitalize',
      },
      svg: {
        color: 'primary.700',
      },
    },
  },
  sizes: {
    lg: {
      svg: {
        height: '16px',
        width: '16px',
      },
    },
  },
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'primary',
    size: 'lg',
  },
});

export default Tag;
