import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@store';
import { showToast } from '@store/app/toasterSlice';
import { userCurrencySelector } from '@store/user/userSlice';
import { fixFloat } from '@utils/numberFormatter';

interface VirtualPortfolioExtended extends VirtualPortfolio {
  price?: number;
  customerPrice?: number;
  pricePerTonne?: number;
  title?: string;
  currency?: string;
  totalTonnesCo2Available?: number;
}

interface PortfolioPrice {
  portfolioId: string;
  pricePerTonne: number;
}

interface State {
  virtualPortfolios: VirtualPortfolioExtended[];
  portfoliosPrices: PortfolioPrice[];
  isFetching: boolean;
}

const initialState: State = {
  virtualPortfolios: [],
  portfoliosPrices: [],
  isFetching: false,
};

const slice = createSlice({
  name: 'portfolioSelect',
  initialState,
  reducers: {
    setVirtualPortfolios: (state: State, action: PayloadAction<VirtualPortfolioExtended[]>) => {
      state.virtualPortfolios = action.payload;

      return state;
    },
    setWeightForPortfolio: (state: State, action: PayloadAction<{ id: string; tonnes: number }>) => {
      const portfolioToChange = state.virtualPortfolios.find(p => p.id === action.payload.id);
      if (portfolioToChange) {
        portfolioToChange.weight = action.payload.tonnes;
      }

      return state;
    },
    setPriceForPortfolio: (state: State, action: PayloadAction<{ id: string; price: number }>) => {
      const portfolioToChange = state.virtualPortfolios.find(p => p.id === action.payload.id);
      if (portfolioToChange) {
        portfolioToChange.price = action.payload.price;
      }

      return state;
    },
    resetPricesForPortfolios: (state: State) => {
      const mappedPortfolios = state.virtualPortfolios.map(portfolio => ({
        ...portfolio,
        customerPrice: undefined,
        price: undefined,
        weight: 0,
      }));
      state.virtualPortfolios = mappedPortfolios;

      return state;
    },
    resetCustomerPricesForPortfolios: (state: State) => {
      const mappedPortfolios = state.virtualPortfolios.map(portfolio => ({
        ...portfolio,
        customerPrice: undefined,
      }));
      state.virtualPortfolios = mappedPortfolios;

      return state;
    },
    setIsFetching: (state: State, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;

      return state;
    },
    setPortfoliosPricePerTonne: (state: State, action: PayloadAction<PortfolioPrice[]>) => {
      state.portfoliosPrices = action.payload;
    },
  },
});

const {
  setVirtualPortfolios,
  setWeightForPortfolio,
  setPriceForPortfolio,
  setIsFetching,
  resetPricesForPortfolios,
  resetCustomerPricesForPortfolios,
  setPortfoliosPricePerTonne,
} = slice.actions;

export {
  resetCustomerPricesForPortfolios,
  resetPricesForPortfolios,
  setIsFetching,
  setPortfoliosPricePerTonne,
  setPriceForPortfolio,
  setWeightForPortfolio,
};

export default slice.reducer;

export const resetPortfolioState =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { allPortfolios } = getState().projects;
    const currency = userCurrencySelector(getState());

    dispatch(setIsFetching(true));

    try {
      const shareData =
        allPortfolios?.map(p => {
          return {
            id: p.portfolioData.id ?? '',
            title: p.portfolioData.title ?? '',
            weight: 0,
            price: 0,
            pricePerTonne: 0,
            currency,
            totalTonnesCo2Available: p.portfolioData.totalTonnesCo2Available,
          };
        }) ?? [];

      dispatch(setVirtualPortfolios(shareData));
      dispatch(setIsFetching(false));
    } catch (err) {
      dispatch(setIsFetching(false));
    }
  };

export const setTonnesForPortfolio =
  (id: string, specifiedWeight: number, orderTotal: number): AppThunk =>
  (dispatch, getState): void => {
    const { virtualPortfolios } = getState().offsetPortfolioSelect;
    const { allPortfolios } = getState().projects;
    const adjustedSpecifiedWeight = fixFloat(specifiedWeight);
    const adjustedOrderTotal = fixFloat(orderTotal);
    const remainingTotal = fixFloat(virtualPortfolios.filter(p => p.id !== id).reduce((prev, next) => prev + next.weight, 0));
    const targetPortfolio = allPortfolios?.find(p => p.portfolioData.id === id);
    const adjustedTargetPortfolioSupply = targetPortfolio?.portfolioData.totalTonnesCo2Available ?? 0;
    const tonnes = Math.min(adjustedTargetPortfolioSupply, adjustedSpecifiedWeight);
    const amountFromRounded = Number(adjustedOrderTotal - (tonnes + remainingTotal));
    const isSpareAmountFromRounded = Math.abs(amountFromRounded / adjustedOrderTotal) < 0.00005;
    // if there is enough tonnage in the order, just set it and forget it; it also checks rounded for values displayed in input as a percentage of total amount.
    if (tonnes <= adjustedOrderTotal - remainingTotal && !isSpareAmountFromRounded) {
      dispatch(setWeightForPortfolio({ id, tonnes }));
      // if the user enters more tonnes than the entire order, then just set this portfolio to max and the rest to 0
    } else if (tonnes > adjustedOrderTotal) {
      for (const p of virtualPortfolios) {
        if (p.id === id) {
          dispatch(setWeightForPortfolio({ id, tonnes: adjustedOrderTotal }));
        } else {
          dispatch(setWeightForPortfolio({ id: p.id, tonnes: 0 }));
        }
      }
      // else - meaning if the user enters more than the remaining tonnage from the order,
      // but it's less than the whole order, then the rest is distributed equally
      // besides it balances rounded
    } else {
      const otherSelectedPortfolios = virtualPortfolios.filter(portfolio => portfolio.id !== id && portfolio.weight > 0);
      const otherSelectedPortfoliosNumber = otherSelectedPortfolios.length;
      const amountForDistribution = fixFloat(adjustedOrderTotal - tonnes);
      if (otherSelectedPortfoliosNumber) {
        dispatch(setWeightForPortfolio({ id, tonnes: 0 }));
        dispatch(distributeEqually('', amountForDistribution, false));
      }

      dispatch(setWeightForPortfolio({ id, tonnes: isSpareAmountFromRounded ? tonnes + amountFromRounded : tonnes }));
    }
  };

const distributeOrderTonnage =
  (weight: number): AppThunk =>
  (dispatch, getState): void => {
    const { virtualPortfolios } = getState().offsetPortfolioSelect;
    const { allPortfolios } = getState().projects;
    const firstPortfolio = allPortfolios && allPortfolios?.length ? allPortfolios[0] : undefined;

    const adjustedFirstPortfolioSupply = firstPortfolio?.portfolioData.totalTonnesCo2Available ?? 0;

    if (adjustedFirstPortfolioSupply >= weight) {
      dispatch(setWeightForPortfolio({ id: firstPortfolio?.portfolioData.id ?? '', tonnes: weight }));

      return;
    }

    dispatch(
      setWeightForPortfolio({
        id: firstPortfolio?.portfolioData.id ?? '',
        tonnes: adjustedFirstPortfolioSupply,
      }),
    );

    let missingAmount = weight - adjustedFirstPortfolioSupply;

    while (missingAmount > 0) {
      for (const vp of virtualPortfolios) {
        if (vp.id === firstPortfolio?.portfolioData.id) {
          continue;
        }
        const adjustedAvailableSupply = allPortfolios?.find(p => p.portfolioData.id === vp.id)?.portfolioData.totalTonnesCo2Available ?? 0;

        if (adjustedAvailableSupply >= missingAmount) {
          dispatch(setWeightForPortfolio({ id: vp.id, tonnes: missingAmount }));
          missingAmount = 0;
        } else {
          missingAmount -= adjustedAvailableSupply;
          dispatch(setWeightForPortfolio({ id: vp.id, tonnes: adjustedAvailableSupply }));
        }
      }
    }
  };

export const initPortfolioState =
  (orderTotal: number): AppThunk =>
  (dispatch, getState) => {
    const mainPortfolio = getState().projects.portfolioData;
    const mainPortfolioId = mainPortfolio?.id;
    const supplyForMainPortfolio = mainPortfolio?.totalTonnesCo2Available ?? 0;
    const prevShareData = getState().offsetPortfolioSelect.virtualPortfolios;
    const { allPortfolios } = getState().projects;

    try {
      const isTotalSupplySufficient =
        (allPortfolios?.reduce((prev, next) => prev + next.portfolioData.totalTonnesCo2Available, 0) ?? 0) >= orderTotal;

      if (mainPortfolioId && supplyForMainPortfolio >= orderTotal) {
        const virtualPortfolios = prevShareData.map((item): VirtualPortfolioExtended => {
          return mainPortfolioId === item.id
            ? {
                ...item,
                weight: orderTotal,
              }
            : { ...item, weight: 0 };
        });

        dispatch(setVirtualPortfolios(virtualPortfolios));

        return;
      }

      const portfolioWithSufficientSupply = allPortfolios?.find(p => p.portfolioData.totalTonnesCo2Available >= orderTotal);

      if (portfolioWithSufficientSupply) {
        const virtualPortfolios = prevShareData.map((item): VirtualPortfolioExtended => {
          return portfolioWithSufficientSupply.portfolioData.id === item.id
            ? {
                ...item,
                weight: orderTotal,
              }
            : { ...item, weight: 0 };
        });
        dispatch(setVirtualPortfolios(virtualPortfolios));

        return;
      } else if (isTotalSupplySufficient) {
        dispatch(distributeOrderTonnage(orderTotal));

        return;
      }
      throw new Error('Insufficient supply');
    } catch (err) {
      dispatch(
        showToast({
          variant: 'error',
          titleI18nKey: 'offset:corporate.portfolios.errorTitle',
          descriptionI18nKey: 'offset:corporate.portfolios.errorMessage',
        }),
      );
    }
  };

export const distributeEqually =
  (
    portfolioId: string,
    totalAmount: number,
    removePortfolio = false,
    temporaryVirtualPortfolio: VirtualPortfolioExtended[] | null = null,
    offsetAmount?: number,
  ): AppThunk =>
  (dispatch, getState) => {
    const { virtualPortfolios } = getState().offsetPortfolioSelect;
    const selectedPortfolioHasWeight = virtualPortfolios.some(({ weight, id }) => weight > 0 && portfolioId === id);
    if (selectedPortfolioHasWeight && !removePortfolio) {
      return;
    }
    const currentVirtualPortfolios = temporaryVirtualPortfolio ?? virtualPortfolios;
    const selectedPortfolios = removePortfolio
      ? currentVirtualPortfolios.filter(({ weight, id }) => weight > 0 && portfolioId !== id)
      : currentVirtualPortfolios.filter(({ weight, id }) => weight > 0 || (weight === 0 && portfolioId === id));
    const selectedPortfoliosWithSufficientSupply = temporaryVirtualPortfolio
      ? selectedPortfolios.filter(portfolio => (portfolio.totalTonnesCo2Available ?? 0) > portfolio.weight)
      : selectedPortfolios;
    const totalOffsetAmount = offsetAmount ?? totalAmount;
    const weight = totalAmount / selectedPortfoliosWithSufficientSupply.length;
    const newVirtualPortfolios = currentVirtualPortfolios.map(portfolio =>
      !!portfolio.weight || portfolioId === portfolio.id
        ? removePortfolio && portfolioId === portfolio.id
          ? {
              ...portfolio,
              weight: 0,
            }
          : {
              ...portfolio,
              weight:
                (portfolio.totalTonnesCo2Available ?? 0) > (temporaryVirtualPortfolio ? portfolio.weight + weight : weight)
                  ? temporaryVirtualPortfolio
                    ? portfolio.weight + weight
                    : weight
                  : portfolio.totalTonnesCo2Available ?? 0,
            }
        : portfolio,
    );
    const totalAmountFromVirtualPortfolios = virtualPortfolios.reduce((prev, curr) => curr.weight + prev, 0);
    const totalAmountFromNewVirtualPortfolios = newVirtualPortfolios.reduce((prev, curr) => curr.weight + prev, 0);
    const updatedTotalAmount = totalOffsetAmount - totalAmountFromNewVirtualPortfolios;
    if (updatedTotalAmount === 0) {
      dispatch(setVirtualPortfolios(newVirtualPortfolios));
    } else if (selectedPortfoliosWithSufficientSupply.length === 1 || updatedTotalAmount <= 0.005) {
      const withAdditionalAmountPortfolioId =
        selectedPortfoliosWithSufficientSupply.length === 1
          ? selectedPortfoliosWithSufficientSupply[0].id
          : selectedPortfoliosWithSufficientSupply.find(
              portfolio => portfolio.weight + updatedTotalAmount <= (portfolio.totalTonnesCo2Available ?? 0),
            )?.id ?? '';
      const index = newVirtualPortfolios.findIndex(({ id }) => withAdditionalAmountPortfolioId === id);
      const chosenPortfolio = newVirtualPortfolios[index];
      if (index >= 0) {
        const updatedWeight = totalAmountFromVirtualPortfolios ? chosenPortfolio.weight + updatedTotalAmount : totalAmount;
        newVirtualPortfolios[index] = {
          ...chosenPortfolio,
          weight: (chosenPortfolio.totalTonnesCo2Available ?? 0) >= updatedWeight ? updatedWeight : chosenPortfolio.totalTonnesCo2Available ?? 0,
        };
      }
      dispatch(setVirtualPortfolios(newVirtualPortfolios));
    } else {
      selectedPortfoliosWithSufficientSupply.length
        ? dispatch(distributeEqually(portfolioId, updatedTotalAmount, removePortfolio, newVirtualPortfolios, totalOffsetAmount))
        : dispatch(setVirtualPortfolios(newVirtualPortfolios));
    }
  };

export const totalAllocatedSelector = createSelector(
  (state: RootState) => state.offsetPortfolioSelect,
  offsetState => {
    const { virtualPortfolios } = offsetState;

    return fixFloat(virtualPortfolios.reduce((prev, next) => prev + next.weight, 0));
  },
);

export const virtualPortfoliosWithPriceSelector = createSelector(
  (state: RootState) => state.offsetPortfolioSelect,

  offsetState => offsetState.virtualPortfolios ?? [],
);

export const virtualPortfoliosSelector = createSelector(
  (state: RootState) => state.offsetPortfolioSelect,
  offsetState => {
    const { virtualPortfolios } = offsetState;

    return virtualPortfolios
      .filter(p => p.weight > 0)
      .map(({ id: portfolioId, weight }) => ({
        portfolioId,
        weight,
      }));
  },
);

export const singlePortfolioSanityIdSelector = createSelector(
  (state: RootState) => state.offsetPortfolioSelect,
  offsetState => {
    const { virtualPortfolios } = offsetState;

    let mainPortfolioId: string | undefined;

    for (const p of virtualPortfolios) {
      if (p.weight > 0) {
        if (mainPortfolioId) {
          return undefined;
        }

        mainPortfolioId = p.id;
      }
    }

    return mainPortfolioId;
  },
);

export const isFetchingPricesSelector = createSelector(
  (state: RootState) => state.offsetPortfolioSelect,

  offsetState => offsetState.isFetching,
);
