import { getI18n } from 'react-i18next';
import {
  AuthenticationResult,
  AuthError,
  BrowserCacheLocation,
  CacheLookupPolicy,
  Configuration,
  EventError,
  EventMessage,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { appInsights } from '@lib/appInsights/appInsights';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { getAccountType, getEnvironment, getPartnerSite } from '@utils/tags';

import { useAuthHandlerStore } from './authHandlerStore';
import * as errorCodes from './errorCodes';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msEdge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msEdge > 0;
const isFirefox = firefox > 0;
const isDevelopment = import.meta.env.DEV;

const CLIENT_ID = import.meta.env.REACT_APP_AZURE_APPLICATION_ID;
const LOGIN_INSTANCE = import.meta.env.REACT_APP_AZURE_LOGIN_INSTANCE;
// TODO(@pkgacek): Fix typo in the translation
const TENANT = import.meta.env.REACT_APP_AZURE_TENAT;
const DEFAULT_SIGN_POLICY = import.meta.env.REACT_APP_AZURE_SIGN_POLICY;
const INVITE_SIGNUP_SIGN_IN_POLICY = import.meta.env.REACT_APP_INVITE_SIGN_UP_SIGN_IN ?? '';
// TODO(@pkgacek): Fix typo in the translation
const GUEST_SIGN_UP = import.meta.env.REACT_APP_GUEST_SIGNIN_SIGNUP_WECHOOOSE ?? '';
const SIGN_UP_POLICY = import.meta.env.REACT_APP_SIGN_UP_POLICY ?? '';
const FORGOT_PASSWORD_POLICY = import.meta.env.REACT_APP_FORGOT_PASSWORD_POLICY ?? '';
const SCOPES = (import.meta.env.REACT_APP_AZURE_SCOPES ?? '').split(' ');
const IS_SESSION_RENEWAL_DISABLED = import.meta.env.REACT_APP_DISABLE_SESSION_RENEWAL === 'true';
const partnershipId = __PARTNERSHIP_ID__;
const disableSignupLink = __DISABLE_SIGNUP_LINK__;

const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: `${LOGIN_INSTANCE}${TENANT}${DEFAULT_SIGN_POLICY}`,
    knownAuthorities: [`${LOGIN_INSTANCE}${TENANT}${DEFAULT_SIGN_POLICY}`],
    navigateToLoginRequestUrl: true,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false,
    iframeHashTimeout: 10000,
    loadFrameTimeout: 10000,
    loggerOptions: {
      piiLoggingEnabled: false,
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii || !isDevelopment) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);

            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);

            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);

            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);

            return;
          default:
            return;
        }
      },
    },
  },
};

export const msalApp = new PublicClientApplication(msalConfig);

msalApp.initialize().then(() => {
  const allAccounts = msalApp.getAllAccounts();
  if (allAccounts?.length > 0) {
    msalApp.setActiveAccount(allAccounts[0]);
  }
});

// This will update account state if a user signs in from another tab or window
msalApp.enableAccountStorageEvents();

export const isAuthenticationResult = (payload: EventMessage['payload']): payload is AuthenticationResult => {
  return payload !== undefined;
};

const isAuthError = (error: unknown): error is AuthError => {
  return error instanceof AuthError;
};

export const isInteractionRequiredError = (error: unknown): error is InteractionRequiredAuthError => {
  return error instanceof InteractionRequiredAuthError;
};

export const isRefreshRequiredError = (error: unknown) => {
  if (!isAuthError(error)) {
    return false;
  }

  return error.errorCode === 'invalid_grant' || error.errorCode === 'token_refresh_required';
};

export const isErrorFromCode = (error: EventError, code: keyof typeof errorCodes) => {
  if (!isAuthError(error)) {
    return false;
  }

  const errorCode = errorCodes[code];

  return error.message.includes(errorCode) || error.errorMessage.includes(errorCode);
};

export const defaultAuthError = new Error('PortalAuthError: something went wrong');

const commonQueryParams = {
  lng: encodeURIComponent(`${getI18n()?.language || 'en'}`),
  customerCompany: import.meta.env.REACT_APP_PARTNER_NAME ?? '',
  partnerSite: getPartnerSite(),
  accountType: getAccountType(getPartnerSite()),
  environment: getEnvironment(),
  weChoooseSignupLink: encodeURIComponent(`${window.origin}/sign-up/chooose`),
  partnershipId: encodeURIComponent(`${partnershipId}`),
  disableSignup: encodeURIComponent(`${disableSignupLink}`),
};

export const signInRequest: RedirectRequest = {
  authority: `${LOGIN_INSTANCE}${TENANT}${DEFAULT_SIGN_POLICY}`,
  scopes: SCOPES,
  extraQueryParameters: commonQueryParams,
};

const silentRequest: SilentRequest = {
  authority: `${LOGIN_INSTANCE}${TENANT}${DEFAULT_SIGN_POLICY}`,
  scopes: SCOPES,
};

export const resetPasswordRequest: RedirectRequest = {
  authority: `${LOGIN_INSTANCE}${TENANT}${FORGOT_PASSWORD_POLICY}`,
  scopes: SCOPES,
  extraQueryParameters: commonQueryParams,
};

export const signUpRequest: RedirectRequest = {
  authority: `${LOGIN_INSTANCE}${TENANT}${SIGN_UP_POLICY}`,
  scopes: SCOPES,
  redirectStartPage: `${window.origin}/setup/chooose`,
  extraQueryParameters: commonQueryParams,
};

export const inviteSignUpRequest: RedirectRequest = {
  authority: `${LOGIN_INSTANCE}${TENANT}${INVITE_SIGNUP_SIGN_IN_POLICY}`,
  scopes: SCOPES,
  extraQueryParameters: commonQueryParams,
};

export const signUpGuestRequest: RedirectRequest = {
  authority: `${LOGIN_INSTANCE}${TENANT}${GUEST_SIGN_UP}`,
  scopes: SCOPES,
  extraQueryParameters: commonQueryParams,
};

export const getAccessToken = async (force = false) => {
  try {
    const response = await msalApp.acquireTokenSilent({
      ...silentRequest,
      cacheLookupPolicy: getCacheLookupPolicy(),
      forceRefresh: force,
    });

    return response.accessToken;
  } catch (err) {
    if (isAuthError(err)) {
      handleAuthError(err, {
        tags: {
          location: 'getAccessToken',
        },
      });

      return;
    }
  }
};

const getCacheLookupPolicy = () => {
  let cacheLookupPolicy: CacheLookupPolicy = CacheLookupPolicy.Default;
  if (IS_SESSION_RENEWAL_DISABLED) {
    cacheLookupPolicy = CacheLookupPolicy.AccessTokenAndRefreshToken;
  }
  if (window.__skipCache__) {
    cacheLookupPolicy = CacheLookupPolicy.Skip;
  }

  return cacheLookupPolicy;
};

export const handleAuthError = (error: EventError, hint?: ICustomProperties) => {
  const setAuthHandler = useAuthHandlerStore.getState().setAuthHandler;
  if (isErrorFromCode(error, 'FORGOT_PASSWORD_ERROR_CODE')) {
    setAuthHandler({
      type: 'LoginRedirect',
      request: resetPasswordRequest,
    });

    return true;
  }

  if (isInteractionRequiredError(error) || isErrorFromCode(error, 'MONITOR_WINDOW_ERROR_CODE') || isRefreshRequiredError(error)) {
    setAuthHandler({
      type: 'AcquireTokenRedirect',
      request: signInRequest,
    });

    return true;
  }

  if (isErrorFromCode(error, 'USER_NOT_EXIST_ERROR_CODE')) {
    const request = {
      ...signInRequest,
      extraQueryParameters: { ...signInRequest.extraQueryParameters, errorCode: errorCodes.USER_NOT_EXIST_ERROR_CODE },
    };
    setAuthHandler({
      type: 'LoginRedirect',
      request,
    });

    return true;
  }

  appInsights?.trackException({ exception: error ?? defaultAuthError, properties: { hint } });

  return false;
};
