import Accordion from './accordion';
import Avatar from './avatar';
import Button from './button';
import Checkbox from './checkbox';
import FormError from './formError';
import FormLabel from './formLabel';
import Input from './input';
import Modal from './modal';
import Progress from './progress';
import Radio from './radio';
import Select from './select';
import Switch from './switch';
import Tabs from './tabs';
import Tag from './tag';
import Text from './text';
import Tooltip from './tooltip';

const components = (customButtonBorderRadius?: number) => ({
  Button: Button(customButtonBorderRadius),
  Input,
  Accordion,
  Tooltip,
  Select,
  Checkbox,
  Radio,
  Switch,
  Tabs,
  FormLabel,
  FormError,
  Modal,
  Avatar,
  Tag,
  Progress,
  Text,
});

export default components;
