import { useCustomer } from '@api/customer/queries';
import { usePartnership } from '@api/partnership/queries';
import { useCurrentAccount } from '@api/user/queries';
import { isPartnerAccount } from '@utils/contextChecker';

export const usePortalItemFees = () => {
  const { account } = useCurrentAccount();
  const { data: customer } = useCustomer();
  const { data: partner } = usePartnership();

  if (isPartnerAccount(account)) {
    return partner?.settings.customerFees?.itemFee;
  }

  return customer?.fees.itemFee;
};
