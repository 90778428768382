import { fontSizes } from './fonts';

import { mq } from '@styles';

export const appMaxWidth = 1320;
const appDesktopPadding = 24;
const appMobilePadding = 12;

const whiteBoxContent = {
  borderRadius: 'base',
  boxShadow: 'whiteBox',
  p: '10px 12px',
  [mq.md]: {
    p: '20px 24px',
  },
};

const layerStyles = (customFontSize?: number) => ({
  heading: {
    color: 'secondary.800',
  },
  body: {
    color: 'secondary.700',
  },
  appWidth: {
    width: '100%',
    paddingLeft: `${appMobilePadding}px`,
    paddingRight: `${appMobilePadding}px`,
    maxWidth: `${appMaxWidth + 2 * appMobilePadding}px`,
    [mq.sm]: {
      paddingLeft: `${appDesktopPadding}px`,
      paddingRight: `${appDesktopPadding}px`,
      maxWidth: `${appMaxWidth + 2 * appDesktopPadding}px`,
    },
  },
  navWidth: {
    width: '100%',
    maxWidth: '2000px',
    paddingX: `${appMobilePadding}px`,
    [mq.sm]: {
      paddingX: '8px',
    },
  },
  header: {
    position: 'relative',
    width: 'auto',
    height: '64px',
    bg: 'white',
    [mq.md]: {
      height: '80px',
      zIndex: 999,
    },
  },
  mainBox: {
    paddingTop: '78px',
    [mq.md]: {
      paddingTop: '116px',
    },
    [mq.lg]: {
      paddingTop: '0px',
      marginTop: '4rem',
    },
  },
  mainBoxMinimumPadding: {
    paddingTop: '50px',
    [mq.md]: {
      paddingTop: '80px',
    },
    [mq.lg]: {
      paddingTop: '0px',
      marginTop: '36px',
    },
  },
  whiteBox: {
    borderRadius: 'base',
    width: '100%',
    transform: 'translate3d(0, 0, 0)',
    willChange: 'opacity, transform',
  },
  whiteBoxContent,
  whiteBoxContentWithoutMobilePadding: {
    ...whiteBoxContent,
    padding: '0px',
    [mq.md]: {
      padding: '0px',
    },
    [mq.lg]: {
      padding: '24px',
    },
  },
  offsetTab: {
    fontSize: fontSizes(customFontSize).mdMax,
    p: '10px 0',
    color: 'secondary.500',
    whiteSpace: 'nowrap',
    borderBottom: '3px solid transparent',
    maxH: '53px',
    '& + &': {
      marginLeft: '35px',
    },
    _selected: {
      color: 'secondary.800',
      borderColor: 'other.CTA',
      fontWeight: 500,
    },
    [mq.md]: {
      fontSize: fontSizes(customFontSize).xl,
    },
  },
  comingSoonWrapper: {
    '& svg': {
      display: 'none',
    },
  },
  infoBox: {
    bg: 'primary.100',
    _after: {
      borderRight: `6px solid`,
      borderColor: 'primary.100',
    },
    '& path': {
      fill: 'primary.600',
    },
  },
  blueBubble: {
    bg: 'radial-gradient(81.43% 81.43% at 32.86% 21.43%, #FFFFFF 0%, rgba(115, 131, 165, 0) 67.19%, #5A6473 92.71%), #092959',
    backgroundBlendMode: 'overlay, normal',
    boxShadow: 'blueBubble',
  },
  lightBubble: {
    bg: 'radial-gradient(71.43% 71.43% at 35.71% 26.19%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #C5DEFB;',
    backgroundBlendMode: 'overlay, normal',
  },
  whiteBubble: {
    bg: 'radial-gradient(71.43% 71.43% at 35.71% 26.19%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #D3D8E2',
    backgroundBlendMode: 'overlay, normal',
  },
  profileSwitcherTabs: {
    borderBottom: '1px solid',
    borderBottomColor: 'secondary.300',
    marginBottom: '25px',
    padding: 0,
  },
  profileSwitcherYourAccountText: {
    color: 'secondary.800',
    fontFamily: 'heading',
    fontWeight: 400,
  },
  logOutButton: {
    '&:hover': {
      background: 'secondary.200',
    },
    '&:focus': {
      background: 'secondary.200',
    },

    '& > span': {
      position: 'absolute',
      left: '15px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  dateSegmentWrapper: {
    fontSize: fontSizes(customFontSize).lgMin,
    '&:first-of-type': {
      fontSize: fontSizes(customFontSize).lg,
    },
  },
  settingsWrapper: {
    borderRadius: 'base',
    mt: '-10px',
    bg: 'transparent',
    [mq.md]: {
      p: '44px 48px',
      bg: 'white',
      boxShadow: 'whiteBox',
      mt: 0,
    },
    [mq.lg]: {
      mt: '35px',
    },
  },
  settingsDesktopMenu: {
    w: '212px',
    pr: '20px',
    mt: '48px',
  },
  settingsWrapperMobile: {
    borderRadius: 'base',
    boxShadow: 'whiteBox',
    bg: 'white',
    p: '24px 12px',
    [mq.md]: {
      borderRadius: 0,
      bg: 'transparent',
      boxShadow: 'none',
      p: 0,
    },
  },
  pageTitle: {
    color: 'secondary.800',
    fontWeight: 'bold',
    fontSize: fontSizes(customFontSize).lgMin,
    lineHeight: fontSizes(customFontSize).lgMax,
    m: '0 0 18px 0',
    textTransform: 'uppercase',
    [mq.sm]: {
      fontSize: fontSizes(customFontSize).lgMin,
      lineHeight: fontSizes(customFontSize).lgMax,
    },
    [mq.md]: {
      display: 'none',
    },
  },
  projects: {
    wrapper: {
      padding: '10px',
      justifyContent: 'space-between',
    },
    projectIconsWrapper: {
      top: '-25px',
      left: '0',
    },
    projectIcon: {
      marginRight: '3px',
      border: '2px solid white',
      borderRadius: '100%',
    },
    projectImage: {
      height: '165px',
      marginBottom: '10px',
    },
    projectTitle: {
      fontWeight: 500,
      fontSize: fontSizes(customFontSize).xl,
      color: 'secondary.800',
    },
    projectLocation: {
      fontWeight: 400,
      fontSize: fontSizes(customFontSize).md,
      color: 'secondary.600',
      marginBottom: '35px',
      marginTop: '5px',
    },
    projectHeadline: {
      color: 'secondary.800',
      fontWeight: 400,
      fontSize: fontSizes(customFontSize).md,
      paddingBottom: '10px',
    },
    projectDescriptionContainer: {
      color: 'secondary.700',
      paddingBottom: '30px',

      span: {
        fontWeight: 400,
        fontSize: fontSizes(customFontSize).sm,
      },
    },
    successMessageTitle: {
      fontSize: fontSizes(customFontSize)['2xl'],
      fontWeight: 400,
    },
    successMessageDescription: {
      fontSize: fontSizes(customFontSize).md,
      fontWeight: 400,
    },
    tileWrapper: {
      border: '1.5px solid',
      borderColor: 'transparent',
      padding: '10px',
      '&:hover': {
        borderColor: 'secondary.300',
        button: {
          opacity: 1,
        },
      },
    },
    projectInfoWrapper: {
      [mq.lg]: {
        maxWidth: 'calc(100% - 130px)',
      },
    },
    projectTileTitle: {
      fontSize: fontSizes(customFontSize).md,
      color: 'secondary.800',
      fontWeight: 400,
    },
    projectTileLocation: {
      fontSize: fontSizes(customFontSize).xs,
      color: 'secondary.600',
      fontWeight: 400,
    },
    downloadProjectButton: {
      opacity: 0,
    },
    seeProjectButton: {
      backgroundColor: 'primary.500',
      color: 'other.primaryBgText',
      opacity: 0,
    },
    SDGTitle: {
      fontWeight: 500,
    },
    SDGSubTitle: {
      fontSize: fontSizes(customFontSize).xs,
      fontWeight: 500,
      lineHeight: '130%',
      color: 'secondary.600',
      mt: '6px',
      mb: '20px',
    },
    certificateSectionTitle: {
      fontSize: fontSizes(customFontSize).lg,
      fontWeight: 400,
    },
    certificateCarouselOuterWrapper: {
      padding: '10px 20px 45px;',
      background: 'white',
    },
    projectBlurb: {
      wrapper: {
        backgroundColor: 'secondary.100',
        borderRadius: 'base',
      },
      descriptionTitle: {
        fontWeight: 400,
        fontSize: fontSizes(customFontSize).xl,
        color: 'secondary.800',
      },
      description: {
        fontSize: fontSizes(customFontSize).md,
        color: 'secondary.700',
        lineHeight: '22.4px',
        marginTop: '25px',
      },
      projectOwnerTitle: {
        fontSize: fontSizes(customFontSize).xs,
        fontWeight: 500,
        color: 'secondary.500',
        width: '30%',
        whiteSpace: 'nowrap',
      },
      projectOwnerLabel: {
        fontSize: fontSizes(customFontSize).md,
        color: 'secondary.700',
        maxWidth: '60%',
        textAlign: 'right',
      },
    },
    projectDescription: {
      sectionTitle: {
        fontSize: fontSizes(customFontSize).md,
        fontWeight: 400,
        marginBottom: 0,
        lineHeight: '18px',
      },
      descriptionText: {
        fontSize: fontSizes(customFontSize).md,
        lineHeight: '22.4px',
        marginTop: '10px',
      },
    },
    descriptionAccordion: {
      sectionTitle: {
        fontSize: fontSizes(customFontSize).md,
        fontWeight: 400,
        marginBottom: 0,
        lineHeight: '18px',
      },
      descriptionText: {
        fontSize: fontSizes(customFontSize).md,
        lineHeight: '22.4px',
        marginTop: '10px',
      },
    },
  },
  account: {
    billing: {
      header: {
        mb: ['20px', '20px', '25px'],
        fontSize: fontSizes(customFontSize).md,
        fontWeight: 400,
        fontFamily: 'heading',
      },
      infoSmall: {
        fontSize: fontSizes(customFontSize).xs,
        fontWeight: 400,
        color: 'secondary.800',
      },
      info: {
        fontSize: fontSizes(customFontSize).md,
        color: 'secondary.700',
      },
      addressInfo: {
        fontSize: fontSizes(customFontSize).md,
        color: 'secondary.600',
        mt: '4px',
      },
    },
    cardDetails: {
      header: {
        mb: ['20px', '20px', '25px'],
        fontSize: fontSizes(customFontSize).md,
        fontWeight: 400,
        fontFamily: 'heading',
      },
    },
    deleteModal: {
      header: {
        fontWeight: 600,
      },
      info: {
        fontSize: fontSizes(customFontSize).sm,
      },
    },
  },
  metadata: {
    switchTitle: {
      fontSize: fontSizes(customFontSize).lg,
      color: 'secondary.800',
      fontWeight: 600,
      [mq.md]: {
        fontSize: fontSizes(customFontSize).lgMin,
      },
    },
    switchText: {
      fontSize: fontSizes(customFontSize).mdMax,
      color: 'secondary.500',
      marginTop: '4px',
      [mq.md]: {
        fontSize: fontSizes(customFontSize).sm,
      },
      [mq.lg]: {
        marginTop: '0px',
      },
    },
    selectText: {
      fontSize: fontSizes(customFontSize).md,
      color: 'secondary.500',
      mr: '10px',
      minW: '65px',
      [mq.md]: {
        fontSize: fontSizes(customFontSize).xs,
      },
    },
  },
});

export default layerStyles;
