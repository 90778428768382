/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* @typescript-eslint/ban-ts-ignore */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createUserApi from '@api/user';
import { AppThunk, RootState } from '@store';
import { appContextSelector, loadEquivalents } from '@store/app/appSlice';
import { getOrderHistory } from '@store/orders/ordersSlice';
import { loadProjects } from '@store/projects/projectsSlice';
import { loadTransactions } from '@store/transactions/transactionsSlice';

import { currentUserSelector } from './userSlice';

interface State extends Attribution {
  loading: boolean;
  wechoooseUserCo2?: CO2Attribution;
  updateToken: string;
  createdOrderKilosCO2?: number;
}

const initialState: State = {
  loading: false,
  co2: undefined,
  statistics: undefined,
  wechoooseUserCo2: undefined,
  updateToken: '',
};

const slice = createSlice({
  name: 'attribution',
  initialState,
  reducers: {
    setAttribution(state: State, action: PayloadAction<Attribution | undefined>): State {
      state.co2 = action.payload?.co2;
      state.statistics = action.payload?.statistics;
      state.updateToken = action.payload?.updateToken ?? '';

      return state;
    },
    setWechoooseUserCo2(state: State, action: PayloadAction<CO2Attribution | undefined>): State {
      state.wechoooseUserCo2 = action.payload;

      return state;
    },
    setLoading(state: State, action: PayloadAction<boolean>): State {
      state.loading = action.payload;

      return state;
    },
    setCreatedOrderKilosCO2(state: State, action: PayloadAction<number>): State {
      state.createdOrderKilosCO2 = action.payload;

      return state;
    },
  },
});

const { setAttribution, setLoading, setWechoooseUserCo2, setCreatedOrderKilosCO2 } = slice.actions;

export { setCreatedOrderKilosCO2 };

export default slice.reducer;

function getCarbonBasedOnContext(attribution: Attribution, context: PortalContext): number {
  let carbon = 0;

  if (context === 'connect') {
    carbon = attribution?.co2?.kilosCo2Facilitated ?? 0;
  } else {
    carbon = attribution?.co2?.kilosCo2Owned ?? 0;
  }

  return carbon;
}

export const syncAttribution =
  (cb?: CallableFunction, retries = 0): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const initialAttribution = getState().attribution;
    const currentAccount = currentUserSelector(getState());

    const context = appContextSelector(getState());
    const userApi = createUserApi(context);
    dispatch(setLoading(true));
    try {
      let attribution;
      let userAttribution;
      const maxRetries = 25;
      switch (context) {
        case 'connect':
          attribution = await userApi.getAttributionForPartnership(currentAccount?.partnershipId ?? '');

          break;
        case 'wechooose':
          attribution = await userApi.getAttributionForCustomer(currentAccount?.customerId ?? '');

          userAttribution = await userApi.getAttributionForUser();
          break;
        default:
          attribution = await userApi.getAttributionForUser();
          break;
      }
      if (
        (initialAttribution.updateToken === attribution.updateToken || initialAttribution.co2?.kilosCo2Owned === attribution.co2?.kilosCo2Owned) &&
        retries < maxRetries
      ) {
        setTimeout(() => dispatch(syncAttribution(undefined, retries + 1)), 3000);

        return;
      }

      const carbon = getCarbonBasedOnContext(attribution, context);

      dispatch(setAttribution(attribution));

      if (userAttribution) {
        dispatch(setWechoooseUserCo2(userAttribution.co2));
      }
      dispatch(loadEquivalents(carbon));
      dispatch(loadProjects(undefined, true));
      if (context === 'connect') {
        dispatch(loadTransactions());
      } else {
        dispatch(getOrderHistory());
      }

      dispatch(setLoading(false));
      dispatch(setCreatedOrderKilosCO2(0));
    } catch {
      dispatch(setLoading(false));
      dispatch(setCreatedOrderKilosCO2(0));
    } finally {
      cb && cb();
    }
  };
export const loadAttributionForCustomer =
  (customerId = 'default'): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);
    try {
      const attribution = await userApi.getAttributionForCustomer(customerId);
      const carbon = getCarbonBasedOnContext(attribution, context);
      dispatch(setAttribution(attribution));
      dispatch(loadEquivalents(carbon));
    } catch {
      // err
    }
  };

export const loadAttributionForPartnership =
  (partnershipId = 'default'): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);

    try {
      const attribution = await userApi.getAttributionForPartnership(partnershipId);
      const carbon = getCarbonBasedOnContext(attribution, context);

      dispatch(setAttribution(attribution));
      dispatch(loadEquivalents(carbon));
    } catch {
      // err
    }
  };

export const loadUserAttributionDataForWeChooose =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);
    try {
      const attribution = await userApi.getAttributionForUser();
      dispatch(setWechoooseUserCo2(attribution.co2));
    } catch {
      // err
    }
  };

export const co2ByOrderTypeForMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.thisMonth?.co2PerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category =>
        !category.category ? { name: 'Other', value: category.kilosCo2 } : { name: category.category, value: category.kilosCo2 / 1000 },
      )
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const co2ByOrderTypeForLastMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.lastMonth?.co2PerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category =>
        !category.category ? { name: 'Other', value: category.kilosCo2 } : { name: category.category, value: category.kilosCo2 / 1000 },
      )
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const co2ByOrderTypeForYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.thisYear?.co2PerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category =>
        !category.category ? { name: 'Other', value: category.kilosCo2 } : { name: category.category, value: category.kilosCo2 / 1000 },
      )
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const co2ByOrderTypeForLastYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.lastYear?.co2PerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category =>
        !category.category ? { name: 'Other', value: category.kilosCo2 } : { name: category.category, value: category.kilosCo2 / 1000 },
      )
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const co2ByOrderTypeForThisWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.thisWeek?.co2PerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category =>
        !category.category ? { name: 'Other', value: category.kilosCo2 } : { name: category.category, value: category.kilosCo2 / 1000 },
      )
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const co2ByOrderTypeForLastWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.lastWeek?.co2PerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category =>
        !category.category ? { name: 'Other', value: category.kilosCo2 } : { name: category.category, value: category.kilosCo2 / 1000 },
      )
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const numberOfOrdersForMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.thisMonth?.countPerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category => (!category.category ? { name: 'Other', value: category.count } : { name: category.category, value: category.count }))
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const numberOfOrdersForLastMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.lastMonth?.countPerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category => (!category.category ? { name: 'Other', value: category.count } : { name: category.category, value: category.count }))
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const numberOfOrdersForYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.thisYear?.countPerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category => (!category.category ? { name: 'Other', value: category.count } : { name: category.category, value: category.count }))
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const numberOfOrdersForLastYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.lastYear?.countPerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category => (!category.category ? { name: 'Other', value: category.count } : { name: category.category, value: category.count }))
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const numberOfOrdersForThisWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.thisWeek?.countPerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category => (!category.category ? { name: 'Other', value: category.count } : { name: category.category, value: category.count }))
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const numberOfOrdersForLastWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics[!state.app.context ? 'paidFor' : 'owned']?.lastWeek?.countPerCategory;
    }

    return [];
  },
  categories => {
    const mappedCategories = categories
      ?.map(category => (!category.category ? { name: 'Other', value: category.count } : { name: category.category, value: category.count }))
      .sort((itemA, itemB) => itemB.value - itemA.value);

    return mappedCategories;
  },
);

export const co2AmountSelector = createSelector(
  (state: RootState) => appContextSelector(state),
  (state: RootState) => state.attribution,
  (context, attribution) => getCarbonBasedOnContext(attribution, context),
);

export const co2AmountSelectorPersonal = createSelector(
  (state: RootState) => state.attribution.co2,
  co2 => co2?.kilosCo2PaidFor ?? 0,
);
