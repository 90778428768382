import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash-es';
import createOrderApi, { FootprintsOrderFilters, FootprintsOrderSelectedAllParams, FootprintsOrderSelectedSomeParams } from '@api/order';
import { invalidateLegacyEmissionsFilters } from '@api/order/queries';
import { VirtualPortfolio } from '@api/order/types';
import { getOrderStatus } from '@pages/shared/CorporateOffset/utils';
import { AppThunk } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, customerCurrencySelector, metadataSelector, userCurrencySelector } from '@store/user/userSlice';
import { getDisplayName } from '@utils/metadata';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent } from '@utils/tags';

import { singlePortfolioSanityIdSelector, virtualPortfoliosSelector } from './portfolioSelectSlice';

interface OffsetInfo {
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  poNumber?: string;
  costCenter?: string;
  [key: string]: string | Option | Date | undefined | boolean | number;
}

interface SelectedFootprints {
  footprintId: string | undefined;
  checked: boolean;
}

interface FootprintsOrderBaseObject {
  mainPortfolioId: string | undefined;
  virtualPortfolios: VirtualPortfolio[];
  currency: string;
  isMultiplierActive: boolean;
  multiplier?: Pick<Multiplier, 'value' | 'mode'>;
}

interface FootprintsOrderSelectedSomeObject extends FootprintsOrderBaseObject {
  selectedFootprints: FootprintsOrderObjectItem[];
}

interface FootprintsSelectedAllOrderObject extends FootprintsOrderBaseObject {
  selectedFootprints: null;
  filter: EmissionsMetadata;
}

type FootprintsOrderObject = FootprintsOrderSelectedSomeObject | FootprintsSelectedAllOrderObject;

interface OrderPayload {
  number: string;
  date: string;
  name?: string;
  id: string;
  billId?: string;
  orderStatus?: string;
}

export interface EmissionsOffsetState {
  footprints: FootprintsItem[];
  compensatedFootprints?: CompensatedFootprint[];
  amount?: number;
  totalKilosCo2?: number;
  totalCount: number;
  vat?: number;
  customerVat?: number;
  excise?: number;
  customerExcise?: number;
  orderName?: string;
  orderId?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  isLoading: boolean;
  customerPrice?: number;
  price?: number;
  currency?: string;
  orderNumber?: string;
  orderDate?: string;
  selectedPortfolioId?: string;
  isOrderInfoInvalid?: boolean;
  selectedCustomMetadata?: string[];
  isPaidByPartner?: boolean;
  customer?: PartnersCustomerOption;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  isMultiplierActive: boolean;
  orderStatus?: string;
  attachmentTempUrl?: undefined;
  fullOffsetRows?: undefined;
  costCenter?: string;
  poNumber?: string;
  totalKilosCo2Avoided?: number;
  totalKilosCo2Offset?: number;
  selectedFootprints: FootprintsItem[];
  quoteItems?: ImpactItem[];
  hasAwaitingFacilitatedOrders?: boolean;
  [key: string]:
    | string
    | Option
    | Date
    | number
    | undefined
    | boolean
    | string[]
    | PartnersCustomerOption
    | FootprintsItem[]
    | CompensatedFootprint[]
    | ImpactItem[];
}

const initialState: EmissionsOffsetState = {
  isLoading: false,
  footprints: [],
  selectedFootprints: [],
  totalCount: 0,
  isMultiplierActive: false,
};

const LIMIT = 10;

const slice = createSlice({
  name: 'offsetEmissions',
  initialState,
  reducers: {
    reset: (): EmissionsOffsetState => {
      return { ...initialState };
    },
    resetWithoutPortfolioId: (state: EmissionsOffsetState): EmissionsOffsetState => {
      return { ...initialState, selectedPortfolioId: state.selectedPortfolioId, isMultiplierActive: state.isMultiplierActive };
    },
    setOrder: (state: EmissionsOffsetState, action: PayloadAction<OrderPayload>): EmissionsOffsetState => {
      state.orderDate = action.payload.date;
      state.orderNumber = action.payload.number;
      state.orderName = action.payload.name ?? '';
      state.orderId = action.payload.id;
      state.billId = action.payload.billId;
      state.orderStatus = action.payload.orderStatus;

      return state;
    },
    setOrderInfo: (state: EmissionsOffsetState, action: PayloadAction<OffsetInfo>): EmissionsOffsetState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setFootprints: (state: EmissionsOffsetState, action: PayloadAction<{ items: FootprintsItem[]; totalCount: number }>): EmissionsOffsetState => {
      state.footprints = action.payload.items;
      state.totalCount = action.payload.totalCount;

      return state;
    },
    setOrderInfoInvalid: (state: EmissionsOffsetState, action: PayloadAction<boolean>): EmissionsOffsetState => {
      state.isOrderInfoInvalid = action.payload;

      return state;
    },
    setSelectedCustomMetadata: (state: EmissionsOffsetState, action: PayloadAction<string[]>): EmissionsOffsetState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    setLoading: (state: EmissionsOffsetState, action: PayloadAction<boolean>): EmissionsOffsetState => {
      state.isLoading = action.payload;

      return state;
    },
    setSelectedFootprints: (state: EmissionsOffsetState, action: PayloadAction<FootprintsItem[]>): EmissionsOffsetState => {
      state.selectedFootprints = action.payload;

      return state;
    },
    setAmount: (state: EmissionsOffsetState, action: PayloadAction<{ amount: number; totalKilosCo2: number }>): EmissionsOffsetState => {
      state.amount = action.payload.amount;
      state.totalKilosCo2 = action.payload.totalKilosCo2;

      return state;
    },
    setOffsetPrice: (
      state: EmissionsOffsetState,
      action: PayloadAction<{ price: number; vat: number; excise: number; currency: string }>,
    ): EmissionsOffsetState => {
      state.currency = action.payload.currency;
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.excise = action.payload.excise;

      return state;
    },
    setEmissionsAvoided: (state: EmissionsOffsetState, action: PayloadAction<number>): EmissionsOffsetState => {
      state.totalKilosCo2Avoided = action.payload;

      return state;
    },
    setEmissionsOffset: (state: EmissionsOffsetState, action: PayloadAction<number>): EmissionsOffsetState => {
      state.totalKilosCo2Offset = action.payload;

      return state;
    },
    setCompensatedFootprints: (state: EmissionsOffsetState, action: PayloadAction<CompensatedFootprint[]>): EmissionsOffsetState => {
      state.compensatedFootprints = action.payload;

      return state;
    },
    setQuoteItems: (state: EmissionsOffsetState, action: PayloadAction<ImpactItem[] | undefined>): EmissionsOffsetState => {
      state.quoteItems = action.payload;

      return state;
    },
    setHasAwaitingFacilitatedOrders: (state: EmissionsOffsetState, action: PayloadAction<boolean>): EmissionsOffsetState => {
      state.hasAwaitingFacilitatedOrders = action.payload;

      return state;
    },
    setIsMultiplierActive: (state: EmissionsOffsetState, action: PayloadAction<boolean>): EmissionsOffsetState => {
      state.isMultiplierActive = action.payload;

      return state;
    },
  },
});

const {
  setOrder,
  setOrderInfo,
  setOrderInfoInvalid,
  setLoading,
  setFootprints,
  setSelectedFootprints,
  setAmount,
  setOffsetPrice,
  setEmissionsAvoided,
  setEmissionsOffset,
  reset,
  setCompensatedFootprints,
  setSelectedCustomMetadata,
  resetWithoutPortfolioId,
  setQuoteItems,
  setHasAwaitingFacilitatedOrders,
  setIsMultiplierActive,
} = slice.actions;

export { reset, setIsMultiplierActive, setOrderInfo, setOrderInfoInvalid, setSelectedCustomMetadata, setSelectedFootprints };

export default slice.reducer;

export const invalidateQueryAndResetWithoutPortfolioId =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    // We need to invalidate Filter queries to make sure we won't fetch old data, e.g.:
    // a flight number in the `flightNumbers` select input that have already been addressed
    // and should not be selectable.
    invalidateLegacyEmissionsFilters();
    dispatch(resetWithoutPortfolioId());
  };

export const getFootprints =
  (filters?: EmissionsFilters): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const currentUser = currentUserSelector(getState());
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState());
    const customerId = currentUser?.customerId ?? '';
    const { page, ...restFilters } = filters ?? {};

    try {
      const appliedFilters = restFilters
        ? pick(restFilters, [
            'fromDate',
            'toDate',
            'agentIataNumber',
            'agentName',
            'corporateName',
            'destination',
            'flightNumber',
            'origin',
            'shipperIataNumber',
            'shipperName',
            'tourCode',
            'travelCardNumber',
            'airwayBill',
            'travelClass',
            'confirmedAsFlown',
            'operatingAirlineName',
          ])
        : undefined;

      const footprints = await orderApi.getFootprintsForEmissionsOrder(
        {
          grouped: false,
          limit: LIMIT,
          offset: (parseInt(page ?? '1', 10) - 1) * LIMIT,
          ...appliedFilters,
        },
        customerId,
      );
      dispatch(
        setFootprints({
          items: footprints.items,
          totalCount: footprints.totalCount,
        }),
      );

      if (!filters) {
        if (isEmissionsDashboardEnabled) {
          const facilitatedOrders = await orderApi.getAll(
            { 'payment.status': 'Draft,Open,Paid,Uncollectible,Void', offset: 0, limit: 1, isfacilitated: true },
            customerId,
          );
          dispatch(setHasAwaitingFacilitatedOrders(facilitatedOrders.totalCount > 0));
        }
      }
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:getFootprintsError' }));
    }
  };

export const setFootprintsForOrder =
  ({ footprintId, checked }: SelectedFootprints): AppThunk =>
  (dispatch, getState): void => {
    const { selectedFootprints, isMultiplierActive, footprints } = getState().emissions;
    let tempArr = [...selectedFootprints];

    if (checked) {
      const newFootprint = footprints.find(el => el.id === footprintId);

      if (newFootprint) {
        tempArr.push(newFootprint);
      }
    } else {
      tempArr = tempArr.filter(el => el.id !== footprintId);
    }
    dispatch(setSelectedFootprintsAndAmount(tempArr, isMultiplierActive));
  };

export const setSelectedFootprintsAndAmount =
  (footprints: FootprintsItem[], isMultiplierActive?: boolean): AppThunk =>
  (dispatch, getState): void => {
    dispatch(setSelectedFootprints(footprints));
    const totalKilosCo2Reduced = footprints.reduce((prev, curr) => prev + curr.totalKilosCo2, 0);
    const multiplier = getState().partnerSettings?.settings?.multiplierSettings;
    const multiplierValue = multiplier?.value ?? 0;
    const isDiscountMode = multiplier?.mode === 'Discount' && multiplier.value;
    const discountValue = multiplierValue / (1 + multiplierValue);
    const totalKilosCo2 = isDiscountMode && isMultiplierActive ? totalKilosCo2Reduced * discountValue : totalKilosCo2Reduced;
    dispatch(
      setAmount({
        amount: totalKilosCo2 / 1000,
        totalKilosCo2: totalKilosCo2,
      }),
    );

    if (!totalKilosCo2) {
      dispatch(setQuoteItems(undefined));
      dispatch(setCompensatedFootprints([]));
    }
  };

export const getFootprintsOrderObject = <
  T extends FootprintsOrderObject,
  R = T['selectedFootprints'] extends FootprintsOrderObjectItem[] ? FootprintsOrderSelectedSomeParams : FootprintsOrderSelectedAllParams,
>({
  mainPortfolioId,
  selectedFootprints,
  currency,
  virtualPortfolios,
  isMultiplierActive,
  multiplier,
  ...params
}: T): R => {
  const footprints = selectedFootprints
    ? selectedFootprints.map(({ id, type, date }) => {
        const [year, month] = date.split('-');

        return {
          year: parseInt(year, 10),
          month: parseInt(month, 10),
          footprintId: id,
          type,
        };
      })
    : null;

  const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, id, weight, supplyFilter }) => ({
    id: id ?? portfolioId,
    weight: weight,
    ...(Object.keys(supplyFilter || {}).length > 0 ? { supplyFilter } : undefined),
  }));

  return {
    currency,
    footprints,
    portfolioId: mainPortfolioId ?? null,
    portfolio: mainPortfolioId
      ? undefined
      : {
          type: 'Virtual',
          impact: 'co2',
          currency,
          childPortfolios: mappedVirtualPortfolios,
        },
    multiplier: isMultiplierActive && multiplier ? { value: multiplier.value, mode: multiplier.mode } : undefined,
    ...params,
  } as R;
};

export const getPriceForOrder =
  (filter?: FootprintsOrderFilters): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const currentUser = currentUserSelector(getState());
    const { selectedFootprints, isMultiplierActive, totalKilosCo2 } = getState().emissions;
    const orderApi = createOrderApi(context);
    const customerId = currentUser?.customerId ?? '';
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const currency = customerCurrencySelector(getState());
    const multiplier = getState().partnerSettings?.settings?.multiplierSettings;

    const params = {
      mainPortfolioId,
      currency,
      virtualPortfolios,
      isMultiplierActive,
      multiplier,
    };

    try {
      dispatch(setLoading(true));
      const order =
        filter === undefined
          ? getFootprintsOrderObject({
              selectedFootprints,
              ...params,
            })
          : getFootprintsOrderObject({
              selectedFootprints: null,
              filter,
              ...params,
            });

      const response = await orderApi.getQuoteForFootprints(order, customerId);

      dispatch(
        setOffsetPrice({
          currency,
          price: response.totalPrice,
          vat: response.totalVat,
          excise: response.totalExcise,
        }),
      );
      dispatch(
        setAmount({
          amount: response.totalKilosCo2 / 1000,
          totalKilosCo2: response.totalKilosCo2,
        }),
      );

      dispatch(setEmissionsAvoided(response.totalKilosCo2Avoided ?? 0));
      dispatch(setEmissionsOffset(response.totalKilosCo2Offset ?? 0));
      dispatch(setCompensatedFootprints(response.footprints ?? []));
      dispatch(setQuoteItems(response.items));

      if (multiplier?.mode === 'Discount' && response.totalKilosCo2 !== totalKilosCo2) {
        dispatch(
          setAmount({
            amount: response.totalKilosCo2 / 1000,
            totalKilosCo2: response.totalKilosCo2,
          }),
        );
      }
    } catch (err) {
      // @ts-ignore
      if (err.data?.includes('Insufficient supply')) {
        dispatch(
          showToast({
            variant: 'error',
            titleI18nKey: 'offset:corporate.portfolios.errorTitle',
            descriptionI18nKey: 'offset:corporate.portfolios.insufficientSupplyErrorMessage',
          }),
        );
        // @ts-ignore
      } else if (err.data?.includes('No items')) {
        dispatch(
          dispatch(
            setAmount({
              amount: 0,
              totalKilosCo2: 0,
            }),
          ),
        );
        // @ts-ignore
      } else if (err.data) {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchPriceError' }));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createEmissionsOrder =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentUser = currentUserSelector(getState());
    const { emissions, user } = getState();
    const { currency, orderName, totalKilosCo2, price, compensatedFootprints, selectedFootprints, quoteItems, isMultiplierActive, ...restProps } =
      emissions;
    const { costCenter, poNumber, description } = restProps;
    const context = appContextSelector(getState());
    const paymentMethodType = context === 'connect' ? 'Invoice' : user?.currentAccount?.customer?.payment?.defaultPaymentMethod?.type;
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const metadata = metadataSelector(getState()) ?? [];
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;
    const userCurrency = userCurrencySelector(getState());
    const portfolioId = mainPortfolioId ?? null;
    const customSingleMetadata: {
      internalName: string;
      displayName: string;
      value: string | Date;
    }[] = [];
    const orderApi = createOrderApi(context);
    const yearsOfFootprints = new Set(selectedFootprints.map(({ date }) => parseInt(date.split('-')[0], 10)));

    const partnerSettingsMultiplier = getState().partnerSettings?.settings?.multiplierSettings;
    const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? partnerSettingsMultiplier?.value ?? 0;
    const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode ?? partnerSettingsMultiplier?.mode;

    Object.keys(restProps)
      .filter(key => !!metadata.find(({ internalName }) => internalName === key))
      .map(key => {
        let value: string | Date = '';

        if ((emissions[key] as Option)?.value) {
          value = (emissions[key] as Option)?.value;
        } else {
          value = emissions[key] as string | Date;
        }

        if (value) {
          customSingleMetadata.push({
            internalName: key,
            value: value,
            displayName: getDisplayName(metadata, key),
          });
        }
      });

    const items = quoteItems?.map(item => ({
      type: 'impact',
      impact: 'Co2',
      kilosCo2: item.kilosCo2,
      ...(mainPortfolioId ? {} : { virtualPortfolios }),
      price,
      portfolioId,
      feeFactors: item.feeFactors,
      taxFactors: item.taxFactors,
    })) ?? [
      {
        type: 'impact',
        impact: 'Co2',
        kilosCo2: totalKilosCo2,
        ...(mainPortfolioId ? {} : { virtualPortfolios }),
        price,
        portfolioId,
      },
    ];

    const order = {
      currency: currency ?? userCurrency,
      comment: description ?? '',
      description: orderName ?? '',
      category: 'Emissions',
      items: items,
      metadata: {
        order: {
          channel: 'portal-fulloffset',
        },
        custom: customSingleMetadata,
      },
      quoteFootprints: compensatedFootprints,
      costCenter: costCenter ?? '',
      poNumber: poNumber ?? '',
      multiplier: isMultiplierActive && multiplierMode ? { value: multiplierValue, mode: multiplierMode } : undefined,
    };

    try {
      dispatch(setLoading(true));
      const orderResult = await orderApi.createOrderFromFootprints(order, user?.currentAccount?.customerId ?? '');
      trackPurchaseEvent(orderResult.order, 'Portal offset', paymentMethodType, compensatedFootprints?.map(({ footprintId }) => footprintId) ?? []);

      dispatch(
        setOrder({
          number: orderResult.order.choooseId,
          date: orderResult.order.createdDate,
          name: orderName ?? '',
          id: orderResult.orderId,
          billId: orderResult.billId,
          orderStatus: getOrderStatus(orderResult.order),
        }),
      );

      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        const promises = Array.from(yearsOfFootprints).map(year =>
          client.invalidateQueries({ queryKey: ['impact', 'customer', currentUser?.customerId, { year }] }),
        );
        await Promise.all(promises);
      }
      dispatch(syncAttribution());
      client.invalidateQueries({ queryKey: ['orders', 'history'] });
      successCb && successCb();
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:createOrderError' }));
      errorCb && errorCb();
    } finally {
      dispatch(setLoading(false));
    }
  };
