import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const PORTAL_SELECTED_ACCOUNT_ID = 'selected-account-id';

interface AccountIdStore {
  accountId?: string;
  setAccountId: (accountId: string) => void;
}

export const useAccountIdStore = create<AccountIdStore>()(
  persist(
    set => ({
      setAccountId: accountId => set({ accountId }),
    }),
    {
      name: PORTAL_SELECTED_ACCOUNT_ID,
    },
  ),
);
