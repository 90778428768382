import { useCallback, useEffect, useSyncExternalStore } from 'react';

const dispatchStorageEvent = (key: string, newValue: string | null) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
};

const setItem = (key: string, value: unknown) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

const getItem = (key: string) => {
  return window.localStorage.getItem(key);
};

const subscribe = (callback: () => void) => {
  window.addEventListener('storage', callback);

  return () => window.removeEventListener('storage', callback);
};

export function useLocalStorage<T>(key: string, initialValue?: T): [T | null, (v: T) => void] {
  const getSnapshot = () => getItem(key);
  const store = useSyncExternalStore(subscribe, getSnapshot);

  const setState = useCallback(
    (nextState: T) => {
      try {
        if (nextState === undefined || nextState === null) {
          return removeItem(key);
        }

        return setItem(key, nextState);
      } catch (err) {
        // err
        return;
      }
    },
    [key, store],
  );

  useEffect(() => {
    if (getItem(key) === null && typeof initialValue !== 'undefined') {
      setItem(key, initialValue);
    }
  }, [key, initialValue]);

  const parsedInitialValue = initialValue ?? null;

  return [store ? (JSON.parse(store) as T) : parsedInitialValue, setState];
}
