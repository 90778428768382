const headingBase = {
  fontFamily: 'heading',
  fontWeight: 'bold',
};

export default {
  h1: {
    ...headingBase,
    fontSize: '5xl',
  },
  h2: {
    ...headingBase,
    fontSize: '4xl',
    fontWeight: '400',
  },
  h3: {
    ...headingBase,
    fontSize: '3xl',
  },
  h4: {
    ...headingBase,
    fontSize: '2xl',
  },
  h5: {
    ...headingBase,
    fontSize: 'xl',
  },
  h6: {
    ...headingBase,
    fontSize: 'lg',
  },
  p: {
    fontSize: { base: 'lgMin', md: 'md' },
    fontFamily: 'body',
  },
  pSM: {
    fontSize: { base: 'mdMax', md: 'sm' },
    fontFamily: 'body',
  },
  pXS: {
    fontSize: { base: 'md', md: 'xs' },
    fontFamily: 'body',
  },
};
