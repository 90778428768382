import styled, { css } from 'styled-components';

export const LicenseText = styled.pre`
  white-space: pre-wrap;
  font-family: inherit;
  ${({ theme }) => css`
    color: ${theme.colors.text.secondary};
    font-size: ${theme.fontSizes.md};
  `}
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.xxxl};
    gap: ${theme.spacing.md};
    border-bottom: 1px solid ${theme.colors.border.default};
    @media ${theme.breakpoints.tablet} {
      display: none;
    }
  `}
`;

export const LicenseWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  ${({ theme }) => css`
    padding: ${theme.spacing.xxxl} 0;
    gap: ${theme.spacing.md};
    border-bottom: 1px solid ${theme.colors.border.default};
    @media ${theme.breakpoints.tablet} {
      grid-template-columns: 1fr;
      padding-left: 0;
      padding-right: 0;
    }
  `}
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.xl};
    margin-top: ${theme.spacing.md};
  `}
`;
