import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionContent, Loader, Typography } from '@chooose/ui';
import { useFAQ } from '@api/sanity/faq.queries';
import { LoaderWrapper } from '@components/LoaderWrapper';
import { SanityBlockContent } from '@components/SanityBlockContent';

import * as s from './styles';

interface Props {
  page: string;
  partnerPage?: string;
  isPadding?: boolean;
}

export const FAQ: FC<React.PropsWithChildren<Props>> = ({ page, partnerPage, isPadding = true }) => {
  const { t } = useTranslation('common');
  const { data, isLoading } = useFAQ(page, partnerPage);

  const isDataEmpty = !data || data?.length === 0;

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (isDataEmpty) {
    return <Typography variant='secondary'>{t('faq.empty')}</Typography>;
  }

  return (
    <s.Wrapper $isPadding={isPadding}>
      <Typography tag='h4'>{t('faq.corporateTitle')}</Typography>
      <Accordion type='single' collapsible>
        {data.map(el => {
          return (
            <s.StyledAccordionItem key={el.question} value={el.question}>
              <s.StyledAccordionTrigger>
                <Typography tag='span'>{el.question}</Typography>
                <s.AccordionChevron aria-hidden />
              </s.StyledAccordionTrigger>
              <AccordionContent>
                <Typography>
                  <SanityBlockContent blocks={el.answer} />
                </Typography>
              </AccordionContent>
            </s.StyledAccordionItem>
          );
        })}
      </Accordion>
    </s.Wrapper>
  );
};
