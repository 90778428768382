import { FC } from 'react';

import * as s from './styles';

interface Props {
  testId?: string;
}

export const LoaderWrapper: FC<React.PropsWithChildren<Props>> = ({ testId, children }) => {
  return <s.LoaderWrapper data-testid={testId}>{children}</s.LoaderWrapper>;
};
