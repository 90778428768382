export const NETWORK_ERROR_CODE = 'network_error';
export const UNAUTHORIZED_ERROR_CODE = 'unauthorized_error';
export const INTERACTION_ERROR_CODE = 'interaction_error';

export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;

export class NetworkError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}
