import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { signUpGuestRequest } from '@lib/msal';
import { useAuthAction } from '@lib/msal/useAuthAction';
import { rgba } from 'polished';
import { usePartnershipSettings } from '@api/partnership/queries';
import { ReactComponent as MyChoooseText } from '@assets/svgs/my-chooose-text.svg';
import { Button } from '@components/Button';
import { useBreakpoint } from '@hooks/useBreakpoint';

import { AppTheme } from '@theme';

const StyledMyChoooseText = styled(MyChoooseText)`
  margin-bottom: 7px;
  width: 115px;
`;

const StyledOverlay = styled(ModalOverlay)`
  backdrop-filter: blur(4px);
`;

const StyledPartnerName = styled(Text)`
  font-weight: 800;
  margin-right: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const SignUpChoiceBox: FC<React.PropsWithChildren<FlexProps>> = ({ children, ...props }) => {
  const { colors } = useTheme<AppTheme>();
  const { isMobile } = useBreakpoint();

  return (
    <Flex w='100%' mt='10px' border={`1px solid ${rgba(colors.gray[900], 0.1)}`} borderRadius='9px' {...props}>
      <Flex w='100%' direction={isMobile ? 'column' : 'row'} alignItems='center' p='35px 18px 35px 30px' justifyContent='space-between'>
        {children}
      </Flex>
    </Flex>
  );
};

export const ContextModal: FC<React.PropsWithChildren<Props>> = ({ onClose, isOpen }) => {
  const { colors } = useTheme<AppTheme>();
  const location = useLocation();
  const { action: signUp } = useAuthAction(signUpGuestRequest);
  const { t } = useTranslation(['signup', 'settings']);
  const { data: settings } = usePartnershipSettings();
  const partnerName = import.meta.env.REACT_APP_PARTNER_NAME;
  const isProgramNameSet = t(`settings:programName.${settings?.partnershipId}`, 'null') !== 'null';
  const isTestEnv = import.meta.env.REACT_APP_PORTAL_API_URL?.includes('chooose-portal-test');
  const myChoooseSignUpSignInPath = `guest-sign-in-sign-up/mychooose${location.search}`;
  const linkToMyChoooseGuestSignInSignUp = isTestEnv
    ? `https://portal-test.chooose.today/${myChoooseSignUpSignInPath}`
    : `https://portal.chooose.today/${myChoooseSignUpSignInPath}`;

  const goToSignUpWeChooose = (): void => {
    signUp({
      redirectAfterAction: `${window.origin}/login-setup${location.search}&context=wechooose`,
      additionalQuery: {
        weChoooseSignupLink: encodeURIComponent(`${window.origin}/sign-up/chooose${location.search}`),
      },
    });
  };

  return (
    <Modal preserveScrollBarGap isOpen={isOpen} onClose={onClose}>
      <StyledOverlay />
      <ModalContent maxW='590px' w='100%' borderRadius='15px'>
        <ModalHeader p='48px 30px 0 30px'>
          <Heading fontSize='xlMax' fontFamily='heading' fontWeight='bold' textAlign='center'>
            {t('signup:claimImpactBanner.modalGeneralHeader')}
          </Heading>
          <Text mt='20px' fontSize='md' fontWeight='500' textAlign='center' color={rgba(colors.gray[900], 0.7)}>
            {t('signup:claimImpactBanner.modalGeneralDescription')}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p='20px 30px 30px 30px'>
          <SignUpChoiceBox>
            <Flex
              justify={{ base: 'center', md: 'flex-start' }}
              align='center'
              w='100%'
              mr='10px'
              sx={{ whiteSpace: 'nowrap' }}
              mb={{ base: '5px', md: 0 }}>
              <StyledPartnerName fontSize='mdMax' fontFamily='heading' maxWidth='130px' color='primary.500'>
                {t(`settings:programName.${settings?.partnershipId}`, partnerName || '')}
              </StyledPartnerName>
              {!isProgramNameSet && (
                <Box as='span' fontFamily='heading' color='secondary.900' fontSize='lgMin' fontWeight={700} lineHeight='mdMax'>
                  CARBON
                </Box>
              )}
            </Flex>
            <Button fontSize='mdMax' height='50px' minW='auto' variant='outline' onClick={goToSignUpWeChooose}>
              {t('signup:claimImpactBanner.modalSignupWeChoooseButton')}
            </Button>
          </SignUpChoiceBox>
          <SignUpChoiceBox>
            <StyledMyChoooseText />
            <a href={linkToMyChoooseGuestSignInSignUp}>
              <Button fontSize='mdMax' height='50px' minW='auto' variant='outline'>
                {t('signup:claimImpactBanner.modalSignupMyChoooseButton')}
              </Button>
            </a>
          </SignUpChoiceBox>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
