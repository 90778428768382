/* @typescript-eslint/ban-ts-ignore */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';

interface State {
  company: Company;
  portfolioId: string;
  inviteId?: string;
  claimCode?: string;
  inviteType?: string;
  customer?: CustomerLegacy;
}

const initialState: Partial<State> = {
  step: 'information',

  // @ts-ignore
  company: {},
};

const slice = createSlice({
  name: 'signUp',
  initialState: initialState as State,
  reducers: {},
});

export default slice.reducer;

export const selectGuestCountry = createSelector(
  (state: RootState) => state.signUp.company,
  company => company?.country,
);
