import { create } from 'zustand';

import { AuthHandler } from './types';

interface AuthHandlerStore {
  authHandler?: AuthHandler;
  setAuthHandler: (authHandler: AuthHandler) => void;
}

export const useAuthHandlerStore = create<AuthHandlerStore>(set => ({
  setAuthHandler: authHandler =>
    set(state => {
      if (state.authHandler) {
        return { authHandler: state.authHandler };
      }

      return { authHandler };
    }),
}));
