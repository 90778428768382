import React, { useEffect, useState } from 'react';

export const PosthogDelayedSurvey: React.FC = () => {
  const [isSurveyVisible, setIsSurveyVisible] = useState(false);
  const surveyTimeout = 60000;

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsSurveyVisible(true);
    }, surveyTimeout);

    return () => clearTimeout(timerId);
  }, []);

  if (!isSurveyVisible) {
    return null;
  }

  return <div id='posthog-survey'></div>;
};
