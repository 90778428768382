import axios from 'axios';
import { defaultInstance } from '@api/instance';

import {
  FacilitatedOrderMultipleCustomersCertificateParams,
  FacilitatedOrderSingleCustomersCertificateParams,
  OrderCertificateParams,
  SafCertificateGenerateParams,
  SafCertificateParams,
  SetDownloadedCertificateParams,
} from './types';

export const generateCustomerOrderCertificate = async ({ id, orderId, ...params }: OrderCertificateParams): Promise<Certificate> => {
  const { data } = await defaultInstance.post<Certificate>(`/customers/${id}/orders/${orderId}/certificate`, {
    useGenerator: true,
    ...params,
  });

  return data;
};

export const downloadFromUrl = async (url: string): Promise<Blob> => {
  const { data } = await axios.get<Blob>(url, { responseType: 'blob' });

  return data;
};

export const getCertificatesOrderForPartner = async ({
  id,
  customerId,
  orderId,
  ...params
}: FacilitatedOrderSingleCustomersCertificateParams): Promise<Certificate> => {
  const { data } = await defaultInstance.post<Certificate>(`/partnerships/${id}/customers/${customerId}/orders/${orderId}/certificate`, {
    ...params,
  });

  return data;
};

export const getMultiCertificatesOrderForPartner = async ({
  id,
  ...params
}: FacilitatedOrderMultipleCustomersCertificateParams): Promise<Certificate[]> => {
  const { data } = await defaultInstance.post<Certificate[]>(`/partnerships/${id}/facilitatedorders/certificates`, { ...params });

  return data;
};

export const generateSafCertificate = async ({ id, orderId, portfolioId, certificateType }: SafCertificateGenerateParams): Promise<Certificate[]> => {
  const { data } = await defaultInstance.post<Certificate[]>(
    `/customers/${id}/orders/${orderId}/portfolios/${portfolioId}/lcf-certificates/saf-${certificateType}/generate`,
    {
      useGenerator: true,
    },
  );

  return data;
};

export const requestSafCertificate = async ({ id, orderId, certificateType, ...params }: SafCertificateParams): Promise<Certificate> => {
  const { data } = await defaultInstance.put<Certificate>(`/customers/${id}/orders/${orderId}/lcf-certificates/saf-${certificateType}/request`, {
    ...params,
  });

  return data;
};

export const downloadSafCertificate = async ({ id, orderId, certificateType }: SafCertificateParams): Promise<Blob> => {
  const { data } = await defaultInstance.get<Blob>(`/customers/${id}/orders/${orderId}/lcf-certificates/saf-${certificateType}/download`, {
    headers: { 'Content-Type': 'application/pdf' },
    responseType: 'blob',
  });

  return data;
};

export const setDownloadedCertificate = async ({ customerId, orderId, blobName }: SetDownloadedCertificateParams): Promise<Blob> => {
  const { data } = await defaultInstance.post<Blob>(`/customers/${customerId}/orders/${orderId}/certificates/downloaded`, { blobName });

  return data;
};
