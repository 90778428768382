import { omit } from 'lodash-es';
import { MultipleOrdersResponse } from '@api/order';
import { Order } from '@api/order/types';

const partnerName = import.meta.env.REACT_APP_PARTNER_NAME;
type Environment = 'production' | 'test' | 'dev' | 'local';

export const getEnvironment = (): Environment => {
  const domain = window.location.hostname.split('.')?.[0];

  return !domain || domain === 'localhost' ? 'local' : domain.includes('test') ? 'test' : domain.includes('dev') ? 'dev' : 'production';
};

export const getPartnerSite = (context?: PortalContext): string => {
  const hostname = window.location.hostname.split('.')?.[0]?.replace('-', '')?.replace('test', '');

  return ['localhost', 'portal'].includes(hostname) ? context ?? 'wechooose' : `${hostname}portal`;
};

export const getAccountType = (site: string): string => (['wechooose'].includes(site) ? 'corporate' : 'portal');

export const tags = {
  portalOffset: {
    entry: {
      event: 'TrackingEvent',
      eventCategory: 'PortalOffset',
      eventAction: 'Entry',
      eventLabel: partnerName,
    },
    next: {
      event: 'TrackingEvent',
      eventCategory: 'PortalOffset',
      eventAction: 'Next',
      eventLabel: partnerName,
    },
  },
  portalFootprint: {
    entry: {
      event: 'TrackingEvent',
      eventCategory: 'PortalFootprint',
      eventAction: 'Entry',
      eventLabel: partnerName,
    },
    next: {
      event: 'TrackingEvent',
      eventCategory: 'PortalFootprint',
      eventAction: 'Next',
      eventLabel: partnerName,
    },
  },
  portalQuickOffset: {
    next: {
      event: 'TrackingEvent',
      eventCategory: 'PortalQuickOffset',
      eventAction: 'Next',
      eventLabel: partnerName,
    },
  },
  registration: {
    createAccount: {
      event: 'TrackingEvent',
      eventCategory: 'SignupWeChooose',
      eventAction: 'CreateAccount',
      eventLabel: partnerName,
    },
  },
};

interface TrackableTransactionProduct {
  price: number;
  category: string;
  quantity: number;
  name: string;
  sku: string;
}

interface SignUp {
  event_name: string;
  account_type: string;
  partner_site: string;
  partnership_id: string;
  environment: string;
  customer_company?: string | null;
  company_size?: string;
}

interface Product {
  currency: string;
  item_category: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
}

type TransactionPaymentType = 'Invoice' | 'Card';

type Affiliation = 'Portal offset' | 'Portal quick offset';

interface TagData {
  event?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  categories?: Array<string>;
  transactionId?: string;
  transactionTotal?: number;
  currencyCode?: string;
  transactionProducts?: Array<TrackableTransactionProduct>;
  trackingEvent_signup_complete_account?: SignUp;
  trackingEvent_signup_create_account?: SignUp;
  trackingEvent_signup_start?: SignUp;
  eventCallback?: () => void;
  fields_to_set?: {
    partnership_id: string;
    partnership_name: string;
    environment: string;
  };
  customer_info?: {
    account_type: string;
    customer_company: string;
    environment: string;
  };
  ecommerce?: {
    value: number;
    tax?: number;
    affiliation: Affiliation;
    transaction_id: string;
    payment_type: TransactionPaymentType;
    items: Product[];
  };
}

interface MultipleOrdersPurchase {
  jobId: string;
  totalPrice: number;
  totalTax: number;
  affiliation: Affiliation;
  paymentMethod: string | undefined;
  orders: Partial<MultipleOrdersResponse[]>;
  category: string;
}

export const setTag = (data: TagData = {}): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const domain = window.location.hostname.split('.')?.[0];

  if (!domain || domain === 'localhost') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  const gtmGlobalData = JSON.parse(sessionStorage.getItem('gtmGlobalData') ?? 'null') ?? {};

  window.dataLayer.push(function () {
    // @ts-ignore
    this.reset();
  });
  const start = new Date().getTime();
  window.dataLayer.push({
    ...data,
    ...gtmGlobalData,
    gtm: {
      start,
    },
  });
};

const setPaymentType = (type: string | undefined): TransactionPaymentType => (type === 'Stripe' ? 'Card' : 'Invoice');

const setItemQuantity = (feeFactor: FeeFactors | undefined): number =>
  feeFactor ? (Object.values(omit(feeFactor, ['flightDistanceKm'])).find(Number.isInteger) as number) ?? 1 : 1;

export const trackPurchaseEvent = (
  order: OrderLegacy<OrderFootprint> | Order,
  affiliation: Affiliation,
  paymentMethod: string | undefined,
  footprintIds: string[] = [],
) =>
  setTag({
    event: 'purchase',
    ecommerce: {
      value: order.totalPrice,
      tax: order?.totalTax,
      affiliation,
      payment_type: setPaymentType(paymentMethod),
      transaction_id: order.id,
      items: order.items.map((item, idx) => ({
        currency: order.currency,
        item_category: `${item.kilosCo2Rounded} kg CO2e`,
        item_id: footprintIds[idx] ?? `${order.id}-${idx + 1}`,
        item_name: order.category,
        quantity: setItemQuantity(item.feeFactors),
        price: item.price,
      })),
    },
  });

export const trackPurchaseEventForMultipleOrders = ({
  jobId,
  totalPrice,
  totalTax,
  affiliation,
  paymentMethod,
  orders,
  category,
}: MultipleOrdersPurchase) =>
  setTag({
    event: 'purchase',
    ecommerce: {
      value: totalPrice,
      tax: totalTax,
      affiliation,
      payment_type: setPaymentType(paymentMethod),
      transaction_id: jobId,
      items: orders?.map(item => ({
        currency: item?.currency ?? '',
        item_category: `${item?.totalKilosCo2 ?? 0} kg CO2e`,
        item_id: item?.orderId ?? '',
        item_name: category,
        quantity: item?.passengers ?? 1,
        price: item?.totalPrice ?? 0,
      })),
    },
  });

export const trackPurchaseEventForSubscription = (subscription: SubscriptionObject, affiliation: Affiliation, paymentMethod: string | undefined) =>
  setTag({
    event: 'purchase',
    ecommerce: {
      value: subscription.totalPrice,
      tax: subscription.totalTax,
      affiliation,
      payment_type: setPaymentType(paymentMethod),
      transaction_id: subscription.id,
      items: subscription.items.map(item => ({
        currency: subscription.currency,
        item_category: `${item.kilosCo2Rounded} kg CO2e`,
        item_id: item.id ?? '',
        item_name: subscription.category,
        quantity: setItemQuantity(item.feeFactors),
        price: item.price,
      })),
    },
  });
