import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { RootState } from '@store';
import { ONE_MINUTE } from '@utils/constants';

import createUserApi from './user';

export const usePartnership = (partnershipId?: string) => {
  const context = useSelector((state: RootState) => state.app.context);
  const currentAccount = useSelector((state: RootState) => state.user.currentAccount);
  const api = createUserApi(context);

  const pid = currentAccount?.partnershipId ?? partnershipId;

  return useQuery({
    queryKey: ['partnerships', pid],
    queryFn: () => (pid ? api.getPartnership(pid) : undefined),
    enabled: !!pid,
    staleTime: ONE_MINUTE * 10,
  });
};
