import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createOrderApi, { OrderParams } from '@api/order';
import { invalidateLegacyEmissionsFilters } from '@api/order/queries';
import { getOrderStatus, validateItemPortfolio } from '@pages/shared/CorporateOffset/utils';
import { AppThunk, RootState } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, metadataSelector, userCurrencySelector } from '@store/user/userSlice';
import { getDisplayName } from '@utils/metadata';
import { fixFloat } from '@utils/numberFormatter';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent } from '@utils/tags';

import { singlePortfolioSanityIdSelector, virtualPortfoliosSelector } from './portfolioSelectSlice';

interface OffsetInfo {
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  poNumber?: string;
  costCenter?: string;
  isPaidByPartner?: boolean;
  [key: string]: string | Option | Date | undefined | boolean | number;
}

interface OrderPayload {
  number: string;
  date: string;
  name?: string;
  id: string;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  orderStatus?: string;
}

export interface CarbonOffsetState {
  amount?: number;
  totalKilosCo2?: number;
  vat?: number;
  customerVat?: number;
  excise?: number;
  customerExcise?: number;
  orderName?: string;
  orderId?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  isLoading: boolean;
  customerPrice?: number;
  price?: number;
  currency?: string;
  orderNumber?: string;
  orderDate?: string;
  selectedPortfolioId?: string;
  isOrderInfoInvalid?: boolean;
  selectedCustomMetadata?: string[];
  isPaidByPartner?: boolean;
  customer?: PartnersCustomerOption;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  isMultiplierActive?: boolean;
  orderStatus?: string;
  attachmentTempUrl?: undefined;
  fullOffsetRows?: undefined;
  costCenter?: string;
  poNumber?: string;
  totalKilosCo2Avoided?: number;
  totalKilosCo2Offset?: number;
  [key: string]: string | Option | Date | number | undefined | boolean | string[] | PartnersCustomerOption;
}

const initialState: CarbonOffsetState = {
  isLoading: false,
  isOrderInfoInvalid: true,
};

const slice = createSlice({
  name: 'offsetCarbon',
  initialState,
  reducers: {
    resetWithoutPortfolioId: (state: CarbonOffsetState): CarbonOffsetState => {
      return { ...initialState, selectedPortfolioId: state.selectedPortfolioId, isMultiplierActive: state.isMultiplierActive };
    },
    setLoading: (state: CarbonOffsetState, action: PayloadAction<boolean>): CarbonOffsetState => {
      state.isLoading = action.payload;

      return state;
    },
    setIsMultiplierActive: (state: CarbonOffsetState, action: PayloadAction<boolean>): CarbonOffsetState => {
      state.isMultiplierActive = action.payload;

      return state;
    },
    setOrder: (state: CarbonOffsetState, action: PayloadAction<OrderPayload>): CarbonOffsetState => {
      state.orderNumber = action.payload.number;
      state.orderDate = action.payload.date;
      state.orderName = action.payload.name ?? '';
      state.orderId = action.payload.id;
      state.inviteId = action.payload.inviteId;
      state.claimCode = action.payload.claimCode;
      state.billId = action.payload.billId;
      state.orderStatus = action.payload.orderStatus;

      return state;
    },
    setAmount: (state: CarbonOffsetState, action: PayloadAction<number>): CarbonOffsetState => {
      state.amount = action.payload;

      return state;
    },
    setOrderInfo: (state: CarbonOffsetState, action: PayloadAction<OffsetInfo>): CarbonOffsetState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setOrderInfoInvalid: (state: CarbonOffsetState, action: PayloadAction<boolean>): CarbonOffsetState => {
      state.isOrderInfoInvalid = action.payload;

      return state;
    },
    setSelectedCustomMetadata: (state: CarbonOffsetState, action: PayloadAction<string[]>): CarbonOffsetState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    setOffsetPrice: (
      state: CarbonOffsetState,
      action: PayloadAction<{ price: number; vat: number; customerPrice: number; customerVat: number; excise: number; customerExcise: number }>,
    ): CarbonOffsetState => {
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.excise = action.payload.excise;
      state.customerPrice = action.payload.customerPrice;
      state.customerVat = action.payload.customerVat;
      state.customerExcise = action.payload.customerExcise;

      return state;
    },
    setEmissionsAvoided: (state: CarbonOffsetState, action: PayloadAction<number>): CarbonOffsetState => {
      state.totalKilosCo2Avoided = action.payload;

      return state;
    },
    setEmissionsOffset: (state: CarbonOffsetState, action: PayloadAction<number>): CarbonOffsetState => {
      state.totalKilosCo2Offset = action.payload;

      return state;
    },
  },
});

const {
  setLoading,
  setOrder,
  setAmount,
  setOrderInfo,
  resetWithoutPortfolioId,
  setOrderInfoInvalid,
  setSelectedCustomMetadata,
  setIsMultiplierActive,
  setOffsetPrice,
  setEmissionsAvoided,
  setEmissionsOffset,
} = slice.actions;

export { setAmount, setIsMultiplierActive, setOrderInfo, setOrderInfoInvalid, setSelectedCustomMetadata };

export default slice.reducer;

// Duplicated code in Order
export const getCarbonOrderObject = ({
  offsetProps,
  currentUser,
  virtualPortfolios,
  mainPortfolioId,
  userCurrency,
  customer,
}: {
  offsetProps: CarbonOffsetState;
  currentUser: AccountLegacy | undefined;
  virtualPortfolios: {
    portfolioId: string;
    weight: number;
  }[];
  mainPortfolioId: string | undefined;
  userCurrency: string;
  customer: PartnersCustomerOption | undefined;
}): OrderParams => {
  const { isMultiplierActive, amount } = offsetProps;
  const tonnesFactor = 1000;

  const totalKilosCo2 = Number(amount ?? 0) * tonnesFactor;
  const portfolioId = mainPortfolioId ?? null;

  const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
  const isDiscountMode = multiplierMode === 'Discount';
  const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
  const discountValue = multiplierValue / (1 + multiplierValue);
  const discountedOffset = isMultiplierActive && multiplierValue ? (totalKilosCo2 ?? 0) * discountValue : 0;
  const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, weight }) => ({
    id: portfolioId,
    weight: isMultiplierActive && isDiscountMode ? weight * discountValue : weight,
  }));

  const order = {
    currency: userCurrency,
    category: 'Carbon',
    items: [
      {
        type: 'impact',
        impact: 'Co2',
        kilosCo2: isDiscountMode ? totalKilosCo2 - discountedOffset : totalKilosCo2,
        portfolio: mainPortfolioId
          ? undefined
          : {
              type: 'Virtual',
              impact: 'co2',
              currency: userCurrency,
              childPortfolios: mappedVirtualPortfolios,
            },
        portfolioId,
        feeFactors: {
          bulkCo2: true,
          percentageBulkCo2: true,
        },
        taxFactors: {
          customerCountry: customer?.country,
          customerCountrySubdivision: customer?.countrySubdivision,
        },
      },
    ],
  };

  return order;
};

export const invalidateQueryAndResetWithoutPortfolioId =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    // We need to invalidate Filter queries to make sure we won't fetch old data, e.g.:
    // a flight number in the `flightNumbers` select input that have already been addressed
    // and should not be selectable.
    invalidateLegacyEmissionsFilters();
    dispatch(resetWithoutPortfolioId());
  };
export const getPriceForOrder =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const carbonOffset = getState().carbonOffset;
    const { customer, currency } = carbonOffset;
    const currentUser = currentUserSelector(getState());
    const currentUserCurrency = userCurrencySelector(getState());
    const context = appContextSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const orderApi = createOrderApi(context);
    const userCurrency = currency ?? currentUserCurrency;
    const customerCurrency = customer?.currency ?? userCurrency;
    const customerId = currentUser?.customerId ?? '';
    const partnershipId = currentUser?.partnershipId ?? '';
    const isConnectContext = context === 'connect';

    dispatch(setLoading(true));
    try {
      const order = getCarbonOrderObject({ offsetProps: carbonOffset, currentUser, virtualPortfolios, mainPortfolioId, userCurrency, customer });

      validateItemPortfolio(order.items?.[0]);

      const response =
        context === 'connect' ? await orderApi.getQuoteForPartner(order, partnershipId) : await orderApi.getQuoteForCustomer(order, customerId);

      let customerPrice = response.totalPrice;
      let customerVat = response.totalVat;
      let customerExcise = response.totalExcise;
      let emissionsAvoided = response.totalKilosCo2Avoided;
      let emissionsOffset = response.totalKilosCo2Offset;
      if (isConnectContext && customer && customerCurrency !== userCurrency) {
        const customerOrder = getCarbonOrderObject({
          offsetProps: carbonOffset,
          currentUser,
          virtualPortfolios,
          mainPortfolioId,
          userCurrency: customerCurrency,
          customer,
        });
        const customerResponse = await orderApi.getQuoteForPartner(customerOrder, partnershipId);
        customerPrice = customerResponse.totalPrice;
        customerVat = customerResponse.totalVat;
        customerExcise = customerResponse.totalExcise;
        emissionsAvoided = customerResponse.totalKilosCo2Avoided;
        emissionsOffset = customerResponse.totalKilosCo2Offset;
      }

      dispatch(
        setOffsetPrice({
          price: response.totalPrice,
          vat: response.totalVat,
          excise: response.totalExcise,
          customerPrice,
          customerVat,
          customerExcise,
        }),
      );

      dispatch(setEmissionsAvoided(emissionsAvoided ?? 0));
      dispatch(setEmissionsOffset(emissionsOffset ?? 0));
    } catch (err) {
      // @ts-ignore
      if (err.data?.includes('Insufficient supply')) {
        dispatch(
          showToast({
            variant: 'error',
            titleI18nKey: 'offset:corporate.portfolios.errorTitle',
            descriptionI18nKey: 'offset:corporate.portfolios.insufficientSupplyErrorMessage',
          }),
        );
        // @ts-ignore
      } else if (err.data) {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchPriceError' }));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createCarbonOrder =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const context = appContextSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const metadata = metadataSelector(getState()) ?? [];
    const currentUser = currentUserSelector(getState());
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;
    const portfolioId = mainPortfolioId ?? null;
    const orderApi = createOrderApi(context);
    const tonnesFactor = 1000;
    const { amount, currency, orderName, isPaidByPartner, customer, isMultiplierActive, price, customerPrice, ...restProps } =
      getState().carbonOffset;
    const { poNumber, costCenter, description } = restProps;
    const { carbonOffset, user } = getState();
    const paymentMethodType = context === 'connect' ? 'Invoice' : user?.currentAccount?.customer?.payment?.defaultPaymentMethod?.type;
    const totalKilosCo2 = Number(amount ?? 0) * tonnesFactor;
    const customSingleMetadata: {
      internalName: string;
      displayName: string;
      value: string | Date;
    }[] = [];
    const userCurrency = userCurrencySelector(getState());
    const orderCurrency = !isPaidByPartner && customer?.currency ? customer.currency ?? userCurrency : currency ?? userCurrency;
    const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
    const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
    const isDiscountMode = multiplierMode === 'Discount';
    const discountValue = multiplierValue / (1 + multiplierValue);
    const discountedOffset = isMultiplierActive && multiplierValue ? (totalKilosCo2 ?? 0) * discountValue : 0;

    Object.keys(restProps)
      .filter(key => !!metadata.find(({ internalName }) => internalName === key))
      .map(key => {
        let value: string | Date = '';

        if ((carbonOffset[key] as Option)?.value) {
          value = (carbonOffset[key] as Option)?.value;
        } else {
          value = carbonOffset[key] as string | Date;
        }
        if (value) {
          customSingleMetadata.push({
            internalName: key,
            value: value,
            displayName: getDisplayName(metadata, key),
          });
        }
      });

    const order = {
      currency: orderCurrency,
      comment: description ?? '',
      description: orderName ?? '',
      category: 'Carbon',
      items: [
        {
          type: 'impact',
          impact: 'Co2',
          kilosCo2: isDiscountMode ? totalKilosCo2 - discountedOffset : totalKilosCo2,
          ...(mainPortfolioId ? {} : { virtualPortfolios }),
          price: isPaidByPartner || !customer ? price ?? 0 : customerPrice ?? 0,
          portfolioId,
          feeFactors: {
            bulkCo2: true,
            percentageBulkCo2: true,
          },
          taxFactors: {
            customerCountry: customer?.country,
            customerCountrySubdivision: customer?.countrySubdivision,
          },
        },
      ],
      metadata: {
        order: {
          channel: 'portal-fulloffset',
        },
        custom: customSingleMetadata,
      },
      costCenter: costCenter ?? '',
      poNumber: poNumber ?? '',
      multiplier: isMultiplierActive && multiplierMode ? { value: multiplierValue, mode: multiplierMode } : undefined,
    };

    try {
      const orderResult =
        context === 'connect'
          ? await orderApi.createForPartner(
              {
                ...order,
                facilitated: { isFacilitated: true, isPaidByPartner: isPaidByPartner ?? true },
                customer: {
                  customerId: customer?.value ?? '',
                },
              },
              user.currentAccount?.partnershipId ?? '',
            )
          : await orderApi.create(order, user.currentAccount?.customerId ?? '');
      if (context !== 'connect' || isPaidByPartner) {
        trackPurchaseEvent(orderResult.order, 'Portal offset', paymentMethodType);
      }
      dispatch(
        setOrder({
          number: orderResult.order.choooseId,
          date: orderResult.order.createdDate,
          name: orderName ?? '',
          id: orderResult.orderId,
          claimCode: orderResult.claimCode,
          inviteId: orderResult.inviteId,
          billId: orderResult.billId,
          orderStatus: getOrderStatus(orderResult.order),
        }),
      );

      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        await client.refetchQueries({ queryKey: ['impact', 'customer', user.currentAccount?.customerId ?? '', { year: new Date().getFullYear() }] });
      }
      dispatch(syncAttribution());
      client.invalidateQueries({ queryKey: ['orders', 'history'] });
      successCb && successCb();
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:createOrderError' }));
      errorCb && errorCb();
    }
  };

export const carbonAmountSelector = createSelector(
  (state: RootState) => state.carbonOffset,
  state => {
    const { amount } = state;

    return fixFloat(amount ?? 0);
  },
);
