import { QueryClient, QueryKey } from '@tanstack/react-query';

const shouldThrowOnError = (error: Error) => {
  // Something is not right with the error object. It's not an AxiosError as
  // it doesn't have a response property and axios.isAxiosError returns false.
  // It only returns status property so I'm not sure if react query overrides
  // some error or axios has wrong type definition.
  // I'm going to ignore this for now.
  // @ts-ignore
  const status = error?.status ?? 200;

  return status >= 500;
};

export const client = new QueryClient({
  defaultOptions: {
    mutations: {
      throwOnError: shouldThrowOnError,
    },
    queries: {
      throwOnError: shouldThrowOnError,
    },
  },
});

export const useAbortQuery = () => {
  const handleAbortQuery = (queryKey: QueryKey) => {
    client.cancelQueries({ queryKey });
  };

  return handleAbortQuery;
};
