import { AccordionItem, AccordionTrigger } from '@chooose/ui';
import styled from 'styled-components';
import { CaretDown } from '@icons';

export const Wrapper = styled.div<{ $isPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.default};
  gap: ${({ theme }) => theme.spacing.sm};
  ${({ theme, $isPadding }) => $isPadding && `padding: ${theme.spacing.md}`};
`;

export const AccordionChevron = styled(CaretDown)`
  transition: transform 0.2s cubic-bezier(0.87, 0, 0.13, 1);
`;

export const StyledAccordionItem = styled(AccordionItem)`
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.radii.default};
    border-top-right-radius: ${({ theme }) => theme.radii.default};
  }
  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.radii.default};
    border-bottom-right-radius: ${({ theme }) => theme.radii.default};
  }
`;

export const StyledAccordionTrigger = styled(AccordionTrigger)`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm};

  &[data-state='open'] > ${AccordionChevron} {
    transform: rotate(180deg);
  }
`;
