import { CustomerUser } from '@api/customer/types';
import { anonymousPortalInstance, defaultInstance } from '@api/instance';

import { Partnership, Settings } from './types';

export const getSettings = async (partnershipId?: string) => {
  const { data } = await anonymousPortalInstance.get<Settings>(`/settings/${partnershipId || ''}`);

  return data;
};

export const getPartnership = async (id: string) => {
  const { data } = await defaultInstance.get<Partnership>(`/partnerships/${id}`);

  return data;
};

export const getPartnershipCustomerUsers = async (id: string, cid: string) => {
  const { data } = await defaultInstance.get<CustomerUser[]>(`/partnerships/${id}/customers/${cid}/users`);

  return data;
};

export const createPartnershipCustomerOwner = async (email: string, id: string, cid: string) => {
  const { data } = await defaultInstance.post(`/partnerships/${id}/customers/${cid}/owner`, { email, customerRoleId: 'owner' });

  return data;
};

export const updatePartnershipCustomerOwner = async (userId: string, id: string, cid: string) => {
  // eslint-disable-next-line no-useless-escape
  const { data } = await defaultInstance.put(`/partnerships/${id}/customers/${cid}/owner`, `\"${userId}\"`, {
    headers: { 'Content-Type': 'text/plain' },
  });

  return data;
};
