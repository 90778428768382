import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as equivalentApi from '@api/equivalents';
import { Settings } from '@api/partnership/types';
import { AppThunk, RootState } from '@store';
import { parseCountryToSelect } from '@utils/countries';

import { showToast } from './toasterSlice';

type FeatureGroup = {
  [featureName: string]: boolean | FeatureGroup;
};

type FeatureFlags = string[] | FeatureGroup;

interface State {
  context: PortalContext;
  countries: Country[];
  showOnboarding: boolean;
  equivalents: Equivalent[];
  theme?: string;
  features: { [key: string]: FeatureFlags };
  logo?: string | null;
  lightLogo?: string | null;
  partnershipId?: string;
  partnershipCurrency?: string;
  languages?: string[];
  isExternalThemeLoaded?: boolean;
  featuresInOrder: FeaturesInOrder;
  feeDisplay: FeeDisplay;
  offsetTypesWithoutSaf: string[];
}

const initialState: State = {
  context: 'wechooose',
  countries: [],
  showOnboarding: false,
  equivalents: [],
  partnershipId: '',
  feeDisplay: {
    allowed: false,
    displayFeeOnPortals: false,
    displayFeeOnTermsAndConditions: false,
  },
  featuresInOrder: {
    wechooose: {},
    connect: {},
    transact: {},
  },
  features: {
    wechooose: {
      flights: {
        byDistance: false,
        fromTo: false,
        upload: false,
      },
      events: false,
      carbon: false,
      disablePercentageSlider: false,
      shipping: {
        voyage: false,
        preCalculatedVoyage: false,
      },
      groundFreight: false,
      railFreight: false,
      airFreight: false,
      teamPerk: false,
      marketingToolkit: false,
      personalFlights: {
        byDistance: false,
        fromTo: false,
        byNumber: false,
      },
      personalEvents: false,
      personalCarbon: false,
      lifestyle: false,
      flightsFootprints: {
        upload: false,
      },
      airFreightFootprint: false,
      hideCertificateWatermarks: false,
      emissions: false,
      emissionsDashboard: false,
      emissionsVariables: false,
      hideRetirementCertificates: false,
      availabilityTooltip: false,
    },
    transact: {
      flights: {
        byDistance: false,
        fromTo: false,
        upload: false,
      },
      events: false,
      carbon: false,
      shipping: {
        voyage: false,
        preCalculatedVoyage: false,
      },
      airFreight: false,
    },
    connect: {
      flights: {
        byDistance: false,
        fromTo: false,
        upload: false,
      },
      events: false,
      carbon: false,
      shipping: {
        voyage: false,
        preCalculatedVoyage: false,
      },
      groundFreight: false,
      railFreight: false,
      airFreight: false,
      teamPerk: false,
      marketingToolkit: false,
      personalFlights: {
        byDistance: false,
        fromTo: false,
        byNumber: false,
      },
      personalEvents: false,
      personalCarbon: false,
      lifestyle: false,
      hidePayAndShareButton: false,
      hideSendPaymentRequestButton: false,
      flightsFootprints: {
        byDistance: false,
        fromTo: false,
        upload: false,
      },
      airFreightFootprint: false,
      availabilityTooltip: false,
    },
  },
  offsetTypesWithoutSaf: [],
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setContext: (state: State, action: PayloadAction<PortalContext>): State => {
      state.context = action.payload;

      return state;
    },

    setEquivalents: (state: State, action: PayloadAction<Equivalent[]>): State => {
      state.equivalents = action.payload;

      return state;
    },
    setFeatures: (state: State, action: PayloadAction<{ features?: Settings['features']; context: string }>): State => {
      const newFeatures: FeatureFlags = {};
      const oneTimeOffsetFeatures: Set<OffsetSubType> = new Set();
      const recurringOffsetFeatures: Set<OffsetSubType> = new Set();
      const flightOffsetFeatures: Set<FlightOffsetTypes> = new Set();
      const shippingOffsetFeatures: Set<ShippingOffsetTypes> = new Set();
      const oneTimeFootprintsFeatures: Set<OffsetSubType> = new Set();
      const flightFootprintsFeatures: Set<FlightOffsetTypes> = new Set();

      if (!action.payload.features) {
        state.features[action.payload.context] = {};

        return state;
      }

      if (!Array.isArray(action.payload.features)) {
        return state;
      }

      action.payload.features.forEach(feature => {
        switch (feature) {
          case 'Flights_FromTo':
            newFeatures.flights = newFeatures.flights ?? {};
            (newFeatures.flights as FeatureGroup).fromTo = true;
            oneTimeOffsetFeatures.add('flights');
            flightOffsetFeatures.add('from/to');
            break;
          case 'Flights_ByDistance':
            newFeatures.flights = newFeatures.flights ?? {};
            (newFeatures.flights as FeatureGroup).byDistance = true;
            oneTimeOffsetFeatures.add('flights');
            flightOffsetFeatures.add('byDistance');
            break;
          case 'Flights_Upload':
            newFeatures.flights = newFeatures.flights ?? {};
            (newFeatures.flights as FeatureGroup).upload = true;
            oneTimeOffsetFeatures.add('flights');
            flightOffsetFeatures.add('upload');
            break;
          case 'FlightsFootprints_Upload':
            newFeatures.flightsFootprints = newFeatures.flightsFootprints ?? {};
            (newFeatures.flightsFootprints as FeatureGroup).upload = true;
            oneTimeFootprintsFeatures.add('flights');
            flightFootprintsFeatures.add('upload');
            break;
          case 'AirFreightFootprint':
            newFeatures.airFreightFootprint = true;
            oneTimeFootprintsFeatures.add('airFreight');
            break;
          case 'Events':
            newFeatures.events = true;
            oneTimeOffsetFeatures.add('events');
            break;
          case 'Carbon':
            newFeatures.carbon = true;
            oneTimeOffsetFeatures.add('carbon');
            break;
          case 'Shipping_Voyage':
            newFeatures.shipping = newFeatures.shipping ?? {};
            (newFeatures.shipping as FeatureGroup).voyage = true;
            oneTimeOffsetFeatures.add('shipping');
            shippingOffsetFeatures.add('voyageCalc');
            break;
          case 'Shipping_Precalculated':
            newFeatures.shipping = newFeatures.shipping ?? {};
            (newFeatures.shipping as FeatureGroup).preCalculatedVoyage = true;
            oneTimeOffsetFeatures.add('shipping');
            shippingOffsetFeatures.add('voyagePreCalc');
            break;
          case 'TeamPerk':
            newFeatures.teamPerk = true;
            recurringOffsetFeatures.add('teamperk');
            break;
          case 'MarketingToolkit':
            newFeatures.marketingToolkit = true;
            break;
          case 'PersonalFlights_FromTo':
            newFeatures.personalFlights = newFeatures.personalFlights ?? {};
            (newFeatures.personalFlights as FeatureGroup).fromTo = true;
            break;
          case 'PersonalFlights_ByDistance':
            newFeatures.personalFlights = newFeatures.personalFlights ?? {};
            (newFeatures.personalFlights as FeatureGroup).byDistance = true;
            break;
          case 'PersonalFlights_ByNumber':
            newFeatures.personalFlights = newFeatures.personalFlights ?? {};
            (newFeatures.personalFlights as FeatureGroup).byNumber = true;
            break;
          case 'PersonalEvents':
            newFeatures.personalEvents = true;
            break;
          case 'PersonalCarbon':
            newFeatures.personalCarbon = true;
            break;
          case 'Lifestyle':
            newFeatures.lifestyle = true;
            break;
          case 'RailFreight':
            newFeatures.railFreight = true;
            oneTimeOffsetFeatures.add('railFreight');
            break;
          case 'GroundFreight':
            newFeatures.groundFreight = true;
            oneTimeOffsetFeatures.add('groundFreight');
            break;
          case 'AirFreight':
            newFeatures.airFreight = true;
            oneTimeOffsetFeatures.add('airFreight');
            break;
          case 'DisablePercentageSlider':
            newFeatures.disablePercentageSlider = true;
            break;
          case 'HidePayAndShareButton':
            newFeatures.hidePayAndShareButton = true;
            break;
          case 'HideSendPaymentRequestButton':
            newFeatures.hideSendPaymentRequestButton = true;
            break;
          case 'HideCertificateWatermarks':
            newFeatures.hideCertificateWatermarks = true;
            break;
          case 'EmissionCompensate':
            oneTimeOffsetFeatures.add('emissions');
            newFeatures.emissions = true;
            break;
          case 'EmissionsDashboard':
            newFeatures.emissionsDashboard = true;
            break;
          case 'EmissionsVariables':
            newFeatures.emissionsVariables = true;
            break;
          case 'HideRetirementCertificates':
            newFeatures.hideRetirementCertificates = true;
            break;
          case 'AvailabilityTooltip':
            newFeatures.availabilityTooltip = true;
        }
      });

      state.features[action.payload.context] = { ...state.features[action.payload.context], ...newFeatures };
      if (action.payload.context === 'wechooose' || action.payload.context === 'connect' || action.payload.context === 'transact') {
        state.featuresInOrder[action.payload.context].oneTimeOffsetFeatures = Array.from(oneTimeOffsetFeatures);
        state.featuresInOrder[action.payload.context].recurringOffsetFeatures = Array.from(recurringOffsetFeatures);
        state.featuresInOrder[action.payload.context].shippingOffsetFeatures = Array.from(shippingOffsetFeatures);
        state.featuresInOrder[action.payload.context].flightOffsetFeatures = Array.from(flightOffsetFeatures);
        state.featuresInOrder[action.payload.context].oneTimeFootprintsFeatures = Array.from(oneTimeFootprintsFeatures);
        state.featuresInOrder[action.payload.context].flightFootprintsFeatures = Array.from(flightFootprintsFeatures);
      }

      return state;
    },
    setOffsetTypesWithoutSaf: (state: State, action: PayloadAction<Settings['safFeatures']>): State => {
      let newOffsetTypes: string[] = [];

      if (action.payload === null) {
        state.offsetTypesWithoutSaf = [];

        return state;
      }

      if (!Array.isArray(action.payload)) {
        return state;
      }

      action.payload.forEach(offsetType => {
        switch (offsetType) {
          case 'Flights_FromTo':
          case 'Flights_ByDistance':
          case 'Flights_Upload':
          case 'PersonalFlights_FromTo':
          case 'PersonalFlights_ByDistance':
          case 'PersonalFlights_ByNumber':
            newOffsetTypes = [...newOffsetTypes, 'flights'];
            break;
          case 'Events':
          case 'PersonalEvents':
            newOffsetTypes = [...newOffsetTypes, 'events'];
            break;
          case 'Carbon':
          case 'PersonalCarbon':
            newOffsetTypes = [...newOffsetTypes, 'carbon'];
            break;
          case 'Shipping_Voyage':
          case 'Shipping_Precalculated':
            newOffsetTypes = [...newOffsetTypes, 'shipping'];
            break;
          case 'TeamPerk':
            newOffsetTypes = [...newOffsetTypes, 'teamPerk'];
            break;
          case 'Lifestyle':
            newOffsetTypes = [...newOffsetTypes, 'lifestyle'];
            break;
          case 'RailFreight':
            newOffsetTypes = [...newOffsetTypes, 'railFreight'];
            break;
          case 'GroundFreight':
            newOffsetTypes = [...newOffsetTypes, 'groundFreight'];
            break;
          case 'AirFreight':
            newOffsetTypes = [...newOffsetTypes, 'airFreight'];
            break;
        }
      });

      state.offsetTypesWithoutSaf = newOffsetTypes;

      return state;
    },
    setPartnershipId: (state: State, action: PayloadAction<string>): State => {
      state.partnershipId = action.payload;

      return state;
    },
    setLogo: (state: State, action: PayloadAction<string | undefined | null>): State => {
      state.logo = action.payload;

      return state;
    },
    setLightLogo: (state: State, action: PayloadAction<string | undefined | null>): State => {
      state.lightLogo = action.payload;

      return state;
    },
    setLanguages: (state: State, action: PayloadAction<string[] | undefined>): State => {
      state.languages = action.payload;

      return state;
    },
    setFeeDisplay: (state: State, action: PayloadAction<Settings['feeDisplay']>): State => {
      if (action.payload === null) {
        return state;
      }
      if (action.payload?.allowed) {
        state.feeDisplay.allowed = action.payload.allowed;
      }
      if (action.payload?.displayFeeOnPortals) {
        state.feeDisplay.displayFeeOnPortals = action.payload.displayFeeOnPortals;
      }
      if (action.payload?.displayFeeOnTermsAndConditions) {
        state.feeDisplay.displayFeeOnTermsAndConditions = action.payload.displayFeeOnTermsAndConditions;
      }

      return state;
    },
    setPartnershipCurrency: (state: State, action: PayloadAction<string | undefined>): State => {
      state.partnershipCurrency = action.payload;

      return state;
    },
  },
});

const {
  setContext,
  setEquivalents,
  setFeatures,
  setOffsetTypesWithoutSaf,
  setLogo,
  setLightLogo,
  setPartnershipId,
  setLanguages,
  setFeeDisplay,
  setPartnershipCurrency,
} = slice.actions;

export {
  setContext,
  setFeatures,
  setFeeDisplay,
  setLanguages,
  setLightLogo,
  setLogo,
  setOffsetTypesWithoutSaf,
  setPartnershipCurrency,
  setPartnershipId,
};

export default slice.reducer;

export const loadEquivalents =
  (kilosCo2: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const equivalents = await equivalentApi.getEquivalents({ kilosCo2, language: 'en' });
      const properEquivalents = [
        'great_pyramid',
        'colosseum',
        'victoria_falls',
        'forest_grown_us',
        'forest_preserved_us',
        'laptop',
        'iphone12',
        'tree_seedlings',
        'easter_island_statues',
        'petrol',
        'avocado',
        'latte',
        'black_coffee',
        'household_electricity_nl',
      ];
      dispatch(setEquivalents(equivalents.filter(equivalent => properEquivalents.includes(equivalent.id))));
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
    }
  };

export const appContextSelector = createSelector(
  (state: RootState) => state.app,
  app => app.context,
);

export const partnershipIdSelector = createSelector(
  (state: RootState) => state.app,
  app => app.partnershipId,
);

const countriesSelector = createSelector(
  (state: RootState) => state.app,
  app => app.countries,
);

export const isShippingEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => {
    const shipping = (app.features[app.context] as FeatureGroup)?.shipping as FeatureGroup;

    return !!(shipping?.voyage || shipping?.preCalculatedVoyage);
  },
);

export const isFlightOffsetEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => {
    const flights = (app.features[app.context] as FeatureGroup)?.flights as FeatureGroup;

    return !!(flights?.byDistance || flights?.fromTo || flights?.upload);
  },
);

export const isAirFreightOffsetEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => !!(app.features[app.context] as FeatureGroup)?.airFreight,
);

const isFacilitatedShippingEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => {
    const shipping = (app.features.connect as FeatureGroup)?.shipping as FeatureGroup;

    return !!(shipping?.voyage || shipping?.preCalculatedVoyage);
  },
);

const isFacilitatedFlightOffsetEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => {
    const flights = (app.features.connect as FeatureGroup)?.flights as FeatureGroup;

    return !!(flights?.byDistance || flights?.fromTo || flights?.upload);
  },
);

const isFacilitatedCarbonOffsetEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => !!(app.features.connect as FeatureGroup)?.carbon,
);

const isFacilitatedAirFreightOffsetEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => !!(app.features.connect as FeatureGroup)?.airFreight,
);

export const isFacilitatedOneTimeOffsetEnabledSelector = createSelector(
  isFacilitatedFlightOffsetEnabledSelector,
  isFacilitatedShippingEnabledSelector,
  isFacilitatedCarbonOffsetEnabledSelector,
  isFacilitatedAirFreightOffsetEnabledSelector,
  (flights, shipping, carbon, airFreight) => !!(flights || shipping || carbon || airFreight),
);

export const oneTimeOffsetsInOrderSelector = createSelector(
  (state: RootState) => state.app,
  app => app.featuresInOrder[app.context].oneTimeOffsetFeatures,
);

export const recurringOffsetsInOrderSelector = createSelector(
  (state: RootState) => state.app,
  app => app.featuresInOrder[app.context].recurringOffsetFeatures,
);

export const flightOffsetsInOrderSelector = createSelector(
  (state: RootState) => state.app,
  app => app.featuresInOrder[app.context].flightOffsetFeatures,
);

export const shippingOffsetsInOrderSelector = createSelector(
  (state: RootState) => state.app,
  app => app.featuresInOrder[app.context].shippingOffsetFeatures,
);

export const oneTimeFootprintsFeaturesInOrderSelector = createSelector(
  (state: RootState) => state.app,
  app => app.featuresInOrder[app.context].oneTimeFootprintsFeatures,
);

export const supportedCountriesISO2Selector = createSelector(countriesSelector, countries =>
  countries
    .filter(country => country.apiSupport.percapitaFootprint.inPopulationSource && country.apiSupport.percapitaFootprint.inPikSource)
    .map(parseCountryToSelect)
    .sort((countryA, countryB) => {
      if (countryA.label < countryB.label) {
        return -1;
      } else if (countryA.label > countryB.label) {
        return 1;
      }

      return 0;
    }),
);

export const hidePayAndShareButtonSelector = createSelector(
  (state: RootState) => state.app,
  app => !!(app.features.connect as FeatureGroup)?.hidePayAndShareButton,
);

export const hideSendPaymentRequestButtonSelector = createSelector(
  (state: RootState) => state.app,
  app => !!(app.features.connect as FeatureGroup)?.hideSendPaymentRequestButton,
);

export const flightFootprintsInOrderSelector = createSelector(
  (state: RootState) => state.app,
  app => app.featuresInOrder[app.context].flightFootprintsFeatures,
);

export const isFlightFootprintEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => {
    const flights = (app.features[app.context] as FeatureGroup)?.flightsFootprints as FeatureGroup;

    return !!(flights?.byDistance || flights?.fromTo || flights?.upload);
  },
);

export const isAirFreightFootprintEnabledSelector = createSelector(
  (state: RootState) => state.app,
  app => !!(app.features[app.context] as FeatureGroup)?.airFreightFootprint,
);

export const isEmissionsDashboardEnabledSelector = createSelector(
  (state: RootState) => state.app,
  // @ts-ignore
  app => app.features?.wechooose?.emissionsDashboard ?? false,
);

export const partnershipCurrencySelector = createSelector(
  (state: RootState) => state.app,
  // @ts-ignore
  app => app.partnershipCurrency,
);

export const areRetirementCertificatesHiddenSelector = createSelector(
  (state: RootState) => state.app,
  // @ts-ignore
  app => app.features?.wechooose?.hideRetirementCertificates ?? false,
);

export const showAvailabilityTooltip = createSelector(
  (state: RootState) => state.app,
  app => (app.features[app.context] as FeatureGroup)?.availabilityTooltip,
);
