import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { theme, Typography } from '@chooose/ui';
import { useCustomer } from '@api/customer/queries';
import { useCurrentContextMainPortfolio } from '@api/project/queries';
import { useSanityPortfolios } from '@api/sanity/project.queries';
import { Loader } from '@components/Loader';
import { LoaderWrapper } from '@components/LoaderWrapper';
import { useIsCarbonEnabled } from '@hooks/partnershipFeatureFlags/useIsCarbonEnabled';
import { useIsEventEnabled } from '@hooks/partnershipFeatureFlags/useIsEventEnabled';
import { useIsFlightsEnabled } from '@hooks/partnershipFeatureFlags/useIsFlightsEnabled';
import { usePortalItemFees } from '@hooks/usePortalItemFees';

import { FeeDetails, SectionBox, StyledLi, StyledUl } from './styles';

const Prices: FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation('settings');
  const isFlightOffsetEnabled = useIsFlightsEnabled();
  const isEventOffsetEnabled = useIsEventEnabled();
  const isCarbonOffsetEnabled = useIsCarbonEnabled();
  const itemFee = usePortalItemFees();
  const { data: customer } = useCustomer();
  const { data: portfolioData, isLoading: portfolioDataIsLoading } = useCurrentContextMainPortfolio();
  const { data: sanityData, isLoading: sanityDataIsLoading } = useSanityPortfolios([portfolioData?.sanityId || '']);
  const currency = customer?.fees.feeCurrency;
  const isLoading = [portfolioDataIsLoading, sanityDataIsLoading].some(e => e);
  const sanityPortfolioData = sanityData?.[0];
  const isSinglePortfolio = sanityPortfolioData?.projects.length === 1;
  const portfolioSlug = sanityPortfolioData?.slug;
  const projectSlug = isSinglePortfolio ? sanityPortfolioData?.projects[0].slug : undefined;
  const projectUrl = `${`/portfolios/${portfolioSlug}/${projectSlug ? 'projects/' + projectSlug : ''}`}`;
  const feePerFlightPax = isFlightOffsetEnabled && itemFee?.feePerFlightPax;
  const feePerEventFlightPax = isEventOffsetEnabled && itemFee?.feePerEventFlightPax;
  const feePerEventPerson = isEventOffsetEnabled && itemFee?.feePerEventPerson;
  const co2Table = isCarbonOffsetEnabled && itemFee?.bulkCo2Fees;
  const defaultPercentageFee = itemFee?.defaultPercentageFee || 0;
  const displayPortalFeeInformation =
    !!defaultPercentageFee && !feePerFlightPax && !feePerEventPerson && !feePerEventFlightPax && !(co2Table && co2Table?.length);
  const portalFeeInformation = [
    'prices.portalFee.diligence',
    'prices.portalFee.delivery',
    'prices.portalFee.reporting',
    'prices.portalFee.support',
    'prices.portalFee.providers',
  ];

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <SectionBox>
      <Typography tag='h4'>{t('prices.carbonOffset.title')}</Typography>
      <StyledUl>
        {displayPortalFeeInformation && (
          <StyledLi>
            <div>
              <Typography variant='secondary' color={theme.colors.text.primary} tag='span'>
                {t('prices.portalFee.title')}
              </Typography>
              <FeeDetails>
                <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
                  {t('prices.portalFee.header')}
                </Typography>
                {portalFeeInformation.map((item, index) => (
                  <Typography variant='primary' color={theme.colors.text.secondary} tag='span' key={index}>
                    - {t(item)}
                  </Typography>
                ))}
                <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
                  {t('prices.portalFee.footer')}
                </Typography>
              </FeeDetails>
            </div>
            <Typography variant='primary' color={theme.colors.text.primary} tag='span'>
              22.5%
            </Typography>
            <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
              {t('prices.portalFee.totalOrderValue')}{' '}
            </Typography>
          </StyledLi>
        )}
        {feePerFlightPax && (
          <StyledLi>
            <Typography variant='secondary' color={theme.colors.text.primary} tag='span'>
              {t('prices.carbonOffset.flights')}
            </Typography>
            <Typography variant='primary' color={theme.colors.text.primary} tag='span'>
              {feePerFlightPax.toFixed(2)}
            </Typography>
            <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
              {currency} {t('prices.per')} {t('prices.carbonOffset.pax')}
            </Typography>
          </StyledLi>
        )}
        {feePerEventPerson && (
          <StyledLi>
            <Typography variant='secondary' color={theme.colors.text.primary} tag='span'>
              {t('prices.carbonOffset.events')}
            </Typography>
            <Typography variant='primary' color={theme.colors.text.primary} tag='span'>
              {(feePerEventPerson || 20).toFixed(2)}
            </Typography>
            <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
              {currency} {t('prices.per')} {t('prices.carbonOffset.person')}
            </Typography>
          </StyledLi>
        )}
        {feePerEventFlightPax && (
          <StyledLi>
            <Typography variant='secondary' color={theme.colors.text.primary} tag='span'>
              {!feePerEventPerson && t('prices.carbonOffset.events')}
            </Typography>
            <Typography variant='primary' color={theme.colors.text.primary} tag='span'>
              {(feePerEventFlightPax || 20).toFixed(2)}
            </Typography>
            <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
              {currency} {t('prices.per')} {t('prices.carbonOffset.person')}
            </Typography>
          </StyledLi>
        )}

        {co2Table &&
          co2Table.length > 0 &&
          co2Table.map((tableItem, index) => {
            const from = `${t('prices.carbonOffset.from')} ${tableItem.tonnesCo2Floor}`;

            const to = co2Table[index + 1] && `${t('prices.carbonOffset.to')} ${co2Table[index + 1].tonnesCo2Floor}`;

            const tons = t('prices.carbonOffset.tons');

            return (
              <StyledLi key={index}>
                <Typography variant='secondary' color={theme.colors.text.primary} tag='span'>
                  {index === 0 && (
                    <>
                      {t('prices.carbonOffset.bulk')} CO<sub>2</sub>e ({from} {to} {tons})
                    </>
                  )}
                </Typography>
                <Typography variant='primary' color={theme.colors.text.primary} tag='span'>
                  {tableItem.feePerTonne?.toFixed(2)}
                </Typography>
                <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
                  {currency} {t('prices.per')} {t('prices.carbonOffset.ton')}
                </Typography>
              </StyledLi>
            );
          })}
      </StyledUl>
      {portfolioData && (
        <>
          <Typography tag='h4'>{t('prices.portfolio.title')}</Typography>
          <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
            {t('prices.portfolio.yourPortfolio')}{' '}
            <Link to={projectUrl} style={{ color: theme.colors.accent.default }}>
              {portfolioData?.title}{' '}
            </Link>
          </Typography>
          <StyledUl>
            <StyledLi>
              <Typography variant='secondary' color={theme.colors.text.primary} tag='span'>
                {t('prices.portfolio.pricePerTonne')}
              </Typography>
              <Typography variant='primary' color={theme.colors.text.primary} tag='span'>
                {portfolioData.pricePerTonneCo2?.toFixed(2)}
              </Typography>
              <Typography variant='primary' color={theme.colors.text.secondary} tag='span'>
                {currency} {t('prices.per')} {t('prices.carbonOffset.ton')}
              </Typography>
            </StyledLi>
          </StyledUl>
        </>
      )}
    </SectionBox>
  );
};

export default Prices;
