import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';

interface Props {
  animate?: boolean;
}

const BoxContentWrapper = motion(Flex);

export const BoxContent: FC<React.PropsWithChildren<Props & FlexProps>> = ({ children, animate, ...props }) => {
  return (
    <BoxContentWrapper
      direction='column'
      initial={animate ? { opacity: 0, y: -25 } : { opacity: 1, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        type: 'tween',
        duration: 0.2,
        ease: 'easeInOut',
      }}
      layerStyle='whiteBox'
      {...props}>
      {children}
    </BoxContentWrapper>
  );
};
