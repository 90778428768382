export type Actions = 'access' | 'view' | 'edit' | 'delete' | 'create';
export type Subjects = 'Car' | 'CompanySettings' | 'Authorization' | 'Orders' | 'BillingReports' | 'Payment';

interface PermissionOrFeature {
  name: string;
  can: Actions;
  resource: Subjects;
}

export const permissions: PermissionOrFeature[] = [
  {
    name: 'CustomerUpdate',
    can: 'view',
    resource: 'CompanySettings',
  },
  {
    name: 'CustomerUpdate',
    can: 'edit',
    resource: 'CompanySettings',
  },
  {
    name: 'AuthorizationCreate',
    can: 'create',
    resource: 'Authorization',
  },
  {
    name: 'AuthorizationRead',
    can: 'view',
    resource: 'Authorization',
  },
  {
    name: 'AuthorizationUpdate',
    can: 'edit',
    resource: 'Authorization',
  },
  {
    name: 'AuthorizationDelete',
    can: 'delete',
    resource: 'Authorization',
  },
  {
    name: 'OrderCreate',
    can: 'create',
    resource: 'Orders',
  },
  {
    name: 'OrderRead',
    can: 'view',
    resource: 'Orders',
  },
  {
    name: 'BillRead',
    can: 'view',
    resource: 'BillingReports',
  },
  {
    name: 'PaymentManage',
    can: 'access',
    resource: 'Payment',
  },
];
