import { theme } from '@chakra-ui/react';

export default {
  ...theme.shadows,
  header: '0 0 0 transparent',
  whiteBox: '0px 2px 4px rgba(230, 234, 240, 0.5)',
  modal: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  offsetTile: '0px 10px 30px rgba(18, 50, 51, 0.05)',
  menuList: `0 2px 50px rgba(0, 0, 0, 0.17)`,
  blueBubble: 'inset -2px -2px 13px rgba(0, 0, 0, 0.08)',
};
