import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addDays, format } from 'date-fns';
import createTransactionApi from '@api/transaction';
import { OrderValues } from '@api/transaction/types';
import { AppThunk } from '@store';
import { appContextSelector } from '@store/app/appSlice';

interface State {
  items: Transaction[];
  summaryItems: TransactionSummary[] | CorporateTransactionSummary[];
  isLoading: boolean;
  totalItems: number;
  orderValues?: OrderValues;
}

interface TransactionPayload {
  total: number;
  transactions: Transaction[];
}

const initialState: State = {
  items: [],
  summaryItems: [],
  isLoading: false,
  totalItems: 0,
};

const LIMIT = 10;

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactions: (state: State, action: PayloadAction<TransactionPayload>): State => {
      state.items = action.payload.transactions;
      state.totalItems = action.payload.total;

      return state;
    },
    setTransactionsSummary: (state: State, action: PayloadAction<TransactionSummary[]>): State => {
      state.summaryItems = action.payload;

      return state;
    },
    setLoading: (state: State, action: PayloadAction<boolean>): State => {
      state.isLoading = action.payload;

      return state;
    },
    setOrderValues: (state: State, action: PayloadAction<OrderValues>): State => {
      state.orderValues = action.payload;

      return state;
    },
  },
});

const { setLoading, setTransactions, setTransactionsSummary, setOrderValues } = slice.actions;

export default slice.reducer;

export const loadTransactions =
  (filters?: TransactionFilters): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const transactionApi = createTransactionApi(context);
    dispatch(setLoading(true));

    try {
      const page = filters && filters.page ? filters.page - 1 : 0;
      const data = await transactionApi.getAll(
        {
          category: filters?.category,
          fromCreatedDate: filters?.from,
          toCreatedDate: filters?.to,
          'metadata.customer.residentCountry': filters?.country,
          'metadata.order.channel': filters?.channel,
          'payment.status': filters?.paymentStatus,
          referenceid: filters?.referenceId,
          limit: LIMIT,
          offset: page * LIMIT,
        },
        currentAccount?.partnershipId ?? '',
      );
      dispatch(
        setTransactions({
          transactions: data.items,
          total: data.totalCount,
        }),
      );
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const loadTransactionsSummary =
  (filters: TransactionSummaryFilters): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const transactionApi = createTransactionApi(context);

    dispatch(setLoading(true));
    const fromCreatedDate = filters.from ? format(new Date(filters.from), 'yyyy-MM-dd') : undefined;
    const toCreatedDate = filters.to ? format(addDays(new Date(filters.to), 1), 'yyyy-MM-dd') : undefined;

    try {
      const data = await transactionApi.getAllSummary(
        {
          category: filters.category,
          fromCreatedDate,
          toCreatedDate: toCreatedDate ?? format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        },
        currentAccount?.partnershipId ?? '',
      );
      dispatch(setTransactionsSummary(data));
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const loadOrderValuesForPartnership =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const transactionApi = createTransactionApi(context);

    try {
      const data = await transactionApi.getOrderValuesForPartnership(currentAccount?.partnershipId ?? '');
      dispatch(setOrderValues(data));
    } catch {
      // err
    }
  };
