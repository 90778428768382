import { defineStyleConfig } from '@chakra-ui/react';

const FormError = defineStyleConfig({
  baseStyle: {
    text: {
      color: 'red',
      fontSize: 'xs',
      fontWeight: 'normal',
      marginTop: '3px',
    },
  },
});

export default FormError;
