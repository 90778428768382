import { FC, PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Flex, Icon, Link, Text } from '@chakra-ui/react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button } from '@components/Button';
import { WarningCircle } from '@icons';

const SSOLoginError: FC<PropsWithChildren> = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const { t } = useTranslation('signup');
  const email = 'support@chooose.today';

  if (!isAuthenticated || (isAuthenticated && !sessionStorage.getItem('loginError'))) {
    return <Redirect to={'/'} />;
  }

  function logout() {
    if (inProgress !== 'none') {
      return;
    }

    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }

  return (
    <Flex w='100%' h='100%'>
      <Flex
        flexDir='column'
        alignItems='center'
        p='44px 16px 27px'
        m='80px auto 0'
        border='1px solid'
        borderColor='secondary.300'
        borderRadius='portfolio'>
        <Icon as={WarningCircle} boxSize='24px' sx={{ '& > *': { strokeWidth: '10px' } }} />
        <Text fontWeight={500} mt='17px'>
          {t('loginError.title')}
        </Text>
        <Text fontSize='md' mt='12px' textAlign='center'>
          <Trans t={t} i18nKey={'loginError.description'}>
            If this issue persists, please contact support at
            <Link href={'mailto:support@chooose.today'} target='_blank' rel='noopener noreferrer' textDecoration='underline'>
              {/* @ts-ignore */}
              {{ email }}
            </Link>
          </Trans>
        </Text>
        <Button variant='link' fontWeight={500} mt='15px' onClick={logout}>
          {t('loginError.goBack')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SSOLoginError;
