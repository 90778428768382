import { Account } from '@api/user/types';

import { Features } from './types';

export const flightsFlags: Features[] = ['Flights_FromTo', 'Flights_ByDistance', 'Flights_Upload'];
export const eventFlags: Features[] = ['Events'];
export const carbonFlags: Features[] = ['Carbon'];
const baseFeatureFlags: Features[] = [...flightsFlags, ...eventFlags, ...carbonFlags, 'Shipping_Voyage', 'Shipping_Precalculated'];

export const partnerOffsetFeatureFlags: Features[] = [...baseFeatureFlags, 'AirFreight'];
const customerOffsetFeatureFlags: Features[] = [...baseFeatureFlags, 'TeamPerk'];
const transactOffsetFeatureFlags: Features[] = [...baseFeatureFlags];

export const offsetFeatureFlags: Partial<{ [key in Account['type']]: Features[] }> = {
  // Corporate Customer
  WeChooose: customerOffsetFeatureFlags,
  Transact: transactOffsetFeatureFlags,
  // Connect
  WeChoooseCommmunity: partnerOffsetFeatureFlags,
};

export const footprintCreationFeatureFlags: Features[] = ['AirFreightFootprint', 'FlightsFootprints_Upload'];
