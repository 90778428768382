import { defineStyleConfig } from '@chakra-ui/react';

const Input = defineStyleConfig({
  baseStyle: {
    field: {
      height: 'auto',
      bg: 'white',
      borderRadius: '3px',
    },
  },
  sizes: {
    lg: {
      field: {
        h: '50px',
        fontSize: 'md',
      },
      addon: {
        h: '50px',
        fontSize: 'md',
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: 'white',
        borderRadius: '3px',
        border: '1px solid',
        borderColor: 'secondary.300',
        paddingLeft: '19px',
        paddingRight: '19px',
        transition: 'all 0.2s, border-width 0s',
        height: 'auto',
        _hover: {
          borderColor: 'secondary.400',
        },
        _focus: {
          borderWidth: '2px',
          borderColor: 'primary.400',
          boxShadow: 'none',
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed',
          borderColor: 'secondary.300',
          backgroundColor: 'secondary.200',
        },
        _invalid: {
          borderColor: 'error.primary',
          backgroundColor: 'error.background',
          boxShadow: 'none',
          _focus: {
            borderColor: 'error.primary',
          },
        },
      },
      addon: {
        borderBottom: '2px solid',
        borderColor: 'secondary.300',
        borderRadius: 0,
        paddingX: 0,
        bg: 'transparent',
      },
    },
    small: {
      field: {
        width: '70px',
        h: '38px',
        color: 'secondary.700',
        _hover: {
          background: 'primary.100',
        },
        _focus: {
          bg: 'primary.100',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'lg',
  },
});

export default Input;
