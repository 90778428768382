import { useAuthHandlerStore } from '@lib/msal/authHandlerStore';
import { Settings } from '@api/partnership/types';
import { Account, CustomerAccount, PartnerAccount, SupplierAccount, User } from '@api/user/types';
import { getTypes, hasType } from '@utils/accountType';

import { RedirectIntent } from './types';

const DEFAULT_PORTAL_URL = import.meta.env.REACT_APP_ACCOUNT_NOT_FOUND_REDIRECT_URL ?? 'https://app.chooose.today';

const logoutOutOfApp = (redirectUri = '/') =>
  useAuthHandlerStore.getState().setAuthHandler({ type: 'LogoutRedirect', request: { postLogoutRedirectUri: redirectUri } });

export const getCurrentAccountAndContext = (user: User, settings: Settings, intentQuery?: RedirectIntent) => {
  const accountTypes = getTypes(user.accounts);
  let context = intentQuery?.account ?? getDefaultContext(accountTypes);

  if (!hasType(accountTypes, ['WeChooose', 'WeChoooseCommmunity', 'Transact'])) {
    redirectOutOfApp(user, context);

    return {
      redirect: true,
    };
  }

  const selectedAccountId = intentQuery?.accountId;
  const customerAccounts = user.accounts.filter(acc => isCustomerAccount(acc, settings));
  const connectAccounts = user.accounts.filter(acc => isConnectAccount(acc, settings));
  const transactAccounts = user.accounts.filter(acc => isTransactAccount(acc, settings));

  let matchingAccounts = [...customerAccounts, ...connectAccounts, ...transactAccounts];
  const matchingAccountsTypes = getTypes(matchingAccounts);
  if (!hasType(matchingAccountsTypes, [toAccountType(context)])) {
    context = getDefaultContext(matchingAccountsTypes);
  }

  const selectedAccount = matchingAccounts.find(acc => isWantedAccount(acc, selectedAccountId));
  if (selectedAccount) {
    matchingAccounts = [selectedAccount];
    context = toContext(selectedAccount.type);
  } else if (context === 'wechooose') {
    matchingAccounts = customerAccounts;
  } else if (context === 'connect') {
    matchingAccounts = connectAccounts;
  } else if (context === 'transact') {
    matchingAccounts = transactAccounts;
  }

  const account = matchingAccounts[0];

  if (!account) {
    if (settings.features?.includes('HideContextSwitcher')) {
      logoutOutOfApp();

      return {
        redirect: true,
      };
    }
    redirectOutOfApp(user, context);

    return {
      redirect: true,
    };
  }

  return { account, context };
};

const toAccountType = (context: PortalContext) => {
  const contextToAccountType: { [x: string]: Account['type'] } = {
    wechooose: 'WeChooose',
    connect: 'WeChoooseCommmunity',
    transact: 'Transact',
  };

  return contextToAccountType[context];
};

const toContext = (accountType: Account['type']) => {
  const accountTypeToContext: { [x: string]: PortalContext } = {
    WeChooose: 'wechooose',
    WeChoooseCommmunity: 'connect',
    Transact: 'transact',
  };

  return accountTypeToContext[accountType];
};

const isWantedAccount = (account: Account, accountId?: string) => {
  return accountId ? accountId === account.id : true;
};

const isCustomerAccount = (account: Account, settings: Settings) => {
  if (account.type !== 'WeChooose') {
    return false;
  }

  const accountBelongsToPartnership = account.context?.sourcePartnershipId === settings.partnershipId;

  return accountBelongsToPartnership;
};

const isTransactAccount = (account: Account, settings: Settings) => {
  if (account.type !== 'Transact') {
    return false;
  }

  const accountBelongsToPartnership = account.context?.sourcePartnershipId === settings.partnershipId;

  return accountBelongsToPartnership;
};

const isConnectAccount = (account: Account, settings: Settings) => {
  if (account.type !== 'WeChoooseCommmunity') {
    return false;
  }
  const accountBelongsToPartnership = [
    account.partnershipId,
    account.context?.parentPartnershipId ?? '',
    ...(account.context?.childPartnershipIds ?? []),
  ].includes(settings.partnershipId);
  const isDefaultPortal = window.location.origin === DEFAULT_PORTAL_URL;

  return accountBelongsToPartnership || isDefaultPortal;
};

const redirectOutOfApp = (user: User, context: PortalContext) => {
  const REDIRECT_TO_MYCHOOOSE = import.meta.env.REACT_APP_MYCHOOOSE_REDIRECT_URL;

  if (context === 'mychooose' && Boolean(REDIRECT_TO_MYCHOOOSE)) {
    logoutOutOfApp(REDIRECT_TO_MYCHOOOSE);

    return;
  }

  const customerAccount = user.accounts.find((item): item is CustomerAccount => item.type === 'WeChooose' && !!item.context?.partnerPortalUrl);
  const customerAccountUrl = customerAccount?.context?.partnerPortalUrl;
  if (context === 'wechooose' && customerAccountUrl) {
    if (customerAccountUrl) {
      logoutOutOfApp(customerAccountUrl);

      return;
    }
  }

  const connectAccount = user.accounts.find(
    (item): item is PartnerAccount => item.type === 'WeChoooseCommmunity' && !!item.context?.partnerPortalUrl,
  );
  const connectAccountUrl = connectAccount?.context?.partnerPortalUrl;
  if (context === 'connect' && connectAccountUrl) {
    logoutOutOfApp(connectAccountUrl);

    return;
  }

  const supplierAccount = user.accounts.find((item): item is SupplierAccount => item.type === 'Supplier' && !!item.context?.eamAppUrl);
  const supplierAccountUrl = supplierAccount?.context?.eamAppUrl;
  if (supplierAccountUrl) {
    logoutOutOfApp(supplierAccountUrl);

    return;
  }

  const mychoooseAccount = user.accounts.find(item => item.type === 'MyChooose');
  if (mychoooseAccount && Boolean(REDIRECT_TO_MYCHOOOSE)) {
    logoutOutOfApp(REDIRECT_TO_MYCHOOOSE);

    return;
  }

  const anyAccountUrl = customerAccountUrl ?? connectAccountUrl;
  if (anyAccountUrl) {
    logoutOutOfApp(anyAccountUrl);

    return;
  }

  logoutOutOfApp();
};

const getDefaultContext = (accountTypes: Account['type'][]): PortalContext => {
  if (hasType(accountTypes, ['WeChooose'])) {
    return 'wechooose';
  } else if (hasType(accountTypes, ['WeChoooseCommmunity'])) {
    return 'connect';
  } else if (hasType(accountTypes, ['Transact'])) {
    return 'transact';
  }

  return 'wechooose';
};
