import { defineStyleConfig } from '@chakra-ui/react';

import { getButtonBorderRadius } from '@utils/styles';

const Button = (customBorderRadius?: number) =>
  defineStyleConfig({
    baseStyle: {
      borderRadius: `${getButtonBorderRadius(customBorderRadius) ?? 'button'}`,
      fontWeight: 600,
      maxW: '100%',
      pos: 'relative',
      px: '24px',

      // This may look ugly, but it's the only way I can think of to position the icons within the button
      '& > span:first-of-type.chakra-button__icon': {
        marginRight: 'auto',
      },
      '& > span:last-of-type.chakra-button__icon': {
        marginLeft: 'auto',
        marginRight: '0',
      },
    },
    variants: {
      primary: {
        bg: 'buttons.primary',
        color: 'buttons.primaryText',
        '&[disabled]:hover': { bg: 'secondary.300' },
        _hover: {
          bg: 'buttons.primaryButtonHover',
        },
        _disabled: {
          bg: 'secondary.300',
          color: 'secondary.500',
          opacity: 1,
        },
      },
      secondary: {
        bg: 'buttons.secondary',
        color: 'buttons.secondaryText',
        '&[disabled]:hover': { bg: 'secondary.300', color: 'secondary.500' },
        _hover: {
          bg: 'buttons.primary',
          color: 'buttons.primaryText',
        },
        _disabled: {
          bg: 'secondary.300',
          color: 'secondary.500',
          opacity: 1,
        },
      },
      outline: {
        bg: 'none',
        color: 'secondary.700',
        border: '1px solid',
        borderColor: 'secondary.700',
        _hover: {
          '&:not(:disabled)': {
            bg: 'buttons.primary',
            color: 'buttons.primaryText',
            borderColor: 'buttons.primary',
          },
        },
        _active: { bg: 'buttons.primary', color: 'buttons.primaryText', borderColor: 'buttons.primary' },
      },
      ghost: {
        bg: 'none',
        border: 'none',
        color: 'secondary.500',
        _hover: {
          color: 'primary.500',
          bg: 'none',
        },
      },
      ghostWithHover: {
        bg: 'none',
        border: 'none',
        color: 'primary.500',
        _hover: {
          color: 'primary.500',
          bg: 'primary.100',
        },
      },
      link: {
        bg: 'none',
        border: 'none',
        color: 'buttons.primary',

        _hover: {
          color: 'buttons.primary',
          textDecoration: 'underline',
          bg: 'none',
        },
      },
      dashed: {
        bg: 'primary.50',
        borderRadius: 'base',
        border: '1px dashed',
        borderColor: 'secondary.300',
        color: 'secondary.700',
      },
      icon: {
        bg: 'primary.100',
        borderRadius: 'round',
        color: 'primary.500',
        '&>svg': {
          height: '24px',
          width: '24px',
        },

        '&[disabled]:hover': { bg: 'secondary.300' },
        _hover: {
          bg: 'primary.500',
          color: 'other.primaryBgText',
        },
        _disabled: {
          bg: 'secondary.300',
          color: 'secondary.500',
          opacity: 1,
        },
      },
    },
    sizes: {
      lg: {
        height: '50px',
        fontSize: 'md',
      },
      sm: {
        height: '40px',
        fontSize: 'xs',
      },
      linkSm: {
        minW: 'unset',
        height: 'auto',
        fontSize: 'xs',
      },
      icon: {
        h: '50px',
        w: '50px',
      },
    },

    defaultProps: {
      size: 'lg',
      variant: 'primary',
    },
  });

export default Button;
