import client from './client';

export const getFaqsFor = async (page: string): Promise<SanityFAQCollection[]> => {
  const query = `
  *[slug.current=="${page}"][0]{
    faqs[]->{
      faqQuestion->{textContent},
      faqAnswer->{textContent}
    }
  }
  `;
  const data = await client.fetch<SanityFAQCollection>(query);

  return [data];
};

export const getFaqsForPages = async (page: string, partnerPage: string): Promise<SanityFAQCollection[]> => {
  const query = `
  *[slug.current == "${page}" || slug.current == "${partnerPage}"]{
    slug {
      current
    },
    inheritEnabled,
    faqs[]->{
      faqQuestion->{textContent},
      faqAnswer->{textContent}
    }
  }
  `;
  const data = await client.fetch<SanityFAQCollection[]>(query);

  return data;
};
