import { AxiosInstance } from 'axios';

import { createContextAwareHandlers } from './instance';

export interface ShippingParams {
  activity: string;
  type: string;
  size: number;
  lca: 'WTW' | 'TTW';
  km: number;
  weight: number;
  customerId: string | undefined;
  calculateForCustomer: string | undefined;
}

const getShippingFootprint =
  (instance: AxiosInstance) =>
  async (params: ShippingParams): Promise<ShippingOffset> => {
    const { data } = await instance.post<ShippingOffset>('/footprint/shipping', undefined, {
      params,
    });

    return data;
  };

const getShippingMetadata = (instance: AxiosInstance) => async (): Promise<ShippingMetadata> => {
  const { data } = await instance.get<ShippingMetadata>('/metadata/shipping');

  return data;
};

const handlers = {
  getShippingFootprint,
  getShippingMetadata,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createShippingApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createShippingApi;
