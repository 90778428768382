import { Ability, AbilityBuilder } from '@casl/ability';
import { Account } from '@api/user/types';
import { isCustomerAccount, isTransactAccount } from '@utils/contextChecker';

import { Actions, permissions, Subjects } from './permissionsAndFeatures';

type AppAbility = Ability<[Actions, Subjects]>;

export function defineAbilityFor(account: Account | undefined): AppAbility {
  const { can, rules } = new AbilityBuilder<AppAbility>(Ability);
  if (account) {
    // Only Customers have permissions, Partner doesn't have them
    if (isCustomerAccount(account) || isTransactAccount(account)) {
      permissions.forEach(permission => {
        const hasPermission = account.effectiveCustomerPermissions.some(p => p.permission.toLowerCase() === permission.name.toLowerCase());
        if (hasPermission) {
          can(permission.can, permission.resource);
        }
      });
    }
  }

  return new Ability<[Actions, Subjects]>(rules);
}
