import { FC } from 'react';
import { Image } from '@chakra-ui/react';
import { usePartnershipSettings } from '@api/partnership/queries';
import { MainLogo } from '@components/MainLogo';

import { mq } from '@styles';

const LogoImage: FC<React.PropsWithChildren> = () => {
  const { data: partnershipSettings } = usePartnershipSettings();
  const { logoUrl } = partnershipSettings;
  const partnerName = import.meta.env.REACT_APP_PARTNER_NAME;

  // TODO: move to settings
  const isHighLogo = import.meta.env.REACT_APP_HIGH_LOGO;

  return logoUrl ? (
    <Image
      src={logoUrl}
      alt={partnerName}
      ignoreFallback
      sx={{
        w: '100%',
        objectFit: 'contain',
        maxW: '200px',
        maxH: isHighLogo ? 'none' : '100%',
        [mq.sm]: { w: '' },
      }}
    />
  ) : (
    <MainLogo color='blue' />
  );
};

export default LogoImage;
