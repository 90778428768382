import { FC, useEffect } from 'react';
import { css, Global } from '@emotion/react/macro';

import { mq } from '@styles';
import '@radix-ui/themes/styles.css';

const GlobalStyles: FC<React.PropsWithChildren> = () => {
  const removeMouseFocusRing = (): void => {
    document.body.classList.add('using-mouse');
  };

  const addKeyboardFocusRing = (): void => {
    document.body.classList.remove('using-mouse');
  };

  useEffect(() => {
    const { body } = document;

    body.addEventListener('mousedown', removeMouseFocusRing);
    body.addEventListener('keydown', addKeyboardFocusRing);

    return (): void => {
      body.removeEventListener('mousedown', removeMouseFocusRing);
      body.removeEventListener('keydown', addKeyboardFocusRing);
    };
  }, []);

  return (
    <>
      <Global
        styles={css`
          html,
          body {
            margin: 0;
            padding: 0;

            .slick-prev:before {
              content: none;
            }

            .slick-next:before {
              content: none;
            }

            .slick-dots {
              display: flex;
              justify-content: space-between;
              align-items: center;
              bottom: -32px;

              li {
                margin: 0;
              }
            }
          }

          /* This is a fix for radix issue, see: https://github.com/radix-ui/primitives/issues/1925 */
          html > body {
            margin: 0 !important;
          }

          body {
            font-size: inherit;
          }

          body.using-mouse {
            *:focus ~ *,
            *:not(section, [data-is-popover-open]):focus {
              outline: none !important;
            }
          }

          *[x-out-of-boundaries] {
            visibility: hidden;
            pointer-events: none;
          }

          .chakra-portal > div:not(.chakra-modal__overlay) {
            z-index: 3;
          }

          ${mq.lg} {
            html {
              overflow-y: scroll;
            }
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
