import { AxiosInstance } from 'axios';

interface CarbonOffsetPriceParams extends FeeFactors {
  kilosCo2: number;
}

export const getCarbonOffsetPrice =
  (instance: AxiosInstance) =>
  async (params: CarbonOffsetPriceParams, customerId: string, portfolioId?: string): Promise<OffsetPrice> => {
    const { data } = await instance.get<OffsetPrice>(`/customers/${customerId}/portfolios/co2/${portfolioId ?? 'default'}/price`, {
      params,
    });

    return data;
  };
