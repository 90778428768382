import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

import { defineAbilityFor } from './defineAbility';

type Context = ReturnType<typeof defineAbilityFor>;

// @ts-ignore
export const AbilityContext = createContext<Context>({
  // @ts-ignore
  on: () => {
    //
  },
  // @ts-ignore
  can: () => {
    //
  },
  // @ts-ignore
  cannot: () => {
    //
  },
});

export const Can = createContextualCan(AbilityContext.Consumer);
