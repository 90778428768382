import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';

interface State {
  subscriptionObjects: SubscriptionObject[];
}

const initialState: State = {
  subscriptionObjects: [],
};

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions: (state: State, action: PayloadAction<SubscriptionObject[]>): State => {
      state.subscriptionObjects = action.payload;

      return state;
    },

    updateSubscription: (state: State, action: PayloadAction<SubscriptionObject>): State => {
      const subscriptionIdx = state.subscriptionObjects.findIndex(subscription => subscription.id === action.payload.id);
      if (typeof subscriptionIdx !== 'undefined' && subscriptionIdx > -1) {
        state.subscriptionObjects[subscriptionIdx] = action.payload;
      }

      return state;
    },

    createSubscription: (state: State, action: PayloadAction<SubscriptionObject>): State => {
      state.subscriptionObjects.push(action.payload);

      return state;
    },
  },
});

const { setSubscriptions, createSubscription, updateSubscription } = slice.actions;

export { createSubscription, setSubscriptions, updateSubscription };
export default slice.reducer;

export const employeeBenefitSubscription = createSelector(
  (state: RootState) => state.subscriptions.subscriptionObjects,
  subscriptions =>
    subscriptions?.find(
      (subscription: SubscriptionObject) =>
        subscription.type === 'EmployeeBenefit' && subscription.category === 'Employee benefit' && subscription.status === 'Active',
    ),
);

export const incompleteEmployeeBenefitSubscription = createSelector(
  (state: RootState) => state.subscriptions.subscriptionObjects,
  subscriptions =>
    subscriptions?.find(
      (subscription: SubscriptionObject) =>
        subscription.type === 'EmployeeBenefit' && subscription.category === 'Employee benefit' && subscription.status === 'Incomplete',
    ),
);
