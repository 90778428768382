import { OrderItem } from '@api/order';
import { paymentStatuses } from '@api/order/types';

export const validateItemPortfolio = (item: OrderItem): void => {
  if (!item.portfolioId && item.portfolio?.childPortfolios?.length === 0) {
    throw Error;
  }
};

// 2 years as in the case of the GDPR
export const MAX_AGE_FOR_POPUP = 6.307e7;

type OrderStatus = 'pending' | 'paid' | 'retired';

export function getOrderStatus(order: OrderLegacy<OrderFootprint>): OrderStatus {
  let orderStatus: OrderStatus = 'pending';
  if (order.supplyStatus === 'Retired') {
    orderStatus = 'retired';
  } else if (order.payment.status === paymentStatuses.paid) {
    orderStatus = 'paid';
  }

  return orderStatus;
}
