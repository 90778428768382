import { FC } from 'react';
import { Box, Flex, FlexProps, useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react/macro';
import { ReactComponent as LoaderComponent } from '@assets/svgs/loader.svg';
import { transientOptions } from '@utils/transientOptions';

interface Props {
  loaderDirection?: string;
  small?: boolean;
  loaderColor?: string;
}

interface StylesProps {
  $loaderDirection?: string;
  $small?: boolean;
}

interface LoaderFullPageProps {
  fixed?: boolean;
}

interface TransformType {
  [id: string]: string;
}

const TRANSFORM: TransformType = {
  left: 'rotate(-90deg) translate3d(30px, 30px, 0)',
  right: 'rotate(90deg) translate3d(-30px, -30px, 0)',
  bottom: 'rotate(180deg)',
  'left-small': 'rotate(-90deg) translate3d(18px, 18px, 0)',
  'right-small': 'rotate(90deg) translate3d(-18px, -18px, 0)',
  'bottom-small': 'rotate(180deg)',
};

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  20%, 30% {
    opacity: 1;
  }
  65%, 100% {
    opacity: 0;
  }
`;

const moveAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  15%, 100% {
    transform: translate3d(-2px, -1px, 0) scale(0.98);
  }
`;

const moveAnimation2 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  15%, 100% {
    transform: translate3d(1px, -1px, 0) scale(0.98);
  }
`;

const moveAnimation3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  15%, 100% {
    transform: translate3d(0, 1px, 0) scale(1);
  }
`;

const moveAnimation4 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  15%, 100% {
    transform: translate3d(1px, 1px, 0) scale(0.97);
  }
`;

const LoaderWrapper = styled(
  Flex,
  transientOptions,
)<StylesProps>(
  props => `
    background: transparent;
    width: ${
      props.$loaderDirection === 'left' || props.$loaderDirection === 'right' ? (props.$small ? '88px' : '154px') : props.$small ? '52px' : '94px'
    };
    height: ${
      props.$loaderDirection === 'left' || props.$loaderDirection === 'right' ? (props.$small ? '52px' : '94px') : props.$small ? '88px' : '154px'
    };
  `,
);

const LoaderBox = styled(
  Box,
  transientOptions,
)<StylesProps>(
  props => `
    position: relative;
    width: ${props.$small ? '52px' : '94px'};
    height: ${props.$small ? '88px' : '154px'};
    transform: ${props.$loaderDirection ? TRANSFORM[props.$small ? `${props.$loaderDirection}-small` : props.$loaderDirection] : 'rotate(0deg)'};
  `,
);

const LoaderSegment = styled(Box, transientOptions)<{ $rightPosition?: boolean; $small?: boolean }>`
  width: ${(props): string => (props.$small ? '24px' : '44px')};
  height: ${(props): string => (props.$small ? '24px' : '44px')};
  position: absolute;
  opacity: 0;
  will-change: opacity;
  transform: ${(props): string => (props.$rightPosition ? 'rotate(45deg)' : 'rotate(0deg)')};
  animation: ${fadeAnimation} 2s ease infinite;
  animation-delay: 0.7s;

  > div {
    animation: ${moveAnimation} 2s linear infinite;
    animation-delay: 0.7s;
  }

  &:nth-of-type(2) {
    animation-delay: 0.9s;

    > div {
      animation: ${moveAnimation2} 2s linear infinite;
      animation-delay: 0.9s;
    }
  }

  &:nth-of-type(3) {
    animation-delay: 1.1s;

    > div {
      animation: ${moveAnimation3} 2s linear infinite;
      animation-delay: 1.1s;
    }
  }

  &:nth-of-type(4) {
    animation-delay: 1.3s;

    > div {
      animation: ${moveAnimation4} 2s linear infinite;
      animation-delay: 1.3s;
    }
  }
`;

const LoaderSegmentWrapper = styled.div<{ pos?: boolean }>`
  width: 100%;
  height: 100%;
  will-change: transform;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Loader: FC<React.PropsWithChildren<Props & FlexProps>> = ({ loaderDirection, loaderColor = 'black', small, ...props }) => {
  return (
    <LoaderWrapper $loaderDirection={loaderDirection} $small={small} {...props}>
      <LoaderBox $loaderDirection={loaderDirection} $small={small}>
        <LoaderSegment $rightPosition right='0' bottom='0' $small={small}>
          <LoaderSegmentWrapper>
            <LoaderComponent fill={loaderColor} />
          </LoaderSegmentWrapper>
        </LoaderSegment>
        <LoaderSegment left='0' bottom={small ? '19px' : '34px'} $small={small}>
          <LoaderSegmentWrapper>
            <LoaderComponent fill={loaderColor} />
          </LoaderSegmentWrapper>
        </LoaderSegment>
        <LoaderSegment $rightPosition right='0' bottom={small ? '46px' : '78px'} $small={small}>
          <LoaderSegmentWrapper>
            <LoaderComponent fill={loaderColor} />
          </LoaderSegmentWrapper>
        </LoaderSegment>
        <LoaderSegment left='0' bottom={small ? '64px' : '110px'} $small={small}>
          <LoaderSegmentWrapper>
            <LoaderComponent fill={loaderColor} />
          </LoaderSegmentWrapper>
        </LoaderSegment>
      </LoaderBox>
    </LoaderWrapper>
  );
};

export const LoaderFullPage: FC<React.PropsWithChildren<LoaderFullPageProps>> = ({ fixed }) => {
  const { colors } = useTheme();
  const loaderColor = colors?.CTA;

  return (
    <Flex
      h={fixed ? '100vh' : 'calc(90vh - 90px)'}
      pos={fixed ? 'fixed' : 'static'}
      bg={fixed ? 'var(--chakra-colors-white)' : 'transparent'}
      p='0'
      m='0'
      left='0'
      top='0'
      w='100%'
      zIndex={9999}
      justifyContent='center'
      alignItems='center'
      data-testid='page-loader'>
      <Loader loaderColor={loaderColor} />
    </Flex>
  );
};

export const BaseLoaderFullPage: FC<React.PropsWithChildren<LoaderFullPageProps>> = ({ fixed }) => {
  return (
    <Flex
      h={fixed ? '100vh' : 'calc(90vh - 90px)'}
      pos={fixed ? 'fixed' : 'static'}
      bg={fixed ? 'var(--chakra-colors-white)' : 'transparent'}
      p='0'
      m='0'
      left='0'
      top='0'
      w='100%'
      zIndex={9999}
      justifyContent='center'
      alignItems='center'
      data-testid='page-loader'>
      <Loader />
    </Flex>
  );
};
