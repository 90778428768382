import { defineStyleConfig } from '@chakra-ui/react';

const Accordion = defineStyleConfig({
  variants: {
    faq: {
      _first: {
        container: {
          marginTop: '12px',
          borderTopWidth: '0px',
        },
      },
      _last: {
        container: {
          marginBottom: '-10px',
          borderBottomWidth: '0px',
        },
      },
      button: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'body',
        fontWeight: 500,
        fontSize: 'md',
        lineHeight: '157%',
        padding: '23px 12px 20px 12px',
        borderRadius: 0,
        color: 'secondary.500',
        _hover: {
          bg: 'secondary.100',
        },
      },
      panel: {
        padding: '23px 12px 20px 24px',
        fontFamily: 'body',
        fontWeight: 500,
        fontSize: 'md',
        lineHeight: '157%',
        color: 'secondary.500',
        'ul, ol': {
          paddingLeft: '16px',
        },
      },
      icon: {
        color: 'secondary.500',
        boxSize: '24px',
      },
    },
  },
});
export default Accordion;
