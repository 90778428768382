interface Plan {
  id: string;
  label: string;
  multiplier: number;
  tooltip: string;
}

const plans: Plan[] = [
  {
    id: 'ClimatePositive',
    label: 'Climate Positive',
    multiplier: 1.05,
    tooltip: '1.05x - You are offsetting the entire yearly carbon footprint of one person in the selected country.',
  },
  {
    id: 'ClimateChampion',
    label: 'Climate Champion',
    multiplier: 2,
    tooltip: '2x - You are offsetting double the entire yearly carbon footprint of one person in the selected country.',
  },
  {
    id: 'ClimateHero',
    label: 'Climate Hero',
    multiplier: 4,
    tooltip: '4x - You are offsetting four times the entire yearly carbon footprint of one person in the selected country.',
  },
];

const getPlan = (name?: string): Plan => {
  if (!name) {
    return plans[0];
  } else {
    const plan = plans.find(plan => plan.id === name);
    if (!plan) {
      return plans[0];
    }

    return plan;
  }
};

export { getPlan, plans };

export type { Plan };
