import { Action, AnyAction, combineReducers, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

import allUsersReducer from './allUsers/allUsersSlice';
import appReducer from './app/appSlice';
import toasterReducer from './app/toasterSlice';
import airFreightFootprintReducer from './footprints/airFreightSlice';
import flightFootprintReducer from './footprints/flightSlice';
import airFreightsReducer from './offset/airFreightSlice';
import carbonOffsetReducer from './offset/carbonSlice';
import customerReducer from './offset/customersSlice';
import emissionsReducer from './offset/emissionsSlice';
import eventOffsetReducer from './offset/eventSlice';
import flightOffsetReducer from './offset/flightSlice';
import offsetPortfolioSelectReducer from './offset/portfolioSelectSlice';
import shippingOffsetReducer from './offset/shippingSlice';
import ordersReducer from './orders/ordersSlice';
import partnerSettingsReducer from './partnerSettings/partnerSettingsSlice';
import projectReducer from './projects/projectsSlice';
import signUpReducer from './signUp/signUpSlice';
import employeeBenefitReducer from './subscriptions/employeeBenefitSlice';
import subscriptionReducer from './subscriptions/subscriptionsSlice';
import transactionsReducer from './transactions/transactionsSlice';
import attributionReducer from './user/attributionSlice';
import certificatesReducer from './user/certificatesSlice';
import userReducer from './user/userSlice';

const rootReducer = combineReducers({
  app: appReducer,
  airFreight: airFreightsReducer,
  airFreightFootprint: airFreightFootprintReducer,
  allUsers: allUsersReducer,
  attribution: attributionReducer,
  carbonOffset: carbonOffsetReducer,
  certificates: certificatesReducer,
  eventOffset: eventOffsetReducer,
  emissions: emissionsReducer,
  employeeBenefit: employeeBenefitReducer,
  flightOffset: flightOffsetReducer,
  flightFootprint: flightFootprintReducer,
  orders: ordersReducer,
  projects: projectReducer,
  shippingOffset: shippingOffsetReducer,
  signUp: signUpReducer,
  subscriptions: subscriptionReducer,
  toaster: toasterReducer,
  transactions: transactionsReducer,
  offsetCustomers: customerReducer,
  offsetPortfolioSelect: offsetPortfolioSelectReducer,
  user: userReducer,
  partnerSettings: partnerSettingsReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<RootState, null, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
