import { defaultInstance } from '@api/instance';
import { Paginated } from '@api/shared/types';

import { Account, MailNotifications, PartnershipCustomer, User, UserInformation } from './types';

export const getUser = async () => {
  const { data } = await defaultInstance.get<User>('/user');

  return data;
};

export const modifyEmailSubscription = async (values: Partial<MailNotifications>, customerId: string): Promise<MailNotifications> => {
  const { data } = await defaultInstance.put<MailNotifications>(`/user/accounts/${customerId}/mailnotifications`, values);

  return data;
};

export const acceptPrivacyPolicy = async (accountId: string) => {
  const { data } = await defaultInstance.post<Account['settings']>(`/user/accounts/${accountId}/privacyPolicy/approve`);

  return data;
};

export const acceptTermsPartnership = async (partnershipId: string) => {
  const { data } = await defaultInstance.post<Account['settings']>(`/partnerships/${partnershipId}/terms/approve`);

  return data;
};

export const acceptTermsCustomer = async (customerId: string) => {
  const { data } = await defaultInstance.post<Account['settings']>(`/customers/${customerId}/terms/approve`);

  return data;
};

export const modifyUserInformation = async (params: UserInformation, customerId: string) => {
  const { data } = await defaultInstance.put<UserLegacy>(`/user/accounts/${customerId}/profile`, params);

  return data;
};

export const deleteUserAccount = async (id: string) => {
  const { data } = await defaultInstance.delete<UserLegacy>(`/user/accounts/${id}`);

  return data;
};

export const transferOwnership = async (customerId: string, userId: string) => {
  const { data } = await defaultInstance.put<User>(`/customers/${customerId}/users/${userId}`, {
    customerRoleId: 'owner',
  });

  return data;
};

export const getPartnersCustomers = async (partnershipId: string, query: string, limit: number, offset: number) => {
  const { data } = await defaultInstance.get<Paginated<PartnershipCustomer>>(`/partnerships/${partnershipId}/customers`, {
    params: {
      query,
      limit,
      offset,
    },
  });

  return data;
};
