import axios, { CancelTokenSource } from 'axios';
import { createSelector, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import createFlightApi from '@api/flightOffset';
import createOrderApi, {
  FlightDetails,
  Metadata,
  MultipleOrdersResponse,
  OrderItem,
  OrderParams,
  OrderResponse,
  PlaceOrderSummary,
} from '@api/order';
import { invalidateLegacyEmissionsFilters } from '@api/order/queries';
import { getOrderStatus, validateItemPortfolio } from '@pages/shared/CorporateOffset/utils';
import { AppThunk, RootState } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, metadataSelector, userCurrencySelector } from '@store/user/userSlice';
import checkJob from '@utils/checkJob';
import { getDisplayName } from '@utils/metadata';
import { fixFloat } from '@utils/numberFormatter';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent, trackPurchaseEventForMultipleOrders } from '@utils/tags';

import { singlePortfolioSanityIdSelector, virtualPortfoliosSelector } from './portfolioSelectSlice';

const CancelToken = axios.CancelToken;
let source: CancelTokenSource = CancelToken.source();

// region types
interface CalculateFromToOffsetParams {
  from: AirportOption | undefined;
  to: AirportOption | undefined;
  via?: AirportOption;
  travelClass: TravelClass | undefined;
  travellers: number;
  flights: number;
  roundTrip: boolean;
}

interface OffsetFromToWithPrice {
  price?: number;
  vat?: number;
  customerVat?: number;
  currency?: string;
  amount?: number;
  totalKilosCo2?: number;
  distance?: number;
  distanceFromVia?: number;
  distanceViaTo?: number;
  flightPax: number;
  excise?: number;
  customerExcise?: number;
}

interface ByDistanceFlightUpdateValues {
  flightDistance: number;
  travelClass: Option;
  roundTrip: boolean;
  travellers: number;
  kilosCo2: number;
  flightPax: number;
  price?: number;
  customerPrice?: number;
  footprintId: string;
}
interface OffsetInfo {
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  poNumber?: string;
  costCenter?: string;
  [key: string]: string | Option | Date | undefined | boolean | number;
}
interface OrderPayload {
  number: string;
  date: string;
  name?: string;
  id: string;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  orderStatus?: string;
}

export interface FlightOffsetState {
  mode?: FlightOffsetMode;
  amount?: number;
  totalKilosCo2?: number;
  totalKilosCo2Avoided?: number;
  totalKilosCo2Offset?: number;
  vat?: number;
  customerVat?: number;
  customerPrice?: number;
  price?: number;
  excise?: number;
  customerExcise?: number;
  totalTax?: number;
  customerTotalTax?: number;
  currency?: string;
  from?: AirportOption;
  to?: AirportOption;
  via?: AirportOption;
  travellers?: number;
  travelClass?: { label: string; value: TravelClass };
  flights?: number;
  roundTrip?: boolean;
  flightPax?: number;
  distance?: number;
  distanceFromVia?: number;
  distanceViaTo?: number;
  isLoading: string[];
  selectedPortfolioId?: string;
  orderName?: string;
  orderNumber?: string;
  orderDate?: string;
  orderId?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  isOrderInfoInvalid?: boolean;
  selectedCustomMetadata?: string[];
  byDistanceFlights: ByDistanceFlight[];
  fileUploading?: boolean;
  fileError?: boolean;
  fileProcessing?: boolean;
  progressPercentage?: number;
  isUploadingFileCancelled?: boolean;
  attachment?: { storageBlobName: string; type: string };
  fileErrors?: string[];
  fileSuccess?: boolean;
  isPaidByPartner?: boolean;
  customer?: PartnersCustomerOption;
  claimCode?: string;
  inviteId?: string;
  attachmentTempUrl?: string;
  customersCount?: number;
  billId?: string;
  isMultiplierActive?: boolean;
  orderStatus?: string;
  fullOffsetRows?: undefined;
  costCenter?: string;
  poNumber?: string;
  flightsNumber?: FlightsNumber;
  uploadedFlightsItems?: UploadOffsetPriceQuote['items'];
  footprintIds: string[];
  deductions?: Deduction[];
  undeductedTotalKilosCo2?: number;
  [key: string]:
    | string
    | Option
    | Date
    | number
    | undefined
    | boolean
    | string[]
    | FlightsNumber
    | ByDistanceFlight[]
    | PartnersCustomerOption
    | UploadOffsetPriceQuote['items']
    | Deduction[]
    | { storageBlobName: string; type: string };
}

const initialState: FlightOffsetState = {
  isLoading: [],
  roundTrip: false,
  byDistanceFlights: [],
  footprintIds: [],
  isOrderInfoInvalid: true,
};

const tonnesFactor = 1000;

const slice = createSlice({
  name: 'offsetFlight',
  initialState,
  reducers: {
    reset: (state: FlightOffsetState): FlightOffsetState => {
      return { mode: state.mode, ...initialState };
    },
    resetWithoutPortfolioId: (state: FlightOffsetState): FlightOffsetState => {
      return { ...initialState, selectedPortfolioId: state.selectedPortfolioId, isMultiplierActive: state.isMultiplierActive };
    },
    resetAmountAndPrice: (state: FlightOffsetState): FlightOffsetState => {
      return { ...state, amount: 0, totalKilosCo2: 0, price: 0 };
    },
    setLoading: (state: FlightOffsetState, action: PayloadAction<{ value: string; state: boolean }>): FlightOffsetState => {
      if (action.payload.state) {
        state.isLoading = [...state.isLoading, action.payload.value];
      } else {
        state.isLoading = state.isLoading.filter(item => item !== action.payload.value);
      }

      return state;
    },
    setOrderInfo: (state: FlightOffsetState, action: PayloadAction<OffsetInfo>): FlightOffsetState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setOrderInfoInvalid: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.isOrderInfoInvalid = action.payload;

      return state;
    },
    setMode: (state: FlightOffsetState, action: PayloadAction<FlightOffsetMode>): FlightOffsetState => {
      state.mode = action.payload;

      return state;
    },
    setOrder: (state: FlightOffsetState, action: PayloadAction<OrderPayload>): FlightOffsetState => {
      state.orderNumber = action.payload.number;
      state.orderDate = action.payload.date;
      state.orderName = action.payload.name ?? '';
      state.orderId = action.payload.id;
      state.inviteId = action.payload.inviteId;
      state.claimCode = action.payload.claimCode;
      state.billId = action.payload.billId;
      state.orderStatus = action.payload.orderStatus;

      return state;
    },
    setSelectedCustomMetadata: (state: FlightOffsetState, action: PayloadAction<string[]>): FlightOffsetState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    setOffsetWithPrice: (state: FlightOffsetState, action: PayloadAction<OffsetFromToWithPrice>): FlightOffsetState => {
      return { ...state, ...action.payload };
    },
    setOffsetPrice: (
      state: FlightOffsetState,
      action: PayloadAction<{
        price: number;
        vat: number;
        customerPrice: number;
        customerVat: number;
        excise: number;
        customerExcise: number;
        totalTax: number;
        customerTotalTax: number;
      }>,
    ): FlightOffsetState => {
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.customerPrice = action.payload.customerPrice;
      state.customerVat = action.payload.customerVat;
      state.excise = action.payload.excise;
      state.customerExcise = action.payload.customerExcise;
      state.totalTax = action.payload.totalTax;
      state.customerTotalTax = action.payload.customerTotalTax;

      return state;
    },
    addByDistanceFlightRow: (state: FlightOffsetState, action: PayloadAction<ByDistanceFlight>): FlightOffsetState => {
      if (action.payload.footprintId) {
        state.footprintIds.push(action.payload.footprintId);
      }
      state.byDistanceFlights.push(action.payload);

      return state;
    },
    setFootprintIds: (state: FlightOffsetState, action: PayloadAction<string[]>): FlightOffsetState => {
      state.footprintIds = action.payload;

      return state;
    },
    replaceFootprintId: (state: FlightOffsetState, action: PayloadAction<{ oldId: string; newId: string }>): FlightOffsetState => {
      const updatedFootprintsIds = state.footprintIds.map(id => (action.payload.oldId === id ? action.payload.newId : id));
      state.footprintIds = updatedFootprintsIds;

      return state;
    },
    removeByDistanceFlightRow: (state: FlightOffsetState, action: PayloadAction<number>): FlightOffsetState => {
      const footprintId = state.byDistanceFlights.find((_, i) => i === action.payload)?.footprintId;
      const filteredFlights = state.byDistanceFlights.filter((_, i) => i !== action.payload);
      state.amount = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2 / tonnesFactor, 0);
      state.totalKilosCo2 = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2, 0);
      state.byDistanceFlights = filteredFlights;
      if (footprintId) {
        const filteredFootprintIds = state.footprintIds.filter(id => id !== footprintId);
        state.footprintIds = filteredFootprintIds;
      }

      return state;
    },
    setByDistanceFlightLength: (state: FlightOffsetState, action: PayloadAction<{ index: number; value: FlightLengthOption }>): FlightOffsetState => {
      state.byDistanceFlights[action.payload.index].flightLength = action.payload.value;

      return state;
    },
    setByDistanceFlight: (
      state: FlightOffsetState,
      action: PayloadAction<{ index: number; value: ByDistanceFlightUpdateValues }>,
    ): FlightOffsetState => {
      state.byDistanceFlights[action.payload.index] = { ...state.byDistanceFlights[action.payload.index], ...action.payload.value };

      return state;
    },
    setByDistanceFlights: (state: FlightOffsetState, action: PayloadAction<ByDistanceFlightUpdateValues[]>): FlightOffsetState => {
      const updatedFlights = action.payload.map((item, index) => ({ ...state.byDistanceFlights[index], ...item }));
      state.byDistanceFlights = updatedFlights;

      return state;
    },
    setByDistanceTotalAmount: (state: FlightOffsetState, action: PayloadAction<{ amount: number; kilosCo2: number }>): FlightOffsetState => {
      const previousAmount = state.amount ?? 0;
      const previousKilosCo2 = state.totalKilosCo2 ?? 0;
      state.amount = previousAmount + action.payload.amount;
      state.totalKilosCo2 = previousKilosCo2 + action.payload.kilosCo2;

      return state;
    },
    replaceByDistanceTotalAmount: (state: FlightOffsetState, action: PayloadAction<{ amount: number; kilosCo2: number }>): FlightOffsetState => {
      state.amount = action.payload.amount;
      state.totalKilosCo2 = action.payload.kilosCo2;

      return state;
    },
    updateByDistanceTotalAmount: (
      state: FlightOffsetState,
      action: PayloadAction<{ amount: number; kilosCo2: number; index: number }>,
    ): FlightOffsetState => {
      const filteredFlights = state.byDistanceFlights.filter((_, i) => i !== action.payload.index);
      state.amount = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2 / tonnesFactor, action.payload.amount);
      state.totalKilosCo2 = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2, action.payload.kilosCo2);

      return state;
    },
    resetUploadState: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.fileUploading = action.payload;
      state.fileProcessing = action.payload;
      state.fileError = action.payload;
      state.progressPercentage = 0;
      state.isUploadingFileCancelled = true;
      state.fileErrors = [];
      state.attachmentTempUrl = undefined;
      state.attachment = undefined;
      state.fileSuccess = false;
      state.amount = undefined;
      state.totalKilosCo2 = undefined;
      state.customersCount = undefined;
      state.flightsNumber = undefined;
      state.deductions = undefined;
      state.undeductedTotalKilosCo2 = undefined;
      state.totalKilosCo2Avoided = undefined;
      state.totalKilosCo2Offset = undefined;

      return state;
    },
    setFileUploading: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.fileUploading = action.payload;

      return state;
    },
    setAttachment: (state: FlightOffsetState, action: PayloadAction<{ storageBlobName: string; type: string }>): FlightOffsetState => {
      state.attachment = action.payload;

      return state;
    },
    setAttachmentTempUrl: (state: FlightOffsetState, action: PayloadAction<string>): FlightOffsetState => {
      state.attachmentTempUrl = action.payload;

      return state;
    },
    setCustomersCount: (state: FlightOffsetState, action: PayloadAction<number>): FlightOffsetState => {
      state.customersCount = action.payload;

      return state;
    },
    setFileErrors: (state: FlightOffsetState, action: PayloadAction<string[]>): FlightOffsetState => {
      state.fileErrors = action.payload;

      return state;
    },
    setFileSuccess: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.fileSuccess = action.payload;

      return state;
    },
    setUploadTotalAmount: (state: FlightOffsetState, action: PayloadAction<{ amount: number; kilosCo2: number }>): FlightOffsetState => {
      state.amount = action.payload.amount;
      state.totalKilosCo2 = action.payload.kilosCo2;

      return state;
    },
    setIsMultiplierActive: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.isMultiplierActive = action.payload;

      return state;
    },
    setProgressPercentage: (state: FlightOffsetState, action: PayloadAction<number>): FlightOffsetState => {
      state.progressPercentage = action.payload;

      return state;
    },
    setFileProcessing: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.fileProcessing = action.payload;

      return state;
    },
    setFileError: (state: FlightOffsetState, action: PayloadAction<boolean>): FlightOffsetState => {
      state.fileError = action.payload;

      return state;
    },
    resetUploadingFileCancelled: (state: FlightOffsetState): FlightOffsetState => {
      state.isUploadingFileCancelled = false;

      return state;
    },
    setEmissionsAvoided: (state: FlightOffsetState, action: PayloadAction<number | undefined>): FlightOffsetState => {
      state.totalKilosCo2Avoided = action.payload;

      return state;
    },
    setEmissionsOffset: (state: FlightOffsetState, action: PayloadAction<number | undefined>): FlightOffsetState => {
      state.totalKilosCo2Offset = action.payload;

      return state;
    },
    setFlightsNumber: (state: FlightOffsetState, action: PayloadAction<FlightsNumber>): FlightOffsetState => {
      state.flightsNumber = action.payload;

      return state;
    },
    setUploadedFlightsItems: (state: FlightOffsetState, action: PayloadAction<UploadOffsetPriceQuote['items']>): FlightOffsetState => {
      state.uploadedFlightsItems = action.payload;

      return state;
    },
    setUndeductedTotalKilosCo2: (state: FlightOffsetState, action: PayloadAction<number | undefined>): FlightOffsetState => {
      state.undeductedTotalKilosCo2 = action.payload;

      return state;
    },
    setDeductions: (state: FlightOffsetState, action: PayloadAction<Deduction[] | undefined>): FlightOffsetState => {
      state.deductions = action.payload;

      return state;
    },
  },
});

const {
  reset,
  resetWithoutPortfolioId,
  setOrderInfo,
  setLoading,
  resetAmountAndPrice,
  setOrderInfoInvalid,
  setSelectedCustomMetadata,
  setMode,
  setOffsetWithPrice,
  setOrder,
  addByDistanceFlightRow,
  removeByDistanceFlightRow,
  setByDistanceFlightLength,
  setByDistanceTotalAmount,
  replaceByDistanceTotalAmount,
  updateByDistanceTotalAmount,
  setByDistanceFlight,
  resetUploadState,
  setFileUploading,
  setFileErrors,
  setFileSuccess,
  setUploadTotalAmount,
  setAttachment,
  setAttachmentTempUrl,
  setCustomersCount,
  setIsMultiplierActive,
  setOffsetPrice,
  setProgressPercentage,
  setFileProcessing,
  setFileError,
  resetUploadingFileCancelled,
  setEmissionsAvoided,
  setEmissionsOffset,
  setFlightsNumber,
  setUploadedFlightsItems,
  setFootprintIds,
  replaceFootprintId,
  setDeductions,
  setUndeductedTotalKilosCo2,
  setByDistanceFlights,
} = slice.actions;

export {
  removeByDistanceFlightRow,
  reset,
  resetAmountAndPrice,
  resetUploadState,
  setByDistanceFlightLength,
  setByDistanceTotalAmount,
  setIsMultiplierActive,
  setMode,
  setOrderInfo,
  setOrderInfoInvalid,
  setSelectedCustomMetadata,
};
export default slice.reducer;

export const invalidateQueryAndResetWithoutPortfolioId =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    // We need to invalidate Filter queries to make sure we won't fetch old data, e.g.:
    // a flight number in the `flightNumbers` select input that have already been addressed
    // and should not be selectable.
    invalidateLegacyEmissionsFilters();
    dispatch(resetWithoutPortfolioId());
  };

const saveUsedAirports =
  (usedAirports: AirportOption[]): AppThunk =>
  (): void => {
    try {
      let savedAirports = JSON.parse(localStorage.getItem('usedAirports') ?? '[]') as AirportOption[];
      savedAirports = savedAirports.filter(s => usedAirports.findIndex(u => u.value === s.value) === -1);
      savedAirports.unshift(...usedAirports);
      const maxSavedAirports = 5;
      savedAirports = savedAirports.slice(0, maxSavedAirports);
      localStorage.setItem('usedAirports', JSON.stringify(savedAirports));
    } catch {
      // err
    }
  };

function generateOrderItemDetailsForFromToMode(flight: FlightOffsetState): FlightDetails {
  const routeLegs: RouteLegs[] = [];

  if (flight.via) {
    routeLegs.push({
      distanceKm: flight.distanceFromVia ?? 0,
      fromAirportCode: flight.from?.airportCode ?? '',
      toAirportCode: flight.via?.airportCode ?? '',
      fromCountryName: flight.from?.countryCode ?? '',
      toCountryName: flight.via?.countryCode ?? '',
      fromCityName: flight.from?.city ?? '',
      toCityName: flight.via?.city ?? '',
    });
    routeLegs.push({
      distanceKm: flight.distanceViaTo ?? 0,
      fromAirportCode: flight.via?.airportCode ?? '',
      toAirportCode: flight.to?.airportCode ?? '',
      fromCountryName: flight.via?.countryCode ?? '',
      toCountryName: flight.to?.countryCode ?? '',
      fromCityName: flight.via?.city ?? '',
      toCityName: flight.to?.city ?? '',
    });
  } else {
    routeLegs.push({
      distanceKm: flight.distance ?? 0,
      fromAirportCode: flight.from?.airportCode ?? '',
      toAirportCode: flight.to?.airportCode ?? '',
      fromCountryName: flight.from?.countryCode ?? '',
      toCountryName: flight.to?.countryCode ?? '',
      fromCityName: flight.from?.city ?? '',
      toCityName: flight.to?.city ?? '',
    });
  }

  return {
    flights: flight.via ? 2 * Number(flight.flights ?? 1) : Number(flight.flights ?? 1),
    passengers: Number(flight.travellers ?? 0),
    roundTrip: flight.roundTrip ?? false,
    travelClass: flight.travelClass?.value ?? ('economy' as TravelClass),
    routeLegs,
  };
}

function generateFlightMetadata(flight: FlightOffsetState): Metadata {
  let flightMetadata;
  if (flight.mode === 'from/to') {
    flightMetadata = {
      travelersCount: Number(flight.travellers ?? 0),
      roundtrip: flight.roundTrip ?? false,
      distanceKm: flight.distance ?? 0,
      fromAirport: flight.from?.airportCode ?? '',
      toAirport: flight.to?.airportCode ?? '',
      fromCountry: flight.from?.countryCode ?? '',
      toCountry: flight.to?.countryCode ?? '',
      fromCity: flight.from?.city ?? '',
      toCity: flight.to?.city ?? '',
      flights: flight.via ? 2 * Number(flight.flights ?? 1) : Number(flight.flights ?? 1),
      passengers: Number(flight.travellers ?? 0),
      travelClass: flight.travelClass?.value ?? ('economy' as TravelClass),
    };
  }

  if (flight.mode === 'byDistance') {
    flightMetadata = {
      travelersCount: flight.byDistanceFlights.reduce((prev, curr) => prev + Number(curr.travellers), 0),
      distanceKm: flight.byDistanceFlights.reduce((prev, curr) => prev + Number(curr.flightDistance), 0),
      flights: flight.byDistanceFlights.reduce((prev, curr) => prev + (curr.roundTrip ? 2 : 1), 0),
      passengers: flight.byDistanceFlights.reduce((prev, curr) => prev + Number(curr.travellers), 0),
    };
  }

  return {
    // @ts-ignore
    flight: flightMetadata,
    order: {
      channel: 'portal-fulloffset',
    },
  };
}

export const getFlightOrderObject = ({
  offsetProps,
  currentUser,
  virtualPortfolios,
  mainPortfolioId,
  userCurrency,
  customer,
}: {
  offsetProps: FlightOffsetState;
  currentUser: AccountLegacy | undefined;
  virtualPortfolios: {
    portfolioId: string;
    weight: number;
  }[];
  mainPortfolioId: string | undefined;
  userCurrency: string;
  customer: PartnersCustomerOption | undefined;
}): OrderParams => {
  const { flightPax, distance, mode, byDistanceFlights, isMultiplierActive, from, to, via, uploadedFlightsItems } = offsetProps;

  const totalKilosCo2 = offsetProps.totalKilosCo2 ?? 0;
  const portfolioId = mainPortfolioId ?? null;

  const isFromToMode = mode === 'from/to';
  const isByDistanceMode = mode === 'byDistance';
  const isUploadMode = mode === 'upload';

  const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
  const isDiscountMode = multiplierMode === 'Discount';
  const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
  const discountValue = multiplierValue / (1 + multiplierValue);
  const discountedOffset = isMultiplierActive && multiplierValue ? (totalKilosCo2 ?? 0) * discountValue : 0;
  const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, weight }) => ({
    id: portfolioId,
    weight: isMultiplierActive && isDiscountMode ? weight * discountValue : weight,
  }));

  let fromToOrderItem: OrderItem | undefined = undefined;

  if (isFromToMode) {
    fromToOrderItem = {
      type: 'impact',
      impact: 'Co2',
      kilosCo2: isDiscountMode ? totalKilosCo2 - discountedOffset : totalKilosCo2,
      portfolio: mainPortfolioId
        ? undefined
        : {
            type: 'Virtual',
            impact: 'co2',
            currency: userCurrency,
            childPortfolios: mappedVirtualPortfolios,
          },
      portfolioId,
      feeFactors: {
        flightPax: flightPax,
        flightDistanceKm: distance ? distance : undefined,
        flight: true,
      },
      taxFactors: {
        airports: [from?.airportCode ?? '', via?.airportCode ?? '', to?.airportCode ?? ''].filter(airport => !!airport),
        customerCountry: customer?.country,
        customerCountrySubdivision: customer?.countrySubdivision,
      },
      details: {
        flight: {
          ...generateOrderItemDetailsForFromToMode(offsetProps),
        },
      },
    };
  }

  let byDistanceFlightItems: OrderItem[] = [];
  if (isByDistanceMode) {
    byDistanceFlightItems = byDistanceFlights.map(item => {
      return {
        type: 'impact',
        impact: 'Co2',
        kilosCo2: isDiscountMode ? item.kilosCo2 - item.kilosCo2 * discountValue : item.kilosCo2,
        portfolio: mainPortfolioId
          ? undefined
          : {
              type: 'Virtual',
              impact: 'co2',
              currency: userCurrency,
              childPortfolios: mappedVirtualPortfolios,
            },
        portfolioId,
        feeFactors: {
          flightPax: item.flightPax,
          flightDistanceKm: item.flightDistance,
          flight: true,
        },
        taxFactors: {
          customerCountry: customer?.country,
          customerCountrySubdivision: customer?.countrySubdivision,
        },
        details: {
          flight: {
            flights: item.roundTrip ? 2 : 1,
            passengers: item.travellers,
            roundTrip: item.roundTrip ?? false,
            travelClass: (item.travelClass?.value as TravelClass) ?? ('economy' as TravelClass),
            routeLegs: [{ distanceKm: item.flightDistance }],
          },
        },
      };
    });
  }

  let uploadOrderItems: OrderItem[] = [];
  if (isUploadMode) {
    uploadOrderItems =
      uploadedFlightsItems
        ?.filter(({ kilosCo2 }) => kilosCo2 > 0)
        ?.map(({ kilosCo2, taxFactors, feeFactors }) => {
          const itemDiscountedOffset = isMultiplierActive && multiplierValue ? (kilosCo2 ?? 0) * discountValue : 0;

          return {
            type: 'impact',
            impact: 'Co2',
            kilosCo2: isDiscountMode ? kilosCo2 - itemDiscountedOffset : kilosCo2,
            portfolio: mainPortfolioId
              ? undefined
              : {
                  type: 'Virtual',
                  impact: 'co2',
                  currency: userCurrency,
                  childPortfolios: mappedVirtualPortfolios,
                },
            portfolioId,
            feeFactors,
            taxFactors,
          };
        }) ?? [];
  }

  let items: OrderItem[] = [];
  if (isFromToMode) {
    items = [fromToOrderItem as OrderItem];
  }
  if (isByDistanceMode) {
    items = byDistanceFlightItems;
  }
  if (isUploadMode) {
    items = uploadOrderItems;
  }

  const order = {
    currency: userCurrency,
    category: 'Flights',
    items,
  };

  return order;
};

export const calculateFromToOffset =
  (params: CalculateFromToOffsetParams, callbackFn?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const { customer, totalKilosCo2 } = getState().flightOffset;
    const flightOffsetApi = createFlightApi(context);
    const { from, to, via, travelClass, travellers, flights, roundTrip } = params;

    if (!from || !to || !travelClass || !travellers || !flights) {
      return;
    }

    dispatch(setLoading({ value: 'calculateFromToOffset', state: true }));

    let offset;
    let partialOffsetFromVia;
    let partialOffsetViaTo;

    try {
      if (via) {
        partialOffsetFromVia = await flightOffsetApi.getFlightFootprint({
          ...params,
          from: from.value,
          to: via.value,
          calculateForCustomer: customer?.value,
        });
        partialOffsetViaTo = await flightOffsetApi.getFlightFootprint({
          ...params,
          from: via.value,
          to: to.value,
          calculateForCustomer: customer?.value,
        });

        const partialOffsetFromViaDeductions = partialOffsetFromVia.deductions ?? [];
        const partialOffsetViaToDeductions = partialOffsetViaTo.deductions ?? [];
        const allDeductions =
          partialOffsetFromVia.deductions || partialOffsetViaTo.deductions
            ? [...partialOffsetFromViaDeductions, ...partialOffsetViaToDeductions]
            : undefined;
        const etsDeductions = allDeductions?.filter(({ type }) => type === 'ETS');
        const alreadyOffsetDeductions = allDeductions?.filter(({ type }) => type === 'AlreadyOffset');
        const deductions: FootprintDeduction[] | undefined = etsDeductions || alreadyOffsetDeductions ? [] : undefined;
        if (etsDeductions && etsDeductions.length > 0) {
          deductions?.push(etsDeductions?.reduce((prev, curr) => ({ ...curr, kilosCo2: (prev?.kilosCo2 ?? 0) + curr.kilosCo2 })));
        }
        if (alreadyOffsetDeductions && alreadyOffsetDeductions.length > 0) {
          deductions?.push(alreadyOffsetDeductions?.reduce((prev, curr) => ({ ...curr, kilosCo2: (prev?.kilosCo2 ?? 0) + curr.kilosCo2 })));
        }

        offset = {
          kilosCo2e:
            partialOffsetFromVia.kilosCo2e && partialOffsetViaTo.kilosCo2e
              ? partialOffsetFromVia?.kilosCo2e + partialOffsetViaTo?.kilosCo2e
              : undefined,
          kilosCo2: partialOffsetFromVia.kilosCo2 + partialOffsetViaTo.kilosCo2,
          distanceKm: partialOffsetFromVia.distanceKm + partialOffsetViaTo.distanceKm,
          deductions,
          undeductedFootprint:
            partialOffsetFromVia.undeductedFootprint || partialOffsetViaTo.undeductedFootprint
              ? {
                  kilosCo2e:
                    (partialOffsetFromVia.undeductedFootprint?.kilosCo2e ?? partialOffsetFromVia.kilosCo2e) +
                    (partialOffsetViaTo?.undeductedFootprint?.kilosCo2e ?? partialOffsetViaTo.kilosCo2e),
                }
              : undefined,
        };
        dispatch(setFootprintIds([partialOffsetFromVia.id, partialOffsetViaTo.id]));
      } else {
        offset = await flightOffsetApi.getFlightFootprint({
          travellers,
          flights,
          roundTrip,
          travelClass,
          from: from.value,
          to: to.value,
          calculateForCustomer: customer?.value,
        });
        dispatch(setFootprintIds([offset.id]));
      }

      let multiplicate = roundTrip ? 2 : 1;
      const kilosCo2 = offset.kilosCo2e ?? offset.kilosCo2;

      if (kilosCo2 < 0) {
        dispatch(resetAmountAndPrice());
        dispatch(setLoading({ value: 'calculateFromToOffset', state: true }));

        return;
      }

      multiplicate = via ? multiplicate * 2 : multiplicate;
      const flightPax = multiplicate * Number(params.flights || 1) * Number(params.travellers ?? 1);

      dispatch(
        setOffsetWithPrice({
          amount: kilosCo2 / tonnesFactor,
          totalKilosCo2: kilosCo2,
          distance: offset.distanceKm,
          distanceFromVia: partialOffsetFromVia?.distanceKm,
          distanceViaTo: partialOffsetViaTo?.distanceKm,
          flightPax,
        }),
      );
      if (totalKilosCo2 === kilosCo2) {
        callbackFn?.();
      }
      dispatch(setLoading({ value: 'calculateFromToOffset', state: false }));
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchAmountError' }));
      dispatch(setLoading({ value: 'calculateFromToOffset', state: false }));
    }
  };

interface OrderData {
  order?: OrderLegacy<OrderFootprint>;
  orderId: string;
  fileUrl: string | undefined;
  claimCode: string | undefined;
  inviteId: string | undefined;
  billId: string | undefined;
  footprintIds?: string[];
  orders?: Partial<MultipleOrdersResponse[]>;
  jobId?: string;
}

function getFlightOrderData(orderResult: OrderResponse): OrderData {
  const { order, orderId, claimCode, inviteId, billId, fileUrl, footprintIds, orders, jobId } = orderResult;

  return {
    order,
    orderId,
    claimCode,
    inviteId,
    billId,
    fileUrl,
    footprintIds,
    orders,
    jobId,
  };
}

export const getPriceForOrder =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const flightOffset = getState().flightOffset;
    const { customer, currency, mode, byDistanceFlights } = flightOffset;
    const currentUser = currentUserSelector(getState());
    const currentUserCurrency = userCurrencySelector(getState());
    const context = appContextSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const orderApi = createOrderApi(context);
    const userCurrency = currency ?? currentUserCurrency;
    const customerCurrency = customer?.currency ?? userCurrency;
    const customerId = currentUser?.customerId ?? '';
    const partnershipId = currentUser?.partnershipId ?? '';
    const isConnectContext = context === 'connect';

    dispatch(setLoading({ value: 'getPriceForOrder', state: true }));
    try {
      const order = getFlightOrderObject({
        offsetProps: flightOffset,
        currentUser,
        virtualPortfolios,
        mainPortfolioId,
        userCurrency,
        customer,
      });

      validateItemPortfolio(order.items?.[0]);

      const response =
        context === 'connect' ? await orderApi.getQuoteForPartner(order, partnershipId) : await orderApi.getQuoteForCustomer(order, customerId);
      let customerResponse: OrderLegacy<OrderFootprint> | undefined = undefined;
      let customerPrice = response.totalPrice;
      let customerTotalTax = response.totalTax;
      let customerVat = response.totalVat;
      let customerExcise = response.totalExcise;
      let emissionsAvoided = response.totalKilosCo2Avoided;
      let emissionsOffset = response.totalKilosCo2Offset;
      if (isConnectContext && customer && customerCurrency !== userCurrency) {
        const customerOrder = getFlightOrderObject({
          offsetProps: flightOffset,
          currentUser,
          virtualPortfolios,
          mainPortfolioId,
          userCurrency: customerCurrency,
          customer,
        });
        customerResponse = await orderApi.getQuoteForPartner(customerOrder, partnershipId);
        customerPrice = customerResponse.totalPrice;
        customerTotalTax = customerResponse.totalTax;
        customerVat = customerResponse.totalVat;
        customerExcise = customerResponse.totalExcise;
        emissionsAvoided = customerResponse.totalKilosCo2Avoided;
        emissionsOffset = customerResponse.totalKilosCo2Offset;
      }

      if (mode === 'byDistance') {
        byDistanceFlights.forEach((item, index) =>
          dispatch(
            setByDistanceFlight({
              index,
              value: {
                ...item,
                price: response.items[index].price,
                customerPrice: customerResponse?.items[index].price ?? response.items[index].price,
              },
            }),
          ),
        );
      }

      dispatch(
        setOffsetPrice({
          price: response.totalPrice,
          vat: response.totalVat,
          excise: response.totalExcise,
          totalTax: response.totalTax,
          customerPrice,
          customerVat,
          customerExcise,
          customerTotalTax,
        }),
      );

      dispatch(setEmissionsAvoided(emissionsAvoided));
      dispatch(setEmissionsOffset(emissionsOffset));
    } catch (err) {
      // @ts-ignore
      if (err.data?.includes('Insufficient supply')) {
        dispatch(
          showToast({
            variant: 'error',
            titleI18nKey: 'offset:corporate.portfolios.errorTitle',
            descriptionI18nKey: 'offset:corporate.portfolios.insufficientSupplyErrorMessage',
          }),
        );
        // @ts-ignore
      } else if (err.data) {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchPriceError' }));
      }
    } finally {
      dispatch(setLoading({ value: 'getPriceForOrder', state: false }));
    }
  };

const handleBatchOrderResult = async (
  partnershipId: string,
  jobId: string,
  dispatch: Dispatch,
  context: PortalContext,
): Promise<JobStatusResponse<PlaceOrderSummary>> => {
  const orderResult = await checkJob<PlaceOrderSummary>({
    partnershipId: partnershipId,
    jobId: jobId,
    context,
    module: 'flightOffset',
    setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
    setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
    setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
    setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
  });
  const errorMessages = orderResult?.messages?.filter(({ type }) => type.toLowerCase() !== 'information')?.map(m => m.message);
  if (errorMessages?.length > 0) {
    dispatch(setFileErrors(errorMessages));
    dispatch(setFileSuccess(false));
    throw new Error();
  }

  return orderResult;
};

export const createFlightOrder =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'createFlightOrder', state: true }));
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const {
      flightPax,
      byDistanceFlights,
      mode,
      isPaidByPartner,
      customer,
      currency,
      attachment,
      isMultiplierActive,
      distance,
      price,
      customerPrice,
      from,
      to,
      footprintIds,
      totalTax,
      customerTotalTax,
      ...restProps
    } = getState().flightOffset;
    const { costCenter, poNumber } = restProps;
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const metadata = metadataSelector(getState()) ?? [];
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;
    const currentUserCurrency = userCurrencySelector(getState());
    const portfolioId = mainPortfolioId ?? null;
    const isFromToMode = mode === 'from/to';
    const isByDistanceMode = mode === 'byDistance';
    const isUploadMode = mode === 'upload';
    const orderCurrency = !isPaidByPartner && customer?.currency ? customer.currency : currency ?? currentUserCurrency;

    const orderApi = createOrderApi(context);
    const { flightOffset, user } = getState();
    const paymentMethodType = context === 'connect' ? 'Invoice' : user?.currentAccount?.customer?.payment?.defaultPaymentMethod?.type;
    const totalKilosCo2 = flightOffset.totalKilosCo2 ?? 0;

    const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
    const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
    const isDiscountMode = multiplierMode === 'Discount';
    const discountValue = multiplierValue / (1 + multiplierValue);
    const discountedOffset = isMultiplierActive && multiplierValue ? (totalKilosCo2 ?? 0) * discountValue : 0;

    const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, weight }) => ({
      portfolioId,
      weight: isMultiplierActive && isDiscountMode ? weight * discountValue : weight,
    }));

    const customSingleMetadata: {
      internalName: string;
      displayName: string;
      value: string | Date;
    }[] = [];

    Object.keys(restProps)
      .filter(key => !!metadata.find(({ internalName }) => internalName === key))
      .map(key => {
        let value: string | Date = '';

        if ((flightOffset[key] as Option)?.value) {
          value = (flightOffset[key] as Option)?.value;
        } else {
          value = flightOffset[key] as string | Date;
        }
        if (value) {
          customSingleMetadata.push({
            internalName: key,
            value: value,
            displayName: getDisplayName(metadata, key),
          });
        }
      });

    let fromToOrderItem: OrderItem | undefined = undefined;

    if (isFromToMode) {
      fromToOrderItem = {
        type: 'impact',
        impact: 'Co2',
        kilosCo2: isDiscountMode ? totalKilosCo2 - discountedOffset : totalKilosCo2,
        price: isPaidByPartner || !customer ? price ?? 0 : customerPrice ?? 0,
        ...(mainPortfolioId ? {} : { virtualPortfolios: mappedVirtualPortfolios }),
        portfolioId,
        feeFactors: {
          flightPax: flightPax,
          flightDistanceKm: distance ? distance : undefined,
          flight: true,
        },
        details: {
          flight: {
            ...generateOrderItemDetailsForFromToMode(flightOffset),
          },
        },
        taxFactors: {
          airports: [from?.airportCode ?? '', to?.airportCode ?? ''],
          customerCountry: customer?.country,
          customerCountrySubdivision: customer?.countrySubdivision,
        },
      };
    }

    try {
      let byDistanceFlightItems: OrderItem[] = [];
      if (isByDistanceMode) {
        byDistanceFlightItems = byDistanceFlights.map(item => {
          return {
            type: 'impact',
            impact: 'Co2',
            kilosCo2: isDiscountMode ? item.kilosCo2 - item.kilosCo2 * discountValue : item.kilosCo2,
            price: isPaidByPartner || !customer ? item.price ?? 0 : item.customerPrice ?? 0,
            ...(mainPortfolioId ? {} : { virtualPortfolios: mappedVirtualPortfolios }),
            portfolioId,
            feeFactors: {
              flightPax: item.flightPax,
              flightDistanceKm: item.flightDistance,
              flight: true,
            },
            taxFactors: {
              customerCountry: customer?.country,
              customerCountrySubdivision: customer?.countrySubdivision,
            },
            details: {
              flight: {
                flights: item.roundTrip ? 2 : 1,
                passengers: item.travellers,
                roundTrip: item.roundTrip ?? false,
                travelClass: (item.travelClass?.value as TravelClass) ?? ('economy' as TravelClass),
                routeLegs: [{ distanceKm: item.flightDistance }],
              },
            },
          };
        });
      }

      let uploadOrderItem: undefined | OrderItem = undefined;
      if (isUploadMode) {
        uploadOrderItem = {
          type: 'impact',
          impact: 'Co2',
          ...(mainPortfolioId ? {} : { virtualPortfolios: mappedVirtualPortfolios }),
          portfolioId,
        };
      }

      const metadata = generateFlightMetadata(flightOffset);

      let items: OrderItem[] = [];
      if (isFromToMode) {
        items = [fromToOrderItem as OrderItem];
      }
      if (isByDistanceMode) {
        items = byDistanceFlightItems;
      }
      if (isUploadMode) {
        items = [uploadOrderItem as OrderItem];
      }

      const order = {
        description: flightOffset.orderName ?? '',
        comment: flightOffset.description ?? '',
        currency: orderCurrency,
        category: 'Flights',
        items,
        metadata: {
          ...metadata,
          custom: customSingleMetadata,
        },
        costCenter: costCenter ?? '',
        poNumber: poNumber ?? '',
        multiplier: isMultiplierActive && multiplierMode ? { value: multiplierValue, mode: multiplierMode } : undefined,
        footprints: footprintIds.length > 0 ? footprintIds.map(id => ({ calculatedFootprintId: id })) : undefined,
        applyDeductions: true,
      };

      let orderData: Partial<OrderData>;
      if (context === 'connect') {
        const orderBody = {
          ...order,
          facilitated: {
            isFacilitated: true,
            isPaidByPartner: isPaidByPartner ?? true,
          },
          attachments: attachment ? [attachment] : [],
        };

        if (isUploadMode) {
          dispatch(setFileProcessing(true));
          const { partnershipId, jobId } = await orderApi.createBatchOrderJobForPartner(orderBody, user.currentAccount?.partnershipId ?? '');

          const orderResult = await handleBatchOrderResult(partnershipId, jobId, dispatch, context);
          const { facilitatedBatchOrderResult } = orderResult.summary;
          // For multi upload or single with different po numbers or cost centres order property has null value
          orderData = !facilitatedBatchOrderResult.order
            ? { fileUrl: facilitatedBatchOrderResult.fileUrl, orders: facilitatedBatchOrderResult.orders, jobId }
            : getFlightOrderData({ ...facilitatedBatchOrderResult.order, fileUrl: facilitatedBatchOrderResult.fileUrl });
        } else {
          const orderResult = await orderApi.createForPartner(
            {
              ...orderBody,
              customer: {
                customerId: customer?.value ?? null,
              },
            },
            user.currentAccount?.partnershipId ?? '',
          );

          orderData = getFlightOrderData(orderResult);
        }
      } else {
        if (isUploadMode) {
          const orderBody = {
            ...order,
            customer: {
              customerId: user.currentAccount?.customerId ?? '',
            },
            attachments: attachment ? [attachment] : [],
          };
          dispatch(setFileProcessing(true));
          const { partnershipId, jobId } = await orderApi.createBatchOrderJobForCustomer(orderBody, user.currentAccount?.customerId ?? '');
          const {
            order: batchOrder,
            fileUrl,
            orders,
          } = (await handleBatchOrderResult(partnershipId, jobId, dispatch, context)).summary.facilitatedBatchOrderResult;

          orderData = getFlightOrderData({ ...batchOrder, fileUrl, orders, jobId });
        } else {
          const orderResult = await orderApi.create(order, user.currentAccount?.customerId ?? '');
          orderData = getFlightOrderData(orderResult);
        }
      }

      const {
        order: createdOrder,
        orderId: createdOrderId,
        billId,
        claimCode,
        inviteId,
        fileUrl,
        footprintIds: orderFootprintIds,
        orders,
        jobId,
      } = orderData;
      const isTransactionFinished = context !== 'connect' || isPaidByPartner;
      if (createdOrder) {
        if (isTransactionFinished) {
          trackPurchaseEvent(createdOrder, 'Portal offset', paymentMethodType, orderFootprintIds);
        }

        dispatch(
          setOrder({
            number: createdOrder.choooseId,
            date: createdOrder.createdDate,
            name: flightOffset.orderName ?? '',
            id: createdOrderId ?? '',
            claimCode,
            inviteId,
            billId,
            orderStatus: createdOrder ? getOrderStatus(createdOrder) : undefined,
          }),
        );
      } else {
        if (isTransactionFinished) {
          trackPurchaseEventForMultipleOrders({
            jobId: jobId ?? '',
            totalPrice: customer ? customerPrice ?? 0 : price ?? 0,
            totalTax: customer ? customerTotalTax ?? 0 : totalTax ?? 0,
            affiliation: 'Portal offset',
            paymentMethod: paymentMethodType,
            orders: orders ?? [],
            category: 'Flights',
          });
        }
        dispatch(setAttachmentTempUrl(fileUrl ?? ''));
      }

      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        await client.refetchQueries({ queryKey: ['impact', 'customer', currentUser?.customerId ?? '', { year: new Date().getFullYear() }] });
      }
      dispatch(syncAttribution());
      client.invalidateQueries({ queryKey: ['orders', 'history'] });

      if (flightOffset.from && flightOffset.to) {
        if (flightOffset.via) {
          dispatch(saveUsedAirports([flightOffset.from, flightOffset.via, flightOffset.to]));
        } else {
          dispatch(saveUsedAirports([flightOffset.from, flightOffset.to]));
        }
      }
      dispatch(setLoading({ value: 'createFlightOrder', state: false }));
      successCb && successCb();
    } catch (err) {
      dispatch(setLoading({ value: 'createFlightOrder', state: false }));
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:createOrderError' }));
      errorCb && errorCb();
    }
  };

export const addNewByDistanceFlightItem =
  (onSuccess?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'addNewByDistanceFlightItem', state: true }));
    const context = appContextSelector(getState());
    const currentUser = currentUserSelector(getState());
    const { customer } = getState().flightOffset;
    const flightOffsetApi = createFlightApi(context);

    const item: ByDistanceFlight = {
      flightLength: {
        id: '1',
        value: 'short',
        label: 'Short (< 2h)',
        distance: 1500,
      },
      flightDistance: 1500,
      travelClass: { value: 'economy', label: 'Economy' },
      roundTrip: false,
      travellers: 1,
      kilosCo2: 0,
      flightPax: 1,
      footprintId: '',
    };

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: item.travellers,
        distance: item.flightDistance,
        travelClass: 'economy' as TravelClass,
        roundTrip: item.roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;
      item.kilosCo2 = kilosCo2;
      item.footprintId = result.id;

      dispatch(addByDistanceFlightRow(item));
      dispatch(setByDistanceTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
      onSuccess && onSuccess(item);
      dispatch(setLoading({ value: 'addNewByDistanceFlightItem', state: false }));
    } catch {
      dispatch(setLoading({ value: 'addNewByDistanceFlightItem', state: false }));
    }
  };

export const updateByDistanceFlight =
  (props: { index: number; value?: number | Option | boolean; name?: string }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value, name } = props;
    dispatch(setLoading({ value: 'updateByDistanceFlight', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const { byDistanceFlights, customer } = getState().flightOffset;
    const currentUser = currentUserSelector(getState());
    const item = byDistanceFlights[index];

    const travellers = name === 'travellers' ? (value as number) : item.travellers;
    const roundTrip = name === 'roundTrip' ? (value as boolean) : item.roundTrip;
    const flightDistance = name === 'flightDistance' ? (value as number) : item.flightDistance;
    const travelClass = name === 'travelClass' ? (value as Option) : item.travelClass;

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers,
        distance: flightDistance,
        travelClass: travelClass.value as TravelClass,
        roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;
      const flightPax = Number(travellers ?? 1) * (roundTrip ? 2 : 1);

      dispatch(replaceFootprintId({ oldId: item.footprintId, newId: result.id }));
      dispatch(
        setByDistanceFlight({
          index,
          value: { travellers, flightDistance, travelClass, roundTrip, kilosCo2, flightPax, footprintId: result.id },
        }),
      );
      dispatch(updateByDistanceTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2, index }));
      dispatch(setLoading({ value: 'updateByDistanceFlight', state: false }));
    } catch {
      dispatch(setLoading({ value: 'updateByDistanceFlight', state: false }));
    }
  };

export const updateByDistanceFlights =
  (callbackFn: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'updateByDistanceFlights', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const { byDistanceFlights, customer, totalKilosCo2 } = getState().flightOffset;
    const currentUser = currentUserSelector(getState());

    try {
      const updatedFlights = await Promise.all(
        byDistanceFlights.map(async item => {
          const { travellers, roundTrip, flightDistance, travelClass } = item;
          const result = await flightOffsetApi.getFlightFootprint({
            travellers,
            distance: flightDistance,
            travelClass: travelClass.value as TravelClass,
            roundTrip,
            customerId: currentUser?.customerId,
            calculateForCustomer: customer?.value,
          });

          const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

          return { ...item, kilosCo2, footprintId: result.id };
        }),
      );
      const footprintIds = updatedFlights.map(({ footprintId }) => footprintId);
      const kilosCo2 = updatedFlights.reduce((prev, curr) => prev + curr.kilosCo2, 0);
      dispatch(setFootprintIds(footprintIds));
      dispatch(setByDistanceFlights(updatedFlights));
      dispatch(replaceByDistanceTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
      if (totalKilosCo2 === kilosCo2) {
        callbackFn?.();
      }
      dispatch(setLoading({ value: 'updateByDistanceFlights', state: false }));
    } catch {
      dispatch(setLoading({ value: 'updateByDistanceFlights', state: false }));
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchAmountError' }));
    }
  };

export const processFile =
  (file: File): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setFileSuccess(false));
    dispatch(setLoading({ value: 'processFile', state: true }));
    dispatch(setUploadTotalAmount({ amount: 0, kilosCo2: 0 }));
    dispatch(resetUploadingFileCancelled());
    dispatch(setFlightsNumber({ compensatedFlights: 0, totalFlights: 0 }));
    dispatch(setEmissionsAvoided(undefined));
    dispatch(setEmissionsOffset(undefined));
    dispatch(setDeductions(undefined));
    dispatch(setUndeductedTotalKilosCo2(undefined));
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const userCurrency = userCurrencySelector(getState());
    const flightAPI = createFlightApi(context);
    const currency = getState().flightOffset.currency ?? userCurrency;

    try {
      source = CancelToken.source();

      let batchResponse;
      if (context === 'connect') {
        batchResponse = await flightAPI.createMultiCustomerBatchOrderForPartner(
          file,
          source.token,
          currentUser?.partnershipId ?? 'default',
          currency,
        );
      } else {
        batchResponse = await flightAPI.createBatchOrderForCustomer(file, source.token, currentUser?.customerId ?? 'default', currency);
      }

      if (batchResponse.partnershipId && batchResponse.jobId) {
        const response = await checkJob<FlightsProcessingFileSummary>({
          partnershipId: batchResponse.partnershipId,
          jobId: batchResponse.jobId,
          context,
          module: 'flightOffset',
          setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
          setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
          setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
          setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
        });
        const processedFile = response?.summary?.processedFile;
        const customersCount = processedFile?.details?.multiCustomerFlights?.customersCount;

        if (processedFile) {
          customersCount && dispatch(setCustomersCount(customersCount));
          dispatch(setAttachment(processedFile.attachment));
          dispatch(setAttachmentTempUrl(processedFile.attachmentTempUrl));
          dispatch(setFileErrors([]));
          dispatch(setFileSuccess(true));
          const kilosCo2 = processedFile.priceQuote.totalKilosCo2;
          dispatch(setUploadTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
          dispatch(setFlightsNumber({ compensatedFlights: processedFile.rowsCompensated, totalFlights: processedFile.rows }));
          if (Array.isArray(processedFile.deductions) && processedFile.deductions.length > 0) {
            dispatch(setDeductions(processedFile.deductions));
            dispatch(setUndeductedTotalKilosCo2(processedFile.undeductedFootprint?.kilosCo2e ?? processedFile.undeductedFootprint?.kilosCo2));
          }
          dispatch(
            setOrderInfo({
              flightPax: processedFile.priceQuote.items.reduce((prev, curr) => prev + curr.feeFactors.flightPax, 0),
              distance: processedFile.priceQuote.items.reduce((prev, curr) => prev + (curr.feeFactors.flightDistanceKm ?? 0), 0),
            }),
          );
          dispatch(setUploadedFlightsItems(processedFile.priceQuote.items));
        } else {
          if (Array.isArray(response.messages)) {
            const textMessages = response.messages.map(({ message }) => message);
            dispatch(setFileErrors(textMessages));
          } else {
            setFileErrors([]);
          }
        }
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.data) {
        // @ts-ignore
        const { data } = err.data;
        if (data && Array.isArray(data)) {
          dispatch(setFileErrors(data));
          // @ts-ignore
        } else if (typeof err.data === 'string' && err.data.includes('No available supplies found')) {
          // TODO: think how to translate all of the errors here
          dispatch(setFileErrors(['No available supplies found for the default project. Please try changing the default project.']));
        }
      }
      dispatch(setFileUploading(false));
    } finally {
      dispatch(setLoading({ value: 'processFile', state: false }));
    }
  };

export const flightAmountSelector = createSelector(
  (state: RootState) => state.flightOffset,
  state => {
    const { amount } = state;

    return fixFloat(amount ?? 0);
  },
);

export const isFlightOffsetLoadingSelector = createSelector(
  (state: RootState) => state.flightOffset.isLoading,
  isLoading => isLoading.length > 0,
);
