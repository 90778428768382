import axios, { CancelTokenSource } from 'axios';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createAirFreightApi from '@api/airFreight';
import createOrderApi, { Metadata, OrderParams, OrderResponse, PlaceOrderSummary } from '@api/order';
import { invalidateLegacyEmissionsFilters } from '@api/order/queries';
import { getOrderStatus, validateItemPortfolio } from '@pages/shared/CorporateOffset/utils';
import { AppThunk, RootState } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, metadataSelector, userCurrencySelector } from '@store/user/userSlice';
import checkJob from '@utils/checkJob';
import { getDisplayName } from '@utils/metadata';
import { fixFloat } from '@utils/numberFormatter';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent, trackPurchaseEventForMultipleOrders } from '@utils/tags';

import { singlePortfolioSanityIdSelector, virtualPortfoliosSelector } from './portfolioSelectSlice';

const CancelToken = axios.CancelToken;
let source: CancelTokenSource = CancelToken.source();

// region types
interface OffsetInfo {
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  [key: string]: string | Option | Date | undefined | boolean | number;
}
interface OrderPayload {
  number: string;
  date: string;
  name?: string;
  id: string;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  orderStatus?: string;
}

export interface AirFreightOffsetState {
  mode?: FlightOffsetMode;
  amount?: number;
  totalKilosCo2?: number;
  totalKilosCo2Avoided?: number;
  totalKilosCo2Offset?: number;
  vat?: number;
  customerVat?: number;
  excise?: number;
  customerExcise?: number;
  totalTax?: number;
  customerTotalTax?: number;
  customerPrice?: number;
  price?: number;
  currency?: string;
  from?: AirportOption;
  to?: AirportOption;
  via?: AirportOption;
  weight?: number;
  flightPax?: number;
  distance?: number;
  distanceFromVia?: number;
  distanceViaTo?: number;
  isLoading: boolean;
  selectedPortfolioId?: string;
  orderName?: string;
  orderNumber?: string;
  orderDate?: string;
  orderId?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  isOrderInfoInvalid?: boolean;
  selectedCustomMetadata?: string[];
  byDistanceAirFreights: ByDistanceAirFreight[];
  fileUploading?: boolean;
  fileProcessing?: boolean;
  fileError?: boolean;
  progressPercentage?: number;
  isUploadingFileCancelled?: boolean;
  attachment?: { storageBlobName: string; type: string };
  fileErrors?: string[];
  fileSuccess?: boolean;
  isPaidByPartner?: boolean;
  customer?: PartnersCustomerOption;
  claimCode?: string;
  inviteId?: string;
  attachmentTempUrl?: string;
  customersCount?: number;
  billId?: string;
  isMultiplierActive?: boolean;
  orderStatus?: string;
  fullOffsetRows?: undefined;
  costCenter?: string;
  poNumber?: string;
  deductions?: Deduction[];
  undeductedTotalKilosCo2?: number;
  uploadedFlightsItems?: UploadOffsetPriceQuote['items'];
  flightsNumber?: FlightsNumber;
  [key: string]:
    | string
    | Option
    | Date
    | number
    | undefined
    | boolean
    | string[]
    | ByDistanceAirFreight[]
    | PartnersCustomerOption
    | UploadOffsetPriceQuote['items']
    | Deduction[]
    | FlightsNumber
    | { storageBlobName: string; type: string };
}

const initialState: AirFreightOffsetState = {
  isLoading: false,
  byDistanceAirFreights: [],
  isOrderInfoInvalid: true,
  mode: 'upload',
};

const tonnesFactor = 1000;

const slice = createSlice({
  name: 'offsetAirFreight',
  initialState,
  reducers: {
    reset: (): AirFreightOffsetState => {
      return initialState;
    },
    resetWithoutPortfolioId: (state: AirFreightOffsetState): AirFreightOffsetState => {
      return { ...initialState, selectedPortfolioId: state.selectedPortfolioId, isMultiplierActive: state.isMultiplierActive };
    },
    setLoading: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.isLoading = action.payload;

      return state;
    },
    setOrderInfo: (state: AirFreightOffsetState, action: PayloadAction<OffsetInfo>): AirFreightOffsetState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setOrderInfoInvalid: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.isOrderInfoInvalid = action.payload;

      return state;
    },
    setOrder: (state: AirFreightOffsetState, action: PayloadAction<OrderPayload>): AirFreightOffsetState => {
      state.orderNumber = action.payload.number;
      state.orderDate = action.payload.date;
      state.orderName = action.payload.name ?? '';
      state.orderId = action.payload.id;
      state.inviteId = action.payload.inviteId;
      state.claimCode = action.payload.claimCode;
      state.billId = action.payload.billId;
      state.orderStatus = action.payload.orderStatus;

      return state;
    },
    setSelectedCustomMetadata: (state: AirFreightOffsetState, action: PayloadAction<string[]>): AirFreightOffsetState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    resetUploadState: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.fileUploading = action.payload;
      state.fileProcessing = action.payload;
      state.fileError = action.payload;
      state.progressPercentage = 0;
      state.isUploadingFileCancelled = true;
      state.fileErrors = [];
      state.attachmentTempUrl = undefined;
      state.attachment = undefined;
      state.fileSuccess = false;
      state.amount = undefined;
      state.totalKilosCo2 = undefined;
      state.customersCount = undefined;
      state.flightsNumber = undefined;
      state.deductions = undefined;
      state.undeductedTotalKilosCo2 = undefined;
      state.totalKilosCo2Avoided = undefined;
      state.totalKilosCo2Offset = undefined;

      return state;
    },
    setFileUploading: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.fileUploading = action.payload;

      return state;
    },
    setFileError: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.fileError = action.payload;

      return state;
    },
    setAttachment: (state: AirFreightOffsetState, action: PayloadAction<{ storageBlobName: string; type: string }>): AirFreightOffsetState => {
      state.attachment = action.payload;

      return state;
    },
    setAttachmentTempUrl: (state: AirFreightOffsetState, action: PayloadAction<string>): AirFreightOffsetState => {
      state.attachmentTempUrl = action.payload;

      return state;
    },
    setCustomersCount: (state: AirFreightOffsetState, action: PayloadAction<number>): AirFreightOffsetState => {
      state.customersCount = action.payload;

      return state;
    },
    setFileErrors: (state: AirFreightOffsetState, action: PayloadAction<string[]>): AirFreightOffsetState => {
      state.fileErrors = action.payload;

      return state;
    },
    setFileSuccess: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.fileSuccess = action.payload;

      return state;
    },
    setUploadTotalAmount: (state: AirFreightOffsetState, action: PayloadAction<{ amount: number; kilosCo2: number }>): AirFreightOffsetState => {
      state.amount = fixFloat(action.payload.amount);
      state.totalKilosCo2 = action.payload.kilosCo2;

      return state;
    },
    setIsMultiplierActive: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.isMultiplierActive = action.payload;

      return state;
    },
    setOffsetPrice: (
      state: AirFreightOffsetState,
      action: PayloadAction<{
        price: number;
        vat: number;
        customerPrice: number;
        customerVat: number;
        excise: number;
        customerExcise: number;
        totalTax: number;
        customerTotalTax: number;
      }>,
    ): AirFreightOffsetState => {
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.excise = action.payload.excise;
      state.customerPrice = action.payload.customerPrice;
      state.customerVat = action.payload.customerVat;
      state.customerExcise = action.payload.customerExcise;
      state.totalTax = action.payload.totalTax;
      state.customerTotalTax = action.payload.customerTotalTax;

      return state;
    },
    setProgressPercentage: (state: AirFreightOffsetState, action: PayloadAction<number>): AirFreightOffsetState => {
      state.progressPercentage = action.payload;

      return state;
    },
    setFileProcessing: (state: AirFreightOffsetState, action: PayloadAction<boolean>): AirFreightOffsetState => {
      state.fileProcessing = action.payload;

      return state;
    },
    resetUploadingFileCancelled: (state: AirFreightOffsetState): AirFreightOffsetState => {
      state.isUploadingFileCancelled = false;

      return state;
    },
    setEmissionsAvoided: (state: AirFreightOffsetState, action: PayloadAction<number | undefined>): AirFreightOffsetState => {
      state.totalKilosCo2Avoided = action.payload;

      return state;
    },
    setEmissionsOffset: (state: AirFreightOffsetState, action: PayloadAction<number | undefined>): AirFreightOffsetState => {
      state.totalKilosCo2Offset = action.payload;

      return state;
    },
    setUploadedFlightsItems: (state: AirFreightOffsetState, action: PayloadAction<UploadOffsetPriceQuote['items']>): AirFreightOffsetState => {
      state.uploadedFlightsItems = action.payload;

      return state;
    },
    setFlightsNumber: (state: AirFreightOffsetState, action: PayloadAction<FlightsNumber>): AirFreightOffsetState => {
      state.flightsNumber = action.payload;

      return state;
    },
    setUndeductedTotalKilosCo2: (state: AirFreightOffsetState, action: PayloadAction<number | undefined>): AirFreightOffsetState => {
      state.undeductedTotalKilosCo2 = action.payload;

      return state;
    },
    setDeductions: (state: AirFreightOffsetState, action: PayloadAction<Deduction[] | undefined>): AirFreightOffsetState => {
      state.deductions = action.payload;

      return state;
    },
  },
});

const {
  reset,
  resetWithoutPortfolioId,
  setOrderInfo,
  setLoading,
  setOrderInfoInvalid,
  setSelectedCustomMetadata,
  setOrder,
  resetUploadState,
  setFileUploading,
  setFileError,
  setFileErrors,
  setFileSuccess,
  setUploadTotalAmount,
  setAttachment,
  setAttachmentTempUrl,
  setCustomersCount,
  setIsMultiplierActive,
  setOffsetPrice,
  setProgressPercentage,
  setFileProcessing,
  resetUploadingFileCancelled,
  setEmissionsAvoided,
  setEmissionsOffset,
  setUploadedFlightsItems,
  setFlightsNumber,
  setDeductions,
  setUndeductedTotalKilosCo2,
} = slice.actions;

export { reset, resetUploadState, setIsMultiplierActive, setOrderInfo, setOrderInfoInvalid, setSelectedCustomMetadata };
export default slice.reducer;

export const invalidateQueryAndResetWithoutPortfolioId =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    // We need to invalidate Filter queries to make sure we won't fetch old data, e.g.:
    // a flight number in the `flightNumbers` select input that have already been addressed
    // and should not be selectable.
    invalidateLegacyEmissionsFilters();
    dispatch(resetWithoutPortfolioId());
  };

const saveUsedAirports =
  (usedAirports: AirportOption[]): AppThunk =>
  (): void => {
    try {
      let savedAirports = JSON.parse(localStorage.getItem('usedAirports') ?? '[]') as AirportOption[];
      savedAirports = savedAirports.filter(s => usedAirports.findIndex(u => u.value === s.value) === -1);
      savedAirports.unshift(...usedAirports);
      const maxSavedAirports = 5;
      savedAirports = savedAirports.slice(0, maxSavedAirports);
      localStorage.setItem('usedAirports', JSON.stringify(savedAirports));
    } catch {
      // err
    }
  };

function generateFlightMetadata(): Metadata {
  return {
    order: {
      channel: 'portal-fulloffset',
    },
  };
}

export const getAirFreightOrderObject = ({
  offsetProps,
  currentUser,
  virtualPortfolios,
  mainPortfolioId,
  userCurrency,
}: {
  offsetProps: AirFreightOffsetState;
  currentUser: AccountLegacy | undefined;
  virtualPortfolios: {
    portfolioId: string;
    weight: number;
  }[];
  mainPortfolioId: string | undefined;
  userCurrency: string;
}): OrderParams => {
  const { isMultiplierActive, uploadedFlightsItems } = offsetProps;

  const portfolioId = mainPortfolioId ?? null;

  const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
  const isDiscountMode = multiplierMode === 'Discount';
  const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
  const discountValue = multiplierValue / (1 + multiplierValue);
  const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, weight }) => ({
    id: portfolioId,
    weight: isMultiplierActive && isDiscountMode ? weight * discountValue : weight,
  }));

  const order = {
    currency: userCurrency,
    category: 'AirFreightSubscription',
    items:
      uploadedFlightsItems
        ?.filter(({ kilosCo2 }) => kilosCo2 > 0)
        ?.map(({ kilosCo2, taxFactors, feeFactors }) => {
          const itemDiscountedOffset = isMultiplierActive && multiplierValue ? (kilosCo2 ?? 0) * discountValue : 0;

          return {
            type: 'impact',
            impact: 'Co2',
            kilosCo2: isDiscountMode ? kilosCo2 - itemDiscountedOffset : kilosCo2,
            portfolio: mainPortfolioId
              ? undefined
              : {
                  type: 'Virtual',
                  impact: 'co2',
                  currency: userCurrency,
                  childPortfolios: mappedVirtualPortfolios,
                },
            portfolioId,
            feeFactors,
            taxFactors,
          };
        }) ?? [],
  };

  return order;
};

export const getPriceForOrder =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const airFreightOffset = getState().airFreight;
    const { currency } = airFreightOffset;
    const currentUser = currentUserSelector(getState());
    const currentUserCurrency = userCurrencySelector(getState());
    const context = appContextSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const orderApi = createOrderApi(context);
    const userCurrency = currency ?? currentUserCurrency;
    const partnershipId = currentUser?.partnershipId ?? '';

    dispatch(setLoading(true));
    try {
      const order = getAirFreightOrderObject({
        offsetProps: airFreightOffset,
        currentUser,
        virtualPortfolios,
        mainPortfolioId,
        userCurrency,
      });

      validateItemPortfolio(order.items?.[0]);

      const { totalPrice, totalVat, totalExcise, totalKilosCo2Avoided, totalKilosCo2Offset, totalTax } = await orderApi.getQuoteForPartner(
        order,
        partnershipId,
      );

      dispatch(
        setOffsetPrice({
          price: totalPrice,
          vat: totalVat,
          excise: totalExcise,
          totalTax: totalTax,
          customerPrice: totalPrice,
          customerVat: totalVat,
          customerExcise: totalExcise,
          customerTotalTax: totalTax,
        }),
      );

      dispatch(setEmissionsAvoided(totalKilosCo2Avoided ?? 0));
      dispatch(setEmissionsOffset(totalKilosCo2Offset ?? 0));
    } catch (err) {
      // @ts-ignore
      if (err?.data?.includes('Insufficient supply')) {
        dispatch(
          showToast({
            variant: 'error',
            titleI18nKey: 'offset:corporate.portfolios.errorTitle',
            descriptionI18nKey: 'offset:corporate.portfolios.insufficientSupplyErrorMessage',
          }),
        );
        // @ts-ignore
      } else if (err?.data) {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchPriceError' }));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createAirFreightOrder =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const { isPaidByPartner, currency, attachment, isMultiplierActive, price, totalTax, ...restProps } = getState().airFreight;
    const { costCenter, poNumber } = restProps;
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const metadata = metadataSelector(getState()) ?? [];
    const userCurrency = userCurrencySelector(getState());
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;
    const portfolioId = mainPortfolioId ?? null;
    const orderCurrency = currency ?? userCurrency;

    const orderApi = createOrderApi(context);
    const { airFreight, user } = getState();
    const paymentMethodType = 'Invoice';

    const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
    const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;

    const customSingleMetadata: {
      internalName: string;
      displayName: string;
      value: string | Date;
    }[] = [];

    Object.keys(restProps)
      .filter(key => !!metadata.find(({ internalName }) => internalName === key))
      .map(key => {
        let value: string | Date = '';

        if ((airFreight[key] as Option)?.value) {
          value = (airFreight[key] as Option)?.value;
        } else {
          value = airFreight[key] as string | Date;
        }
        if (value) {
          customSingleMetadata.push({
            internalName: key,
            value: value,
            displayName: getDisplayName(metadata, key),
          });
        }
      });

    try {
      const metadata = generateFlightMetadata();

      const order = {
        description: airFreight.orderName ?? '',
        comment: airFreight.description ?? '',
        currency: orderCurrency,
        category: 'AirFreightSubscription',
        items: [
          {
            type: 'impact',
            impact: 'Co2',
            ...(mainPortfolioId ? {} : { virtualPortfolios }),
            portfolioId,
          },
        ],
        metadata: {
          ...metadata,
          custom: customSingleMetadata,
        },
        costCenter: costCenter ?? '',
        poNumber: poNumber ?? '',
        multiplier: isMultiplierActive && multiplierMode ? { value: multiplierValue, mode: multiplierMode } : undefined,
      };

      let orderResult: OrderResponse;
      if (context === 'connect') {
        dispatch(setFileProcessing(true));
        const orderBody = {
          ...order,
          facilitated: {
            isFacilitated: true,
            isPaidByPartner: isPaidByPartner ?? true,
          },
          attachments: attachment ? [attachment] : [],
        };

        const batchResponse = await orderApi.createBatchOrderJobForPartner(orderBody, user.currentAccount?.partnershipId ?? '');
        const result = await checkJob<PlaceOrderSummary>({
          partnershipId: batchResponse.partnershipId,
          jobId: batchResponse.jobId,
          context,
          module: 'airFreight',
          setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
          setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
          setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
          setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
        });
        orderResult = {
          ...result.summary.facilitatedBatchOrderResult.order,
          fileUrl: result.summary.facilitatedBatchOrderResult.fileUrl,
          orderIds: result.summary.facilitatedBatchOrderResult.orderIds,
          jobId: batchResponse.jobId,
          orders: result.summary.facilitatedBatchOrderResult.orders,
        };
      } else {
        orderResult = await orderApi.create(order, user.currentAccount?.customerId ?? '');
      }

      const isTransactionFinished = context !== 'connect' || isPaidByPartner;
      if (orderResult.order) {
        if (isTransactionFinished) {
          trackPurchaseEvent(orderResult.order, 'Portal offset', paymentMethodType, orderResult.footprintIds);
        }

        dispatch(
          setOrder({
            number: orderResult.order.choooseId,
            date: orderResult.order.createdDate,
            name: airFreight.orderName ?? '',
            id: orderResult.orderId,
            claimCode: orderResult.claimCode,
            inviteId: orderResult.inviteId,
            billId: orderResult.billId,
            orderStatus: getOrderStatus(orderResult.order),
          }),
        );
      } else if (isTransactionFinished) {
        trackPurchaseEventForMultipleOrders({
          jobId: orderResult.jobId ?? '',
          totalPrice: price ?? 0,
          totalTax: totalTax ?? 0,
          affiliation: 'Portal offset',
          paymentMethod: paymentMethodType,
          orders: orderResult.orders ?? [],
          category: 'Air freight',
        });
      }

      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        await client.refetchQueries({ queryKey: ['impact', 'customer', user.currentAccount?.customerId ?? '', { year: new Date().getFullYear() }] });
      }
      dispatch(setAttachmentTempUrl(orderResult.fileUrl ?? ''));
      dispatch(syncAttribution());
      client.invalidateQueries({ queryKey: ['orders', 'history'] });

      if (airFreight.from && airFreight.to) {
        if (airFreight.via) {
          dispatch(saveUsedAirports([airFreight.from, airFreight.via, airFreight.to]));
        } else {
          dispatch(saveUsedAirports([airFreight.from, airFreight.to]));
        }
      }
      dispatch(setLoading(false));
      successCb && successCb();
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:createOrderError' }));
      errorCb && errorCb();
    }
  };

export const processFile =
  (file: File): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setFileSuccess(false));
    dispatch(setFileProcessing(true));
    dispatch(setUploadTotalAmount({ amount: 0, kilosCo2: 0 }));
    dispatch(resetUploadingFileCancelled());
    dispatch(setFlightsNumber({ compensatedFlights: 0, totalFlights: 0 }));
    dispatch(setDeductions(undefined));
    dispatch(setUndeductedTotalKilosCo2(undefined));
    dispatch(setEmissionsAvoided(undefined));
    dispatch(setEmissionsOffset(undefined));
    const currentUser = currentUserSelector(getState());
    const userCurrency = userCurrencySelector(getState());
    const context = appContextSelector(getState());
    const airFreightAPI = createAirFreightApi(context);
    const currency = getState().airFreight.currency ?? userCurrency;

    try {
      source = CancelToken.source();

      const batchResponse = await airFreightAPI.createMultiCustomerBatchOrderForPartner(
        currentUser?.partnershipId ?? 'default',
        currency,
        file,
        source.token,
      );
      if (batchResponse.partnershipId && batchResponse.jobId) {
        const response = await checkJob<FlightsProcessingFileSummary>({
          partnershipId: batchResponse.partnershipId,
          jobId: batchResponse.jobId,
          context,
          module: 'airFreight',
          setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
          setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
          setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
          setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
        });
        const processedFile = response?.summary?.processedFile;
        const customersCount = processedFile?.details?.multiCustomerFlights?.customersCount;
        const errorMessages = response?.messages?.filter(({ type }) => type.toLowerCase() !== 'information')?.map(m => m.message);

        if (processedFile) {
          customersCount && dispatch(setCustomersCount(customersCount));
          dispatch(setAttachment(processedFile.attachment));
          dispatch(setAttachmentTempUrl(processedFile.attachmentTempUrl));
          const kilosCo2 = processedFile.priceQuote.totalKilosCo2;
          dispatch(setUploadTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
          dispatch(setFlightsNumber({ compensatedFlights: processedFile.rowsCompensated, totalFlights: processedFile.rows }));
          if (Array.isArray(processedFile.deductions) && processedFile.deductions.length > 0) {
            dispatch(setDeductions(processedFile.deductions));
            dispatch(setUndeductedTotalKilosCo2(processedFile.undeductedFootprint?.kilosCo2e ?? processedFile.undeductedFootprint?.kilosCo2));
          }
          dispatch(setFileErrors([]));
          dispatch(setFileSuccess(true));
          dispatch(setUploadedFlightsItems(processedFile.priceQuote.items));
        } else if (Array.isArray(errorMessages) && errorMessages.length > 0) {
          dispatch(setFileErrors(errorMessages));
        } else {
          dispatch(setFileErrors([]));
        }
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.data) {
        // @ts-ignore
        const { data } = err.data;
        dispatch(setFileErrors(data ? [...data] : []));
      }
      dispatch(setFileUploading(false));
    }
  };

export const airFreightAmountSelector = createSelector(
  (state: RootState) => state.airFreight,
  state => {
    const { amount } = state;

    return fixFloat(amount ?? 0);
  },
);
