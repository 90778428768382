import { Account, CustomerAccount, PartnerAccount, TransactAccount } from '@api/user/types';

export function isCustomerAccount(account: Account | undefined): account is CustomerAccount {
  if (!account) {
    return false;
  }

  return account.type === 'WeChooose';
}

export function isTransactAccount(account?: Account): account is TransactAccount {
  if (!account) {
    return false;
  }

  return account.type === 'Transact';
}

export function isPartnerAccount(account?: Account): account is PartnerAccount {
  if (!account) {
    return false;
  }

  return account.type === 'WeChoooseCommmunity';
}
