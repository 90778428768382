import { useQuery } from '@tanstack/react-query';
import { useCurrentCustomerAccount } from '@api/user/queries';
import { ONE_MINUTE } from '@utils/constants';
import { isPartnerAccount } from '@utils/contextChecker';

import * as api from './api';
import { Customer, CustomerCustomMetadata } from './types';

const toOption = (currency: string) => ({
  label: currency,
  value: currency,
});

const useCustomerQuery = <T>(select: (data: Customer) => T | undefined, customerId?: string) => {
  const { account } = useCurrentCustomerAccount();
  const cid = customerId ?? account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['customers', cid],
    queryFn: () => api.getCustomer(cid),
    enabled: cid !== 'default',
    staleTime: ONE_MINUTE * 10,
    select,
  });
};

export const useIsCustomerPaymentMethodInvalid = () => {
  const { data: customer } = useCustomer();
  const paymentMethod = customer?.payment?.defaultPaymentMethod;
  const isCustomerPaymentInvalid = paymentMethod?.status !== 'Valid' && paymentMethod?.type !== 'Invoice';

  return { isCustomerPaymentInvalid };
};

export const useCustomer = (customerId?: string) => {
  const { account } = useCurrentCustomerAccount();
  const cid = customerId ?? account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['customers', cid],
    queryFn: () => api.getCustomer(cid),
    enabled: cid !== 'default',
    staleTime: ONE_MINUTE * 10,
  });
};

export const useCustomerUsers = (customerId?: string, hasAccess: boolean = true) => {
  const { account } = useCurrentCustomerAccount();
  const cid = customerId ?? account?.customerId ?? 'default';
  const isPartner = isPartnerAccount(account);

  // Condition to check if the current user has no permissions to get users
  const hasNoPermissionsToGetUsers = !hasAccess || isPartner || cid === 'default';

  // Log an error if permissions are insufficient
  if (account && hasNoPermissionsToGetUsers) {
    // eslint-disable-next-line no-console
    console.error(
      `Access denied: Attempting to fetch users without proper permissions.\nConditions:\n- hasAccess: ${hasAccess}\n- context: ${account?.type}\n- customerId: ${cid}`,
    );
  }

  return useQuery({
    queryKey: ['customerUsers', cid],
    queryFn: () => api.getAllUsers(cid),
    enabled: !hasNoPermissionsToGetUsers,
    staleTime: ONE_MINUTE * 10,
    refetchOnWindowFocus: false,
  });
};

export const useCustomerMetadata = (customerId?: string) =>
  useCustomerQuery<CustomerCustomMetadata[]>(customer => customer.customMetadata, customerId);

export const useCustomerCurrencies = (customerId?: string) => {
  const { account } = useCurrentCustomerAccount();
  const cid = customerId ?? account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['customerCurrencies', cid],
    queryFn: () => api.getCustomerCurrencies(cid),
    enabled: cid !== 'default',
    staleTime: ONE_MINUTE * 10,
    select: data => data?.map(toOption),
  });
};
