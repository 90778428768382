import { offsetFeatureFlags } from '@api/partnership/featureFlags';
import { usePortalFeatures } from '@api/partnership/queries';
import { useCurrentAccount } from '@api/user/queries';
import { useLoadedFeatureFlag } from '@hooks/useLoadedFeatureFlag';

import { useFeature } from './useFeature';

export const useIsOffsetEnabled = () => {
  const { account } = useCurrentAccount();
  const { data: features } = usePortalFeatures();
  const { isFeatureFlagEnabled: isNewEmissionsTableEnabled } = useLoadedFeatureFlag(
    'new_emissions_table',
    import.meta.env.REACT_APP_NEW_EMISSIONS_TABLE,
  );
  const isEmissionsCompensationEnabled = useFeature('EmissionCompensate');
  const shouldLinkToEmissionsOffset = isNewEmissionsTableEnabled ? false : isEmissionsCompensationEnabled;

  if (!account) {
    return false;
  }

  return (shouldLinkToEmissionsOffset || offsetFeatureFlags[account?.type]?.some(i => features?.includes(i))) ?? false;
};
