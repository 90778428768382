const minCountryNameLength = 5;

const restrictedCountries = ['CU', 'IR', 'KP', 'SY', 'VE'];

export function restrictedCountriesFilter(c: Option): boolean {
  return !restrictedCountries.includes(c.value);
}

export const parseCountryToSelect = (country?: Country): { label: string; value: string } => ({
  label: country?.name
    ? country?.name.length < minCountryNameLength || country?.name.startsWith('U.S.')
      ? country?.officialName ?? ''
      : country?.name ?? ''
    : '',
  value: country?.codeIso2 ?? '',
});
