import { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Overlay } from '@components/Overlay';

import { mq } from '@styles';

interface BoxMainProps {
  height?: string;
  ready?: boolean;
  overlayHeight?: string;
  overlayType?: OverlayType;
  noPaddingOnMobile?: boolean;
  noBgOnMobile?: boolean;
}

export const BoxMain: FC<React.PropsWithChildren<BoxMainProps & BoxProps>> = ({
  children,
  height,
  ready = true,
  overlayHeight,
  overlayType,
  noPaddingOnMobile,
  noBgOnMobile,
  ...rest
}) => {
  return (
    <Box
      h={height ?? '100%'}
      sx={{
        background: noBgOnMobile ? 'transparent' : 'white',
        [mq.md]: {
          background: 'white',
          p: '20px 24px',
        },
      }}
      layerStyle={noPaddingOnMobile ? 'whiteBoxContentWithoutMobilePadding' : 'whiteBoxContent'}
      {...rest}>
      {ready ? children : <Overlay overlayHeight={overlayHeight} type={overlayType} />}
    </Box>
  );
};
