import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['track', 'filledTrack', 'label']);

const Progress = helpers.defineMultiStyleConfig({
  baseStyle: defineStyle(props => {
    return {
      track: {
        bg: 'secondary.300',
        borderRadius: 'base',
      },
      filledTrack: {
        bg: props.colorScheme || 'primary.500',
      },
    };
  }),
  sizes: {
    xs: {
      track: { h: '4px' },
    },
    sm: {
      track: { h: '6px' },
    },
  },
});

export default Progress;
