import { CreateStyled } from '@emotion/styled/macro';

// If you want to prevent props meant to be consumed by styled components from being passed to the
// underlying React node or rendered to the DOM element, you can prefix the prop name with a dollar
// sign ($), turning it into a transient prop.
// @see: https://styled-components.com/docs/api pattern

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};
