import axios, { CancelTokenSource } from 'axios';
import createFootprintFlightApi from '@api/flightFootprint';
import store from '@store';

const CancelToken = axios.CancelToken;
let source: CancelTokenSource = CancelToken.source();

interface Data {
  partnershipId: string;
  jobId: string;
  context: PortalContext;
  module: 'flightOffset' | 'airFreight' | 'flightFootprint' | 'airFreightFootprint';
  setFileProcessingCb: (state: boolean) => void;
  setFileUploadingCb: (state: boolean) => void;
  setProgressPercentageCb: (percentage: number) => void;
  setFileErrorCb: (state: boolean) => void;
}

const checkJob = async <T>(data: Data): Promise<JobStatusResponse<T>> => {
  const { getState } = store;
  const { partnershipId, jobId, context, module, setFileProcessingCb, setFileUploadingCb, setProgressPercentageCb, setFileErrorCb } = data;
  const isUploadingFileCancelled = getState()[module].isUploadingFileCancelled;
  const flightAPI = createFootprintFlightApi(context);
  source = CancelToken.source();
  let response = await flightAPI.getJobStatus<T>(partnershipId, jobId, source.token);
  if (!isUploadingFileCancelled) {
    setFileProcessingCb(response?.status === 'Created');
    setFileUploadingCb(response.status === 'Started');
    setFileErrorCb(response.status === 'Failed');
    setProgressPercentageCb(response.progressPercentage);
  }
  if ((response.status === 'Created' || response.status === 'Started') && !isUploadingFileCancelled) {
    await new Promise(resolve => {
      setTimeout(async () => {
        response = await checkJob(data);
        resolve(response);
      }, 1000);
    });
  }
  // Clear out when the status is finished
  if (response.status === 'Succeeded') {
    sessionStorage.removeItem(module);
  }

  return response;
};

export default checkJob;
