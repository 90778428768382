import { AxiosInstance, CancelToken } from 'axios';

import { anonymousPortalInstance, createContextAwareHandlers } from './instance';

const createMetadata =
  (instance: AxiosInstance) =>
  async (
    internalName: string,
    displayName: string,
    type: string,
    customerId: string,
    listValues?: string[],
    isMandatory?: boolean,
  ): Promise<CustomerLegacy> => {
    const { data } = await instance.post<CustomerLegacy>(`/customers/${customerId}/custommetadata`, {
      internalName,
      type,
      displayName,
      listValues,
      isMandatory,
    });

    return data;
  };

const modifyMetadata =
  (instance: AxiosInstance) =>
  async (
    name: string,
    displayName: string,
    customerId: string,
    type?: string,
    listValues?: string[],
    isMandatory?: boolean,
  ): Promise<CustomerLegacy> => {
    const { data } = await instance.put<CustomerLegacy>(`/customers/${customerId}/custommetadata/${name}`, {
      displayName,
      type,
      listValues,
      isMandatory,
    });

    return data;
  };

const deleteMetadata =
  (instance: AxiosInstance) =>
  async (name: string, customerId: string): Promise<void> => {
    await instance.delete<CustomerLegacy>(`/customers/${customerId}/custommetadata/${name}`);
  };

type UserInformation = 'name' | 'preferredName' | 'language';

const modifyUserInformation =
  (instance: AxiosInstance) =>
  async (name: UserInformation, value: string, customerId: string): Promise<UserLegacy> => {
    const { data } = await instance.put<UserLegacy>(`/user/accounts/${customerId}/profile/${name}`, `"${value}"`, {
      headers: {
        'Content-Type': 'plain/text',
      },
    });

    return data;
  };

const modifyBillingInformation =
  (instance: AxiosInstance) =>
  async (billingInformation: BillingInformation, weight: number, customerId: string, paymentMethodId: string): Promise<CustomerLegacy> => {
    const { data } = await instance.put<CustomerLegacy>(`customers/${customerId}/paymentmethods/${paymentMethodId}`, {
      weight: weight > 0 ? weight ?? 1 : 1,
      invoice: billingInformation,
    });

    return data;
  };

const updateCustomerInformation =
  (instance: AxiosInstance) =>
  async (customerInformation: CustomerInformation, customerId: string): Promise<CustomerInformation> => {
    const { data } = await instance.put<CustomerInformation>(`customers/${customerId}/info`, customerInformation);

    return data;
  };

const deleteUserAccount =
  (instance: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await instance.delete<UserLegacy>(`/user/accounts/${id}`);
  };

const deleteCustomer =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<void> => {
    await instance.delete<CustomerUser>(`/customers/${customerId}`);
  };

const transferOwnership =
  (instance: AxiosInstance) =>
  async (customerId: string, userId: string): Promise<unknown> => {
    const { data } = await instance.put<UserLegacy>(`/customers/${customerId}/users/${userId}`, {
      customerRoleId: 'owner',
    });

    return data;
  };

const modifyEmailSubscription =
  (instance: AxiosInstance) =>
  async (values: Partial<MailNotifications>, customerId: string): Promise<MailNotifications> => {
    const { data } = await instance.put<MailNotifications>(`/user/accounts/${customerId}/mailnotifications`, values);

    return data;
  };

interface CustomerBatchResponseOk {
  jobId: string;
  partnershipId: string;
}

interface CustomerBatchResponseError {
  message: string;
  data: string[];
}

interface CustomerData {
  ownerName: string | null;
  email: string;
  name: string;
  currency: string;
  customerReference: string;
  country?: string;
  state?: string;
  language?: string;
}

export type CustomerBatchRequest = CustomerData[];

const createMultipleCustomers =
  (instance: AxiosInstance) =>
  async (
    file: File | CustomerBatchRequest,
    sourceToken: CancelToken,
    partnershipId: string,
    isTest: boolean,
  ): Promise<CustomerBatchResponseOk & CustomerBatchResponseError> => {
    const { data } = await instance.post<CustomerBatchResponseOk & CustomerBatchResponseError>(
      `/partnerships/${partnershipId}/customers/batch?testRun=${isTest ? 'true' : 'false'}`,
      file,
      {
        headers: {
          'Content-Type': file instanceof File ? file.type : 'application/json',
        },
        cancelToken: sourceToken,
      },
    );

    return data;
  };

interface CreateCustomer {
  name: string;
  organizationNumber?: string;
  type: string;
  payment?: {
    currency?: string;
  };
  info: {
    address: Address;
  };
  settings: {
    termsAndConditions: {
      accepted: boolean;
    };
    language: string;
  };
}

interface InviteUserRequest {
  partnershipId: string;
  name: string;
  email: string;
  type: string;
  organizationNumber: string;
  payment: {
    currency: string;
  };
  settings: {
    termsAndConditions: {
      accepted: boolean;
    };
  };
}

const createCustomer =
  (instance: AxiosInstance) =>
  async (body: CreateCustomer): Promise<CustomerLegacy> => {
    const { data } = await instance.post<CustomerLegacy>(`/customers`, body);

    return data;
  };

const inviteUser =
  (instance: AxiosInstance) =>
  async (body: InviteUserRequest): Promise<CustomerLegacy> => {
    const { data } = await instance.post<CustomerLegacy>(`/customers`, body);

    return data;
  };

const getCustomers = (instance: AxiosInstance) => async (): Promise<CustomerLegacy[]> => {
  const { data } = await instance.get<CustomerLegacy[]>(`/customers`);

  return data;
};

const completeInvitation =
  (instance: AxiosInstance) =>
  async (id: string, code: string, mergeWithCustomerId?: string): Promise<unknown> => {
    const { data } = await instance.post<unknown>(`/invites/${id}/claim`, {
      claimCode: code,
      mergeWithCustomerId,
    });

    return data;
  };

const getAttributionSyncStatus =
  (instance: AxiosInstance) =>
  async (customerId: string, orderId: string): Promise<unknown> => {
    const { data } = await instance.get<unknown>(`/customers/${customerId}/orders/${orderId}/attribution`);

    return data;
  };

const getAttributionForCustomer =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<Attribution> => {
    const { data } = await instance.get<Attribution>(`/customers/${customerId}/attribution`);

    return data;
  };
const getAttributionForPartnership =
  (instance: AxiosInstance) =>
  async (partnershipId: string): Promise<Attribution> => {
    const { data } = await instance.get<Attribution>(`/partnerships/${partnershipId}/attribution`);

    return data;
  };

const getAttributionForUser = (instance: AxiosInstance) => async (): Promise<Attribution> => {
  const { data } = await instance.get<Attribution>(`/user/attribution`);

  return data;
};

const getCustomer =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<CustomerLegacy> => {
    const { data } = await instance.get<CustomerLegacy>(`/customers/${customerId}`);

    return data;
  };

const getPartnership =
  (instance: AxiosInstance) =>
  async (id: string): Promise<PartnershipLegacy> => {
    const { data } = await instance.get<PartnershipLegacy>(`/partnerships/${id}`);

    return data;
  };

const updatePartnershipInvoiceInformation =
  (instance: AxiosInstance) =>
  async (newBillingData: BillingInformation, partnershipId: string): Promise<BillingInformation> => {
    const { data } = await instance.put<BillingInformation>(`/partnerships/${partnershipId}/paymentmethods`, { invoice: newBillingData });

    return data;
  };

const updateCustomer =
  (instance: AxiosInstance) =>
  async ({ id, ...body }: UpdateCustomer): Promise<CustomerLegacy> => {
    const { data } = await instance.put<CustomerLegacy>(`/customers/${id}`, body);

    return data;
  };

const getSettingsForUser =
  () =>
  async (partnershipId?: string): Promise<SettingsResponse> => {
    const { data } = await anonymousPortalInstance.get<SettingsResponse>(`/settings/${partnershipId || ''}`, {
      headers: {
        'x-functions-key': import.meta.env.REACT_APP_WECHOOOSE_API_KEY,
      },
    });

    return data;
  };

const resendCustomerInvite =
  (instance: AxiosInstance) =>
  async (partnershipId: string, customerId: string): Promise<void> => {
    await instance.post(`/partnerships/${partnershipId}/customers/${customerId}/resend-welcome-email`);
  };

const resendUserInvite =
  (instance: AxiosInstance) =>
  async (inviteId: string): Promise<void> => {
    await instance.post(`/invites/${inviteId}/resend`);
  };

interface UpdatePartnership {
  id: string;
  settings?: {
    public?: boolean;
  };
}

const updatePartnership =
  (instance: AxiosInstance) =>
  async ({ id, ...body }: UpdatePartnership): Promise<PartnershipLegacy> => {
    const { data } = await instance.put<PartnershipLegacy>(`/partnerships/${id}/settings.public`, `${body.settings?.public}`);

    return data;
  };

const getPartnersCustomers =
  (instance: AxiosInstance) =>
  async (partnershipId: string, query: string, limit: number, offset: number): Promise<PartnershipCustomers> => {
    const { data } = await instance.get<PartnershipCustomers>(`/partnerships/${partnershipId}/customers`, {
      params: {
        query,
        limit,
        offset,
      },
    });

    return data;
  };

const getReceivedGiftCards =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<GiftCardData[]> => {
    const { data } = await instance.get<GiftCardData[]>(`/customers/${customerId}/gifts`);

    return data;
  };

const acceptTermsCustomer =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<SettingsResponse> => {
    const { data } = await instance.post<SettingsResponse>(`/customers/${customerId}/terms/approve`, {});

    return data;
  };

const acceptTermsPartnership =
  (instance: AxiosInstance) =>
  async (partnershipId: string): Promise<SettingsResponse> => {
    const { data } = await instance.post<SettingsResponse>(`/partnerships/${partnershipId}/terms/approve`, {});

    return data;
  };

const acceptPrivacyPolicy =
  (instance: AxiosInstance) =>
  async (accountId: string): Promise<PrivacyPolicyResponse> => {
    const { data } = await instance.post(`/user/accounts/${accountId}/privacyPolicy/approve`, {});

    return data;
  };

const getJobStatus =
  (instance: AxiosInstance) =>
  async <T>(partnershipId: string, jobId: string, sourceToken: CancelToken): Promise<JobStatusResponse<T>> => {
    const { data } = await instance.get<JobStatusResponse<T>>(`/partnerships/${partnershipId}/jobs/${jobId}`, { cancelToken: sourceToken });

    return data;
  };

const updateCustomerFootprintCalculatorSettings =
  (instance: AxiosInstance) =>
  async (id: string, body: FootprintCalculator): Promise<CustomerLegacy> => {
    const { data } = await instance.put<CustomerLegacy>(`/customers/${id}/settings/footprintCalculator`, body);

    return data;
  };

const handlers = {
  createMetadata,
  modifyMetadata,
  deleteMetadata,
  modifyUserInformation,
  modifyBillingInformation,
  deleteUserAccount,
  transferOwnership,
  modifyEmailSubscription,
  createCustomer,
  createMultipleCustomers,
  completeInvitation,
  getAttributionSyncStatus,
  getAttributionForCustomer,
  getAttributionForPartnership,
  getAttributionForUser,
  getCustomer,
  getPartnership,
  updateCustomer,
  updatePartnership,
  getReceivedGiftCards,
  getSettingsForUser,
  deleteCustomer,
  getPartnersCustomers,
  updateCustomerInformation,
  updatePartnershipInvoiceInformation,
  acceptTermsCustomer,
  acceptTermsPartnership,
  acceptPrivacyPolicy,
  inviteUser,
  getCustomers,
  getJobStatus,
  updateCustomerFootprintCalculatorSettings,
  resendCustomerInvite,
  resendUserInvite,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createUserApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export const getInvitedUser = async (id: string): Promise<Invitation> => {
  const { data } = await anonymousPortalInstance.get<Invitation>(`/invites/${id}`);

  return data;
};

export default createUserApi;
