import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createOrderApi, { AdditionalApproveParams, ApproveParams, ApproveParamsItem, SortKey } from '@api/order';
import { AppThunk } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, customerCurrencySelector } from '@store/user/userSlice';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent } from '@utils/tags';

interface SetOrderParams<T> {
  orders: T[];
  total: number;
}

interface State {
  allOrders: OrderLegacy<OrderFootprint>[];
  orders: OrderLegacy<OrderFootprint>[];
  isLoading: boolean;
  error?: string;
  totalItems: number;
  facilitatedOrder?: OrderLegacy<OrderFootprint>;
  viewedOrder?: OrderLegacy<OrderFootprint>;
  corporateOrders: {
    [key: string]: { total: number; orders: OrderLegacy<OrderFootprint>[] };
  };
  approveParamsItems?: ApproveParamsItem[];
  updatedFacilitatedOrderPrice?: number;
  updatedFacilitatedOrderInitialPrice?: number;
  updatedFacilitatedOrderVat?: number;
  updatedFacilitatedOrderExcise?: number;
  updatedFacilitatedOrderTotalKilosCo2Avoided?: number;
  updatedFacilitatedOrderTotalKilosCo2Offset?: number;
  isFetchingFacilitatedOrderData: boolean;
  multiplier?: Multiplier;
  facilitatedOrderFootprint?: Footprint[];
  additionalApproveParams?: AdditionalApproveParams;
  facilitatedOrdersForSingleCustomers: SetOrderParams<OrderLegacy<OrderFootprint>>;
  facilitatedOrdersForMultipleCustomers: SetOrderParams<MultiCustomersOrder>;
  orderCreateCurrentStep: string;
  billId?: string;
}

interface OrderHistoryFilters {
  type?: string;
  from?: string;
  to?: string;
  department?: string;
  country?: string;
  page?: number;
  paymentStatus?: string;
}

interface FacilitatedOrderHistoryFilters {
  page?: number;
}

const paymentStatusFilters = ['Draft', 'Open', 'Paid', 'Uncollectible', 'Void', 'Proposed'];

export const LIMIT = 10;

const initialState: State = {
  allOrders: [],
  orders: [],
  isLoading: false,
  totalItems: 0,
  corporateOrders: {},
  isFetchingFacilitatedOrderData: false,
  facilitatedOrdersForSingleCustomers: {
    orders: [],
    total: 0,
  },
  facilitatedOrdersForMultipleCustomers: {
    orders: [],
    total: 0,
  },
  orderCreateCurrentStep: '',
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state: State, action: PayloadAction<SetOrderParams<OrderLegacy<OrderFootprint>>>): State => {
      state.orders = action.payload.orders;
      state.totalItems = action.payload.total;

      return state;
    },
    setFacilitatedOrder: (state: State, action: PayloadAction<OrderLegacy<OrderFootprint>>): State => {
      state.facilitatedOrder = action.payload;

      return state;
    },
    setLoading: (state: State, action: PayloadAction<boolean>): State => {
      state.isLoading = action.payload;

      return state;
    },
    setIsFetchingFacilitatedOrderData: (state: State, action: PayloadAction<boolean>): State => {
      state.isFetchingFacilitatedOrderData = action.payload;

      return state;
    },
    setError: (state: State, action: PayloadAction<string>): State => {
      state.error = action.payload;

      return state;
    },
    setCorporateOrderItem: (state: State, action: PayloadAction<{ key: string; orders: SetOrderParams<OrderLegacy<OrderFootprint>> }>): State => {
      state.corporateOrders[action.payload.key] = action.payload.orders;

      return state;
    },
    setApproveParamItems: (state: State, action: PayloadAction<ApproveParamsItem[]>): State => {
      state.approveParamsItems = action.payload;

      return state;
    },
    setUpdatedFacilitatedOrderPrice: (state: State, action: PayloadAction<number>): State => {
      state.updatedFacilitatedOrderPrice = action.payload;

      return state;
    },
    setUpdatedFacilitatedOrderInitialPrice: (state: State, action: PayloadAction<number>): State => {
      state.updatedFacilitatedOrderInitialPrice = action.payload;

      return state;
    },
    setUpdatedFacilitatedOrderVat: (state: State, action: PayloadAction<number>): State => {
      state.updatedFacilitatedOrderVat = action.payload;

      return state;
    },
    setUpdatedFacilitatedOrderExcise: (state: State, action: PayloadAction<number>): State => {
      state.updatedFacilitatedOrderExcise = action.payload;

      return state;
    },
    setUpdatedFacilitatedOrderTotalKilosCo2Avoided: (state: State, action: PayloadAction<number>): State => {
      state.updatedFacilitatedOrderTotalKilosCo2Avoided = action.payload;

      return state;
    },
    setUpdatedFacilitatedOrderTotalKilosCo2Offset: (state: State, action: PayloadAction<number>): State => {
      state.updatedFacilitatedOrderTotalKilosCo2Offset = action.payload;

      return state;
    },
    setMultiplier: (state: State, action: PayloadAction<Multiplier>): State => {
      state.multiplier = action.payload;

      return state;
    },
    setFacilitatedOrderFootprint: (state: State, action: PayloadAction<Footprint[]>): State => {
      state.facilitatedOrderFootprint = action.payload;

      return state;
    },
    setAdditionalApproveParams: (state: State, action: PayloadAction<AdditionalApproveParams>): State => {
      state.additionalApproveParams = action.payload;

      return state;
    },
    resetAdditionalApproveParams: (state: State): State => {
      state.additionalApproveParams = undefined;

      return state;
    },
    setFacilitatedOrdersForSingleCustomer: (state: State, action: PayloadAction<SetOrderParams<OrderLegacy<OrderFootprint>>>): State => {
      state.facilitatedOrdersForSingleCustomers = action.payload;

      return state;
    },
    setFacilitatedOrdersForMultipleCustomers: (state: State, action: PayloadAction<SetOrderParams<MultiCustomersOrder>>): State => {
      state.facilitatedOrdersForMultipleCustomers = action.payload;

      return state;
    },
    resetFacilitatedOrder: (state: State): State => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { facilitatedOrder, ...rest } = state;

      return rest;
    },
    setOrderCreateCurrentStep: (state: State, action: PayloadAction<string>): State => {
      state.orderCreateCurrentStep = action.payload;

      return state;
    },
    setBillId: (state: State, action: PayloadAction<string>): State => {
      state.billId = action.payload;

      return state;
    },
  },
});
const {
  setOrders,
  setError,
  setLoading,
  setFacilitatedOrder,
  setCorporateOrderItem,
  setApproveParamItems,
  setUpdatedFacilitatedOrderPrice,
  setUpdatedFacilitatedOrderInitialPrice,
  setUpdatedFacilitatedOrderVat,
  setUpdatedFacilitatedOrderExcise,
  setUpdatedFacilitatedOrderTotalKilosCo2Avoided,
  setUpdatedFacilitatedOrderTotalKilosCo2Offset,
  setIsFetchingFacilitatedOrderData,
  setMultiplier,
  setFacilitatedOrderFootprint,
  setAdditionalApproveParams,
  resetAdditionalApproveParams,
  setFacilitatedOrdersForSingleCustomer,
  setFacilitatedOrdersForMultipleCustomers,
  resetFacilitatedOrder,
  setOrderCreateCurrentStep,
  setBillId,
} = slice.actions;

export { resetAdditionalApproveParams, resetFacilitatedOrder, setAdditionalApproveParams, setMultiplier, setOrderCreateCurrentStep };

export default slice.reducer;

type OrderHistoryTab = 'pendingConfirmation' | 'pendingPayment' | 'paid';

export const getOrderHistory =
  (filters?: OrderHistoryFilters, sortKey?: SortKey, tab?: OrderHistoryTab): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);

    try {
      const excludedStatuses = filters?.paymentStatus ? paymentStatusFilters.filter(f => !filters.paymentStatus?.includes(f)).join(',') : undefined;

      const appliedFilters = {
        category: filters?.type,
        fromCreatedDate: filters?.from,
        toCreatedDate: filters?.to,
        'metadata.custom.department': filters?.department,
        'metadata.custom.country': filters?.country,
        'payment.status': excludedStatuses,
      };

      const page = filters && filters.page ? filters.page - 1 : 0;

      const result = await orderApi.getAll({ offset: page * LIMIT, limit: LIMIT, ...appliedFilters, sortKey }, currentUser?.customerId ?? '');

      if (tab) {
        dispatch(setCorporateOrderItem({ key: tab, orders: { orders: result.items, total: result.totalCount } }));
      } else {
        dispatch(
          setOrders({
            orders: result.items,
            total: result.totalCount,
          }),
        );
      }

      dispatch(setLoading(false));
    } catch (err) {
      // @ts-ignore
      dispatch(setError(err?.error ? err.error : err?.message));
      dispatch(setLoading(false));
    }
  };

export const getFacilitatedOrderData =
  (customerId: string, orderId: string, errorCb?: CallableFunction, retries = 0): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const maxNumberOfRetries = 20;
    const retriesNumber = retries;

    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);

    try {
      const order = await orderApi.getOne(orderId, customerId, {
        includeStats: true,
        includeAttachments: true,
        includeVirtualPortfolio: true,
      });
      const footprint = await orderApi.getFootprint(orderId, customerId);
      dispatch(setFacilitatedOrderFootprint(footprint));
      dispatch(setFacilitatedOrder(order));
      dispatch(setLoading(false));
    } catch (err) {
      if (maxNumberOfRetries > retriesNumber) {
        setTimeout(() => dispatch(getFacilitatedOrderData(customerId, orderId, errorCb, retriesNumber + 1)), 3000);
        if (retriesNumber % 2 === 1) {
          dispatch(
            showToast({
              variant: 'error',
              titleI18nKey: 'offset:orderClaim.errorOrderNotFoundTitleWillRetry',
              descriptionI18nKey: 'offset:orderClaim.errorOrderNotFoundDescriptionWillRetry',
            }),
          );
        }
      } else {
        dispatch(setLoading(false));
        dispatch(
          showToast({ variant: 'error', descriptionI18nKey: 'offset:orderClaim.errorOrderNotFoundDescription', titleI18nKey: 'common:errorTitle' }),
        );
        errorCb && errorCb();
      }
    }
  };

export const getApproveParams =
  (percentage: number, setInitialPrice = false): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { facilitatedOrder } = getState().orders;

    const context = appContextSelector(getState());
    const customerId = getState().user.currentAccount?.customerId ?? '';
    const currency = customerCurrencySelector(getState());
    const { virtualPortfolios } = getState().offsetPortfolioSelect;
    const arePortfoliosRedistributed = virtualPortfolios.some(p => p.weight > 0);

    const percentageAsFraction = percentage / 100;

    try {
      dispatch(setIsFetchingFacilitatedOrderData(true));

      if (facilitatedOrder) {
        const mappedVirtualPortfolios = arePortfoliosRedistributed
          ? virtualPortfolios.filter(({ weight }) => weight > 0).map(({ id, weight }) => ({ id, weight: weight * percentageAsFraction }))
          : undefined;
        const orderApi = createOrderApi(context);
        const newItems = facilitatedOrder.items.map(item => {
          const airports = item.details?.flight?.routeLegs
            ?.map((route, index) => {
              if (index === 0) {
                return [route.fromAirportCode, route.toAirportCode];
              }

              return route.toAirportCode;
            })
            ?.flat();

          return {
            type: 'impact',
            impact: 'Co2',
            details: item.details,
            feeFactors: item.feeFactors,
            taxFactors: {
              airports,
            },
            kilosCo2: item.kilosCo2 * percentageAsFraction,
            portfolio:
              mappedVirtualPortfolios || facilitatedOrder.virtualPortfolio?.childPortfolios
                ? {
                    type: 'Virtual',
                    impact: 'co2',
                    currency: currency,
                    childPortfolios: (mappedVirtualPortfolios as VirtualPortfolio[]) ?? facilitatedOrder.virtualPortfolio?.childPortfolios ?? [],
                  }
                : undefined,
            portfolioId: mappedVirtualPortfolios || facilitatedOrder.virtualPortfolio?.childPortfolios ? null : facilitatedOrder.portfolioId ?? null,
          };
        });

        const order = {
          currency: currency,
          category: facilitatedOrder.category,
          items: newItems,
        };

        // @ts-ignore
        const response = await orderApi.getQuoteForCustomer(order, customerId);
        const approveParamsItems = response.items.map(({ kilosCo2, price }) => ({ kilosCo2, price }));
        if (setInitialPrice) {
          dispatch(setUpdatedFacilitatedOrderInitialPrice(response.totalPrice));
        } else {
          dispatch(setApproveParamItems(approveParamsItems));
          dispatch(setUpdatedFacilitatedOrderPrice(response.totalPrice));
          dispatch(setUpdatedFacilitatedOrderVat(response.totalVat));
          dispatch(setUpdatedFacilitatedOrderExcise(response.totalExcise));
          dispatch(setUpdatedFacilitatedOrderTotalKilosCo2Avoided(response.totalKilosCo2Avoided ?? 0));
          dispatch(setUpdatedFacilitatedOrderTotalKilosCo2Offset(response.totalKilosCo2Offset ?? 0));
          percentage === 100 && dispatch(setUpdatedFacilitatedOrderInitialPrice(response.totalPrice));
        }
      }
    } catch (err) {
      // err
    } finally {
      dispatch(setIsFetchingFacilitatedOrderData(false));
    }
  };

export const approveFacilitatedOrder =
  (orderId: string, years?: number[], approveParams?: ApproveParams, successCb?: CallableFunction, errorCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));

    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const currentAccount = getState().user.currentAccount;
    const customerId = currentAccount?.customerId ?? '';
    const paymentMethodType = currentAccount?.customer?.payment?.defaultPaymentMethod?.type;
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;
    const { orders } = getState();

    try {
      const { order, billId } = await orderApi.approveOrder(orderId, customerId, approveParams ?? {});
      billId && dispatch(setBillId(billId));
      dispatch(syncAttribution());
      await client.invalidateQueries({ queryKey: ['orders', 'history'] });
      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        const promises =
          years?.map(year =>
            client.refetchQueries({
              queryKey: ['impact', 'customer', customerId, { year }],
            }),
          ) ?? [];
        await Promise.all(promises);
      }
      trackPurchaseEvent(
        order,
        'Portal offset',
        paymentMethodType,
        orders.facilitatedOrderFootprint?.map(({ id }) => id),
      );
      successCb && successCb();
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(showToast({ variant: 'error', descriptionI18nKey: 'common:errorDescription', titleI18nKey: 'common:errorTitle' }));
      errorCb && errorCb();
    }
  };

export const rejectFacilitatedOrder =
  (orderId: string, successCb?: CallableFunction, errorCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const customerId = getState().user.currentAccount?.customerId ?? '';

    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);

    try {
      await orderApi.rejectOrder(orderId, customerId);
      successCb && successCb();
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(showToast({ variant: 'error', descriptionI18nKey: 'common:errorDescription', titleI18nKey: 'common:errorTitle' }));
      client.invalidateQueries({ queryKey: ['orders', 'history'] });
      dispatch(syncAttribution());
      errorCb && errorCb();
      dispatch(setLoading(false));
    }
  };

export const getFacilitatedOrderHistoryForSingleCustomers =
  (filters: FacilitatedOrderHistoryFilters): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const { currentAccount } = getState().user;
    const currentPage = filters?.page ? filters.page - 1 : 0;

    try {
      const facilitatedOrders = await orderApi.getFacilitatedOrdersForSingleCustomers(
        { offset: currentPage * LIMIT, limit: LIMIT },
        currentAccount?.partnershipId ?? '',
      );

      dispatch(
        setFacilitatedOrdersForSingleCustomer({
          orders: facilitatedOrders.items,
          total: facilitatedOrders.totalCount,
        }),
      );
    } catch (err) {
      // err
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getFacilitatedOrderHistoryForMultipleCustomers =
  (filters: FacilitatedOrderHistoryFilters): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const { currentAccount } = getState().user;
    const currentPage = filters?.page ? filters.page - 1 : 0;

    try {
      const multipleCustomersOrder = await orderApi.getFacilitatedOrdersForMultipleCustomers(
        { offset: currentPage * LIMIT, limit: LIMIT },
        currentAccount?.partnershipId ?? '',
      );

      dispatch(
        setFacilitatedOrdersForMultipleCustomers({
          orders: multipleCustomersOrder.items,
          total: multipleCustomersOrder.totalCount,
        }),
      );
    } catch (err) {
      // err
    } finally {
      dispatch(setLoading(false));
    }
  };
