import { defineStyleConfig } from '@chakra-ui/react';

const Modal = defineStyleConfig({
  baseStyle: {
    header: {
      defaultProps: {
        textStyle: 'h5',
      },
      padding: '24px',
      textStyle: 'h5',
    },
    dialog: {
      borderRadius: 'base',
      boxShadow: 'modal',
      maxW: '100%',
    },
    closeButton: {
      w: '36px',
      h: '36px',
      color: 'secondary.400',
      top: '24px',
      right: '24px',
    },
    body: {
      p: '24px',
      textAlign: 'center',
    },
    footer: {
      p: '0 24px 24px',
    },
  },
  sizes: {
    md: {
      dialog: {
        w: '424px',
      },
    },
    lg: {
      dialog: {
        maxW: '872px',
      },
    },
  },
});

export default Modal;
