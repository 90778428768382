export const setSessionStorageObject = <T>(key: string, data: T) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getSessionStorageObject = <T>(key: string): T | undefined => {
  try {
    return JSON.parse(sessionStorage.getItem(key) ?? '') as T;
  } catch (err) {
    return undefined;
  }
};
