import { useEffect } from 'react';
import { useAuthHandlerStore } from '@lib/msal/authHandlerStore';
import { LoaderFullPage } from '@components/Loader';

export const LogoutAction = () => {
  const setAuthHandler = useAuthHandlerStore(state => state.setAuthHandler);

  useEffect(() => {
    setAuthHandler({
      type: 'LogoutRedirect',
      request: { postLogoutRedirectUri: '/' },
    });
  }, []);

  return <LoaderFullPage />;
};
