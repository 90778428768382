import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getAccessToken } from '@lib/msal';

import { INTERACTION_ERROR_CODE, NETWORK_ERROR_CODE, NetworkError, UNAUTHORIZED_ERROR_CODE } from './error';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

async function onRequestWithToken(config: InternalAxiosRequestConfig) {
  let token = await getAccessToken();
  if (!token) {
    token = await getAccessToken(true);
  }
  if (!token) {
    throw new NetworkError(INTERACTION_ERROR_CODE);
  }
  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}

function onRequest(config: InternalAxiosRequestConfig) {
  return config;
}

function onResponseErrorWithRetry(error: AxiosError) {
  // @ts-ignore
  if (error.response?.status === UNAUTHORIZED && !error.config?.isRetry) {
    return getAccessToken(true).then(token => {
      if (error?.config?.headers) {
        error.config.headers['Authorization'] = `Bearer ${token}`;

        // @ts-ignore
        error.config.isRetry = true;

        return axios.request(error.config);
      }
    });
  }
  if (error.response?.status === FORBIDDEN) {
    return Promise.reject(new NetworkError(UNAUTHORIZED_ERROR_CODE));
  }

  return Promise.reject(error?.response);
}

function onResponseError(error: AxiosError): Promise<never> {
  if (error.message === 'Network Error') {
    return Promise.reject(new NetworkError(NETWORK_ERROR_CODE));
  }

  return Promise.reject(error?.response);
}

const defaultInstance = axios.create({
  baseURL: import.meta.env.REACT_APP_PORTAL_API_URL,
  headers: {
    'x-functions-key': import.meta.env.REACT_APP_WECHOOOSE_API_KEY,
  },
});
defaultInstance.interceptors.request.use(onRequestWithToken);
defaultInstance.interceptors.response.use(undefined, onResponseErrorWithRetry);

const anonymousPortalInstance = axios.create({
  baseURL: import.meta.env.REACT_APP_PORTAL_API_URL,
  headers: {
    'x-functions-key': import.meta.env.REACT_APP_WECHOOOSE_API_KEY,
  },
});
anonymousPortalInstance.interceptors.request.use(onRequest);
anonymousPortalInstance.interceptors.response.use(undefined, onResponseError);

const publicInstance = axios.create({
  baseURL: import.meta.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'x-functions-key': import.meta.env.REACT_APP_PUBLIC_API_FUNCTION_KEY,
  },
});
publicInstance.interceptors.request.use(onRequest);
publicInstance.interceptors.response.use(undefined, onResponseError);

const sanityInstance = axios.create({
  baseURL: `https://${import.meta.env.REACT_APP_SANITY_PROJECT_ID}.api.sanity.io/v1/data/query/${import.meta.env.REACT_APP_SANITY_DATASET}`,
  headers: {
    Authorization: `Bearer ${import.meta.env.REACT_APP_SANITY_TOKEN}`,
  },
});
sanityInstance.interceptors.request.use(onRequest);
sanityInstance.interceptors.response.use(undefined, onResponseError);

function getInstance(context: PortalContext): AxiosInstance {
  let instance = anonymousPortalInstance;
  if (['guest-wechooose', 'wechooose', 'connect', 'transact'].includes(context)) {
    instance = defaultInstance;
  }

  return instance;
}

type InjectableFunction<TFunction> = (instance: AxiosInstance) => TFunction;

function createContextAwareHandlers<T extends { [x: string]: InjectableFunction<ReturnType<T[K]>> }, K extends keyof T>(
  context: PortalContext,
  handlers: T,
): { [K in keyof T]: ReturnType<T[K]> } {
  const instance = getInstance(context);
  const injectedHandlers: { [K in keyof T]: ReturnType<T[K]> } = Object.assign(
    {},
    ...Object.entries(handlers).map(([key, handler]) => ({ [key]: handler(instance) })),
  );

  return injectedHandlers;
}

export { anonymousPortalInstance, createContextAwareHandlers, defaultInstance, publicInstance, sanityInstance };
