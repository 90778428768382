import { Link, Typography } from '@chooose/ui';
import styled, { css } from 'styled-components';

export const RootErrorBoundaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  ${({ theme }) => css`
    gap: ${theme.spacing.md};
  `}
`;

export const Heading = styled(Typography)`
  text-align: center;
`;

export const CustomerSupportLink = styled(Link)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.xxxs};
    font-size: ${theme.fontSizes.md};
  `}
`;
