import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, theme } from '@chooose/ui';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ArrowsCounterClockwise } from '@icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div > *:first-child {
    margin-top: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-top: ${({ theme }) => theme.spacing.xxl};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }

  strong {
    font-weight: 500;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  ul,
  ol {
    padding-left: ${({ theme }) => theme.fontSizes.xl};
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  li + li {
    margin-top: ${({ theme }) => theme.spacing.xxs};
  }
`;

interface Props {
  lastUpdated?: string;
}
export const TermsPage: FC<React.PropsWithChildren<Props>> = ({ children, lastUpdated }) => {
  const { t } = useTranslation('settings');

  return (
    <Wrapper>
      {children}
      {lastUpdated && (
        <Badge
          style={{ marginTop: theme.spacing.xxxl }}
          startAdornment={<ArrowsCounterClockwise color={theme.colors.icon.default} />}
          variant='outline'
          label={`${t('terms.updated')}: ${format(new Date(lastUpdated), 'PP')}`}></Badge>
      )}
    </Wrapper>
  );
};
