import React, { FC, Suspense, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Global as ChoooseUIGlobalStyles, theme as choooseTheme, ToastProvider } from '@chooose/ui';
import { ThemeProvider } from 'styled-components';
import './i18n';
import { useIsAuthenticated } from '@azure/msal-react';
import { reactPlugin } from '@lib/appInsights/appInsights';
import RequireAuth from '@lib/msal/RequireAuth';
import { PosthogDelayedSurvey } from '@lib/posthog/PosthogDelayedSurvey';
import { useCurrentAccount } from '@api/user/queries';
import { Account } from '@api/user/types';
import { AccountInitializer } from '@components/AccountInitializer';
import { LoaderFullPage } from '@components/Loader';
import { RootErrorBoundary } from '@components/RootErrorBoundary';
import { Toaster } from '@components/Toaster';
import { useAnalytics } from '@hooks/useAnalytics';
import { useAnalyticsWatcher } from '@hooks/useAnalyticsWatcher';
import { useAppTheme } from '@hooks/useAppTheme';
import { usePartnershipStylesOverride } from '@hooks/usePartnershipStylesOverride';
import { LogoutAction } from '@pages/shared/Action/LogoutAction';
import Blank from '@pages/shared/Blank';
import DataLayer from '@pages/shared/DataLayer';
import FaviconInjector from '@pages/shared/FaviconInjector';
import LoginSetup from '@pages/shared/LoginSetup/LoginSetup';
import SSOLoginError from '@pages/shared/SSOLoginError';
import Terms from '@pages/shared/Terms';
import { RootState } from '@store';

import { GlobalStyles } from '@styles';
import { GlobalThemeStyles } from '@theme';

// Apps
const CustomerGuestApp = React.lazy(() => import('./apps/CustomerGuestApp'));
const CustomerApp = React.lazy(() => import('./apps/Customer'));
const ConnectApp = React.lazy(() => import('./apps/Connect'));
const TransactApp = React.lazy(() => import('./apps/Transact'));
const CustomerSignUp = React.lazy(() => import('./pages/wechooose/SignUp'));
const CustomerSetup = React.lazy(() => import('./pages/wechooose/Setup'));
const OrderClaim = React.lazy(() => import('./pages/wechooose/OrderClaim/OrderClaimGuest'));
const Invite = React.lazy(() => import('./pages/shared/Invite'));
const InviteClaim = React.lazy(() => import('./pages/shared/InviteClaim'));
const Logout = React.lazy(() => import('./pages/shared/Logout'));

const getApp = (type: Account['type']) => {
  switch (type) {
    case 'WeChoooseCommmunity':
      return ConnectApp;
    case 'Transact':
      return TransactApp;
    default:
      return CustomerApp;
  }
};

const App: FC<React.PropsWithChildren> = () => {
  useAnalyticsWatcher();
  const { i18n } = useTranslation();
  const { theme } = useAppTheme();
  const [cookies, setCookie] = useCookies(['chooose-cookies-consent-state']);
  const partnerStyles = usePartnershipStylesOverride();
  const currentLanguage = i18n.language;
  const languages = useSelector((state: RootState) => state.app.languages);
  const { track } = useAnalytics();
  const isAuthenticated = useIsAuthenticated();
  const { account: currentAccount } = useCurrentAccount();
  const App = currentAccount ? getApp(currentAccount?.type) : undefined;

  useEffect(() => {
    const pageShow = (ev: PageTransitionEvent) => {
      if (ev.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', pageShow);

    return () => {
      window.removeEventListener('pageshow', pageShow);
    };
  }, []);

  useEffect(() => {
    // Fallback to english if user's language is not supported
    if (languages && !languages.includes(currentLanguage)) {
      i18n.changeLanguage('en');
    }
  }, [currentLanguage, languages]);

  useEffect(() => {
    // @ts-ignore
    window.zE && window.zE(() => window.zE.setLocale(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (!cookies['chooose-cookies-consent-state']) {
      setCookie('chooose-cookies-consent-state', JSON.stringify({ tracking: true, targeting: true }), { path: '/', maxAge: 60 });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && currentAccount) {
      track('user_logged_in', { user: currentAccount?.email, id: currentAccount?.id });
      track('$pageview');
    }
  }, [isAuthenticated, currentAccount]);

  return (
    <ThemeProvider theme={choooseTheme}>
      <ChoooseUIGlobalStyles {...partnerStyles} />
      <GlobalStyles />
      <RootErrorBoundary appInsights={reactPlugin}>
        <ToastProvider>
          <ChakraProvider theme={theme} portalZIndex={999}>
            <GlobalThemeStyles />
            <Suspense fallback={<LoaderFullPage />}>
              <FaviconInjector />
              <DataLayer />
              <Toaster />
              <Switch>
                <Redirect exact from='/signup' to='/sign-up/chooose' />
                <Redirect exact from='/sign-up' to='/sign-up/chooose' />
                <Redirect exact from='/sign-up/wechooose' to='/sign-up/chooose' />
                <Redirect exact from='/loggedout' to='/logout' />
                <Redirect exact from='/setup/wechooose' to='/setup/chooose' />
                <Route exact path='/blank' component={Blank} />
                <Route exact path='/sign-up/chooose' component={CustomerSignUp} />
                <Route exact path='/setup/chooose' component={CustomerSetup} />
                <Route exact path='/invite' component={Invite} />
                <Route exact path='/invite/claim' component={InviteClaim} />
                <Route exact path='/login-setup' component={LoginSetup} />
                <Route exact path='/offset-claim' component={OrderClaim} />
                <Route exact path='/terms' component={Terms} />
                <Route exact path='/login-error' component={SSOLoginError} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/action/logout' component={LogoutAction} />
                <Route path='/guest' component={CustomerGuestApp} />
                <Route path='*'>
                  <Suspense fallback={<LoaderFullPage />}>
                    <RequireAuth>
                      <AccountInitializer>
                        <PosthogDelayedSurvey />
                        {App && <App />}
                      </AccountInitializer>
                    </RequireAuth>
                  </Suspense>
                </Route>
              </Switch>
            </Suspense>
          </ChakraProvider>
        </ToastProvider>
      </RootErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
