import axios, { CancelTokenSource } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createUserApi, { CustomerBatchRequest } from '@api/user';
import { AppThunk } from '@store';
import { appContextSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { currentUserSelector } from '@store/user/userSlice';

const CancelToken = axios.CancelToken;
let source: CancelTokenSource = CancelToken.source();

interface CustomersOffsetState {
  processFile?: boolean;
  fileUploading: boolean;
  attachment?: { storageBlobName: string; type: string };
  fileErrors?: string[];
  fileSuccess?: boolean;
  attachmentTempUrl?: string;
  customersCount?: number;
  isModalOpen: boolean;
  customerListVersion: number;
}

const initialState: CustomersOffsetState = {
  fileUploading: false,
  isModalOpen: false,
  customerListVersion: 0,
};

const slice = createSlice({
  name: 'offsetCustomers',
  initialState,
  reducers: {
    resetUploadState: (state: CustomersOffsetState, action: PayloadAction<boolean>): CustomersOffsetState => {
      state.processFile = action.payload;
      state.fileUploading = action.payload;
      state.fileErrors = [];
      state.attachmentTempUrl = undefined;
      state.attachment = undefined;
      state.fileSuccess = false;

      return state;
    },
    setFileUploading: (state: CustomersOffsetState, action: PayloadAction<boolean>): CustomersOffsetState => {
      state.processFile = action.payload;
      state.fileUploading = action.payload;

      return state;
    },
    setFileErrors: (state: CustomersOffsetState, action: PayloadAction<string[]>): CustomersOffsetState => {
      state.fileErrors = action.payload;

      return state;
    },
    setFileSuccess: (state: CustomersOffsetState, action: PayloadAction<boolean>): CustomersOffsetState => {
      state.fileSuccess = action.payload;
      state.customerListVersion = state.customerListVersion + 1;

      return state;
    },
    setIsModalOpen: (state: CustomersOffsetState, action: PayloadAction<boolean>): CustomersOffsetState => {
      state.isModalOpen = action.payload;

      return state;
    },
  },
});

const { resetUploadState, setFileUploading, setFileErrors, setFileSuccess, setIsModalOpen } = slice.actions;

export { resetUploadState, setIsModalOpen };

export default slice.reducer;

export const processFile =
  (data: File | CustomerBatchRequest, dryRun: boolean, isSingleCustomerCreation?: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setFileSuccess(false));
    dispatch(setFileUploading(true));
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);

    try {
      source = CancelToken.source();

      const { partnershipId, jobId } = await userApi.createMultipleCustomers(data, source.token, currentUser?.partnershipId ?? 'default', dryRun);

      let response: JobStatusResponse<{}>;

      do {
        await new Promise(resolve => setTimeout(resolve, 1000));
        response = await userApi.getJobStatus(partnershipId, jobId, source.token);
      } while (response.progressPercentage !== 100);

      if (getState().offsetCustomers.processFile) {
        const errorMessages = response.messages.filter(({ type }) => type.toLowerCase() !== 'information').map(m => m.message);
        if ((response.status === 'Succeeded' || response.status === 'Created') && errorMessages.length === 0) {
          dispatch(setFileErrors([]));
          dispatch(setFileSuccess(true));
          if (!dryRun) {
            dispatch(setIsModalOpen(false));
            dispatch(resetUploadState(true));
            dispatch(
              showToast({
                variant: 'success',
                titleI18nKey: 'offset:modals.addCustomer.creationSuccessTitle',
                descriptionI18nKey: 'offset:modals.addCustomer.creationSuccess',
              }),
            );
          }
        } else {
          if (isSingleCustomerCreation) {
            const customerAlreadyExistsError = errorMessages.find(
              message => message.includes('Skipping customer') && message.includes('because it already exists'),
            );
            dispatch(
              showToast({
                variant: 'error',
                titleI18nKey: 'offset:modals.addCustomer.creationErrorTitle',
                descriptionI18nKey: `offset:modals.addCustomer.${
                  customerAlreadyExistsError ? 'creationErrorCustomerAlreadyExists' : 'creationError'
                }`,
              }),
            );
          } else {
            dispatch(setFileErrors(errorMessages));
          }
        }
      }
      dispatch(setFileUploading(false));
    } catch (err) {
      // @ts-ignore
      if (err && err.data) {
        // @ts-ignore
        const { data } = err.data;
        if (data && Array.isArray(data)) {
          dispatch(setFileErrors(data));
          // @ts-ignore
        }
      }
      dispatch(
        showToast({
          variant: 'error',
          titleI18nKey: 'offset:modals.addCustomer.creationErrorTitle',
          descriptionI18nKey: 'offset:modals.addCustomer.creationError',
        }),
      );
      dispatch(setFileUploading(false));
    }
  };
