/* eslint-disable no-undef */
import { AxiosInstance, CancelToken } from 'axios';

import { createContextAwareHandlers } from './instance';

const createMultiCustomerBatchOrderForPartner =
  (instance: AxiosInstance) =>
  async (partnershipId: string, currency: string, file: File, sourceToken: CancelToken): Promise<BatchJobResponseOk & BatchJobResponseError> => {
    const { data } = await instance.post<BatchJobResponseOk & BatchJobResponseError>(
      `/partnerships/${partnershipId}/attachments/processmultipleairfreightbatchjob?currency=${currency}`,
      file,
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        cancelToken: sourceToken,
      },
    );

    return data;
  };

const handlers = {
  createMultiCustomerBatchOrderForPartner,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createAirFreightApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createAirFreightApi;
