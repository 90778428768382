import { FC, PropsWithChildren, useEffect } from 'react';
import { useCurrentAccount } from '@api/user/queries';
import { isCustomerAccount, isPartnerAccount, isTransactAccount } from '@utils/contextChecker';
import { getEnvironment } from '@utils/tags';

const DataLayer: FC<PropsWithChildren> = () => {
  const { account } = useCurrentAccount();
  const isPartner = isPartnerAccount(account);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (!account) {
      return;
    }
    let partnershipId = '';
    let customerId = '';
    if (isCustomerAccount(account) || isTransactAccount(account)) {
      partnershipId = account.context?.sourcePartnershipId ?? '';
      customerId = account.customerId;
    } else if (isPartner) {
      partnershipId = account.partnershipId;
    }

    window.chooosePartnershipId = partnershipId;
    window.choooseCustomerId = customerId;

    const domain = window.location.hostname.split('.')?.[0];

    if (!domain || domain === 'localhost') {
      return;
    }

    window.dataLayer.push({
      event: 'customer-data-loaded',
      env: domain.includes('-test') ? 'test' : 'production',
      domain: domain.replace('-test', ''),
      chooosePartnershipId: partnershipId,
      choooseCustomerId: customerId,
    });
    const gmtGlobalData = {
      fields_to_set: {
        partnership_id: partnershipId,
        partnership_name: import.meta.env.REACT_APP_PARTNER_NAME ?? 'no_partnership_id',
        environment: getEnvironment(),
      },
      customer_info: {
        account_type: isPartner ? 'portal' : 'corporate',
        customer_company: isPartner ? account?.context?.partnerPortalName ?? '' : account?.context?.customerName ?? '',
      },
    };
    window.dataLayer.push({
      event: 'global-data-loaded',
      ...gmtGlobalData,
    });
    sessionStorage.setItem('gtmGlobalData', JSON.stringify(gmtGlobalData));
  }, [account?.id]);

  return null;
};

export default DataLayer;
