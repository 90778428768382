import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { appInsights } from './appInsights';

export const AppInsightsWatcher = () => {
  const { accounts } = useMsal();
  const setUserEmail = (userEmail: string) => {
    if (!appInsights) {
      return;
    }

    appInsights.addTelemetryInitializer(item => {
      if (!item) {
        return;
      }
      item.tags = item.tags || [];

      item.tags.push({ 'ai.user.email': userEmail });
    });
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      const userEmail = account.username ?? '';
      setUserEmail(userEmail);
    }
  }, [accounts]);

  return null;
};
