import { theme } from '@chakra-ui/react';
import { darken, rgba } from 'polished';

export const CTA = '#1B6FF0';

const accent = '#3781F2';
const white = '#ffffff';

const baseColors = {
  primary: {
    50: '#F8FAFE',
    100: rgba(CTA, 0.2),
    200: '#C5DEFB',
    300: '#8FB8F8',
    400: accent,
    500: CTA,
    600: '#165ECD',
    700: '#104492',
    800: '#0C3573',
    900: '#092959',
  },
  secondary: {
    50: '#FAFBFD',
    100: '#F8F9FB',
    200: '#EEF1F5',
    300: '#D3D8E2',
    400: '#A5ACB8',
    500: '#7E8899',
    600: '#5A6473',
    700: '#3C4451',
    800: '#1F2837',
    900: '#0F1929',
  },
  green: {
    600: '#C3E5C6',
  },
  yellow: {
    200: '#F8E494',
  },
  red: {
    900: '#FF0000',
  },
  pink: {
    500: '#E8308A',
  },
  gray: {
    ...theme.colors.gray,
    50: '#F7F9F9',
    100: '#F1F3F8',
    200: '#E3E6E8',
    300: '#a0abb2',
    400: '#a1abb2',
    600: '#809999',
    500: '#7F9999',
    700: '#123233',
    900: '#003333',
  },
  error: {
    primary: '#E53737',
    background: '#E5373708',
    overlay: rgba('#ff0000', 0.15),
  },
  other: {
    CTA,
    accent,
    sliderGray: '#7E8899',
    counterBg: '#1F2837',
    toastError: '#7F9999', // TEMPORARY
    toastSuccess: '#C3E5C6', // TEMPORARY
    settingsDivider: '#EEF1F5',
    tooltipBg: '#092959',
    orderCarbon: '#C5DEFB',
    orderFlight: '#3781F2',
    orderEvent: '#B9E6C2',
    orderOther: accent,
    orderLifestyle: '#7F9999',
    orderCar: '#104492',
    positiveGreen: '#09BE67',
    primaryBgText: white,
    secondaryBgText: white,
    successGreen: '#1ACB7F',
    statusPendingConfirmation: '#7E8899',
    statusPendingRetirement: '#94A4F8',
    statusReserved: '#56B7E9',
    statusPartiallyRetired: '#F1A12B',
    statusTotalOrders: CTA,
    orderClaim: '#BFC6D8',
  },
  charts: {
    flightsBar: '#3781F2',
    employeesBar: '#FDB96A',
    eventsBar: '#B9E6C2',
    carsBar: '#104492',
    carbonBar: '#C5DEFB',
    shippingBar: '#7F9999',
    otherBar: '#003333',
    emptyBar1: '#335C5C',
    emptyBar2: '#668585',
    emptyBar3: '#99ADAD',
    emptyBar4: '#CCD6D6',
    emissionsBar: '#2F5142',
    compensationsBar: '#A5B7AF',
    fullCompensationsBar: '#1ACB7F',
    matchedCompensationsBar: '#B49BD4',
    discountedCompensationsBar: '#F9D6A4',
  },
  impact: {
    impactSlide: '#C5DEFB',
  },
  buttons: {
    primary: CTA,
    primaryText: '#FFFFFF',
    primaryButtonHover: darken(0.2, CTA),
    secondary: '#EFF5FE',
    secondaryText: CTA,
  },
  backgrounds: {
    primary: '#EFF5FE',
    lightGreen: '#E8FAF2',
    darkGreen: '#95E7BB',
  },
  text: {
    light: '#4E5867',
    dark: '#181F2C',
  },
  greyLinkActive: '#003333',
  blackLabel: '#1C1C1C',
  inputBorderColor: '#e2e8f0',
};

const targetColors = (customColors?: CorporateColors) => {
  return {
    ...theme.colors,
    ...baseColors,
    primary: {
      ...baseColors.primary,
      50: customColors?.background ? customColors?.background : baseColors.primary[50],
      100: customColors?.primary ? rgba(customColors?.primary, 0.2) : baseColors.primary[200],
      200: customColors?.primary ? rgba(customColors.primary, 0.3) : baseColors.primary[300],
      400: customColors?.secondary ?? baseColors.primary[500],
      500: customColors?.primary ?? baseColors.primary[600],
      700: customColors?.icons ?? baseColors.primary[800],
    },
    secondary: {
      ...baseColors.secondary,
      400: customColors?.lightText ?? baseColors.secondary[500],
      500: customColors?.lightText ?? baseColors.secondary[600],
      600: customColors?.bodyText ? rgba(customColors.bodyText, 0.7) : baseColors.secondary[700],
      700: customColors?.bodyText ?? baseColors.secondary[800],
      800: customColors?.darkColor ?? baseColors.secondary[800],
    },
    buttons: {
      ...baseColors.buttons,
      primary: customColors?.primaryButtonBackground ?? baseColors.buttons.primary,
      primaryText: customColors?.primaryButtonText ?? baseColors.buttons.primaryText,
      primaryButtonHover: customColors?.primary ? darken(0.2, customColors?.primary) : darken(0.2, CTA),
      secondary: customColors?.secondaryButtonBackground ?? baseColors.buttons.secondary,
      secondaryText: customColors?.secondaryButtonText ?? baseColors.buttons.secondaryText,
    },
    other: {
      ...baseColors.other,
      CTA: customColors?.primary ?? CTA,
      accent: customColors?.secondary ?? accent,
      sliderGray: customColors?.lightText ?? baseColors.secondary[500],
      counterBg: customColors?.darkColor ?? baseColors.secondary[800],
      tooltipBg: customColors?.darkColor ?? baseColors.primary[900],
      orderCarbon: customColors?.background ?? baseColors.primary[100],
      orderFlight: customColors?.secondary ?? accent,
      orderOther: accent,
      orderCar: customColors?.icons ?? baseColors.primary[700],
      primaryBgText: customColors?.primaryColorMode === 'Light' ? white : customColors?.bodyText ?? baseColors.secondary[800],
      secondaryBgText: customColors?.secondaryColorMode === 'Light' ? white : customColors?.bodyText ?? baseColors.secondary[800],
    },
    impact: {
      impactSlide: customColors?.secondary ?? baseColors.primary[400],
    },
  };
};

export default targetColors;
