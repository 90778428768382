import { FC } from 'react';
import { usePartnerTerms } from '@api/sanity/terms.queries';
import { Loader } from '@components/Loader';
import { LoaderWrapper } from '@components/LoaderWrapper';
import { SanityBlockContent } from '@components/SanityBlockContent';
import { TermsPage } from '@components/TermsPage';

const PartnerTerms: FC<React.PropsWithChildren> = () => {
  const { data, isLoading } = usePartnerTerms();

  if (isLoading) {
    return (
      <LoaderWrapper data-testid='loader'>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (!data || !data.partnerTerms) {
    return null;
  }

  return (
    <>
      <TermsPage lastUpdated={data?.partnerTermsUpdateTime}>
        <SanityBlockContent blocks={data.partnerTerms} />
      </TermsPage>
    </>
  );
};

export default PartnerTerms;
