import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HTTP from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Locize from 'i18next-locize-backend';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: [
      'en',
      'ar',
      'at',
      'ca',
      'cs',
      'da',
      'de',
      'el',
      'en-US',
      'es',
      'es-MX',
      'fi',
      'fr',
      'hu',
      'id',
      'is',
      'it',
      'ja',
      'ko',
      'ms',
      'nb-NO',
      'nl',
      'pl',
      'pt',
      'pt-BR',
      'ro',
      'ru',
      'sv',
      'th',
      'tl',
      'tr',
      'uk',
      'vi',
      'zh-Hans',
      'zh-Hant',
    ],
    defaultNS: 'common',
    ns: ['common', 'graphs', 'projects', 'history', 'settings', 'offset', 'transactions', 'paymentMethod', 'signup', 'footprints'],
    load: 'languageOnly',
    returnNull: false,
    returnEmptyString: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [
        LocalStorageBackend, // primary
        Locize, // primary
        HTTP, // fallback
      ],
      backendOptions: [
        {
          prefix: 'i18_',
          expirationTime: parseInt(import.meta.env.REACT_APP_LOCIZE_EXPIRATION ?? '3600000'), // cache
        },
        {
          projectId: import.meta.env.REACT_APP_LOCIZE_PROJECT_ID,
          apiKey: import.meta.env.REACT_APP_LOCIZE_API_KEY,
          version: 'latest',
          failLoadingOnEmptyJSON: true,
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json', // xhr load path for my own fallback
        },
      ],
    },
  });

export default i18n;
