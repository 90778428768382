import { Account } from '@api/user/types';

import { ContextElement } from '.';

export const groupByKey = <T, K extends keyof T>(array: T[], key: K): Record<string, T[]> => {
  return array.reduce(
    (map, item) => {
      const itemKey = item[key] as unknown as string;
      map[itemKey] = map[itemKey] || [];
      map[itemKey].push(item);

      return map;
    },
    {} as Record<string, T[]>,
  );
};

export const sortByKey = <T>(object: { [x: string]: T[] }): { [x: string]: T[] } => {
  const sortedKeys = Object.keys(object).sort();

  return sortedKeys.reduce(
    (obj, key) => {
      obj[key] = object[key];

      return obj;
    },
    {} as { [x: string]: T[] },
  );
};

const throwErrorForUnknownAccountType = (accountType: string): never => {
  throw new Error(`Unknown account type: ${accountType}`);
};

const getAccountTypeName = (accountType: string): string => {
  const typeTranslations: Record<string, string> = {
    WeChoooseCommmunity: 'partner',
    WeChooose: 'customer',
    Supplier: 'supplier',
    Transact: 'transact',
  };

  if (!(accountType in typeTranslations)) {
    throwErrorForUnknownAccountType(accountType);
  }

  return typeTranslations[accountType];
};

const getMenuItemTestId = (accountType: string): string => {
  const testIds: Record<string, string> = {
    WeChoooseCommmunity: 'chooose-partner-text',
    MyChooose: 'guest-individual-text',
    WeChooose: 'customer-text',
    Supplier: 'supplier-text',
    Transact: 'transact-text',
  };

  if (!(accountType in testIds)) {
    throwErrorForUnknownAccountType(accountType);
  }

  return testIds[accountType];
};

export const transformAccount = (account: Account): ContextElement => {
  const redirectToMyChoooseUrl = import.meta.env.REACT_APP_MYCHOOOSE_REDIRECT_URL;
  const { context, profile, type, id } = account;
  const prefix = getAccountTypeName(type);
  const testId = getMenuItemTestId(type);

  let customerName, partnerPortalUrl, portalName, supplierName, url;

  if (type === 'Supplier') {
    const { eamAppUrl, supplierName: name } = context ?? {};
    supplierName = name;
    url = eamAppUrl;
    portalName = supplierName ?? 'EAM';
  } else {
    const { partnerPortalName: name, customerName: profileName, partnerPortalUrl: portalUrl } = context ?? {};
    customerName = profileName;
    portalName = name;
    partnerPortalUrl = portalUrl;
    url = partnerPortalUrl
      ? `${partnerPortalUrl}?account=${type === 'WeChoooseCommmunity' ? 'connect' : 'wechooose'}&accountId=${id}`
      : redirectToMyChoooseUrl ?? '';
  }

  const suffix = customerName ?? profile?.name ?? '';

  return { id, portalName, prefix, suffix, testId, type, url };
};
