import styled from 'styled-components';

export const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const StyledLi = styled.li`
  display: grid;
  grid-template-columns: 424px 48px auto;
  gap: ${({ theme }) => theme.spacing.sm};

  @media ${props => props.theme.breakpoints.laptopL} {
    grid-template-columns: 48px 1fr;

    & > *:first-child {
      grid-column: 1/3;
    }
  }
`;

export const FeeDetails = styled.div`
  display: grid;
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const StyledUl = styled.ul`
  list-style: none;

  ${StyledLi} + ${StyledLi} {
    margin-top: 20px;
  }
`;
