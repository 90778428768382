import { useQueries, useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { usePartnerSettings } from '@api/partnership/queries';
import * as termsSanityApi from '@api/sanity/terms';

import { injectPartnerNameInString, preparePartnerText, replaceCustomContents } from './sanityContentUtils';
import { PartnerTermsItem, Terms } from './terms.types';

const initialData: Terms = {
  isLoading: true,
  data: {},
};

const SANITY_TERMS = {
  general: 'general-terms-and-conditions',
  weChooose: 'wechooose-terms-and-conditions',
  privacy: 'chooose-data-protection-policy',
  cookie: 'chooose-cookie-policy',
};

export const useGeneralTerms = () => {
  const { data: settings } = usePartnerSettings();
  const lang = i18next.language;

  return useQuery({
    queryKey: ['sanityTerms', SANITY_TERMS.general],
    queryFn: () => termsSanityApi.getTermsAndConditions(SANITY_TERMS.general),
    select: sanitySettings => {
      return {
        generalTerms: replaceCustomContents(
          sanitySettings.content[lang] ? sanitySettings.content[lang] : sanitySettings.content.en,
          sanitySettings,
          settings,
        ),
        generalTermsUpdateTime: sanitySettings._updatedAt,
        generalTermsTitle: injectPartnerNameInString(sanitySettings.title[lang] ? sanitySettings.title[lang] : sanitySettings.title.en),
      };
    },
  });
};

export const useWeChoooseTerms = () => {
  const { data: settings } = usePartnerSettings();

  const lang = i18next.language;

  return useQuery({
    queryKey: ['sanityTerms', SANITY_TERMS.weChooose],
    queryFn: () => termsSanityApi.getTermsAndConditions(SANITY_TERMS.weChooose),
    select: sanitySettings => {
      return {
        weChoooseTerms: replaceCustomContents(
          sanitySettings.content[lang] ? sanitySettings.content[lang] : sanitySettings.content.en,
          sanitySettings,
          settings,
        ),
        weChoooseTermsUpdateTime: sanitySettings._updatedAt,
        weChoooseTermsTitle: injectPartnerNameInString(sanitySettings.title[lang] ? sanitySettings.title[lang] : sanitySettings.title.en),
      };
    },
  });
};

export const usePrivacyTerms = () => {
  const { data: settings } = usePartnerSettings();

  const lang = i18next.language;

  return useQuery({
    queryKey: ['sanityTerms', SANITY_TERMS.privacy],
    queryFn: () => termsSanityApi.getTermsAndConditions(SANITY_TERMS.privacy),
    select: sanitySettings => {
      return {
        privacyTerms: replaceCustomContents(
          sanitySettings.content[lang] ? sanitySettings.content[lang] : sanitySettings.content.en,
          sanitySettings,
          settings,
        ),
        privacyTermsUpdateTime: sanitySettings._updatedAt,
        privacyTermsTitle: injectPartnerNameInString(sanitySettings.title[lang] ? sanitySettings.title[lang] : sanitySettings.title.en),
      };
    },
  });
};

export const useCookieTerms = () => {
  const { data: settings } = usePartnerSettings();

  const lang = i18next.language;

  return useQuery({
    queryKey: ['sanityTerms', SANITY_TERMS.cookie],
    queryFn: () => termsSanityApi.getTermsAndConditions(SANITY_TERMS.cookie),
    select: sanitySettings => {
      return {
        cookieTerms: replaceCustomContents(
          sanitySettings.content[lang] ? sanitySettings.content[lang] : sanitySettings.content.en,
          sanitySettings,
          settings,
        ),
        cookieTermsUpdateTime: sanitySettings._updatedAt,
        cookieTermsTitle: injectPartnerNameInString(sanitySettings.title[lang] ? sanitySettings.title[lang] : sanitySettings.title.en),
      };
    },
  });
};

export const useAllTerms = (partnershipId?: string, language?: string): Terms => {
  const { data: generalTermsData, isLoading: generalTermsIsLoading } = useGeneralTerms();
  const { data: privacyTermsData, isLoading: privacyTermsIsLoading } = usePrivacyTerms();
  const { data: weChoooseTermsData, isLoading: weChoooseTermsIsLoading } = useWeChoooseTerms();
  const { data: cookieTermsData, isLoading: cookieTermsIsLoading } = useCookieTerms();
  const { data: partnerTermsData, isLoading: partnerTermsIsLoading } = usePartnerTerms(partnershipId, language);

  const isLoading = [generalTermsIsLoading, privacyTermsIsLoading, weChoooseTermsIsLoading, partnerTermsIsLoading, cookieTermsIsLoading].some(
    e => e === true,
  );

  return {
    isLoading,
    data: {
      ...generalTermsData,
      ...weChoooseTermsData,
      ...privacyTermsData,
      ...cookieTermsData,
      // This will overwrite previous data
      ...partnerTermsData,
    },
  };
};

export const usePartnerTerms = (partnershipId?: string, language?: string): Terms => {
  const { data: settings } = usePartnerSettings();
  const lang = i18next.language;
  const pId: string | undefined = partnershipId ?? settings.partnershipId ?? '';

  return useQueries({
    queries: [
      {
        queryKey: ['sanityTerms', pId, language],
        queryFn: () => termsSanityApi.getTermsAndConditions(pId),
      },
      {
        queryKey: ['sanityTerms', 'partnershipTerms', pId, language],
        queryFn: () => termsSanityApi.getPartnershipTerms(pId),
      },
    ],
    combine: results => {
      const termsData = results[0].data;
      const additionalData = results[1].data;
      const isLoading = results.some(result => result.isLoading);
      const payload = {
        ...initialData,
        isLoading,
      };
      if (additionalData?.length) {
        const additionalTerms = additionalData.map(
          (item): PartnerTermsItem => ({
            title: injectPartnerNameInString(item.title[lang] ? item.title[lang] : item.title.en),
            content: preparePartnerText(item.content[lang] ? item.content[lang] : item.content.en, settings),
            updateTime: item._updatedAt,
            override: item.override ?? '',
            slug: item.slug?.current ?? '',
          }),
        );

        const newPartnerTerms = additionalTerms.find(set => set.override === 'partner');
        const newGeneralTerms = additionalTerms.find(set => set.override === 'general');
        const newPortalTerms = additionalTerms.find(set => set.override === 'portal');
        const newPrivacyTerms = additionalTerms.find(set => set.override === 'privacy');
        const newCookieTerms = additionalTerms.find(set => set.override === 'cookie');

        payload.data = {
          ...payload.data,
          additionalPartnerTerms: additionalTerms,
        };

        if (newPartnerTerms?.content) {
          payload.data = {
            ...payload.data,
            partnerTerms: newPartnerTerms.content,
            partnerTermsUpdateTime: newPartnerTerms.updateTime,
            partnerTermsTitle: newPartnerTerms.title,
          };
        } else if (termsData?.title) {
          payload.data = {
            ...payload.data,
            partnerTerms: preparePartnerText(termsData?.content[lang] ? termsData?.content[lang] : termsData?.content.en, settings),
            partnerTermsUpdateTime: termsData?._updatedAt,
            partnerTermsTitle: injectPartnerNameInString(termsData?.title[lang] ? termsData?.title[lang] : termsData?.title.en),
          };
        }

        if (newGeneralTerms?.content) {
          payload.data = {
            ...payload.data,
            generalTerms: newGeneralTerms.content,
            generalTermsUpdateTime: newGeneralTerms.updateTime,
            generalTermsTitle: newGeneralTerms.title,
          };
        }

        if (newPortalTerms?.content) {
          payload.data = {
            ...payload.data,
            weChoooseTerms: newPortalTerms.content,
            weChoooseTermsUpdateTime: newPortalTerms.updateTime,
            weChoooseTermsTitle: newPortalTerms.title,
          };
        }

        if (newPrivacyTerms?.content) {
          payload.data = {
            ...payload.data,
            privacyTerms: newPrivacyTerms.content,
            privacyTermsUpdateTime: newPrivacyTerms.updateTime,
            privacyTermsTitle: newPrivacyTerms.title,
          };
        }

        if (newCookieTerms?.content) {
          payload.data = {
            ...payload.data,
            cookieTerms: newCookieTerms.content,
            cookieTermsUpdateTime: newCookieTerms.updateTime,
            cookieTermsTitle: newCookieTerms.title,
          };
        }
      } else if (termsData?.title) {
        payload.data = {
          ...payload.data,
          partnerTerms: preparePartnerText(termsData?.content[lang] ? termsData?.content[lang] : termsData?.content.en, settings),
          partnerTermsUpdateTime: termsData?._updatedAt,
          partnerTermsTitle: injectPartnerNameInString(termsData?.title[lang] ? termsData?.title[lang] : termsData?.title.en),
        };
      }

      return payload;
    },
  });
};

export const useOverriddenTerms = () => {
  const { data: terms } = usePartnerTerms();
  const overrideSet = terms.additionalPartnerTerms?.map(set => set.override);

  return {
    general: overrideSet?.includes('general'),
    portal: overrideSet?.includes('portal'),
    partner: overrideSet?.includes('partner'),
    privacy: overrideSet?.includes('privacy'),
    cookie: overrideSet?.includes('cookie'),
  };
};
