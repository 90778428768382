import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createEventApi from '@api/eventOffset';
import createFlightApi from '@api/flightOffset';
import createOrderApi, { OrderItem, OrderParams } from '@api/order';
import { invalidateLegacyEmissionsFilters } from '@api/order/queries';
import { getOrderStatus, validateItemPortfolio } from '@pages/shared/CorporateOffset/utils';
import { AppThunk, RootState } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, metadataSelector, userCurrencySelector } from '@store/user/userSlice';
import { getDisplayName } from '@utils/metadata';
import { fixFloat } from '@utils/numberFormatter';
import { getPlan, plans } from '@utils/planMapper';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent } from '@utils/tags';

import { singlePortfolioSanityIdSelector, virtualPortfoliosSelector } from './portfolioSelectSlice';
// region types

export interface FullOffsetPeopleRow {
  category?: string;
  countryCode?: string;
  countryName?: string;
  plan: string;
  duration: number;
  participants: number;
  multiplier: number;
  kilosCo2: number;
  price?: number;
  customerPrice?: number;
  footprintId: string;
}

interface EditProps {
  index: number;
  value: number;
  price?: number;
  footprintId?: string;
}

interface EditCategoryProps {
  index: number;
  value: string;
}

interface EditTravelClassProps {
  index: number;
  value: string;
}

interface EditRoundTripProps {
  index: number;
  value: boolean;
}

export interface FlightLinkedToFullOffset {
  flightLength: string;
  flightDistance: number;
  travelClass: TravelClass;
  roundTrip: boolean;
  travellers: number;
  kilosCo2: number;
  id?: string;
  price?: number;
  customerPrice?: number;
  footprintId: string;
}

interface OrderPayload {
  number: string;
  date: string;
  name?: string;
  id: string;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  orderStatus?: string;
}

interface CountryProps {
  index: number;
  countryName: string;
  countryCode: string;
}

interface OffsetInfo {
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  poNumber?: string;
  costCenter?: string;
  isPaidByPartner?: boolean;
  [key: string]: string | Option | Date | undefined | boolean | number;
}

export interface EventsOffsetState {
  amount?: number;
  totalKilosCo2?: number;
  totalKilosCo2Avoided?: number;
  totalKilosCo2Offset?: number;
  vat?: number;
  customerVat?: number;
  excise?: number;
  customerExcise?: number;
  fullOffsetRows: FullOffsetPeopleRow[];
  flightsLinkedToFullOffset: FlightLinkedToFullOffset[];
  participants?: number;
  days?: number;
  flightLength?: FlightLengthOption;
  travellers?: number;
  roundTrip?: boolean;
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  isLoading: string[];
  currency?: string;
  orderNumber?: string;
  orderDate?: string;
  flightKilosCo2?: number;
  eventKilosCo2?: number;
  flightPrice?: number;
  eventPrice?: number;
  selectedPortfolioId?: string;
  selectedCustomMetadata?: string[];
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  isPaidByPartner?: boolean;
  customer?: PartnersCustomerOption;
  isOrderInfoInvalid?: boolean;
  isMultiplierActive?: boolean;
  orderId?: string;
  inviteId?: string;
  claimCode?: string;
  billId?: string;
  orderStatus?: string;
  attachmentTempUrl?: undefined;
  eventCountry?: Option;
  customerPrice?: number;
  price?: number;
  costCenter?: string;
  poNumber?: string;
  [key: string]:
    | string
    | Option
    | Date
    | number
    | undefined
    | boolean
    | string[]
    | PartnersCustomerOption
    | FlightLinkedToFullOffset[]
    | FullOffsetPeopleRow[];
}

const initialState: EventsOffsetState = {
  fullOffsetRows: [],
  flightsLinkedToFullOffset: [],
  isLoading: [],
  isOrderInfoInvalid: true,
  eventCountry: {
    label: 'Norway',
    value: 'NO',
  },
};

const slice = createSlice({
  name: 'offsetEvent',
  initialState,
  reducers: {
    resetWithoutPortfolioId: (state: EventsOffsetState): EventsOffsetState => {
      return { ...initialState, selectedPortfolioId: state.selectedPortfolioId, isMultiplierActive: state.isMultiplierActive };
    },
    setLoading: (state: EventsOffsetState, action: PayloadAction<{ value: string; state: boolean }>): EventsOffsetState => {
      if (action.payload.state) {
        state.isLoading = [...state.isLoading, action.payload.value];
      } else {
        state.isLoading = state.isLoading.filter(item => item !== action.payload.value);
      }

      return state;
    },
    setOrder: (state: EventsOffsetState, action: PayloadAction<OrderPayload>): EventsOffsetState => {
      state.orderNumber = action.payload.number;
      state.orderDate = action.payload.date;
      state.orderName = action.payload.name ?? '';
      state.orderId = action.payload.id;
      state.inviteId = action.payload.inviteId;
      state.claimCode = action.payload.claimCode;
      state.billId = action.payload.billId;
      state.orderStatus = action.payload.orderStatus;

      return state;
    },
    setIsMultiplierActive: (state: EventsOffsetState, action: PayloadAction<boolean>): EventsOffsetState => {
      state.isMultiplierActive = action.payload;

      return state;
    },
    setOrderInfo: (state: EventsOffsetState, action: PayloadAction<OffsetInfo>): EventsOffsetState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setOrderInfoInvalid: (state: EventsOffsetState, action: PayloadAction<boolean>): EventsOffsetState => {
      state.isOrderInfoInvalid = action.payload;

      return state;
    },
    setSelectedCustomMetadata: (state: EventsOffsetState, action: PayloadAction<string[]>): EventsOffsetState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    updateItemParticipantsState: (state: EventsOffsetState, action: PayloadAction<EditProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.fullOffsetRows[index];
      item.participants = value;

      return state;
    },
    updateItemCategory: (state: EventsOffsetState, action: PayloadAction<EditCategoryProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.fullOffsetRows[index];

      if (value !== 'DigitalParticipant') {
        item.countryCode = undefined;
        item.countryName = undefined;
      } else {
        if (!item.countryCode) {
          item.countryCode = state.eventCountry?.value ?? 'earth';
        }
        if (!item.countryName) {
          item.countryName = state.eventCountry?.label ?? 'Average';
        }
      }

      item.category = value;

      return state;
    },
    updateItemDurationState: (state: EventsOffsetState, action: PayloadAction<EditProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.fullOffsetRows[index];
      item.duration = value;

      return state;
    },

    updateItemCO2: (state: EventsOffsetState, action: PayloadAction<EditProps>): EventsOffsetState => {
      const { index, value, footprintId } = action.payload;
      const item = state.fullOffsetRows[index];
      item.kilosCo2 = value;
      if (footprintId) {
        item.footprintId = footprintId;
      }

      return state;
    },

    addRow: (state: EventsOffsetState, action: PayloadAction<FullOffsetPeopleRow>): EventsOffsetState => {
      const item = {
        category: action.payload.category || 'Participant',
        plan: action.payload.plan || plans[0].id,
        duration: action.payload.duration || 1,
        participants: action.payload.participants || 1,
        kilosCo2: action.payload.kilosCo2 || 1,
        multiplier: getPlan(action.payload.plan)?.multiplier,
        footprintId: action.payload.footprintId || '',
      };
      state.fullOffsetRows.push(item);

      return state;
    },
    addLinkedFlightRow: (state: EventsOffsetState, action: PayloadAction<FlightLinkedToFullOffset>): EventsOffsetState => {
      state.flightsLinkedToFullOffset.push(action.payload);

      return state;
    },

    updateItemCountryState: (state: EventsOffsetState, action: PayloadAction<CountryProps>): EventsOffsetState => {
      const { index, countryCode, countryName } = action.payload;
      const item = state.fullOffsetRows[index];
      item.countryCode = countryCode;
      item.countryName = countryName;

      return state;
    },
    updatePeopleItemPrice: (
      state: EventsOffsetState,
      action: PayloadAction<{ price: number; customerPrice?: number; index: number }>,
    ): EventsOffsetState => {
      const item = state.fullOffsetRows[action.payload.index];
      item.price = action.payload.price;
      if (action.payload.customerPrice) {
        item.customerPrice = action.payload.customerPrice;
      }

      return state;
    },
    updateFlightItemPrice: (
      state: EventsOffsetState,
      action: PayloadAction<{ price: number; customerPrice?: number; index: number }>,
    ): EventsOffsetState => {
      const item = state.flightsLinkedToFullOffset[action.payload.index];
      item.price = action.payload.price;
      if (action.payload.customerPrice) {
        item.customerPrice = action.payload.customerPrice;
      }

      return state;
    },
    updateFlightDistanceState: (state: EventsOffsetState, action: PayloadAction<EditProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.flightsLinkedToFullOffset[index];
      item.flightDistance = value;

      let flightLength = 'short';

      if (value > 1500 && value < 8000) {
        flightLength = 'medium';
      }

      if (value >= 8000 && value < 14000) {
        flightLength = 'long';
      }

      if (value >= 14000) {
        flightLength = 'ultra';
      }

      item.flightLength = flightLength;

      return state;
    },

    updateTravelClassState: (state: EventsOffsetState, action: PayloadAction<EditTravelClassProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.flightsLinkedToFullOffset[index];
      item.travelClass = value as TravelClass;

      return state;
    },

    updateRoundTripState: (state: EventsOffsetState, action: PayloadAction<EditRoundTripProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.flightsLinkedToFullOffset[index];
      item.roundTrip = value;

      return state;
    },

    updateTravellersState: (state: EventsOffsetState, action: PayloadAction<EditProps>): EventsOffsetState => {
      const { index, value } = action.payload;
      const item = state.flightsLinkedToFullOffset[index];
      item.travellers = value;

      return state;
    },

    updateFlightOffsetState: (state: EventsOffsetState, action: PayloadAction<EditProps>): EventsOffsetState => {
      const { index, value, footprintId } = action.payload;
      const item = state.flightsLinkedToFullOffset[index];
      item.kilosCo2 = value;
      if (footprintId) {
        item.footprintId = footprintId;
      }

      return state;
    },

    removeRow: (state: EventsOffsetState, action: PayloadAction<number>): EventsOffsetState => {
      state.fullOffsetRows.splice(action.payload, 1);

      return state;
    },

    removeLinkedFlightRow: (state: EventsOffsetState, action: PayloadAction<number>): EventsOffsetState => {
      state.flightsLinkedToFullOffset.splice(action.payload, 1);

      return state;
    },
    setOffsetPrice: (
      state: EventsOffsetState,
      action: PayloadAction<{ price: number; vat: number; customerPrice: number; customerVat: number; excise: number; customerExcise: number }>,
    ): EventsOffsetState => {
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.excise = action.payload.excise;
      state.customerPrice = action.payload.customerPrice;
      state.customerVat = action.payload.customerVat;
      state.customerExcise = action.payload.customerExcise;

      return state;
    },
    setEmissionsAvoided: (state: EventsOffsetState, action: PayloadAction<number>): EventsOffsetState => {
      state.totalKilosCo2Avoided = action.payload;

      return state;
    },
    setEmissionsOffset: (state: EventsOffsetState, action: PayloadAction<number>): EventsOffsetState => {
      state.totalKilosCo2Offset = action.payload;

      return state;
    },
    updateRecalculatedFlightItems: (state: EventsOffsetState, action: PayloadAction<FlightLinkedToFullOffset[]>): EventsOffsetState => {
      state.flightsLinkedToFullOffset = action.payload;

      return state;
    },
  },
});

const {
  setLoading,
  setOrder,
  addRow,
  removeRow,
  updateItemCountryState,
  updateItemCO2,
  updateItemParticipantsState,
  updateItemDurationState,
  updateItemCategory,
  addLinkedFlightRow,
  removeLinkedFlightRow,
  updateFlightDistanceState,
  updateTravelClassState,
  updateRoundTripState,
  updateTravellersState,
  updateFlightOffsetState,
  resetWithoutPortfolioId,
  setOrderInfo,
  setSelectedCustomMetadata,
  setOrderInfoInvalid,
  setIsMultiplierActive,
  setOffsetPrice,
  updatePeopleItemPrice,
  updateFlightItemPrice,
  setEmissionsAvoided,
  setEmissionsOffset,
  updateRecalculatedFlightItems,
} = slice.actions;

export { removeLinkedFlightRow, removeRow, setIsMultiplierActive, setOrderInfo, setOrderInfoInvalid, setSelectedCustomMetadata };

export default slice.reducer;

export const invalidateQueryAndResetWithoutPortfolioId =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    // We need to invalidate Filter queries to make sure we won't fetch old data, e.g.:
    // a flight number in the `flightNumbers` select input that have already been addressed
    // and should not be selectable.
    invalidateLegacyEmissionsFilters();
    dispatch(resetWithoutPortfolioId());
  };

export const addNewLinkedFlightItem =
  (props?: FlightLinkedToFullOffset): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'addNewLinkedFlightItem', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const { customer } = getState().eventOffset;

    const item: FlightLinkedToFullOffset = {
      flightLength: 'short',
      flightDistance: 1500,
      travelClass: 'economy' as TravelClass,
      roundTrip: false,
      travellers: 1,
      kilosCo2: 0,
      footprintId: '',
    };

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: item.travellers,
        distance: item.flightDistance,
        travelClass: item.travelClass,
        roundTrip: item.roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });
      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

      item.kilosCo2 = kilosCo2;
      item.footprintId = result.id;

      dispatch(addLinkedFlightRow({ ...props, ...item }));
      dispatch(setLoading({ value: 'addNewLinkedFlightItem', state: false }));
    } catch {
      dispatch(setLoading({ value: 'addNewLinkedFlightItem', state: false }));
    }
  };

export const addNewFullOffsetItem =
  (props?: FullOffsetPeopleRow): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'addNewFullOffsetItem', state: true }));
    const context = appContextSelector(getState());
    const {
      eventOffset: { eventCountry },
    } = getState();
    const eventOffsetApi = createEventApi(context);
    const countryCode = eventCountry?.value;
    const participants = props?.participants ?? 1;
    const plan = getPlan();
    const days = props?.duration ?? 1;

    try {
      const footprint = await eventOffsetApi.getPerCapitaFootprint(days, participants, countryCode);

      const kilosCo2 = footprint.kilosCo2e ?? footprint.kilosCo2;
      const multiplier = plan.multiplier;

      dispatch(
        addRow({
          ...props,
          multiplier,
          kilosCo2: kilosCo2 * multiplier,
          plan: plan.id,
          participants,
          duration: days,
          footprintId: footprint.id,
        }),
      );
      dispatch(setLoading({ value: 'addNewFullOffsetItem', state: false }));
    } catch (err) {
      dispatch(setLoading({ value: 'addNewFullOffsetItem', state: false }));
    }
  };

export const updateItemCountry =
  (props: CountryProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'updateItemCountry', state: true }));
    const context = appContextSelector(getState());
    const eventOffsetApi = createEventApi(context);
    const { eventCountry } = getState().eventOffset;
    const { index, countryCode } = props;

    const { fullOffsetRows } = getState().eventOffset;
    const item = fullOffsetRows[index];
    const multiplier = getPlan(item.plan).multiplier;

    try {
      const footprint = await eventOffsetApi.getPerCapitaFootprint(item.duration, item.participants, countryCode ?? eventCountry?.value);

      const kilosCo2 = footprint.kilosCo2e ?? footprint.kilosCo2;

      dispatch(updateItemCO2({ index: props.index, value: kilosCo2 * multiplier, footprintId: footprint.id }));
      dispatch(updateItemCountryState({ ...props }));
      dispatch(setLoading({ value: 'updateItemCountry', state: false }));
    } catch (err) {
      dispatch(setLoading({ value: 'updateItemCountry', state: false }));
    }
  };

export const updateItemParticipantType =
  (props: { index: number; value: string }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    const context = appContextSelector(getState());
    const eventOffsetApi = createEventApi(context);
    const { fullOffsetRows, eventCountry } = getState().eventOffset;
    const item = fullOffsetRows[index];
    const multiplier = getPlan(item.plan)?.multiplier;

    try {
      if (item.countryCode !== eventCountry?.value) {
        dispatch(setLoading({ value: 'updateItemParticipantType', state: true }));
        const footprint = await eventOffsetApi.getPerCapitaFootprint(item.duration, item.participants, eventCountry?.value);
        const kilosCo2 = footprint.kilosCo2e ?? footprint.kilosCo2;

        dispatch(updateItemCO2({ index, value: kilosCo2 * multiplier, footprintId: footprint.id }));
        dispatch(setLoading({ value: 'updateItemParticipantType', state: false }));
      }

      dispatch(updateItemCategory({ index, value: value }));
    } catch (err) {
      dispatch(setLoading({ value: 'updateItemParticipantType', state: false }));
    }
  };

export const updateItemParticipants =
  (props: EditProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    const context = appContextSelector(getState());
    const eventOffsetApi = createEventApi(context);
    dispatch(setLoading({ value: 'updateItemParticipants', state: true }));
    const { fullOffsetRows, eventCountry } = getState().eventOffset;
    const item = fullOffsetRows[index];
    const multiplier = getPlan(item.plan)?.multiplier;

    try {
      const footprint = await eventOffsetApi.getPerCapitaFootprint(item.duration, value, item.countryCode ?? eventCountry?.value);
      const kilosCo2 = footprint.kilosCo2e ?? footprint.kilosCo2;

      dispatch(updateItemCO2({ index, value: kilosCo2 * multiplier, footprintId: footprint.id }));
      dispatch(updateItemParticipantsState({ index, value: Number(value) }));
      dispatch(setLoading({ value: 'updateItemParticipants', state: false }));
    } catch (err) {
      dispatch(setLoading({ value: 'updateItemParticipants', state: false }));
    }
  };

export const updateItemDuration =
  (props: EditProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    const context = appContextSelector(getState());
    const eventOffsetApi = createEventApi(context);
    dispatch(setLoading({ value: 'updateItemDuration', state: true }));
    const { fullOffsetRows, eventCountry } = getState().eventOffset;
    const item = fullOffsetRows[index];
    const multiplier = getPlan(item.plan)?.multiplier;

    try {
      const footprint = await eventOffsetApi.getPerCapitaFootprint(value, item.participants, item.countryCode ?? eventCountry?.value);

      const kilosCo2 = footprint.kilosCo2e ?? footprint.kilosCo2;

      dispatch(updateItemCO2({ index, value: kilosCo2 * multiplier, footprintId: footprint.id }));
      dispatch(updateItemDurationState({ index, value: Number(value) }));
      dispatch(setLoading({ value: 'updateItemDuration', state: false }));
    } catch (err) {
      dispatch(setLoading({ value: 'updateItemDuration', state: false }));
    }
  };

export const updateItemPriceAndAmount =
  (props: { eventCountry: Option }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { value } = props.eventCountry;
    const context = appContextSelector(getState());
    const eventOffsetApi = createEventApi(context);
    dispatch(setLoading({ value: 'updateItemPriceAndAmount', state: true }));
    const { fullOffsetRows } = getState().eventOffset;

    try {
      fullOffsetRows.forEach(async (item, index) => {
        if (!item.countryCode) {
          const footprint = await eventOffsetApi.getPerCapitaFootprint(item.duration, item.participants, value);

          const multiplier = getPlan(item.plan)?.multiplier;
          const kilosCo2 = footprint.kilosCo2e ?? footprint.kilosCo2;

          dispatch(updateItemCO2({ index, value: kilosCo2 * multiplier, footprintId: footprint.id }));
        }
      });
      dispatch(setLoading({ value: 'updateItemPriceAndAmount', state: false }));
    } catch (err) {
      dispatch(setLoading({ value: 'updateItemPriceAndAmount', state: false }));
    }
  };

export const updateFlightDistance =
  (props: EditProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    dispatch(setLoading({ value: 'updateFlightDistance', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const { flightsLinkedToFullOffset, customer } = getState().eventOffset;
    const item = flightsLinkedToFullOffset[index];

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: item.travellers,
        distance: value,
        travelClass: item.travelClass,
        roundTrip: item.roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

      dispatch(updateFlightOffsetState({ index, value: kilosCo2, footprintId: result.id }));
      dispatch(updateFlightDistanceState(props));
      dispatch(setLoading({ value: 'updateFlightDistance', state: false }));
    } catch {
      dispatch(setLoading({ value: 'updateFlightDistance', state: false }));
    }
  };

export const updateTravelClass =
  (props: EditTravelClassProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    dispatch(setLoading({ value: 'updateTravelClass', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const { customer, flightsLinkedToFullOffset } = getState().eventOffset;
    const item = flightsLinkedToFullOffset[index];

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: item.travellers,
        distance: item.flightDistance,
        travelClass: value as TravelClass,
        roundTrip: item.roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

      dispatch(updateFlightOffsetState({ index, value: kilosCo2, footprintId: result.id }));
      dispatch(updateTravelClassState(props));
      dispatch(setLoading({ value: 'updateTravelClass', state: false }));
    } catch {
      dispatch(setLoading({ value: 'updateTravelClass', state: false }));
    }
  };

export const updateRoundTrip =
  (props: EditRoundTripProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    dispatch(setLoading({ value: 'updateRoundTrip', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const { customer, flightsLinkedToFullOffset } = getState().eventOffset;
    const item = flightsLinkedToFullOffset[index];

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: item.travellers,
        distance: item.flightDistance,
        travelClass: item.travelClass,
        roundTrip: value,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

      dispatch(updateFlightOffsetState({ index, value: kilosCo2, footprintId: result.id }));
      dispatch(updateRoundTripState(props));
      dispatch(setLoading({ value: 'updateRoundTrip', state: false }));
    } catch {
      dispatch(setLoading({ value: 'updateRoundTrip', state: false }));
    }
  };

export const updateTravellers =
  (props: EditProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value } = props;
    dispatch(setLoading({ value: 'updateTravellers', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const { customer, flightsLinkedToFullOffset } = getState().eventOffset;
    const item = flightsLinkedToFullOffset[index];

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: value,
        distance: item.flightDistance,
        travelClass: item.travelClass,
        roundTrip: item.roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

      dispatch(updateFlightOffsetState({ index, value: kilosCo2, footprintId: result.id }));
      dispatch(updateTravellersState(props));
      dispatch(setLoading({ value: 'updateTravellers', state: false }));
    } catch {
      dispatch(setLoading({ value: 'updateTravellers', state: false }));
    }
  };

export const recalculateFlightItems =
  (callbackFn: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { customer, flightsLinkedToFullOffset } = getState().eventOffset;
    if (flightsLinkedToFullOffset.length === 0) {
      callbackFn();

      return;
    }

    dispatch(setLoading({ value: 'recalculateFlightItems', state: true }));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const totalKilosCo2 = flightsLinkedToFullOffset.reduce((prev, curr) => prev + curr.kilosCo2, 0);

    try {
      const flights = await Promise.all(
        flightsLinkedToFullOffset.map(async item => {
          const result = await flightOffsetApi.getFlightFootprint({
            travellers: item.travellers,
            distance: item.flightDistance,
            travelClass: item.travelClass,
            roundTrip: item.roundTrip,
            customerId: currentUser?.customerId,
            calculateForCustomer: customer?.value,
          });
          const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;

          return {
            ...item,
            kilosCo2,
            footprintId: result.id,
          };
        }),
      );

      const totalCo2 = flights.reduce((prev, curr) => prev + curr.kilosCo2, 0);

      dispatch(updateRecalculatedFlightItems(flights));
      if (totalKilosCo2 === totalCo2) {
        callbackFn();
      }
      dispatch(setLoading({ value: 'recalculateFlightItems', state: false }));
    } catch (err) {
      dispatch(setLoading({ value: 'recalculateFlightItems', state: false }));
    }
  };

export const getEventOrderObject = ({
  offsetProps,
  currentUser,
  virtualPortfolios,
  mainPortfolioId,
  userCurrency,
  customer,
}: {
  offsetProps: EventsOffsetState;
  currentUser: AccountLegacy | undefined;
  virtualPortfolios: {
    portfolioId: string;
    weight: number;
  }[];
  mainPortfolioId: string | undefined;
  userCurrency: string;
  customer: PartnersCustomerOption | undefined;
}): OrderParams => {
  const { isMultiplierActive, fullOffsetRows, flightsLinkedToFullOffset, eventCountry } = offsetProps;

  const portfolioId = mainPortfolioId ?? null;

  const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
  const isDiscountMode = multiplierMode === 'Discount';
  const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
  const discountValue = multiplierValue / (1 + multiplierValue);
  const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, weight }) => ({
    id: portfolioId,
    weight: isMultiplierActive && isDiscountMode ? weight * discountValue : weight,
  }));

  const items: OrderItem[] = [];

  fullOffsetRows.map(async row => {
    const kilos = row.kilosCo2 ?? 0;
    const discountedOffset = isMultiplierActive && multiplierValue ? (kilos ?? 0) * discountValue : 0;
    items.push({
      type: 'impact',
      impact: 'Co2',
      kilosCo2: isDiscountMode ? kilos - discountedOffset : kilos,
      portfolio: mainPortfolioId
        ? undefined
        : {
            type: 'Virtual',
            impact: 'co2',
            currency: userCurrency,
            childPortfolios: mappedVirtualPortfolios,
          },
      portfolioId,
      details: {
        event: {
          days: row.duration,
          personCount: row.participants,
          countryCode: row.countryCode ?? eventCountry?.value,
          countryName: row.countryName ?? eventCountry?.label,
          participantType: row.category,
          plan: row.plan,
        },
      },
      feeFactors: {
        eventPersons: Number(row.participants ?? 0),
      },
      taxFactors: {
        customerCountry: customer?.country,
        customerCountrySubdivision: customer?.countrySubdivision,
      },
    });
  });

  flightsLinkedToFullOffset.map(row => {
    const kilos = row.kilosCo2 ?? 0;
    const discountedOffset = isMultiplierActive && multiplierValue ? (kilos ?? 0) * discountValue : 0;
    items.push({
      type: 'impact',
      impact: 'Co2',
      kilosCo2: isDiscountMode ? kilos - discountedOffset : kilos,
      portfolio: mainPortfolioId
        ? undefined
        : {
            type: 'Virtual',
            impact: 'co2',
            currency: userCurrency,
            childPortfolios: mappedVirtualPortfolios,
          },
      portfolioId,
      details: {
        flight: {
          roundTrip: row.roundTrip,
          flights: 1,
          passengers: row.travellers,
          travelClass: row.travelClass,
          routeLegs: [
            {
              distanceKm: row.flightDistance ?? 0,
            },
          ],
        },
        event: {
          countryCode: eventCountry?.value,
          countryName: eventCountry?.label,
        },
      },
      feeFactors: {
        eventFlightPax: row.roundTrip ? 2 * Number(row.travellers ?? 0) : Number(row.travellers ?? 0),
        flight: true,
      },
      taxFactors: {
        customerCountry: customer?.country,
        customerCountrySubdivision: customer?.countrySubdivision,
      },
    });
  });

  const order = {
    currency: userCurrency,
    category: 'Events',
    items: items,
  };

  return order;
};

export const getPriceForOrder =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const eventOffset = getState().eventOffset;
    const { customer, currency, fullOffsetRows, flightsLinkedToFullOffset } = eventOffset;
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const orderApi = createOrderApi(context);
    const currentUserCurrency = userCurrencySelector(getState());
    const userCurrency = currency ?? currentUserCurrency;
    const customerCurrency = customer?.currency ?? userCurrency;
    const customerId = currentUser?.customerId ?? '';
    const partnershipId = currentUser?.partnershipId ?? '';
    const isConnectContext = context === 'connect';

    dispatch(setLoading({ value: 'getPriceForOrder', state: true }));
    try {
      const order = getEventOrderObject({ offsetProps: eventOffset, currentUser, virtualPortfolios, mainPortfolioId, userCurrency, customer });

      validateItemPortfolio(order.items?.[0]);

      const response =
        context === 'connect' ? await orderApi.getQuoteForPartner(order, partnershipId) : await orderApi.getQuoteForCustomer(order, customerId);

      let customerPrice = response.totalPrice;
      let customerVat = response.totalVat;
      let customerExcise = response.totalExcise;
      let emissionsAvoided = response.totalKilosCo2Avoided;
      let emissionsOffset = response.totalKilosCo2Offset;

      const flightsItems = response.items.filter(({ feeFactors }) => feeFactors?.flight);
      const peopleItems = response.items.filter(({ feeFactors }) => feeFactors?.eventPersons);

      let customerFlightsItems = response.items.filter(({ feeFactors }) => feeFactors?.flight);
      let customerPeopleItems = response.items.filter(({ feeFactors }) => feeFactors?.eventPersons);

      if (isConnectContext && customer && customerCurrency !== userCurrency) {
        const customerOrder = getEventOrderObject({
          offsetProps: eventOffset,
          currentUser,
          virtualPortfolios,
          mainPortfolioId,
          userCurrency: customerCurrency,
          customer,
        });
        const customerResponse = await orderApi.getQuoteForPartner(customerOrder, partnershipId);
        customerPrice = customerResponse.totalPrice;
        customerVat = customerResponse.totalVat;
        customerExcise = customerResponse.totalExcise;
        customerFlightsItems = customerResponse.items.filter(({ feeFactors }) => feeFactors?.flight);
        customerPeopleItems = customerResponse.items.filter(({ feeFactors }) => feeFactors?.eventPersons);
        emissionsAvoided = customerResponse.totalKilosCo2Avoided;
        emissionsOffset = customerResponse.totalKilosCo2Offset;
      }

      flightsLinkedToFullOffset.forEach((_, index) =>
        dispatch(
          updateFlightItemPrice({
            index,
            price: flightsItems[index].price,
            customerPrice: customerFlightsItems[index].price,
          }),
        ),
      );

      fullOffsetRows.forEach((_, index) =>
        dispatch(
          updatePeopleItemPrice({
            index,
            price: peopleItems[index].price,
            customerPrice: customerPeopleItems[index].price,
          }),
        ),
      );

      dispatch(
        setOffsetPrice({
          price: response.totalPrice,
          vat: response.totalVat,
          excise: response.totalExcise,
          customerPrice,
          customerVat,
          customerExcise,
        }),
      );

      dispatch(setEmissionsAvoided(emissionsAvoided ?? 0));
      dispatch(setEmissionsOffset(emissionsOffset ?? 0));
    } catch (err) {
      // @ts-ignore
      if (err.data?.includes('Insufficient supply')) {
        dispatch(
          showToast({
            variant: 'error',
            titleI18nKey: 'offset:corporate.portfolios.errorTitle',
            descriptionI18nKey: 'offset:corporate.portfolios.insufficientSupplyErrorMessage',
          }),
        );
        // @ts-ignore
      } else if (err.data) {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchPriceError' }));
      }
    } finally {
      dispatch(setLoading({ value: 'getPriceForOrder', state: false }));
    }
  };

export const createFullOffsetOrder =
  (errorCb: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const {
      fullOffsetRows,
      flightsLinkedToFullOffset,
      currency,
      isPaidByPartner,
      orderName,
      customer,
      eventCountry,
      isMultiplierActive,
      ...restProps
    } = getState().eventOffset;
    const { costCenter, poNumber, description } = restProps;
    const { eventOffset } = getState();
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const paymentMethodType = context === 'connect' ? 'Invoice' : currentUser?.customer?.payment?.defaultPaymentMethod?.type;
    const orderApi = createOrderApi(context);
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const metadata = metadataSelector(getState()) ?? [];
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;

    const customSingleMetadata: {
      internalName: string;
      value: string | Date;
      displayName: string;
    }[] = [];

    const orderCurrency = !isPaidByPartner && customer?.currency ? customer.currency ?? '' : currency ?? '';
    const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
    const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
    const isDiscountMode = multiplierMode === 'Discount';
    const discountValue = multiplierValue / (1 + multiplierValue);

    Object.keys(restProps)
      .filter(key => !!metadata.find(({ internalName }) => internalName === key))
      .map(key => {
        let value: string | Date = '';

        if ((eventOffset[key] as Option)?.value) {
          value = (eventOffset[key] as Option)?.value;
        } else {
          value = eventOffset[key] as string | Date;
        }
        if (value) {
          customSingleMetadata.push({
            internalName: key,
            value: value,
            displayName: getDisplayName(metadata, key),
          });
        }
      });

    dispatch(setLoading({ value: 'createFullOffsetOrder', state: true }));
    try {
      const items: OrderItem[] = [];
      const footprints: { calculatedFootprintId: string }[] = [];

      fullOffsetRows.map(async row => {
        const kilos = row.kilosCo2 ?? 0;
        const discountedOffset = isMultiplierActive && multiplierValue ? (kilos ?? 0) * discountValue : 0;
        items.push({
          type: 'impact',
          impact: 'Co2',
          kilosCo2: isDiscountMode ? kilos - discountedOffset : kilos,
          price: row.customerPrice ?? row.price,
          ...(mainPortfolioId ? {} : { virtualPortfolios }),
          portfolioId: mainPortfolioId ?? null,
          details: {
            event: {
              days: row.duration,
              personCount: row.participants,
              countryCode: row.countryCode ?? eventCountry?.value,
              countryName: row.countryName ?? eventCountry?.label,
              participantType: row.category,
              plan: row.plan,
            },
          },
          feeFactors: {
            eventPersons: Number(row.participants ?? 0),
          },
          taxFactors: {
            customerCountry: customer?.country,
            customerCountrySubdivision: customer?.countrySubdivision,
          },
        });
        footprints.push({ calculatedFootprintId: row.footprintId });
      });

      flightsLinkedToFullOffset.map(async row => {
        const kilos = row.kilosCo2 ?? 0;
        const discountedOffset = isMultiplierActive && multiplierValue ? (kilos ?? 0) * discountValue : 0;
        items.push({
          type: 'impact',
          impact: 'Co2',
          kilosCo2: isDiscountMode ? kilos - discountedOffset : kilos,
          price: row.customerPrice ?? row.price,
          portfolioId: mainPortfolioId ?? null,
          ...(mainPortfolioId ? {} : { virtualPortfolios }),
          details: {
            flight: {
              roundTrip: row.roundTrip,
              flights: 1,
              passengers: row.travellers,
              travelClass: row.travelClass,
              routeLegs: [
                {
                  distanceKm: row.flightDistance ?? 0,
                },
              ],
            },
            event: {
              countryCode: eventCountry?.value,
              countryName: eventCountry?.label,
            },
          },
          feeFactors: {
            eventFlightPax: row.roundTrip ? 2 * Number(row.travellers ?? 0) : Number(row.travellers ?? 0),
            flight: true,
          },
          taxFactors: {
            customerCountry: customer?.country,
            customerCountrySubdivision: customer?.countrySubdivision,
          },
        });
        footprints.push({ calculatedFootprintId: row.footprintId });
      });

      const orderPayload = {
        currency: orderCurrency,
        comment: description ?? 'order',
        description: orderName ?? 'order',
        category: 'Events',
        items,
        metadata: {
          order: {
            channel: 'portal-fulloffset',
          },
          event: {
            days: fullOffsetRows.reduce((prev, curr) => prev + curr.duration, 0),
            personCount: fullOffsetRows.reduce((prev, curr) => prev + curr.participants, 0),
            flightTravelersCount: flightsLinkedToFullOffset.reduce((prev, curr) => prev + curr.travellers, 0),
            eventCountry: eventCountry?.value,
          },
          custom: customSingleMetadata,
        },
        costCenter: costCenter ?? '',
        poNumber: poNumber ?? '',
        footprints,
        applyDeductions: true,
        multiplier: isMultiplierActive && multiplierMode ? { value: multiplierValue, mode: multiplierMode } : undefined,
      };

      const order =
        context === 'connect'
          ? await orderApi.createForPartner(
              {
                ...orderPayload,
                facilitated: {
                  isFacilitated: true,
                  isPaidByPartner: isPaidByPartner ?? true,
                },
                customer: {
                  customerId: customer?.value ?? '',
                },
              },
              currentUser?.partnershipId ?? '',
            )
          : await orderApi.create(orderPayload, currentUser?.customerId ?? '');

      if (context !== 'connect' || isPaidByPartner) {
        trackPurchaseEvent(order.order, 'Portal offset', paymentMethodType, order.footprintIds);
      }
      dispatch(
        setOrder({
          number: order.order.choooseId,
          date: order.order.createdDate,
          name: orderName ?? '',
          id: order.orderId,
          claimCode: order.claimCode,
          inviteId: order.inviteId,
          billId: order.billId,
          orderStatus: getOrderStatus(order.order),
        }),
      );
      successCb && successCb();
      dispatch(setLoading({ value: 'createFullOffsetOrder', state: false }));
      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        await client.refetchQueries({ queryKey: ['impact', 'customer', currentUser?.customerId ?? '', { year: new Date().getFullYear() }] });
      }
      dispatch(syncAttribution());
      client.invalidateQueries({ queryKey: ['orders', 'history'] });
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:createOrderError' }));
      errorCb && errorCb();
      dispatch(setLoading({ value: 'createFullOffsetOrder', state: false }));
    }
  };

export const fullEventOffsetTotalImpact = createSelector(
  (state: RootState) => state.eventOffset.fullOffsetRows,
  items => items.reduce<number>((prev, next) => (prev += next.kilosCo2), 0),
);

export const linkedFlightsTotalImpact = createSelector(
  (state: RootState) => state.eventOffset.flightsLinkedToFullOffset,
  items => items.reduce<number>((prev, next) => (prev += next.kilosCo2), 0),
);

export const eventAmountSelector = createSelector(fullEventOffsetTotalImpact, linkedFlightsTotalImpact, (totalImpact, flightImpact) => {
  return fixFloat(totalImpact / 1000 + flightImpact / 1000);
});
