import { TFunction } from 'react-i18next';
import { AdvancedFiltersGroupProps, FilterOptions, OptionType } from '@chooose/ui';
import { CERTIFICATE_SCHEMES, PRODUCERS, VENDORS } from '@api/order/constants';

const STRING_FILTERS = (translate: TFunction<'translation', undefined>) => ({
  type: FilterOptions.STRING,
  options: [
    {
      label: translate('common:filter.equalTo'),
      value: '',
      isMulti: false,
    },
    {
      label: translate('common:filter.contains'),
      value: '_in',
      isMulti: true,
    },
    {
      label: translate('common:filter.doesNotContain'),
      value: '_not_in',
      isMulti: true,
    },
  ],
  additionalProps: {
    placeholder: translate('common:filter.selectProperty'),
  },
});

export const FILTER_PROPERTIES = ['producer', 'vendor', 'sustainabilityCertificationScheme'] as const;

export const toOptionType = (data: string[]): OptionType[] => data.map(value => ({ label: value, value: value }));

export const getSAFFilterOptions = (
  translate: TFunction<'translation', undefined>,
  producerOptions?: OptionType[],
  vendorOptions?: OptionType[],
  sustainabilityCertificationSchemeOptions?: OptionType[],
): AdvancedFiltersGroupProps['options'] => [
  {
    label: 'Producer',
    value: 'producer',
    filter: STRING_FILTERS(translate),
    options: producerOptions?.map(({ label, value }) => ({ value, label: PRODUCERS[label as keyof typeof PRODUCERS] })) ?? [],
  },
  {
    label: 'Vendor',
    value: 'vendor',
    filter: STRING_FILTERS(translate),
    options: vendorOptions?.map(({ label, value }) => ({ value, label: VENDORS[label as keyof typeof VENDORS] })) ?? [],
  },
  {
    label: 'Sustainability Certification Scheme',
    value: 'sustainabilityCertificationScheme',
    filter: STRING_FILTERS(translate),
    options:
      sustainabilityCertificationSchemeOptions?.map(({ label, value }) => ({
        value,
        label: CERTIFICATE_SCHEMES[label as keyof typeof CERTIFICATE_SCHEMES][0],
      })) ?? [],
  },
];
