import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { IMsalContext, MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { ErrorFallback } from '@components/ErrorFallback';
import { LoaderFullPage } from '@components/Loader';

import { defaultAuthError, handleAuthError, signInRequest } from '.';
import { useAuthHandlerStore } from './authHandlerStore';

const RequireAuth: FC<PropsWithChildren> = ({ children }) => {
  const request = useAuthHandlerStore(state => state.authHandler);

  if (request) {
    return <LoaderFullPage />;
  }

  return (
    <MsalAuthenticationTemplate
      loadingComponent={AuthLoading}
      errorComponent={AuthError}
      interactionType={InteractionType.Redirect}
      authenticationRequest={signInRequest}>
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default RequireAuth;

const AuthError: FC<MsalAuthenticationResult> = ({ error }) => {
  const setAuthHandler = useAuthHandlerStore(state => state.setAuthHandler);
  const [handled, setHandled] = useState(true);

  useEffect(() => {
    const isHandled = handleAuthError(error, { tags: { location: 'AuthError' } });
    setHandled(isHandled);
  }, [error]);

  if (handled) {
    return <LoaderFullPage />;
  }

  return (
    <ErrorFallback
      error={error ?? defaultAuthError}
      resetError={() => {
        setAuthHandler({ type: 'LogoutRedirect', request: { postLogoutRedirectUri: '/' } });
      }}
    />
  );
};

const AuthLoading: FC<IMsalContext> = () => {
  return <LoaderFullPage />;
};
