import { AxiosInstance } from 'axios';

import { createContextAwareHandlers } from './instance';

const getAllUsers =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<CustomerUser[]> => {
    const { data } = await instance.get<CustomerUser[]>(`/customers/${customerId}/users`);

    return data;
  };

const createUserInvite =
  (instance: AxiosInstance) =>
  async (customerId: string, payload: UserPayload): Promise<InvitationResponse> => {
    const { data } = await instance.post<InvitationResponse>(`/customers/${customerId}/users`, payload);

    return data;
  };

const updateUser =
  (instance: AxiosInstance) =>
  async (customerId: string, payload: UserPayload, userId: string): Promise<void> => {
    await instance.put<CustomerUser>(`/customers/${customerId}/users/${userId}`, payload);
  };

const deleteUser =
  (instance: AxiosInstance) =>
  async (customerId: string, userId: string): Promise<void> => {
    await instance.delete<CustomerUser>(`/customers/${customerId}/users/${userId}`);
  };

const getRoles =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<Roles> => {
    const { data } = await instance.get<Roles>(`/customers/${customerId}/authorization`);

    return data;
  };

const handlers = {
  getAllUsers,
  createUserInvite,
  updateUser,
  deleteUser,
  getRoles,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createAllUsersApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createAllUsersApi;
