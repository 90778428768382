// All error codes https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
const FORGOT_PASSWORD_ERROR_CODE = 'AADB2C90118';
const USER_ACTION_CANCEL_ERROR_CODE = 'AADB2C90091';
const USER_NOT_EXIST_ERROR_CODE = 'AADB2C99002';
const NOT_TOKEN_REQUEST_CACHE_CODE = 'no_token_request_cache_error';
const NO_CACHED_AUTHORITY_CODE = 'no_cached_authority_error';
const MONITOR_WINDOW_ERROR_CODE = 'monitor_window_timeout';

export {
  FORGOT_PASSWORD_ERROR_CODE,
  MONITOR_WINDOW_ERROR_CODE,
  NO_CACHED_AUTHORITY_CODE,
  NOT_TOKEN_REQUEST_CACHE_CODE,
  USER_ACTION_CANCEL_ERROR_CODE,
  USER_NOT_EXIST_ERROR_CODE,
};
