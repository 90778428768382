import { CustomerAccount, PartnerAccount } from '@api/user/types';
import { OnboardingTaskType } from '@components/types';

export const ONBOARDING_FLOW_LOCAL_STORAGE_KEY = 'onboardingFlow';

export const onboardingData: Record<CustomerAccount['type'] | PartnerAccount['type'], OnboardingTaskType[]> = {
  WeChooose: [
    {
      id: 'task-1',
      order: 1,
      title: 'WeChoooseOnboarding.task1.title',
      subtitle: 'WeChoooseOnboarding.task1.subtitle',
      text: 'WeChoooseOnboarding.task1.text',
      redirect: '/',
    },
    {
      id: 'task-2',
      order: 2,
      title: 'WeChoooseOnboarding.task2.title',
      subtitle: 'WeChoooseOnboarding.task2.subtitle',
      text: 'WeChoooseOnboarding.task2.text',
      redirect: '/emissions',
      permission: {
        action: 'view',
        subject: 'Orders',
      },
      featureFlag: {
        key: 'areFootprintsEnabledSelector',
        value: true,
      },
    },
    {
      id: 'task-3',
      order: 3,
      title: 'WeChoooseOnboarding.task3.title',
      subtitle: 'WeChoooseOnboarding.task3.subtitle',
      text: 'WeChoooseOnboarding.task3.text',
      redirect: '/address-emissions',
      featureFlag: {
        key: 'isOffsetEnabledSelector',
        value: true,
      },
      permission: {
        action: 'create',
        subject: 'Orders',
      },
    },
    {
      id: 'task-4',
      order: 4,
      title: 'WeChoooseOnboarding.task4.title',
      subtitle: 'WeChoooseOnboarding.task4.subtitle',
      text: 'WeChoooseOnboarding.task4.text',
      redirect: '/orders',
      permission: {
        action: 'view',
        subject: 'Orders',
      },
    },
    {
      id: 'task-5',
      order: 5,
      title: 'WeChoooseOnboarding.task5.title',
      subtitle: 'WeChoooseOnboarding.task5.subtitle',
      text: 'WeChoooseOnboarding.task5.text',
      redirect: '/settings/people',
      featureFlag: {
        key: 'isUserFederatedSelector',
        value: false,
      },
      permission: {
        action: 'edit',
        subject: 'CompanySettings',
      },
    },
  ],
  // NOTE(@pkgacek): `connect` does not have any permissions available
  WeChoooseCommmunity: [
    {
      id: 'task-1',
      order: 1,
      title: 'WeChoooseCommunityOnboarding.task1.title',
      subtitle: 'WeChoooseCommunityOnboarding.task1.subtitle',
      text: 'WeChoooseCommunityOnboarding.task1.text',
      redirect: '/connect',
    },
    {
      id: 'task-2',
      order: 2,
      title: 'WeChoooseCommunityOnboarding.task2.title',
      subtitle: 'WeChoooseCommunityOnboarding.task2.subtitle',
      text: 'WeChoooseCommunityOnboarding.task2.text',
      redirect: '/projects',
    },
    {
      id: 'task-3',
      order: 3,
      title: 'WeChoooseCommunityOnboarding.task3.title',
      subtitle: 'WeChoooseCommunityOnboarding.task3.subtitle',
      text: 'WeChoooseCommunityOnboarding.task3.text',
      redirect: '/emissions',
      featureFlag: {
        key: 'isFacilitatedOneTimeOffsetEnabledSelector',
        value: true,
      },
    },
    {
      id: 'task-4',
      order: 4,
      title: 'WeChoooseCommunityOnboarding.task4.title',
      subtitle: 'WeChoooseCommunityOnboarding.task4.subtitle',
      text: 'WeChoooseCommunityOnboarding.task4.text',
      redirect: '/analytics',
    },
    {
      id: 'task-5',
      order: 5,
      title: 'WeChoooseCommunityOnboarding.task5.title',
      subtitle: 'WeChoooseCommunityOnboarding.task5.subtitle',
      text: 'WeChoooseCommunityOnboarding.task5.text',
      redirect: '/settings/account',
    },
  ],
};
