import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'track', 'thumb']);
const Switch = helpers.defineMultiStyleConfig({
  baseStyle: {
    track: {
      bg: 'secondary.300',
      '&[data-checked]': {
        bg: 'primary.400',
        '&:hover': {
          bg: 'primary.400',
        },
      },
      '&[data-disabled][data-checked]': {
        bg: 'primary.400',
        opacity: 0.5,
      },
      '&[data-disabled]': {
        opacity: 0.5,
      },
    },
  },
  sizes: {
    lg: {
      track: {
        height: '20px',
        width: '42px',
      },
      thumb: {
        height: '20px',
        width: '20px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
});

export default Switch;
