import { getI18n } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ONE_DAY } from '@utils/constants';

import { getFaqsFor, getFaqsForPages } from './faq';

const toFaq = (sanityFaq: SanityFAQ): FAQ => {
  const lang = getI18n().language as Language;

  return {
    question: sanityFaq.faqQuestion.textContent[lang] ?? sanityFaq.faqQuestion.textContent['en'],
    answer: sanityFaq.faqAnswer.textContent[lang] ?? sanityFaq.faqAnswer.textContent['en'],
  };
};

const getFAQForPartnerPage = (collection: SanityFAQCollection[], partnerPage: string, page: string) => {
  let data: SanityFAQ[];
  const partnerIndex = collection.findIndex(item => partnerPage.includes(item.slug.current));
  const globalIndex = collection.findIndex(item => page.includes(item.slug.current));
  if (partnerIndex !== -1) {
    const partnerFAQs = collection[partnerIndex]?.faqs ?? [];
    const globalFAQs = globalIndex !== -1 ? collection[globalIndex]?.faqs : [];
    data = collection[partnerIndex]?.inheritEnabled ? [...globalFAQs, ...partnerFAQs] : [...partnerFAQs];
  } else {
    data = collection[0]?.faqs ?? [];
  }

  return data.map(toFaq);
};

export const useFAQ = (page: string, partnerPage?: string) => {
  return useQuery({
    queryKey: ['faq', page, partnerPage],
    queryFn: () => (partnerPage ? getFaqsForPages(page, partnerPage) : getFaqsFor(page)),
    staleTime: ONE_DAY,
    select: (data: SanityFAQCollection[]) => {
      if (partnerPage) {
        return getFAQForPartnerPage(data, partnerPage || '', page);
      } else {
        return data[0].faqs.map(toFaq);
      }
    },
  });
};
