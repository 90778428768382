import { useMsal } from '@azure/msal-react';
import { client } from '@lib/posthog';
import { useCurrentAccount } from '@api/user/queries';

interface LogoutProps {
  redirectAfterLogout?: string;
}
export const useLogout = (props?: LogoutProps) => {
  const { instance, inProgress } = useMsal();
  const { account: currentAccount } = useCurrentAccount();

  const logout = () => {
    if (inProgress === 'logout') {
      return;
    }

    const isFederated = currentAccount?.providerType === 'Federated';
    const defaultLogoutRedirect = isFederated ? '/logout' : '/';

    client.reset();
    instance.logoutRedirect({ postLogoutRedirectUri: props?.redirectAfterLogout || defaultLogoutRedirect });
  };

  return logout;
};
