/* eslint-disable no-undef */
import { AxiosInstance, CancelToken } from 'axios';
import { CustomMetadata } from '@store/footprints/flightSlice';

import { createContextAwareHandlers } from './instance';

interface BatchPayload extends CustomMetadata {
  emissionName: string;
  description: string;
  storageBlobName: string;
}

interface BatchResponseOk {
  jobId: string;
  partnershipId: string;
}

interface BatchResponseError {
  message: string;
  data: string[];
}

const createBatchOrderForPartner =
  (instance: AxiosInstance) =>
  async (payload: BatchPayload, sourceToken: CancelToken, partnershipId: string): Promise<BatchResponseOk & BatchResponseError> => {
    const { data } = await instance.post<BatchResponseOk & BatchResponseError>(
      `/partnerships/${partnershipId}/footprints/multiplecustomerflightbatch`,
      payload,
      {
        cancelToken: sourceToken,
      },
    );

    return data;
  };

const createBatchOrderForCustomer =
  (instance: AxiosInstance) =>
  async (payload: BatchPayload, sourceToken: CancelToken, customerId: string): Promise<BatchResponseOk & BatchResponseError> => {
    const { data } = await instance.post<BatchResponseOk & BatchResponseError>(`/customers/${customerId}/footprints/flightbatch`, payload, {
      cancelToken: sourceToken,
    });

    return data;
  };

const uploadFootprintBatchForCustomer =
  (instance: AxiosInstance) =>
  async (file: File, sourceToken: CancelToken, customerId: string): Promise<BatchResponseOk & BatchResponseError> => {
    const { data } = await instance.post<BatchResponseOk & BatchResponseError>(`/customers/${customerId}/attachments/footprintflightbatch`, file, {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      cancelToken: sourceToken,
    });

    return data;
  };

const uploadFootprintBatchForPartner =
  (instance: AxiosInstance) =>
  async (file: File, sourceToken: CancelToken, partnershipId: string): Promise<BatchResponseOk & BatchResponseError> => {
    const { data } = await instance.post<BatchResponseOk & BatchResponseError>(
      `/partnerships/${partnershipId}/attachments/footprintmultiplecustomerflightbatch`,
      file,
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        cancelToken: sourceToken,
      },
    );

    return data;
  };

const getJobStatus =
  (instance: AxiosInstance) =>
  async <T>(partnershipId: string, jobId: string, sourceToken: CancelToken): Promise<JobStatusResponse<T>> => {
    const { data } = await instance.get<JobStatusResponse<T>>(`/partnerships/${partnershipId}/jobs/${jobId}`, { cancelToken: sourceToken });

    return data;
  };

const handlers = {
  createBatchOrderForPartner,
  createBatchOrderForCustomer,
  getJobStatus,
  uploadFootprintBatchForCustomer,
  uploadFootprintBatchForPartner,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createFootprintFlightApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createFootprintFlightApi;
