import { setFontSize } from '@utils/fonts';

const cofo = '"cofo", sans-serif';
const airnz = '"airnz", sans-serif';

const fontSizes = (customFontSize?: number) => ({
  xxs: setFontSize(8, customFontSize),
  xsMin: setFontSize(10, customFontSize),
  xsMinor: setFontSize(11, customFontSize),
  xs: setFontSize(12, customFontSize),
  sm: setFontSize(13, customFontSize),
  md: setFontSize(14, customFontSize),
  mdMax: setFontSize(15, customFontSize),
  lgMin: setFontSize(16, customFontSize),
  lgMinor: setFontSize(17, customFontSize),
  lg: setFontSize(18, customFontSize),
  lgMax: setFontSize(19, customFontSize),
  xl: setFontSize(20, customFontSize),
  xlMax: setFontSize(22, customFontSize),
  '2xl': setFontSize(24, customFontSize),
  '2xlMajor': setFontSize(25, customFontSize),
  '2xlMax': setFontSize(26, customFontSize),
  '3xlMinor': setFontSize(27, customFontSize),
  '3xl': setFontSize(28, customFontSize),
  '3xlMajor': setFontSize(29, customFontSize),
  '3xlMax': setFontSize(30, customFontSize),
  '4xl': setFontSize(32, customFontSize),
  '4xlMajor': setFontSize(34, customFontSize),
  '4xlMax': setFontSize(36, customFontSize),
  '5xl': setFontSize(42, customFontSize),
  '5xlMax': setFontSize(44, customFontSize),
  '6xl': setFontSize(46, customFontSize),
  '7xlMin': setFontSize(48, customFontSize),
  '7xl': setFontSize(50, customFontSize),
  '7xlMax': setFontSize(52, customFontSize),
  '8xl': setFontSize(54, customFontSize),
  '9xl': setFontSize(58, customFontSize),
});

const CustomFamilyFonts = {
  airnz,
};

const setFontFamily = (): string => {
  const fontFamily = import.meta.env.REACT_APP_FONT_FAMILY;

  return fontFamily && Object.keys(CustomFamilyFonts).includes(fontFamily) ? CustomFamilyFonts[fontFamily as keyof typeof CustomFamilyFonts] : cofo;
};

export { fontSizes, setFontFamily };
