import { defineStyleConfig } from '@chakra-ui/react';

const FormLabel = defineStyleConfig({
  baseStyle: {
    marginBottom: '5px',
    fontSize: 'md',
    fontWeight: 500,
    color: 'secondary.600',
  },
});

export default FormLabel;
