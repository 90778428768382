import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Heading, Tab as BaseTab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import qs from 'qs';
import { usePartnershipSettings } from '@api/partnership/queries';
import { useAllTerms } from '@api/sanity/terms.queries';
import { BoxContent as CorporateBoxContent } from '@components/BoxContent/Corporate';
import { BoxMain } from '@components/BoxMain';
import { DisplayedTerm } from '@components/DisplayedTerm';
import { LoaderFullPage } from '@components/Loader';
import { MainBox } from '@components/MainBox';
import { Navbar } from '@components/Navbar';
import { useBreakpoint } from '@hooks/useBreakpoint';
import GeneralTerms from '@pages/shared/GeneralTerms';
import PartnerTerms from '@pages/shared/PartnerTerms';
import WeChoooseTerms from '@pages/shared/WeChoooseTerms';

const weChoooseTermTypes = ['general', 'wechooose', 'partner'];

interface DeprecatedTabStructureProps {
  isMobile: boolean;
  generalTermsTitle?: string;
  weChoooseTermsTitle?: string;
  isDisplayPartnerTerms: boolean;
  partnerTermsTitle?: string;
}

interface NewTabStructureProps {
  isMobile: boolean;
  filteredTermTypes: (
    | {
        value: string;
        label: string | undefined;
      }
    | undefined
  )[];
  partnershipId?: string;
}

const DeprecatedTabStructure: FC<DeprecatedTabStructureProps> = ({
  isMobile,
  generalTermsTitle,
  weChoooseTermsTitle,
  isDisplayPartnerTerms,
  partnerTermsTitle,
}) => {
  return (
    <>
      <TabList mb={['34px', '60px']} flexDirection={isMobile ? 'column' : 'row'}>
        <BaseTab>{generalTermsTitle}</BaseTab>
        <BaseTab>{weChoooseTermsTitle}</BaseTab>
        {isDisplayPartnerTerms && partnerTermsTitle && <BaseTab>{partnerTermsTitle}</BaseTab>}
      </TabList>
      <TabPanels>
        <TabPanel p='0px'>
          <GeneralTerms />
        </TabPanel>
        <TabPanel p='0px'>
          <WeChoooseTerms />
        </TabPanel>
        {isDisplayPartnerTerms && partnerTermsTitle && (
          <TabPanel p='0px'>
            <PartnerTerms />
          </TabPanel>
        )}
      </TabPanels>
    </>
  );
};

const NewTabStructure: FC<NewTabStructureProps> = ({ isMobile, filteredTermTypes, partnershipId }) => {
  return (
    <>
      <TabList mb={['34px', '60px']} flexDirection={isMobile ? 'column' : 'row'}>
        {filteredTermTypes.map(termType => (
          <BaseTab key={termType?.value}>{termType?.label}</BaseTab>
        ))}
      </TabList>
      <TabPanels>
        {filteredTermTypes.map(termType => (
          <TabPanel p='0px' key={termType?.value}>
            {termType ? <DisplayedTerm type={termType.value} partnershipId={partnershipId} /> : null}
          </TabPanel>
        ))}
      </TabPanels>
    </>
  );
};

const Terms: FC<React.PropsWithChildren> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('settings');
  const location = useLocation();
  const history = useHistory();
  const params: {
    terms?: string;
    context?: string;
    partnershipId?: string;
    theme?: string;
    set?: string;
    included?: string;
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { isMobile } = useBreakpoint();
  const { data: settings, isLoading: isLoadingSettings } = usePartnershipSettings(params.partnershipId);
  const { data, isLoading: allTermsIsLoading } = useAllTerms(params.partnershipId || settings.partnershipId, language);
  const partnershipId = params.partnershipId || settings.partnershipId;
  const { partnerTermsTitle, generalTermsTitle, weChoooseTermsTitle, privacyTermsTitle, cookieTermsTitle } = data;
  const isDeprecatedStyle = !!params.context || !!params.set;
  const isDisplayPartnerTerms = !!(partnershipId && partnerTermsTitle);
  const isLoading = [isLoadingSettings, allTermsIsLoading].some(e => e);
  const tabs = [
    {
      value: 'general',
      label: generalTermsTitle,
    },
    {
      value: 'portal',
      label: weChoooseTermsTitle,
    },
    {
      value: 'partner',
      label: partnerTermsTitle,
    },
    {
      value: 'privacy',
      label: privacyTermsTitle,
    },
    {
      value: 'cookie',
      label: cookieTermsTitle,
    },
  ];
  const defaultTerms = ['general', 'privacy', ...(isDisplayPartnerTerms ? ['partner'] : [])];
  const parsedIncludedTerms = params.included?.split(',');
  const displayedTerms = parsedIncludedTerms ?? defaultTerms;
  const filteredTermTypes = displayedTerms.map(setName => tabs.find(tab => tab.value === setName)).filter(x => !!x) ?? [];

  const onTabChange = (idx: number): void => {
    history.push({
      search: qs.stringify({
        theme: params.theme,
        context: params.context,
        terms: isDeprecatedStyle ? weChoooseTermTypes[idx] : filteredTermTypes?.[idx]?.value,
        partnershipId: params.partnershipId,
        set: params.set,
        included: params.included,
      }),
    });
  };

  const index = useMemo(() => {
    if (params.terms && weChoooseTermTypes.includes(params.terms) && isDeprecatedStyle) {
      return weChoooseTermTypes.indexOf(params.terms);
    }

    if (params.terms && displayedTerms.includes(params.terms)) {
      return displayedTerms.indexOf(params.terms);
    }

    if (location.hash.includes('cookie')) {
      return 1;
    }

    return 0;
  }, [params.context, params.terms, displayedTerms, params.theme]);

  if (isLoading) {
    return <LoaderFullPage />;
  }

  return (
    <>
      <Navbar />
      <MainBox>
        <CorporateBoxContent>
          <BoxMain>
            <Box p='24px'>
              <Heading as='h3' mb={['40px', '40px', '36px']} fontSize='lg' fontWeight={400} color='secondary.800'>
                {t('routes.terms')}
              </Heading>
              <Tabs index={index} onChange={onTabChange}>
                {isDeprecatedStyle ? (
                  <DeprecatedTabStructure
                    isMobile={isMobile}
                    generalTermsTitle={generalTermsTitle}
                    weChoooseTermsTitle={weChoooseTermsTitle}
                    isDisplayPartnerTerms={isDisplayPartnerTerms}
                    partnerTermsTitle={partnerTermsTitle}
                  />
                ) : (
                  <NewTabStructure isMobile={isMobile} partnershipId={partnershipId} filteredTermTypes={filteredTermTypes} />
                )}
              </Tabs>
            </Box>
          </BoxMain>
        </CorporateBoxContent>
      </MainBox>
    </>
  );
};

export default Terms;
