import { usePostHog } from 'posthog-js/react';

export const useAnalytics = () => {
  const posthog = usePostHog();
  const track = (event: string, properties?: Record<string, unknown>) => {
    posthog.capture(event, properties);
  };

  const reset = () => {
    posthog.reset();
  };

  return { track, reset };
};
