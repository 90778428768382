import { FC } from 'react';
import { Typography } from '@chooose/ui';
import styled from 'styled-components';
import { PortableText, PortableTextComponents } from '@portabletext/react';

const StyledUl = styled.ul`
  padding-left: ${props => props.theme.spacing.md};
`;

const components: Partial<PortableTextComponents> = {
  marks: {
    link: ({ children, value }) => {
      return (
        <a href={value.href} target='_blank' rel='noreferrer noopener'>
          {children}
        </a>
      );
    },
  },
  block: ({ children }) => <Typography variant='long'>{children}</Typography>,
  list: {
    bullet: ({ children }) => <StyledUl>{children}</StyledUl>,
  },
  listItem: ({ children }) => (
    <Typography tag='li' variant='long'>
      {children}
    </Typography>
  ),
};

type Props = {
  blocks: SanityRichText[];
};
export const SanityBlockContent: FC<Props> = ({ blocks }) => {
  return <PortableText value={blocks} components={components} />;
};
