import posthog, { PostHogConfig } from 'posthog-js';

const options: Partial<PostHogConfig> = {
  api_host: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: true,
  opt_in_site_apps: true,
  debug: import.meta.env.DEV,
  capture_pageview: false,
  bootstrap: {
    featureFlags: {
      new_emissions_table: false,
      new_order_history: false,
    },
  },
};

const apiKey = import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY;
const canRun = Boolean(import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY && import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST);
canRun && posthog.init(apiKey, options);

export const client = posthog;
