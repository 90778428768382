import React, { FC } from 'react';
import { Flex, FlexProps, Skeleton, useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { rgba } from 'polished';
import { transientOptions } from '@utils/transientOptions';

import { AppTheme } from '@theme';

interface OverlayProps {
  overlayHeight?: string;
  type?: OverlayType;
}

const OverlayWrapper = styled(Flex, transientOptions)<{ $overlayHeight: string }>`
  height: ${(props): string => props.$overlayHeight};
  width: 100%;
  flex-direction: column;
`;

export const Overlay: FC<React.PropsWithChildren<FlexProps & OverlayProps>> = ({ overlayHeight = '100px', type = 'standard', ...props }) => {
  const { colors } = useTheme<AppTheme>();
  let OverlayBody;

  switch (type) {
    case 'graph':
      OverlayBody = (
        <>
          <Skeleton w='100%' mb='5%' h='4%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' mb='12%' h='4%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' h='75%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
        </>
      );
      break;
    case 'split':
      OverlayBody = (
        <>
          <Skeleton w='100%' mb='5%' h='5%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' mb='7%' h='5%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Flex h='80%' w='100%'>
            <Skeleton w='48%' h='100%' mr='4%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
            <Skeleton w='48%' h='100%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          </Flex>
        </>
      );
      break;
    case 'header':
      OverlayBody = (
        <Flex h='100%' w='100%' alignItems='center'>
          <Skeleton w='15%' h='50%' mr='5%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='30%' h='20%' mr='30%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='20%' h='20%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
        </Flex>
      );
      break;
    case 'list':
      OverlayBody = (
        <>
          <Skeleton w='30%' mb='60px' flex={1.5} startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' mb='25px' flex={1} startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' mb='25px' flex={1} startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' mb='25px' flex={1} startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' flex={1} startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
        </>
      );
      break;
    case 'SDG':
      OverlayBody = (
        <Flex h='100%'>
          <Skeleton h='100%' mr='11%' w='26%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton h='100%' mr='11%' w='26%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton h='100%' w='26%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
        </Flex>
      );
      break;
    case 'input':
      OverlayBody = <Skeleton h='65px' mr='11%' w='250px' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />;
      break;
    default:
      OverlayBody = (
        <>
          <Skeleton w='100%' mb='11%' h='26%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' mb='11%' h='26%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
          <Skeleton w='100%' h='26%' startColor={rgba(colors.gray[400], 0.5)} endColor={colors.gray[100]} />
        </>
      );
  }

  return (
    <OverlayWrapper $overlayHeight={overlayHeight} data-testid='skeleton-overlay' {...props}>
      {OverlayBody}
    </OverlayWrapper>
  );
};
