import { AxiosInstance } from 'axios';
import { getCarbonOffsetPrice } from '@api/carbonOffset';

import { createContextAwareHandlers } from './instance';

interface PerCapitaOffset {
  totalDays: number;
  persons: number;
  kilosCo2: number;
  kilosCo2e: number;
  lbsCo2: number;
  lbsCo2e: number;
  source: string;
  id: string;
}

interface EventOffsetPriceParams {
  kilosCo2: number;
  eventFlightPax?: number;
  eventPersons?: number;
}
const getFootprint = async (instance: AxiosInstance, source: string, days: number, people: number, countryCode: string): Promise<PerCapitaOffset> => {
  const { data } = await instance.post<PerCapitaOffset>(`/footprint/percapita/${source}/${countryCode}`, undefined, {
    params: {
      years: 0,
      months: 0,
      days,
      persons: people,
      applyTradeAdjustment: true,
    },
  });

  return data;
};

const getOffsetEventPrice =
  (instance: AxiosInstance) =>
  async (param: EventOffsetPriceParams, customerId: string, portfolioId?: string): Promise<OffsetPrice> => {
    const data = await getCarbonOffsetPrice(instance)(param, customerId, portfolioId);

    return data;
  };

const getPerCapitaFootprint =
  (instance: AxiosInstance) =>
  async (days: number, people: number, countryCode = 'earth'): Promise<PerCapitaOffset> => {
    return getFootprint(instance, 'pik', days, people, countryCode).catch(() => getFootprint(instance, 'owid', days, people, countryCode));
  };

const handlers = {
  getPerCapitaFootprint,
  getOffsetEventPrice,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createEventApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createEventApi;
