/* eslint-disable no-undef */
import { AxiosInstance, CancelToken } from 'axios';

import { getCarbonOffsetPrice } from './carbonOffset';
import { createContextAwareHandlers } from './instance';

interface FlightOffsetPriceParams {
  kilosCo2: number;
  flightPax: number;
  flightDistanceKm: number;
  flight?: boolean;
}

const getFlightFootprint =
  (instance: AxiosInstance) =>
  async ({
    travelClass,
    travellers,
    roundTrip,
    from,
    to,
    flights,
    distance,
    flightNumber,
    customerId,
    calculateForCustomer,
  }: FlightOffsetParams): Promise<Offset> => {
    const { data } = await instance.post<Offset>('footprint/flights', undefined, {
      params: {
        travelClass,
        passengers: travellers,
        roundTrip,
        flights,
        flightNumber,
        distanceKm: distance,
        from,
        to,
        customerId,
        calculateForCustomer,
      },
    });

    return data;
  };

const getFlightOffsetPrice =
  (instance: AxiosInstance) =>
  async ({ kilosCo2, flightPax, flightDistanceKm }: FlightOffsetPriceParams, customerId: string, portfolioId?: string): Promise<OffsetPrice> => {
    const data = await getCarbonOffsetPrice(instance)({ kilosCo2, flightPax, flightDistanceKm, flight: true }, customerId, portfolioId);

    return data;
  };

const createBatchOrderForCustomer =
  (instance: AxiosInstance) =>
  async (file: File, sourceToken: CancelToken, customerId: string, currency: string): Promise<BatchJobResponseOk & BatchJobResponseError> => {
    const { data } = await instance.post<BatchJobResponseOk & BatchJobResponseError>(
      `/customers/${customerId}/attachments/processflightbatchjob?currency=${currency}`,
      file,
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        cancelToken: sourceToken,
      },
    );

    return data;
  };

const createMultiCustomerBatchOrderForPartner =
  (instance: AxiosInstance) =>
  async (file: File, sourceToken: CancelToken, partnershipId: string, currency: string): Promise<BatchJobResponseOk & BatchJobResponseError> => {
    const { data } = await instance.post<BatchJobResponseOk & BatchJobResponseError>(
      `/partnerships/${partnershipId}/attachments/processmultipleflightbatchjob?currency=${currency}`,
      file,
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        cancelToken: sourceToken,
      },
    );

    return data;
  };

const handlers = {
  getFlightOffsetPrice,
  getFlightFootprint,
  createBatchOrderForCustomer,
  createMultiCustomerBatchOrderForPartner,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createFlightApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createFlightApi;
