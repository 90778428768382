import { ReactNode } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { reactPlugin } from './appInsights';

const isDevelopment = import.meta.env.DEV;
const shouldUseAppInsights =
  import.meta.env.REACT_APP_DISABLE_APP_INSIGHTS !== 'true' || !isDevelopment || !import.meta.env.REACT_APP_INSIGHTS_CONNECTION_STRING;

export const withAppInsights = (rootElement: ReactNode): ReactNode =>
  shouldUseAppInsights ? <AppInsightsContext.Provider value={reactPlugin}>{rootElement}</AppInsightsContext.Provider> : rootElement;
