import { publicInstance } from './instance';

interface EquivalentsParams {
  kilosCo2: number;
  language: string;
}

const getEquivalents = async (params: EquivalentsParams): Promise<Equivalent[]> => {
  const { data } = await publicInstance.get<Equivalent[]>(`/equivalents/co2`, {
    params,
  });

  return data;
};

export { getEquivalents };
