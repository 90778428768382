import axios from 'axios';
import { defaultInstance } from '@api/instance';
import { Portfolio } from '@api/order/types';

import { Project } from './types';

export const getPortfolioForCustomer = async (customerId: string, params?: URLSearchParams) => {
  const { data } = await defaultInstance.get<Portfolio[]>(`/customers/${customerId}/portfolios`, { params });

  return data;
};

export const getSinglePortfolioForCustomer = async (customerId: string, portfolioId: string) => {
  const { data } = await defaultInstance.get<Portfolio>(`/customers/${customerId}/portfolios/${portfolioId}`);

  return data;
};

export const getPortfolioForPartnership = async (partnershipId: string, currency: string) => {
  const { data } = await defaultInstance.get<Portfolio[]>(`/partnerships/${partnershipId}/portfolios?currency=${currency}`);

  return data;
};
export const getSinglePortfolioForPartnership = async (partnershipId: string, portfolioId: string) => {
  const { data } = await defaultInstance.get<Portfolio>(`/partnerships/${partnershipId}/portfolios/${portfolioId}`);

  return data;
};

export const getPortfolio = async (id: string) => {
  const { data } = await defaultInstance.get<Portfolio>(`/portfolios/${id}`);

  return data;
};

export const getAllProjects = async () => {
  const { data } = await defaultInstance.get<Project[]>('/projects');

  return data;
};

export const getProject = async (id: string) => {
  const { data } = await defaultInstance.get<Project>(`/projects/${id}`);

  return data;
};

export const downloadFromUrl = async (url: string) => {
  const { data } = await axios.get<Blob>(url, {
    responseType: 'blob',
  });

  return data;
};

// TODO: Rename function after removing old projects page
export const switchPortfolioForCustomerQuery = async (customerId: string, sanityPortfolioId: string): Promise<void> => {
  await defaultInstance.put<Portfolio>(`/customers/${customerId}/portfolios`, { sanityPortfolioId });
};

// TODO: Rename function after removing old projects page
export const switchPortfolioForPartnershipQuery = async (partnershipId: string, sanityPortfolioId: string): Promise<void> => {
  await defaultInstance.put<Portfolio>(`/partnerships/${partnershipId}/portfolios`, { sanityPortfolioId });
};
