import { KeyboardEvent, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button as BaseButton, ButtonProps, forwardRef, useTheme } from '@chakra-ui/react';
import { rgba } from 'polished';
import { partnerSettingsSelector } from '@store/partnerSettings/partnerSettingsSlice';

import { AppTheme } from '@theme';
import { getButtonBorderRadius } from '@utils/styles';

export const Button = forwardRef<ButtonProps, 'button'>(({ children, ...props }, ref) => {
  const [isActive, setIsActive] = useState(false);
  const { colors } = useTheme<AppTheme>();
  const customButtonBorderRadius = useSelector(partnerSettingsSelector)?.styles?.buttonBorderRadius;

  const handleClick = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    setIsActive(true);
    setTimeout(() => setIsActive(false), 2000);
    // @ts-ignore
    props.onClick && props.onClick(e);
  };

  const getRippleColor = () => {
    // @ts-ignore
    if (['ghost', 'ghostWithHover', 'dashed', 'link'].includes(props.variant ?? '')) {
      return colors?.white ?? '#fff';
    }

    return colors?.buttons?.primary ?? colors?.other?.CTA ?? '#fff';
  };

  return (
    <BaseButton
      ref={ref}
      height={(props.height || props.h) ?? '50px'}
      borderRadius={getButtonBorderRadius(customButtonBorderRadius) ?? '35px'}
      colorScheme='primary'
      fontWeight={600}
      {...props}
      onMouseDown={e => e.preventDefault()}
      onClick={handleClick}
      onKeyUp={e => (e.key === 'Enter' || e.key === ' ') && handleClick(e)}
      sx={{
        '@keyframes ripple': {
          '0%': {
            boxShadow: `0 0 0 0 ${rgba(getRippleColor(), 0.2)}`,
          },
          '70%': {
            boxShadow: `0 0 0 15px ${rgba(getRippleColor(), 0)}`,
          },
          '100%': {
            boxShadow: `0 0 0 0 ${rgba(getRippleColor(), 0)}`,
          },
        },
        animation: isActive ? 'ripple 1.5s 1' : 'none',
        ...props.sx,
      }}>
      {children}
    </BaseButton>
  );
});
