import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createEmployeeBenefitApi from '@api/employeeBenefit';
import createOrderApi, { OrderItem } from '@api/order';
import { AppThunk, RootState } from '@store';
import { addFakeUsers } from '@store/allUsers/allUsersSlice';
import { appContextSelector, supportedCountriesISO2Selector } from '@store/app/appSlice';
import { defaultPortfolioSelector } from '@store/projects/projectsSlice';
import { createSubscription, updateSubscription } from '@store/subscriptions/subscriptionsSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserCountryCodeSelector, currentUserSelector, customerCurrencySelector } from '@store/user/userSlice';
import { getPlan, plans } from '@utils/planMapper';
import { client } from '@utils/reactQuery';
import { trackPurchaseEventForSubscription } from '@utils/tags';

export const globalAverageOptionValue = 'XX';

export type ProcessType = 'create' | 'update' | 'cancel' | 'restart';
export type ErrorType =
  | 'country'
  | 'insufficientSupply'
  | 'selectedPlanInsufficientSupply'
  | 'selectedCountryInsufficientSupply'
  | 'selectedEmployeesNumberInsufficientSupply'
  | 'noError';

export interface State {
  items: SubscriptionItem[];
  clonedItems: SubscriptionItem[];
  isLoading: boolean;
  error?: string;
  errorType?: ErrorType;
  subscriptionId?: string;
  latestOrderDate?: string;
  currency: string;
  process: ProcessType;
  currentStep: string;
  cancelAtPeriodEnd?: boolean;
  newEmployeeOffsets?: NewEmployeeOffset[];
  newEmployeeInvites?: UserPayload[];
  selectedPlanId: string;
  orderDetails?: OrderDetails;
  vat?: number;
  excise?: number;
  price?: number;
}

interface EditProps {
  index: number;
  value: number;
  price?: number;
  vat?: number;
}

interface CreateProps {
  price?: number;
  vat?: number;
  kilosCo2?: number;
  plan?: string;
  employees?: number;
  countryName?: string;
  countryCode?: string;
  multiplier?: number;
  callback?: () => void;
  additionalOption?: Option;
}

interface CountryProps {
  index: number;
  countryName: string;
  countryCode: string;
}

interface PlanProps {
  index: number;
  plan: string;
}

interface OrderDetails {
  orderNumber: string;
  orderName: string;
  orderDate: string;
  orderId: string;
  billId: string;
  totalKilosCo2: number;
}

const initialState: State = {
  items: [],
  clonedItems: [],
  isLoading: true,
  currency: 'usd',
  process: 'create',
  error: '',
  currentStep: 'offsetPlan',
  cancelAtPeriodEnd: false,
  selectedPlanId: 'ClimatePositive',
};

const slice = createSlice({
  name: 'employeeBenefit',
  initialState,
  reducers: {
    setEmployeeBenefit: (state: State, action: PayloadAction<SubscriptionObject>): State => {
      state.items = action.payload.items;
      state.clonedItems = structuredClone(state.items);
      state.subscriptionId = action.payload.id;
      state.currency = action.payload.currency ?? 'usd';
      state.latestOrderDate = action.payload.latestOrderDate ?? '';
      state.cancelAtPeriodEnd = action.payload.cancelAtPeriodEnd ?? false;
      state.newEmployeeInvites = [];
      state.newEmployeeOffsets = [];
      state.selectedPlanId = action.payload.items ? action.payload.items[0]?.details.person?.plan ?? plans[0].id : plans[0].id;

      return state;
    },
    clearEmployeeBenefit: (): State => {
      return initialState;
    },
    removeItem: (state: State, action: PayloadAction<number>): State => {
      state.items.splice(action.payload, 1);

      return state;
    },
    updateItemCO2: (state: State, action: PayloadAction<EditProps>): State => {
      const { index, value, price, vat } = action.payload;
      const item = state.items[index];
      item.kilosCo2 = value;
      item.price = price || 0;
      item.vat = vat || 0;

      return state;
    },
    updateItemPeopleState: (state: State, action: PayloadAction<EditProps>): State => {
      const { index, value } = action.payload;
      const item = state.items[index];
      if (item.details.person) {
        item.details.person.personCount = value;
      }

      return state;
    },
    updateItemCountryState: (state: State, action: PayloadAction<CountryProps>): State => {
      const { index, countryCode, countryName } = action.payload;
      const item = state.items[index];
      if (item.details.person) {
        item.details.person.countryCode = countryCode;
        item.details.person.countryName = countryName;
      }

      return state;
    },
    updateItemPlanState: (state: State, action: PayloadAction<PlanProps>): State => {
      const { index, plan } = action.payload;
      const item = state.items[index];
      if (item.details.person) {
        item.details.person.plan = plan;
      }
      item.multiplier = getPlan(plan)?.multiplier;

      return state;
    },
    addItem: (state: State, action: PayloadAction<CreateProps>): State => {
      const item = {
        portfolioid: 'defaultco2',
        kilosCo2: action.payload.kilosCo2 || 1000,
        price: action.payload.price || 0,
        multiplier: action.payload.multiplier || plans[0].multiplier,
        details: {
          person: {
            category: 'Employee',
            countryCode: action.payload.countryCode || 'NO',
            countryName: action.payload.countryName || 'Norway',
            plan: action.payload.plan || state.selectedPlanId || plans[0].id,
            days: 0,
            months: 1,
            years: 0,
            personCount: action.payload.employees || 1,
          },
        },
      };
      state.items.push(item);

      return state;
    },
    refreshItems: (state: State): State => {
      state.items = structuredClone(state.clonedItems);

      return state;
    },
    setProcess: (state: State, action: PayloadAction<ProcessType | undefined>): State => {
      if (action.payload) {
        state.process = action.payload;

        return state;
      }

      if (state.subscriptionId) {
        if (state.items.length) {
          state.process = 'update';
        } else {
          state.process = 'cancel';
        }
      } else {
        state.process = 'create';
      }

      return state;
    },
    setCurrency: (state: State, action: PayloadAction<string>): State => {
      state.currency = action.payload;

      return state;
    },
    setLoading: (state: State, action: PayloadAction<boolean>): State => {
      state.isLoading = action.payload;

      return state;
    },
    setError: (state: State, action: PayloadAction<string>): State => {
      state.error = action.payload;

      return state;
    },
    setErrorType: (state: State, action: PayloadAction<ErrorType>): State => {
      state.errorType = action.payload;

      return state;
    },
    setCurrentStep: (state: State, action: PayloadAction<string>): State => {
      state.currentStep = action.payload;

      return state;
    },
    setNewEmployeeOffsets: (state: State, action: PayloadAction<NewEmployeeOffset[]>): State => {
      state.newEmployeeOffsets = action.payload;

      return state;
    },
    setNewEmployeeInvites: (state: State, action: PayloadAction<UserPayload[]>): State => {
      state.newEmployeeInvites = action.payload;

      return state;
    },
    setSelectedPlanId: (state: State, action: PayloadAction<string>): State => {
      state.selectedPlanId = action.payload;

      return state;
    },
    setOrderDetails: (state: State, action: PayloadAction<OrderDetails>): State => {
      state.orderDetails = action.payload;

      return state;
    },
    setOffsetPrice: (state: State, action: PayloadAction<{ price: number; vat: number; excise: number }>): State => {
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.excise = action.payload.excise;

      return state;
    },
    updateMultiplier: (state: State, action: PayloadAction<number>): State => {
      state.items.forEach((item, index) => (state.items[index].multiplier = action.payload));

      return state;
    },
  },
});

const {
  clearEmployeeBenefit,
  removeItem,
  addItem,
  setLoading,
  setProcess,
  setEmployeeBenefit,
  updateItemCO2,
  updateItemPeopleState,
  setError,
  setErrorType,
  updateItemCountryState,
  updateItemPlanState,
  setCurrency,
  refreshItems,
  setCurrentStep,
  setSelectedPlanId,
  setOrderDetails,
  setOffsetPrice,
  updateMultiplier,
} = slice.actions;

export { refreshItems, setCurrentStep, setProcess };

export default slice.reducer;

const getOrderItem = (item: SubscriptionItem, portfolioId: string | null): OrderItem => ({
  ...item,
  kilosCo2: item.kilosCo2 * (item.multiplier || plans[0].multiplier),
  type: 'impact',
  impact: 'Co2',
  portfolioId,
  details: {
    person: item.details.person,
  },
});

const setUnchangedItems = (items: SubscriptionItem[], item: SubscriptionItem, portfolioId: string | null) =>
  items.filter(({ details }) => details.person?.countryCode !== item.details.person?.countryCode).map(item => getOrderItem(item, portfolioId));

const normalizeItems = (items: SubscriptionItem[]): SubscriptionItem[] =>
  items.map(item => {
    return {
      type: 'Impact',
      impact: 'Co2',
      portfolioid: item.portfolioid || 'defaultco2',
      kilosCo2: item.kilosCo2 * (item.multiplier || plans[0].multiplier),
      price: item.price,
      details: {
        person: item.details.person,
      },
      feeFactors: {
        employeeBenefitMonthlyPersons: item.details.person?.personCount ?? 0,
      },
    };
  });

export const saveSubscription =
  (cb?: CallableFunction, shouldRetryAttribution = true): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { items, clonedItems, subscriptionId, process, newEmployeeInvites, newEmployeeOffsets } = getState().employeeBenefit;

    const employeeAmount = items.reduce<number>((prev, next) => (prev += next.details.person?.personCount ?? 0), 0);
    dispatch(setLoading(true));
    const currentUser = currentUserSelector(getState());
    const normalizedItems = normalizeItems(items);
    const normalizedClonedItems = normalizeItems(clonedItems);
    const context = appContextSelector(getState());
    const employeeBenefitAPI = createEmployeeBenefitApi(context);
    const orderAPI = createOrderApi(context);
    const paymentMethodType = currentUser?.customer?.payment?.defaultPaymentMethod?.type;
    let currentOrderId = '';

    try {
      if (process === 'update' || process === 'restart') {
        if (subscriptionId) {
          const payload = {
            interval: 'monthly',
            category: 'Employee benefit',
            description: `Employee benefit for ${employeeAmount} employees`,
            items: normalizedItems.length ? normalizedItems : normalizedClonedItems,
            ...(newEmployeeInvites !== undefined ? { newEmployeeInvites } : null),
            ...(newEmployeeOffsets !== undefined ? { newEmployeeOffsets } : null),
          };
          const data = await employeeBenefitAPI.update(subscriptionId, payload, currentUser?.customerId ?? '');

          if (data?.invites?.length) {
            dispatch(
              addFakeUsers(
                data.invites.map(invite => ({
                  userAccount: { email: invite.userAccount.email },
                  role: { id: invite.role.id, name: invite.role?.name },
                  employeeCountryCodeIso2: invite.employeeCountryCodeIso2,
                  employeeOffset: invite.employeeOffset,
                  status: 'Invited',
                  userId: invite.userId ?? '',
                })),
              ),
            );
          }

          dispatch(updateSubscription({ ...data.subscription }));
          currentOrderId = data.orderId ?? data.subscription.latestOrderId;
          process === 'update' && shouldRetryAttribution && dispatch(syncAttribution());
        }
      } else if (process === 'cancel') {
        if (subscriptionId) {
          const data = await employeeBenefitAPI.remove(subscriptionId, currentUser?.customerId ?? '');
          dispatch(updateSubscription({ ...data.subscription }));
        }
      } else {
        const subscription = {
          type: 'EmployeeBenefit',
          interval: 'monthly',
          category: 'Employee benefit',
          description: `Employee benefit for ${employeeAmount} employees`,
          items: normalizedItems,
          ...(newEmployeeInvites !== undefined ? { newEmployeeInvites } : null),
          ...(newEmployeeOffsets !== undefined ? { newEmployeeOffsets } : null),
        };
        const data = await employeeBenefitAPI.create(subscription, currentUser?.customerId ?? '');
        dispatch(createSubscription({ ...data.subscription }));
        currentOrderId = data.orderId ?? data.subscription.latestOrderId;
        client.invalidateQueries({ queryKey: ['orders', 'history'] });
        dispatch(syncAttribution());
        trackPurchaseEventForSubscription(data.subscription, 'Portal offset', paymentMethodType);
      }

      if (currentOrderId) {
        const { description, modifiedDate, choooseId, payment, totalKilosCo2 } = await orderAPI.getOne(currentOrderId, currentUser?.customerId ?? '');
        dispatch(
          setOrderDetails({
            orderDate: modifiedDate,
            orderId: currentOrderId,
            orderName: description,
            orderNumber: choooseId,
            billId: payment.billId,
            totalKilosCo2,
          }),
        );
      }
      cb && cb();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      dispatch(setError(process !== 'create' ? 'Unable to update a subscription plan.' : 'Unable to create a subscription plan.'));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const prepareEmployeeBenefit =
  (subscription: SubscriptionObject | undefined): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    dispatch(setErrorType('noError'));
    dispatch(clearEmployeeBenefit());
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const defaultPortfolio = defaultPortfolioSelector(getState());
    const currency = currentUser?.customer?.payment?.currency ?? 'USD';

    try {
      if (subscription) {
        const items = subscription.items.map(item => {
          const orderItem: OrderItem = {
            type: 'impact',
            impact: 'Co2',
            kilosCo2: item.kilosCo2,
            portfolioId: defaultPortfolio?.portfolioData.id ?? null,
            feeFactors: item.feeFactors,
            details: {
              person: item.details.person,
            },
          };

          return orderItem;
        });

        const order = {
          currency,
          category: 'Employee benefit',
          items,
        };
        const data = await orderApi.getQuoteForCustomer(order, currentUser?.customerId ?? '');
        const normalizedSubscriptionItems = subscription.items.map((item, index) => {
          let multiplier = plans[0].multiplier;
          if (item.details.person) {
            multiplier = getPlan(item.details.person.plan)?.multiplier || plans[0].multiplier;
          }

          return {
            ...item,
            multiplier: multiplier,
            kilosCo2: item.kilosCo2 / multiplier,
            price: data.items[index]?.price,
            vat: data.items[index]?.vat,
          };
        });
        dispatch(setEmployeeBenefit({ ...subscription, items: normalizedSubscriptionItems }));
        dispatch(
          setOffsetPrice({
            price: data.totalPrice,
            vat: data.totalVat,
            excise: data.totalExcise,
          }),
        );

        if (subscription.cancelAtPeriodEnd) {
          dispatch(setCurrentStep('cancelPlan'));
        }
      } else {
        const currency = customerCurrencySelector(getState());
        dispatch(setCurrency(currency));
      }
      dispatch(setLoading(false));
    } catch (err) {
      // @ts-ignore
      if (err.data?.includes('Insufficient supply')) {
        dispatch(setErrorType('insufficientSupply'));
      }
      dispatch(setLoading(false));
      dispatch(setError('Unable to fetch price for subscription items.'));
    }
  };

export const employeeBenefitCountries = createSelector(
  (state: RootState) => state.employeeBenefit.items,
  items => {
    const countries = items.map(item => item.details.person?.countryName);

    return [...new Set(countries)];
  },
);

export const employeeBenefitCountriesCodes = createSelector(
  (state: RootState) => state.employeeBenefit.items,
  items => {
    const countries = items.map(item => item.details.person?.countryCode);

    return [...new Set(countries)];
  },
);

const employeeBenefitUnusedCountries = createSelector(supportedCountriesISO2Selector, employeeBenefitCountries, (countries, usedCountries) =>
  countries.filter(country => !usedCountries.some(usedCountry => usedCountry === country.label)),
);

export const employeeBenefitUnusedCountriesWithUserCountryOnTop = createSelector(
  employeeBenefitUnusedCountries,
  currentUserCountryCodeSelector,
  (countries, userCountryCode) => {
    const userCountryOption = countries.find(country => country.value === userCountryCode);
    const countriesWithoutUserCountry = countries.filter(country => country.value !== userCountryCode);

    return userCountryOption ? [userCountryOption, ...countriesWithoutUserCountry] : countries;
  },
);

// temporary solution for insufficient supply when there are more than 1 item
// supply is checked on BE per item not total amount
// if every item separately has amount less than supply but together the amount is grater subscription can be created or updated
const checkInsufficientSupply =
  (items: OrderItem[]): AppThunk =>
  (dispatch, getState): void => {
    if (items.length > 1) {
      const defaultPortfolioTotalKilosCo2Available = (defaultPortfolioSelector(getState())?.portfolioData.totalTonnesCo2Available ?? 0) * 1000;
      const totalKilosCo2 = items.reduce((prev, curr) => prev + (curr.kilosCo2 ?? 0), 0);
      if (totalKilosCo2 > defaultPortfolioTotalKilosCo2Available) {
        throw 'Insufficient supply';
      }
    }
  };

// @ts-ignore
const isInsufficientSupply = (error: unknown): boolean => error?.data?.includes('Insufficient supply') || error.includes('Insufficient supply');

export const addNewItem =
  (props?: CreateProps, cb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const usedCountries = employeeBenefitCountriesCodes(getState()).filter(code => code !== globalAverageOptionValue);
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const employeeBenefitAPI = createEmployeeBenefitApi(context);

    let availableCountries = employeeBenefitUnusedCountriesWithUserCountryOnTop(getState());
    const additionalOption = props?.additionalOption;
    additionalOption && (availableCountries = [additionalOption, ...availableCountries]);
    const { selectedPlanId, items } = getState().employeeBenefit;
    const defaultPortfolioId = defaultPortfolioSelector(getState())?.portfolioData.id ?? null;
    const currentUser = currentUserSelector(getState());
    const currency = currentUser?.customer?.payment?.currency ?? 'USD';

    const availableCountry = availableCountries.find(country => !usedCountries.some(usedCountryName => usedCountryName === country?.label));
    const availableCountryCode = availableCountry?.value ?? '';
    const availableCountryName = availableCountry?.label ?? '';

    const countryCode = props?.countryCode ?? (availableCountryCode || globalAverageOptionValue);

    const employees = props?.employees ?? 1;
    const plan = getPlan(props?.plan ?? selectedPlanId);

    try {
      if (plan) {
        const footprintData = await employeeBenefitAPI.getPerCapitaFootprint(countryCode, employees);
        const kilosCo2 = footprintData.kilosCo2e;
        const multiplier = plan.multiplier || plans[0].multiplier;

        const itemsWithPortfolioId = items.map(item => getOrderItem(item, defaultPortfolioId));

        const order = {
          currency,
          category: 'Employee benefit',
          items: [
            {
              type: 'impact',
              impact: 'Co2',
              kilosCo2: kilosCo2 * multiplier,
              portfolioId: defaultPortfolioId,
              feeFactors: {
                employeeBenefitMonthlyPersons: employees,
              },
              details: {
                person: {
                  category: 'Employee',
                  countryCode: props?.countryCode ?? availableCountryCode,
                  countryName: props?.countryName ?? availableCountryName,
                  months: 1,
                  personCount: employees,
                  plan: plan.id,
                },
              },
            },
            ...itemsWithPortfolioId,
          ],
        };

        const priceData = await orderApi.getQuoteForCustomer(order, currentUser?.customerId ?? '');

        dispatch(
          addItem({
            ...props,
            multiplier,
            price: priceData.items[0]?.price,
            vat: priceData.items[0]?.vat,
            kilosCo2,
            countryCode: props?.countryCode ?? availableCountryCode,
            countryName: props?.countryName ?? availableCountryName,
          }),
        );
        dispatch(checkInsufficientSupply(order.items));
        dispatch(
          setOffsetPrice({
            price: priceData.totalPrice,
            vat: priceData.totalVat,
            excise: priceData.totalExcise,
          }),
        );
        cb && cb();
      } else {
        dispatch(setError('Unable to add new subscription item. Plan is incorrect.'));
      }
      dispatch(setLoading(false));
    } catch (err) {
      if (isInsufficientSupply(err)) {
        dispatch(setErrorType('selectedCountryInsufficientSupply'));
      }
      dispatch(setLoading(false));
      dispatch(setError('Unable to add new subscription item.'));
    }
  };

const updateItemPlan =
  (plan: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const subscription = getState().employeeBenefit;
    const multiplier = getPlan(plan)?.multiplier;
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const defaultPortfolioId = defaultPortfolioSelector(getState())?.portfolioData.id ?? null;
    const currency = currentUser?.customer?.payment?.currency ?? 'USD';
    const orderApi = createOrderApi(context);

    try {
      const items = subscription.items.map(item => {
        const orderItem: OrderItem = {
          type: 'impact',
          impact: 'Co2',
          kilosCo2: item.kilosCo2 * multiplier,
          portfolioId: defaultPortfolioId,
          feeFactors: item.feeFactors,
          details: {
            person: item.details.person,
          },
        };

        return orderItem;
      });

      const order = {
        currency,
        category: 'Employee benefit',
        items,
      };
      const data = await orderApi.getQuoteForCustomer(order, currentUser?.customerId ?? '');
      dispatch(checkInsufficientSupply(items));
      const normalizedSubscriptionItems = subscription.items.map((item, index) => {
        let multiplier = plans[0].multiplier;
        if (items.length > 0 && items[0]?.details?.person) {
          multiplier = getPlan(items[0].details.person.plan)?.multiplier || plans[0].multiplier;
        }

        return {
          ...item,
          multiplier: multiplier,
          kilosCo2: item.kilosCo2,
          price: data.items[index]?.price,
          vat: data.items[index]?.vat,
        };
      });
      normalizedSubscriptionItems.map((item, index) => {
        dispatch(updateItemCO2({ index, value: item.kilosCo2, price: data.items[index]?.price, vat: data.items[index]?.vat }));
        dispatch(updateItemPlanState({ plan, index }));
      });
      dispatch(
        setOffsetPrice({
          price: data.totalPrice,
          vat: data.totalVat,
          excise: data.totalExcise,
        }),
      );

      dispatch(setLoading(false));
      dispatch(setErrorType('noError'));
    } catch (err) {
      if (isInsufficientSupply(err)) {
        if (subscription.errorType === 'noError') {
          dispatch(setErrorType('selectedPlanInsufficientSupply'));
        }
        dispatch(updateMultiplier(multiplier));
      }
      dispatch(setLoading(false));
      dispatch(setError('Unable to update plan for subscription item.'));
    }
  };

export const updateItemCountry =
  (props: CountryProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const { items } = getState().employeeBenefit;
    const item = items[props.index];
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const defaultPortfolioId = defaultPortfolioSelector(getState())?.portfolioData.id ?? null;
    const currency = currentUser?.customer?.payment?.currency ?? 'USD';
    const orderApi = createOrderApi(context);
    const employeeBenefitAPI = createEmployeeBenefitApi(context);
    const itemsWithPortfolioId = setUnchangedItems(items, item, defaultPortfolioId);

    const multiplier = item.multiplier || plans[0].multiplier;
    let kilosCo2 = 0;

    try {
      const data = await employeeBenefitAPI.getPerCapitaFootprint(props.countryCode, item.details.person?.personCount ?? 0);
      kilosCo2 = data.kilosCo2e;
      const order = {
        currency,
        category: 'Employee benefit',
        items: [
          {
            type: 'impact',
            impact: 'Co2',
            kilosCo2: kilosCo2 * multiplier,
            portfolioId: defaultPortfolioId,
            feeFactors: item.feeFactors,
            details: {
              person: item.details.person,
            },
          },
          ...itemsWithPortfolioId,
        ],
      };

      dispatch(updateItemCountryState({ ...props }));
      dispatch(checkInsufficientSupply(order.items));
      const priceData = await orderApi.getQuoteForCustomer(order, currentUser?.customerId ?? '');
      dispatch(
        setOffsetPrice({
          price: priceData.totalPrice,
          vat: priceData.totalVat,
          excise: priceData.totalExcise,
        }),
      );
      dispatch(updateItemCO2({ index: props.index, value: kilosCo2, price: priceData.items[0]?.price, vat: priceData.items[0]?.vat }));
      dispatch(setLoading(false));
      dispatch(setErrorType('noError'));
    } catch (err) {
      if (isInsufficientSupply(err)) {
        dispatch(setErrorType('selectedCountryInsufficientSupply'));
        dispatch(updateItemCO2({ index: props.index, value: kilosCo2 }));
      }
      dispatch(setLoading(false));
      dispatch(setError('Unable to update country for subscription item.'));
    }
  };

export const updateItemPeople =
  (props: EditProps): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const { items } = getState().employeeBenefit;
    const item = items[props.index];
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const defaultPortfolioId = defaultPortfolioSelector(getState())?.portfolioData.id ?? null;
    const currency = currentUser?.customer?.payment?.currency ?? 'USD';
    const orderApi = createOrderApi(context);
    const employeeBenefitAPI = createEmployeeBenefitApi(context);
    const itemsWithPortfolioId = setUnchangedItems(items, item, defaultPortfolioId);

    const multiplier = item.multiplier || plans[0].multiplier;
    let kilosCo2 = 0;

    try {
      const data = await employeeBenefitAPI.getPerCapitaFootprint(item.details.person?.countryCode ?? 'NO', props.value);
      kilosCo2 = data.kilosCo2e;
      const order = {
        currency,
        category: 'Employee benefit',
        items: [
          {
            type: 'impact',
            impact: 'Co2',
            kilosCo2: kilosCo2 * multiplier,
            portfolioId: defaultPortfolioId,
            feeFactors: item.feeFactors,
            details: {
              person: item.details.person,
            },
          },
          ...itemsWithPortfolioId,
        ],
      };

      dispatch(updateItemPeopleState({ ...props }));
      dispatch(checkInsufficientSupply(order.items));
      const priceData = await orderApi.getQuoteForCustomer(order, currentUser?.customerId ?? '');
      dispatch(
        setOffsetPrice({
          price: priceData.totalPrice,
          vat: priceData.totalVat,
          excise: priceData.totalExcise,
        }),
      );
      dispatch(updateItemCO2({ index: props.index, value: kilosCo2, price: priceData.items[0]?.price, vat: priceData.items[0]?.vat }));
      dispatch(setLoading(false));
      dispatch(setErrorType('noError'));
    } catch (err) {
      if (isInsufficientSupply(err)) {
        dispatch(setErrorType('selectedEmployeesNumberInsufficientSupply'));
        dispatch(updateItemCO2({ index: props.index, value: kilosCo2 }));
      }
      dispatch(setLoading(false));
      dispatch(setError('Unable to update number of people for subscription item.'));
    }
  };

export const deleteItem =
  (index: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { items } = getState().employeeBenefit;
    dispatch(setLoading(true));
    dispatch(removeItem(index));
    const context = appContextSelector(getState());
    const orderApi = createOrderApi(context);
    const itemsCopy = [...items];
    const defaultPortfolioId = defaultPortfolioSelector(getState())?.portfolioData.id ?? null;
    const currentUser = currentUserSelector(getState());
    const currency = currentUser?.customer?.payment?.currency ?? 'USD';

    try {
      itemsCopy.splice(index, 1);
      const itemsWithPortfolioId = itemsCopy.map(item => getOrderItem(item, defaultPortfolioId));
      const order = {
        currency,
        category: 'Employee benefit',
        items: itemsWithPortfolioId,
      };
      const priceData = await orderApi.getQuoteForCustomer(order, currentUser?.customerId ?? '');
      dispatch(checkInsufficientSupply(itemsWithPortfolioId));

      dispatch(
        setOffsetPrice({
          price: priceData.totalPrice,
          vat: priceData.totalVat,
          excise: priceData.totalExcise,
        }),
      );

      dispatch(setLoading(false));
      dispatch(setErrorType('noError'));
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const updateSelectedPlan =
  (plan: string): AppThunk =>
  (dispatch): void => {
    dispatch(setSelectedPlanId(plan));
    dispatch(updateItemPlan(plan));
  };

export const employeeBenefitTotalKilosCO2 = createSelector(
  (state: RootState) => state.employeeBenefit.items,
  items => items.reduce<number>((prev, next) => prev + next.kilosCo2 * (next.multiplier || plans[0].multiplier), 0),
);
