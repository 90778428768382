import { FC } from 'react';
import { FAQ as CorporateFAQ } from '@components/FAQ/Corporate';
import CookieTerms from '@pages/shared/CookieTerms';
import GeneralTerms from '@pages/shared/GeneralTerms';
import Licenses from '@pages/shared/KnowledgeBase/Licenses';
import Prices from '@pages/shared/KnowledgeBase/Prices';
import PartnerTerms from '@pages/shared/PartnerTerms';
import PrivacyTerms from '@pages/shared/PrivacyTerms';
import WeChoooseTerms from '@pages/shared/WeChoooseTerms';

interface DisplayedTermProps {
  type?: string;
  partnershipId?: string;
}

export const DisplayedTerm: FC<DisplayedTermProps> = ({ type, partnershipId }) => {
  switch (type) {
    case 'faq':
      return <CorporateFAQ page='portal-corporate-dashboard' partnerPage={`portal-dashboard-${partnershipId}`} isPadding={false} />;
    case 'portal':
      return <WeChoooseTerms />;
    case 'partner':
      return <PartnerTerms />;
    case 'privacy':
      return <PrivacyTerms />;
    case 'cookie':
      return <CookieTerms />;
    case 'prices':
      return <Prices />;
    case 'licenses':
      return <Licenses />;
    case 'general':
    default:
      return <GeneralTerms />;
  }
};
