import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { usePartnership } from '@api/partnership/queries';
import { useCurrentAccount } from '@api/user/queries';
import { ONE_DAY } from '@utils/constants';
import { isPartnerAccount } from '@utils/contextChecker';

import { Portfolio } from '../order/types';
import * as api from './api';
import { Project } from './types';

// TODO: Split this hook to customer and partner.
const useCurrentContextPortfoliosQuery = <T>(select: (data: Portfolio[]) => T | undefined, params?: URLSearchParams) => {
  const { account } = useCurrentAccount();
  const { data: partnership, isPending } = usePartnership();
  const isPartner = isPartnerAccount(account);
  const accountId = (isPartner ? account?.partnershipId : account?.customerId) ?? '';

  const paramsForKeys: string[] = [];
  if (params) {
    params.forEach(value => {
      paramsForKeys.push(value);
    });
  }

  return useQuery({
    queryKey: [accountId, 'portfolios', ...paramsForKeys],
    queryFn: () =>
      isPartner ? api.getPortfolioForPartnership(accountId, partnership?.currency ?? 'USD') : api.getPortfolioForCustomer(accountId, params),
    staleTime: ONE_DAY,
    select,
    enabled: isPartner ? !isPending : !!accountId,
  });
};

export const usePortfoliosFromIds = (ids: string[]) => {
  return useQueries({
    queries: ids.map<UseQueryOptions<Portfolio>>(id => ({
      queryKey: ['portfolios', id],
      queryFn: () => api.getPortfolio(id),
      staleTime: ONE_DAY,
    })),
    combine: results => {
      return {
        data: results.filter(r => !!r.data && r.data.type !== 'Virtual').map(result => result.data!),
        isLoading: results.some(result => result.isLoading),
      };
    },
  });
};

export const useProjects = () =>
  useQuery({
    queryKey: ['projects'],
    queryFn: api.getAllProjects,
    staleTime: ONE_DAY,
    select: data => data ?? [],
  });

export const useProjectsFromIds = (ids: string[]) =>
  useQueries({
    queries: ids.map<UseQueryOptions<Project>>(id => ({
      queryKey: ['projects', id],
      queryFn: () => api.getProject(id),
      staleTime: ONE_DAY,
    })),
    combine: results => ({
      data: results.filter(result => !!result.data).map(result => result.data!),
      isLoading: results.some(result => result.isLoading),
    }),
  });

export const usePortfolio = (portfolioId: string) => {
  const { account } = useCurrentAccount();
  const isPartner = isPartnerAccount(account);
  const accountId = (isPartner ? account?.partnershipId : account?.customerId) ?? '';

  return useQuery({
    queryKey: [accountId, 'portfolios', portfolioId],
    queryFn: () =>
      isPartner ? api.getSinglePortfolioForPartnership(accountId, portfolioId) : api.getSinglePortfolioForCustomer(accountId, portfolioId),
    staleTime: ONE_DAY,
    select: data => data ?? [],
  });
};

export const useCurrentContextPortfolios = (params?: URLSearchParams) => useCurrentContextPortfoliosQuery<Portfolio[]>(data => data ?? [], params);
export const useCurrentContextMainPortfolio = () =>
  useCurrentContextPortfoliosQuery<Portfolio>(data => (data || [])?.find(p => p.default) ?? undefined);
