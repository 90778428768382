export const nicerScrollbar = (color: string): string => `
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: ${color} transparent;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${color};
    border-radius: 11px;
  }
`;

export const getButtonBorderRadius = (customButtonBorderRadius?: number): string | undefined => {
  return customButtonBorderRadius !== undefined ? `${customButtonBorderRadius}px` : undefined;
};
