import i18next from 'i18next';
import { PartnerSettings } from '@store/partnerSettings/partnerSettingsSlice';

// NOTE: We need to escape the Sanity CMS content in order to render it properly
const escapeSanityContent = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const replaceVariablesWithFallback = (content: string, settings: PartnerSettings): string => {
  if (!settings) {
    return content;
  }

  // Overwrite customerFees from settings
  if (!settings?.feeDisplay?.allowed || !settings?.feeDisplay?.displayFeeOnTermsAndConditions) {
    settings = { ...settings, customerFees: undefined };
  }
  // This regex will try to catch anything that is wrapped in double curly braces
  const variableRegex = /{{[a-zA-Z_.0-9 ?]*([??]{1}[^]*)?}}/g;
  const variables = new Set(content.match(variableRegex));

  if (variables) {
    variables.forEach(variable => {
      let variableName = variable.replace(/[{}]/g, '');
      let fallbackValue = '';
      if (variableName.includes('??')) {
        const [v, fallback] = variableName.split('??');
        variableName = v?.replace(/\s+/g, '');
        fallbackValue = fallback?.trim().replace(/["']/g, '');
      } else {
        variableName = variableName?.replace(/\s+/g, '');
      }
      let variableValue;
      if (variableName.includes('.')) {
        const nestedVariables = variableName.split('.');
        variableValue = nestedVariables?.reduce(
          (prev, variable) => (prev?.[variable as keyof PartnerSettings] as PartnerSettings) ?? fallbackValue,
          settings,
        );
      } else {
        variableValue = settings[variableName as keyof PartnerSettings] ?? fallbackValue;
      }
      if (Array.isArray(variableValue)) {
        variableValue = variableValue.reduce((curr, prev, index, arr) => curr + prev + (index !== arr.length - 1 ? ', ' : ''), '');
      }
      content = content.replace(new RegExp(escapeSanityContent(variable), 'g'), variableValue as string);
    });
  }

  return content;
};

export function injectPartnerNameInString(text: string): string {
  return text.replace(/{{\s*partnerName\s*}}/g, import.meta.env.REACT_APP_PARTNER_NAME ?? 'partner');
}

export function injectVariablesInRichText(terms: SanityRichText[], settings: PartnerSettings): SanityRichText[] {
  return terms.map(item => {
    if (item.text && item.marks?.includes('partnerName')) {
      return { ...item, text: import.meta.env.REACT_APP_PARTNER_NAME };
    }

    if (item.text) {
      return { ...item, text: replaceVariablesWithFallback(item.text, settings) };
    }

    if (item.children) {
      return { ...item, children: injectVariablesInRichText(item.children, settings) };
    }

    return item;
  });
}

export function prepareText(text: string) {
  const conditionStartRegex = /{{\s*#if\s*[a-zA-Z_.0-9 ]*}}/g;
  const conditionEndRegex = /{{\s*\/if\s*}}/g;
  const conditionStart = text.match(conditionStartRegex);
  const conditionEnd = text.match(conditionEndRegex);
  let content = text;
  conditionStart?.map(item => (content = content.replace(new RegExp(escapeSanityContent(item), 'g'), '')));
  conditionEnd?.map(item => (content = content.replace(new RegExp(escapeSanityContent(item), 'g'), '')));

  return content;
}

// NOTE: This function checks the provided condition (e.g.: ''{{#if customerFees.itemFee.defaultPercentageFee}}')
// against provided `settings` and decides if the condition is true or not - that
// means if the condition should be displayed to end user or not
export const verifyIfVariableExists = (condition: string, settings: PartnerSettings) => {
  if (!settings?.feeDisplay?.allowed || !settings?.feeDisplay?.displayFeeOnTermsAndConditions) {
    settings = { ...settings, customerFees: undefined };
  }
  const variableName = condition.replaceAll('{{', '').replaceAll('}}', '').replace(/\s/g, '').replaceAll('#if', '');
  let variableValue;
  // If variable includes `.` that means this is a nested variable
  // and we need to traverse `settings` to get to the bottom of that variable
  if (variableName.includes('.')) {
    const nestedVariables = variableName.split('.');
    variableValue = nestedVariables?.reduce(
      (prev, variable) => (prev?.[variable as keyof PartnerSettings] as PartnerSettings) ?? undefined,
      settings,
    );
  } else {
    variableValue = settings[variableName as keyof PartnerSettings];
  }

  return !!variableValue;
};

export function checkCondition(
  text: string,
  isConditionTrue: boolean,
  isResolvingCondition = false,
  settings: PartnerSettings,
): { content: string; resultOfTheCheck: boolean; resolvingCondition: boolean } {
  const conditionStartRegex = /{{\s*#if\s*[a-zA-Z_.0-9 ]*}}/g;
  const conditionEndRegex = /{{\s*\/if\s*}}/g;
  const fullConditionRegex = /({{\s*#if\s*[a-zA-Z_.0-9 ]*}})([^#]+?(?={{\s*\/if\s*}}))({{\s*\/if\s*}})/g;
  let fullConditionBlocks = text.match(fullConditionRegex);
  let resultOfTheCheck = isConditionTrue;
  let resolvingCondition = isResolvingCondition;
  let content = text;

  // If text contains full conditional regex, eg.: '{{#if condition}}\nThe default fees are: {{variable}}\n{{/if}}'
  if (fullConditionBlocks && fullConditionBlocks.length > 0) {
    resolvingCondition = false;
    fullConditionBlocks.map(item => {
      const conditionStartBlocks = item.match(conditionStartRegex);
      conditionStartBlocks?.map(condition => {
        resultOfTheCheck = verifyIfVariableExists(condition, settings) ?? false;
        if (resultOfTheCheck) {
          // If variable is verified, replace the text - remove
          // references to the condition and so on. This will
          // make sure the content is only text, without any curly braces
          // or references to variables.
          const replacedText = prepareText(item);
          content = content.replaceAll(item, replacedText);
        } else {
          // Otherwise, make entire content an empty string
          content = content.replaceAll(item, '');
        }
      });
    });
  }
  // Reassign `fullConditionBlocks` after first conditional run
  fullConditionBlocks = content.match(fullConditionRegex);
  // If text contains nested full conditional regex, eg.:
  // '{{#if condition}}\nThe default fees are: {{variable}}\n{{#if condition2}}\Another condition: {{variable2}}\n{{/if}}test{{/if}}'
  // we are parsing it once again (until no nested conditions)
  if (fullConditionBlocks && fullConditionBlocks.length > 0) {
    return checkCondition(content, resultOfTheCheck, resolvingCondition, settings);
  }

  const conditionEndBlocks = content.match(conditionEndRegex);
  let conditionStartBlocks = content.match(conditionStartRegex);

  if (resolvingCondition) {
    if (conditionEndBlocks && conditionEndBlocks.length > 0) {
      const conditionEnd = conditionEndBlocks[0];
      const textOfTheCurrentCondition = content.split(conditionEnd)?.[0];
      content = content.replace(conditionEnd, '');
      if (isConditionTrue) {
        content = content.replace(textOfTheCurrentCondition, prepareText(textOfTheCurrentCondition));
      } else {
        content = content.replace(textOfTheCurrentCondition, '');
      }
      resolvingCondition = false;
    } else if (conditionStartBlocks && conditionStartBlocks.length > 0) {
      const newConditionStart = conditionStartBlocks[0];
      const textOfTheCurrentCondition = content.split(newConditionStart)?.[0];
      content = content.replace(newConditionStart, '');
      if (isConditionTrue) {
        content = content.replace(textOfTheCurrentCondition, prepareText(textOfTheCurrentCondition));
      } else {
        content = content.replace(textOfTheCurrentCondition, '');
      }
    } else {
      if (resultOfTheCheck) {
        content = prepareText(content);
      } else {
        content = '';
      }
    }
  }

  conditionStartBlocks = content.match(conditionStartRegex);

  // If content contains starting condition, e.g.: '{{#if customerFees.itemFee.defaultPercentageFee}}\nThe default fees are: {{customerFees.itemFee.defaultPercentageFee}}\n'
  // we need to set `resolvingCondition` to true - this means
  // that we will return `resolvingCondition` as true to inform
  // the algorithm that parsed text is not a full, valid condition
  if (conditionStartBlocks && conditionStartBlocks.length > 0) {
    const conditionStart = conditionStartBlocks[0];
    resultOfTheCheck = verifyIfVariableExists(conditionStart, settings);
    resolvingCondition = true;
    const textOfTheCurrentCondition = content.split(conditionStart)?.[1];
    content = content.replace(conditionStart, '');
    if (resultOfTheCheck) {
      content = content.replace(textOfTheCurrentCondition, prepareText(textOfTheCurrentCondition));
    } else {
      // Otherwise, make entire content an empty string
      content = content.replace(textOfTheCurrentCondition, '');
    }
  }

  return { resultOfTheCheck, content, resolvingCondition };
}

// TODO: Create tests for this, see: https://dev.azure.com/CHOOOSE/CHOOOSE%20Platform/_workitems/edit/15457
export function resolveConditionalBlock(sanityRichTextArray: SanityRichText[], settings: PartnerSettings): SanityRichText[] {
  // We need to store those variables outside of the loop function
  // Those variables are telling us if the parsed `child.text` has
  // a valid condition (`isConditionTrue`) and if the current `child.text` node
  // is resolving that condition (basically, if `isResolvingCondition` is true,
  // that means `child.text` passed to the `checkCondition` function should match
  // `conditionEndRegex` - "/{{\s*\/if\s*}}/g").
  let isConditionTrue = false;
  let isResolvingCondition = false;

  return sanityRichTextArray.map(item => {
    if (item.children) {
      const updatedChildren = item.children.map(child => {
        if (child.text) {
          const { resultOfTheCheck, content, resolvingCondition } = checkCondition(child.text, isConditionTrue, isResolvingCondition, settings);
          isConditionTrue = resultOfTheCheck;
          isResolvingCondition = resolvingCondition;

          return { ...child, text: content };
        }

        return child;
      });

      return { ...item, children: updatedChildren };
    }

    return item;
  });
}

function replaceCustomSupplyContent(terms: SanityRichText[], sanitySettings: SanityTermsAndConditions, settings: PartnerSettings): SanityRichText[] {
  const lang = i18next.language;
  const supplyTextVersion = settings?.termsAndConditionsSupplyParagraphs;
  if (!supplyTextVersion || !sanitySettings[supplyTextVersion as keyof SanityTermsAndConditions]) {
    return terms;
  }
  const parsedSupplyText = sanitySettings[supplyTextVersion as keyof SanityTermsAndConditions] as SanityRichTextObject & {
    [lang: string]: SanityRichText[];
  };
  let supplyText = parsedSupplyText[lang] ? parsedSupplyText[lang] : parsedSupplyText.en;
  supplyText = resolveConditionalBlock(supplyText, settings);
  supplyText = injectVariablesInRichText(supplyText, settings);

  const content = terms.map(item => {
    let isSupplyText = false;
    if (item.children) {
      return {
        ...item,
        children: item.children.map(child => {
          if (child.text && child.marks?.includes('supplyText')) {
            isSupplyText = true;

            return { ...child, text: '' };
          }

          return child;
        }),
        supplyText: isSupplyText,
      };
    }

    return item;
  });

  const supplyTextIndex = (content as (SanityRichText & { supplyText: boolean })[]).findIndex(item => item.supplyText);
  if (content[supplyTextIndex]) {
    const supplyTextChildIndex = content[supplyTextIndex].children?.findIndex(child => child.marks?.includes('supplyText'));
    const supplyTextFirstChild = content[supplyTextIndex].children;
    if (Array.isArray(supplyTextFirstChild)) {
      content[supplyTextIndex].children = [
        ...supplyTextFirstChild.slice(0, supplyTextChildIndex),
        ...supplyText,
        ...supplyTextFirstChild.slice(supplyTextChildIndex),
      ];
    }
  }

  return content;
}

function replaceRefundContent(terms: SanityRichText[], sanitySettings: SanityTermsAndConditions, settings: PartnerSettings): SanityRichText[] {
  const lang = i18next.language;
  const isRefundPolicySet = !!settings?.refundPolicy;
  const isRefundAllowed = settings?.refundPolicy?.allowed;
  const isRefundAllowedText = isRefundAllowed ? sanitySettings.refundYesText : sanitySettings.refundYesText;
  if (!isRefundPolicySet || !isRefundAllowedText) {
    return terms;
  }
  let refundText = isRefundAllowedText[lang] ? isRefundAllowedText[lang] : isRefundAllowedText.en;
  refundText = resolveConditionalBlock(refundText, settings);
  refundText = injectVariablesInRichText(refundText, settings);

  const content = terms.map(item => {
    let isRefundText = false;
    if (item.children) {
      return {
        ...item,
        children: item.children.map(child => {
          if (child.text && child.marks?.includes('refundText')) {
            isRefundText = true;

            return { ...child, text: '' };
          }

          return child;
        }),
        refundText: isRefundText,
      };
    }

    return item;
  });

  const refundTextIndex = (content as (SanityRichText & { refundText: boolean })[]).findIndex(item => item.refundText);
  if (content[refundTextIndex]) {
    const refundTextChildIndex = content[refundTextIndex].children?.findIndex(child => child.marks?.includes('refundText'));
    const refundTextFirstChild = content[refundTextIndex].children;
    if (Array.isArray(refundTextFirstChild)) {
      content[refundTextIndex].children = [
        ...refundTextFirstChild.slice(0, refundTextChildIndex),
        ...refundText,
        ...refundTextFirstChild.slice(refundTextChildIndex),
      ];
    }
  }

  return content;
}

export const replaceCustomContents = (terms: SanityRichText[], sanitySettings: SanityTermsAndConditions, settings: PartnerSettings) => {
  let content = terms;
  content = resolveConditionalBlock(content, settings);
  content = replaceCustomSupplyContent(content, sanitySettings, settings);
  content = replaceRefundContent(content, sanitySettings, settings);
  content = injectVariablesInRichText(content, settings);

  return content;
};

export const preparePartnerText = (terms: SanityRichText[], settings: PartnerSettings) => {
  let content = terms;
  content = resolveConditionalBlock(content, settings);
  content = injectVariablesInRichText(content, settings);

  return content;
};
