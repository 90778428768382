import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';

// This hook accepts local flag which will in most cases be "true" / "false" or undefined
export const useLoadedFeatureFlag = (flag: string, localFlag?: string) => {
  const client = usePostHog();
  const [isFeatureFlagLoaded, setIsFeatureFlagLoaded] = useState<boolean>(false);
  const [isFeatureFlagEnabled, setIsFeatureFlagEnabled] = useState<boolean>(false);

  useEffect(() => {
    // If local flag is defined, use it and ignore PostHog
    if (localFlag !== undefined) {
      setIsFeatureFlagLoaded(true);
      setIsFeatureFlagEnabled(localFlag === 'true');

      return;
    }
    // If PostHog client is not loaded, fallback to defaults
    if (!client.__loaded) {
      setIsFeatureFlagLoaded(true);

      return;
    }

    // Set latest current url property that can be used for enabling/disabling feature flags
    client.setPersonPropertiesForFlags({ $current_url: window.location.href });

    // Otherwise, ask PostHog for the feature flag value
    client.onFeatureFlags(() => {
      // When feature flag is undefined, fallback to false
      const isEnabled = !!client.isFeatureEnabled(flag);
      setIsFeatureFlagEnabled(isEnabled);
      setIsFeatureFlagLoaded(true);
    });
  }, [flag, localFlag]);

  return { isFeatureFlagLoaded, isFeatureFlagEnabled };
};
