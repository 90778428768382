import client from './client';
import { ISanityPortfolio, ISanityProject } from './project.types';
const projectFragment = `
{
  _id,
  ...,
  mainImage{
    ...,
    asset->{url}
  },
  additionalImages[]{
    ...,
    alt,
    asset->{url}
  },
  certifiedBy[]->{
    title->{textContent},
    description->{textContent},
    logo{
      asset->{url}
    }
  },
  sdgs[]{
    affect,
    sdg->{
      ...,
      backgroundImage{
        asset->{url}
      },
      icon{
        asset->{url}
      },
      localizedIcon{
        en{
          asset->{url},
          alt
        }
      }
    }
  },
  supplyType,
  lcfType,
  projectTheme->{
    ...,
  category-> {
    ...
  }
},
}`;

export const getProjectBySlug = async (slug: string): Promise<ISanityProject> => {
  const query = `*[slug.current == "${slug}"][0]${projectFragment}`;
  const data = await client.fetch<ISanityProject>(query);

  return data;
};

export const getPortfolioBySlug = async (slug: string): Promise<ISanityPortfolio> => {
  const query = `*[_type == 'portfolio' && slug.current == "${slug}"][0]{
    _id,
  ...,
    title->{textContent},
    subtitle->{textContent},
    description->{textContent},
    portfolioSegment->{
      title
    },
    pricePerImpactUnit,
    currency,
    portfolioTheme->{title},
    mainImage{
      ...,
      asset->{url, alt}
    },
    vatByCountry,
    'projects':projects[]->${projectFragment}
  }`;

  const data = await client.fetch<ISanityPortfolio>(query);

  return data;
};

export const getPortfoliosFromIds = async (ids: string[]): Promise<ISanityPortfolio[]> => {
  const query = `*[_type == 'portfolio' && _id in [${ids.filter(item => item !== null).map(item => `"${item}"`)}]]{
    _id,
  ...,
    title->{textContent},
    subtitle->{textContent},
    description->{textContent},
    portfolioSegment->{
      title
    },
    pricePerImpactUnit,
    currency,
    portfolioTheme->{title},
    mainImage{
      ...,
      asset->{url, alt}
    },
    vatByCountry,
    'projects':projects[]->${projectFragment}
  }`;

  const data = await client.fetch<ISanityPortfolio[]>(query);

  return data;
};

export const getAllProjects = async (): Promise<SanityProject[]> => {
  const query = `*[_type == 'project']${projectFragment}`;

  const data = await client.fetch<SanityProject[]>(query);

  return data;
};

export const getProjectsFromIds = async (ids: string[]): Promise<ISanityProject[]> => {
  const query = `*[_type == 'project' && _id in [${ids.filter(item => item !== null).map(item => `"${item}"`)}]]${projectFragment}`;

  const data = await client.fetch<ISanityProject[]>(query);

  return data;
};
