import { useState } from 'react';
import { OptionType } from '@chooose/ui';
import { keepPreviousData, QueryFunctionContext, useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';
import { addDays } from 'date-fns';
import { useCustomer } from '@api/customer/queries';
import { Paginated, PaginationParams } from '@api/shared/types';
import { useCurrentCustomerAccount } from '@api/user/queries';
import { HALF_MINUTE, ITEMS_LIMIT_PER_PAGE, ONE_HOUR, ONE_MINUTE } from '@utils/constants';
import { ParseTypeOfKeys } from '@utils/Order';
import { FILTER_PROPERTIES, toOptionType } from '@utils/PortfolioFiltersData';
import { client } from '@utils/reactQuery';

import type { EmissionsFiltersGetParams } from './api';
import * as api from './api';
import { Order, OrdersParams, PortfolioFiltersData, Quote, SupplyFilter } from './types';

export const useOrder = (id: string, isEnabled: boolean = true, staleTime: number = HALF_MINUTE) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['orders', id],
    queryFn: () =>
      api.getOrder(id, customerId, {
        includeStats: true,
        includeAttachments: true,
        includeVirtualPortfolio: true,
      }),
    staleTime,
    refetchInterval: query => {
      switch (query.state.data?.supplyStatus) {
        case undefined:
        case null:
        case 'Unassigned':
          return 5000;
        case 'Reserved':
          return ONE_MINUTE;
        default:
          return staleTime;
      }
    },
    enabled: customerId !== 'default' && isEnabled,
  });
};

export type UseOrdersSelector<R> = (data: Paginated<Order>) => R;

export const useOrders = <R>(
  params: ParseTypeOfKeys<Omit<OrdersParams, keyof PaginationParams>>,
  select?: UseOrdersSelector<R>,
  staleTime?: number,
) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';
  const { page, category, ...rest } = params;

  return useQuery({
    queryKey: ['orders', 'history', customerId, category, page, Object.values(rest)],
    queryFn: () => {
      const offset = page ? (page - 1) * ITEMS_LIMIT_PER_PAGE : 0;

      const apiParams: OrdersParams = {
        ...rest,
        limit: ITEMS_LIMIT_PER_PAGE,
        offset,
      };
      if (category === 'CORSIA') {
        apiParams.complianceAgreementType = category;
      } else {
        apiParams.category = category;
      }

      return api.getOrders(apiParams, customerId);
    },
    placeholderData: keepPreviousData,
    enabled: customerId !== 'default',
    staleTime: staleTime ?? ONE_HOUR,
    select,
  });
};

// TODO: define the type of the response
type EmissionsResponse = any;

export const useEmissions = ({ page = '1', type, ...filters }: ParseTypeOfKeys<EmissionsResponse>) => {
  const [prevType, setPrevType] = useState(type);
  const [prevFilters, setPrevFilters] = useState(filters);
  const [queryPage, setQueryPage] = useState(page);
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  if (type !== prevType) {
    setPrevType(type);
    setQueryPage('1');
  }

  if (JSON.stringify(filters) !== JSON.stringify(prevFilters)) {
    setPrevFilters(filters);
    setQueryPage('1');
  }

  if (page !== queryPage) {
    setQueryPage(page);
  }

  const keys = Object.values(filters || []).filter(el => el);
  const { fromDate, toDate, ...restFilters } = filters;
  const parsedFilters = Object.fromEntries(
    Object.entries(restFilters).map(el => {
      if (Array.isArray(el[1])) {
        return [el[0], el[1].join(',')];
      } else {
        return el;
      }
    }),
  );

  const {
    data: emissionsData,
    isLoading,
    isFetching,
    isRefetching,
    isFetched,
  } = useQuery({
    queryKey: ['orders', 'footprint', queryPage, type, ...keys],
    queryFn: () =>
      api.getFootprintsForEmissionsOrder(
        {
          grouped: false,
          limit: ITEMS_LIMIT_PER_PAGE,
          offset: (parseInt(queryPage, 10 ?? '1') - 1) * ITEMS_LIMIT_PER_PAGE,
          type,
          ...(fromDate ? { fromDate: new Date(fromDate).toISOString() } : undefined),
          ...(toDate ? { toDate: addOneDayToDate(toDate).toISOString() } : undefined),
          ...parsedFilters,
        },
        customerId,
      ),
    staleTime: ONE_HOUR * 5,
    placeholderData: keepPreviousData,
  });

  const { data: facilitatedOrdersData, isLoading: facilitatedOrdersIsLoading } = useQuery({
    queryKey: ['orders', 'footprint', 'facilitated'],
    queryFn: () => api.getAll({ 'payment.status': 'Draft,Open,Paid,Uncollectible,Void', offset: 0, limit: 1, isfacilitated: true }, customerId),
    staleTime: ONE_HOUR,
    select: (data): boolean => data.totalCount > 0,
  });

  const facilitatedOrders = !!facilitatedOrdersData;

  return {
    data: emissionsData,
    hasAwaitingFacilitatedOrders: facilitatedOrders,
    isLoading: !emissionsData && (isLoading || isFetching || isRefetching || facilitatedOrdersIsLoading),
    isLoadingFootprints: isLoading || isFetching || isRefetching,
    isFetched,
  };
};

const addOneDayToDate = (dateString: string) => {
  // We need it for the BE to return the items we expect

  const date = new Date(dateString);
  const dayLater = addDays(date, 1);

  return dayLater;
};

export const useAllOrdersOfType = (type: string) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['orders', type],
    queryFn: () => api.getAll({ category: type, offset: 0, limit: 1000 }, customerId),
    staleTime: HALF_MINUTE,
    refetchInterval: HALF_MINUTE,
  });
};

export const useOrderFootprint = (id: string) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['orders', 'footprint', id],
    queryFn: () => api.getFootprint(id, customerId),
    staleTime: ONE_HOUR,
  });
};

export const invalidateLegacyEmissionsFilters = async () => {
  await client.invalidateQueries({ queryKey: ['orders', 'footprint', 'filters', 'legacy'] });
};

export type UseEmissionFilterQueryParams = {
  type: EmissionsType;
  page: `${number}`;
};
export const emissionsTypes = ['Flight', 'AirFreight'] as const;
type EmissionsType = (typeof emissionsTypes)[number];

export const useEmissionFilter = (filterName: string, params: UseEmissionFilterQueryParams) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useInfiniteQuery({
    queryKey: ['orders', 'footprint', 'filters', 'infiniteQuery', filterName, params],
    queryFn: ({ pageParam, signal }) => {
      // TODO: revise this limit once we're ready to use infinite scroll with search
      const limitPerPage = 1000;

      return api.getEmissionsFilter(filterName, customerId, signal, { ...params, limit: limitPerPage, offset: pageParam });
    },
    initialPageParam: 0,
    staleTime: ONE_HOUR,
    getNextPageParam: (lastPage, pages) => (lastPage.totalCount > pages.length * 10 ? (pages.length + 1) * 10 : undefined),
    select: data => ({
      ...data,
      pages: data.pages.map(page => page.items).flat(),
    }),
  });
};

export const useEmissionsFilters = (params: EmissionsFiltersGetParams) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useQuery({
    queryKey: ['orders', 'footprint', 'filters'],
    queryFn: () => {
      return api.getEmissionsFilters(customerId, params);
    },
    staleTime: ONE_HOUR,
  });
};

export const useLegacyEmissionsFilters = (propertyNames: string[]) => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useQueries({
    queries: propertyNames.map(propertyName => {
      return {
        queryKey: ['orders', 'footprint', 'filters', 'legacy', propertyName],
        // Missing typings is a bug inside @tanstack/query itself
        queryFn: ({ signal }: { signal: QueryFunctionContext['signal'] }) => api.getLegacyEmissionsFilter(propertyName, customerId, signal),
        staleTime: ONE_HOUR,
        select: (data: string[]): [el: string, data: string[]] => [propertyName, data],
      };
    }),
    combine: results => {
      return {
        data: Object.fromEntries(results.filter(r => !!r.data).map(result => result.data!)),
        isSomeFilterLoading: results.some(result => result.isLoading),
      };
    },
  });
};

export const usePortfolioFilters = (
  portfolioId: string,
  propertyNames: typeof FILTER_PROPERTIES,
): {
  data: PortfolioFiltersData;
  isLoading: boolean;
} => {
  const { account } = useCurrentCustomerAccount();
  const customerId = account?.customerId ?? 'default';

  return useQueries({
    queries: propertyNames.map(propertyName => ({
      queryKey: ['safFilters', propertyName],
      queryFn: () => api.getPortfolioFilters(propertyName, portfolioId, customerId),
      staleTime: ONE_HOUR,
      select: (data: string[]): [el: string, options: OptionType[]] => [propertyName, toOptionType(data)],
    })),
    combine: results => {
      return {
        data: Object.fromEntries(results.filter(r => !!r.data).map(result => result.data!)) as PortfolioFiltersData,
        isLoading: results.some(result => result.isLoading),
      };
    },
  });
};

export const useQuote = (queryKey: string[]) => {
  return useQuery<Quote>({
    queryKey,
  });
};

type JobParams = {
  partnershipId?: string;
  jobId?: string;
};
export const useJob = (params: JobParams) => {
  return useQuery({
    queryKey: ['jobs', params.jobId],
    queryFn: ({ signal }) => api.getJob(params.partnershipId ?? '', params.jobId ?? '', signal),
    enabled: !!params.jobId && !!params.partnershipId,
    staleTime: 1000,
    refetchInterval: query => {
      const data = query.state.data;
      if (data?.status === 'Created' || data?.status === 'Started') {
        return 1000;
      }

      return false;
    },
  });
};

const toSearchParams = (filter?: SupplyFilter, baseSearchParams?: URLSearchParams) => {
  if (!filter) {
    return;
  }

  const searchParams = new URLSearchParams(baseSearchParams ?? '');
  for (const key in filter) {
    const filterValue = filter[key as keyof SupplyFilter];
    if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
      searchParams.append(key, filterValue.join(','));
    }
  }

  return searchParams.toString();
};

export const useCustomerPortfolio = (portfolioId: string, filter?: SupplyFilter, portfolioFilters?: URLSearchParams) => {
  const { data: customer } = useCustomer();

  const search = toSearchParams(filter, portfolioFilters);

  const customerId = customer?.id ?? 'default';

  return useQuery({
    queryKey: ['compensate', 'customers', customerId, 'portfolios', portfolioId].concat(search ? [search] : []),
    queryFn: () => api.getCustomerPortfolio(customerId, portfolioId, search),
    staleTime: ONE_HOUR,
    enabled: customerId !== 'default',
  });
};
