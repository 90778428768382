import axios, { CancelTokenSource } from 'axios';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createFootprintFlightApi from '@api/flightFootprint';
import createFlightApi from '@api/flightOffset';
import { AppThunk, RootState } from '@store';
import { appContextSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { currentUserSelector, customMetadataSelector } from '@store/user/userSlice';
import checkJob from '@utils/checkJob';
import { fixFloat } from '@utils/numberFormatter';
import { getSessionStorageObject, setSessionStorageObject } from '@utils/sessionStorage';

import { DataProps } from './types';

const CancelToken = axios.CancelToken;
let source: CancelTokenSource = CancelToken.source();

// region types
interface CalculateFromToFootprintParams {
  from: AirportOption;
  to: AirportOption;
  via?: AirportOption;
  travelClass: TravelClass | undefined;
  travellers: number;
  flights: number;
  roundTrip: boolean;
}

interface FootprintFromTo {
  amount?: number;
  totalKilosCo2?: number;
  distance?: number;
  distanceFromVia?: number;
  distanceViaTo?: number;
  flightPax: number;
}

interface ByDistanceFlightUpdateValues {
  flightDistance: number;
  travelClass: Option;
  roundTrip: boolean;
  travellers: number;
  kilosCo2: number;
  flightPax: number;
}
interface FootprintInfo {
  emissionName?: string;
  country?: Option;
  department?: string;
  description?: string;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  [key: string]: string | Option | Date | undefined | boolean | number;
}

export interface CustomMetadata {
  [key: string]: string | Option | Date | number | undefined | boolean | string[] | ByDistanceFlight[] | PartnersCustomerOption | File | Deduction[];
}

export interface FlightFootprintState extends CustomMetadata {
  mode?: FlightOffsetMode;
  amount?: number;
  totalKilosCo2?: number;
  from?: AirportOption;
  to?: AirportOption;
  via?: AirportOption;
  travellers?: number;
  travelClass?: { label: string; value: TravelClass };
  flights?: number;
  roundTrip?: boolean;
  flightPax?: number;
  distance?: number;
  distanceFromVia?: number;
  distanceViaTo?: number;
  isLoading: boolean;
  emissionName?: string;
  country?: Option;
  department?: string;
  description?: string;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  timeFrameMonth?: Option;
  timeFrameMode: TimeFrameMode;
  isFootprintInfoInvalid: boolean;
  isTimeFrameInfoInvalid: boolean;
  selectedCustomMetadata?: string[];
  byDistanceFlights: ByDistanceFlight[];
  fileUploading?: boolean;
  fileError?: boolean;
  storageBlobName?: string;
  fileErrors?: string[];
  fileSuccess?: boolean;
  customer?: PartnersCustomerOption;
  claimCode?: string;
  inviteId?: string;
  footprintsCount?: number;
  fullOffsetRows?: undefined;
  isUploadingFileCancelled?: boolean;
  fileProcessing?: boolean;
  progressPercentage?: number;
  deductions?: Deduction[];
  undeductedTotalKilosCo2?: number;
  isCreatingFootprint?: boolean;
}

const initialState: FlightFootprintState = {
  isLoading: false,
  roundTrip: false,
  byDistanceFlights: [],
  isFootprintInfoInvalid: true,
  isTimeFrameInfoInvalid: true,
  timeFrameMode: 'month',
  emissionName: '',
  description: '',
};

const tonnesFactor = 1000;

const slice = createSlice({
  name: 'flightFootprint',
  initialState,
  reducers: {
    reset: (state: FlightFootprintState): FlightFootprintState => {
      return { mode: state.mode, ...initialState };
    },
    resetAmount: (state: FlightFootprintState): FlightFootprintState => {
      return { ...state, amount: 0, totalKilosCo2: 0 };
    },
    setLoading: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.isLoading = action.payload;

      return state;
    },
    setFootprintInfo: (state: FlightFootprintState, action: PayloadAction<FootprintInfo>): FlightFootprintState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setFootprintInfoInvalid: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.isFootprintInfoInvalid = action.payload;

      return state;
    },
    setTimeFrameInfoInvalid: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.isTimeFrameInfoInvalid = action.payload;

      return state;
    },
    setMode: (state: FlightFootprintState, action: PayloadAction<FlightOffsetMode>): FlightFootprintState => {
      state.mode = action.payload;

      return state;
    },
    setSelectedCustomMetadata: (state: FlightFootprintState, action: PayloadAction<string[]>): FlightFootprintState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    setOffsetFootprint: (state: FlightFootprintState, action: PayloadAction<FootprintFromTo>): FlightFootprintState => {
      return { ...state, ...action.payload };
    },
    addByDistanceFlightRow: (state: FlightFootprintState, action: PayloadAction<ByDistanceFlight>): FlightFootprintState => {
      state.byDistanceFlights.push(action.payload);

      return state;
    },
    removeByDistanceFlightRow: (state: FlightFootprintState, action: PayloadAction<number>): FlightFootprintState => {
      const filteredFlights = state.byDistanceFlights.filter((_, i) => i !== action.payload);
      state.amount = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2 / tonnesFactor, 0);
      state.totalKilosCo2 = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2, 0);
      state.byDistanceFlights = filteredFlights;

      return state;
    },
    setByDistanceFlightLength: (
      state: FlightFootprintState,
      action: PayloadAction<{ index: number; value: FlightLengthOption }>,
    ): FlightFootprintState => {
      state.byDistanceFlights[action.payload.index].flightLength = action.payload.value;

      return state;
    },
    setByDistanceFlight: (
      state: FlightFootprintState,
      action: PayloadAction<{ index: number; value: ByDistanceFlightUpdateValues }>,
    ): FlightFootprintState => {
      state.byDistanceFlights[action.payload.index] = { ...state.byDistanceFlights[action.payload.index], ...action.payload.value };

      return state;
    },
    setByDistanceTotalAmount: (
      state: FlightFootprintState,
      action: PayloadAction<{ amount: number; kilosCo2: number; index?: number }>,
    ): FlightFootprintState => {
      if (typeof action.payload.index === 'number') {
        const filteredFlights = state.byDistanceFlights.filter((_, i) => i !== action.payload.index);
        state.amount = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2 / tonnesFactor, action.payload.amount);
        state.totalKilosCo2 = filteredFlights.reduce((prev, curr) => prev + curr.kilosCo2, action.payload.kilosCo2);
      } else {
        const previousAmount = state.amount ?? 0;
        const previousKilosCo2 = state.totalKilosCo2 ?? 0;
        state.amount = previousAmount + action.payload.amount;
        state.totalKilosCo2 = previousKilosCo2 + action.payload.kilosCo2;
      }

      return state;
    },
    resetUploadState: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.fileUploading = action.payload;
      state.fileProcessing = action.payload;
      state.fileError = action.payload;
      state.progressPercentage = 0;
      state.isUploadingFileCancelled = true;
      state.fileErrors = [];
      state.fileSuccess = false;
      state.amount = undefined;
      state.totalKilosCo2 = undefined;
      state.footprintsCount = undefined;
      state.deductions = undefined;
      state.undeductedTotalKilosCo2 = undefined;

      return state;
    },
    resetUploadingFileCancelled: (state: FlightFootprintState): FlightFootprintState => {
      state.isUploadingFileCancelled = false;

      return state;
    },
    setFileUploading: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.fileUploading = action.payload;

      return state;
    },
    setFootprintsCount: (state: FlightFootprintState, action: PayloadAction<number>): FlightFootprintState => {
      state.footprintsCount = action.payload;

      return state;
    },
    setFileErrors: (state: FlightFootprintState, action: PayloadAction<string[]>): FlightFootprintState => {
      state.fileErrors = action.payload;

      return state;
    },
    setFileSuccess: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.fileSuccess = action.payload;

      return state;
    },
    setUploadTotalAmount: (state: FlightFootprintState, action: PayloadAction<{ amount: number; kilosCo2: number }>): FlightFootprintState => {
      state.amount = action.payload.amount;
      state.totalKilosCo2 = action.payload.kilosCo2;

      return state;
    },
    setProgressPercentage: (state: FlightFootprintState, action: PayloadAction<number>): FlightFootprintState => {
      state.progressPercentage = action.payload;

      return state;
    },
    setFileProcessing: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.fileProcessing = action.payload;

      return state;
    },
    setFileError: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.fileError = action.payload;

      return state;
    },
    setStorageBlobName: (state: FlightFootprintState, action: PayloadAction<string>): FlightFootprintState => {
      state.storageBlobName = action.payload;

      return state;
    },
    setUndeductedTotalKilosCo2: (state: FlightFootprintState, action: PayloadAction<number | undefined>): FlightFootprintState => {
      state.undeductedTotalKilosCo2 = action.payload;

      return state;
    },
    setDeductions: (state: FlightFootprintState, action: PayloadAction<Deduction[] | undefined>): FlightFootprintState => {
      state.deductions = action.payload;

      return state;
    },
    setIsCreatingFootprint: (state: FlightFootprintState, action: PayloadAction<boolean>): FlightFootprintState => {
      state.isCreatingFootprint = action.payload;

      return state;
    },
  },
});

const {
  reset,
  setFootprintInfo,
  setLoading,
  resetAmount,
  setFootprintInfoInvalid,
  setTimeFrameInfoInvalid,
  setSelectedCustomMetadata,
  setMode,
  setOffsetFootprint,
  addByDistanceFlightRow,
  removeByDistanceFlightRow,
  setByDistanceFlightLength,
  setByDistanceTotalAmount,
  setByDistanceFlight,
  resetUploadState,
  setFileUploading,
  setFileError,
  setFileErrors,
  setFileSuccess,
  setUploadTotalAmount,
  setFootprintsCount,
  resetUploadingFileCancelled,
  setProgressPercentage,
  setFileProcessing,
  setStorageBlobName,
  setUndeductedTotalKilosCo2,
  setDeductions,
  setIsCreatingFootprint,
} = slice.actions;

export {
  removeByDistanceFlightRow,
  reset,
  resetUploadState,
  setByDistanceFlightLength,
  setByDistanceTotalAmount,
  setFootprintInfo,
  setFootprintInfoInvalid,
  setMode,
  setSelectedCustomMetadata,
  setTimeFrameInfoInvalid,
};
export default slice.reducer;

export const calculateFromToOffset =
  (params: CalculateFromToFootprintParams): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const currentUser = currentUserSelector(getState());
    const { customer } = getState().flightFootprint;
    const flightOffsetApi = createFlightApi(context);
    const { from, to, via, travelClass, travellers, flights, roundTrip } = params;

    if (!from || !to || !travelClass || !travellers || !flights) {
      return;
    }

    dispatch(setLoading(true));

    let offset;
    let partialOffsetFromVia;
    let partialOffsetViaTo;

    try {
      if (via) {
        partialOffsetFromVia = await flightOffsetApi.getFlightFootprint({
          ...params,
          from: from.value,
          to: via.value,
          customerId: currentUser?.customerId,
          calculateForCustomer: customer?.value,
        });
        partialOffsetViaTo = await flightOffsetApi.getFlightFootprint({
          ...params,
          from: via.value,
          to: to.value,
          customerId: currentUser?.customerId,
          calculateForCustomer: customer?.value,
        });

        offset = {
          kilosCo2e:
            partialOffsetFromVia.kilosCo2e && partialOffsetViaTo.kilosCo2e
              ? partialOffsetFromVia?.kilosCo2e + partialOffsetViaTo?.kilosCo2e
              : undefined,
          kilosCo2: partialOffsetFromVia.kilosCo2 + partialOffsetViaTo.kilosCo2,
          distanceKm: partialOffsetFromVia.distanceKm + partialOffsetViaTo.distanceKm,
        };
      } else {
        offset = await flightOffsetApi.getFlightFootprint({
          travellers,
          flights,
          roundTrip,
          travelClass,
          from: from.value,
          to: to.value,
          customerId: currentUser?.customerId,
          calculateForCustomer: customer?.value,
        });
      }

      let multiplicate = roundTrip ? 2 : 1;
      const kilosCo2 = offset.kilosCo2e ?? offset.kilosCo2;

      if (kilosCo2 < 0) {
        dispatch(resetAmount());
        dispatch(setLoading(true));

        return;
      }

      multiplicate = via ? multiplicate * 2 : multiplicate;
      const flightPax = multiplicate * Number(params.flights || 1) * Number(params.travellers ?? 1);

      dispatch(
        setOffsetFootprint({
          amount: kilosCo2 / tonnesFactor,
          totalKilosCo2: kilosCo2,
          distance: offset.distanceKm,
          distanceFromVia: partialOffsetFromVia?.distanceKm,
          distanceViaTo: partialOffsetViaTo?.distanceKm,
          flightPax,
        }),
      );
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchAmountError' }));
      dispatch(setLoading(false));
    }
  };

const checkFootprint =
  (jobId: string, partnershipId: string, errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const { mode, storageBlobName, emissionName, description } = getState().flightFootprint;
    const isUploadMode = mode === 'upload';

    dispatch(setLoading(true));
    if (isUploadMode) {
      try {
        dispatch(setIsCreatingFootprint(true));
        if (storageBlobName && emissionName && description) {
          const result = await checkJob<FlightsFootprintProcessingFileSummary>({
            partnershipId,
            jobId,
            context,
            module: 'flightFootprint',
            setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
            setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
            setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
            setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
          });
          const errorMessages = result?.messages?.filter(({ type }) => type.toLowerCase() !== 'information')?.map(m => m.message);
          if (result.status === 'Succeeded' && errorMessages.length === 0) {
            successCb && successCb();
          } else {
            dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
            if (Array.isArray(errorMessages)) {
              dispatch(setFileErrors(errorMessages));
            }
            errorCb && errorCb();
          }
        }
        dispatch(setIsCreatingFootprint(false));
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setIsCreatingFootprint(false));
        dispatch(setLoading(false));
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
        errorCb && errorCb();
      }
    }
  };

export const handleCheckJob =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = getSessionStorageObject<DataProps>('flightFootprint');

    if (data) {
      dispatch(setMode('upload'));
      if (data.storageBlobName && data.totalKilosCo2 && data.amount && data.footprintsCount) {
        dispatch(setFootprintInfo({ emissionName: data.emissionName, description: data.description }));
        dispatch(resetUploadingFileCancelled());
        dispatch(setFootprintsCount(data.footprintsCount));
        dispatch(setStorageBlobName(data.storageBlobName));
        dispatch(setFileErrors([]));
        dispatch(setFileSuccess(true));
        dispatch(setFileUploading(true));
        dispatch(setUploadTotalAmount({ amount: data.amount, kilosCo2: data.totalKilosCo2 }));
        if (Array.isArray(data.deductions) && data.deductions.length > 0) {
          dispatch(setDeductions(data.deductions));
          dispatch(setUndeductedTotalKilosCo2(data.undeductedTotalKilosCo2));
        }
      }
      try {
        dispatch(checkFootprint(data.jobId, data.partnershipId, errorCb, successCb));
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    }
  };

export const createFlightFootprint =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const flightAPI = createFootprintFlightApi(context);
    const app = getState().app;
    const currentUser = currentUserSelector(getState());
    const customMetadata = customMetadataSelector(getState()) ?? [];
    const { mode, storageBlobName, emissionName, description, ...restProps } = getState().flightFootprint;
    const isUploadMode = mode === 'upload';
    const isConnectContext = context === 'connect';
    const partnershipId = isConnectContext ? currentUser?.partnershipId ?? '' : app.partnershipId ?? '';
    const customerId = currentUser?.customerId ?? '';

    const customMetadataPayload: CustomMetadata = {};
    customMetadata?.map(
      ({ internalName }) =>
        // @ts-ignore
        (customMetadataPayload[internalName] = restProps[internalName]?.value ? restProps[internalName]?.value : restProps[internalName]),
    );

    dispatch(setLoading(true));
    if (isUploadMode) {
      try {
        dispatch(setIsCreatingFootprint(true));
        source = CancelToken.source();
        if (storageBlobName && emissionName && description) {
          const payload = {
            storageBlobName,
            emissionName,
            description,
            ...customMetadataPayload,
          };
          const response = isConnectContext
            ? await flightAPI.createBatchOrderForPartner(payload, source.token, partnershipId)
            : await flightAPI.createBatchOrderForCustomer(payload, source.token, customerId);

          setSessionStorageObject('flightFootprint', {
            storageBlobName,
            jobId: response.jobId,
            partnershipId: response.partnershipId,
            footprintsCount: restProps.footprintsCount,
            amount: restProps.amount,
            totalKilosCo2: restProps.totalKilosCo2,
            deductions: restProps.deductions,
            undeductedTotalKilosCo2: restProps.undeductedTotalKilosCo2,
            emissionName,
            description,
          });
          dispatch(checkFootprint(response.jobId, response.partnershipId, errorCb, successCb));
        }
        dispatch(setIsCreatingFootprint(false));
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setIsCreatingFootprint(false));
        dispatch(setLoading(false));
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
        errorCb && errorCb();
      }
    }
  };

export const addNewByDistanceFlightItem =
  (onSuccess?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading(true));
    const context = appContextSelector(getState());
    const currentUser = currentUserSelector(getState());
    const { customer } = getState().flightFootprint;
    const flightOffsetApi = createFlightApi(context);

    const item: ByDistanceFlight = {
      flightLength: {
        id: '1',
        value: 'short',
        label: 'Short (< 2h)',
        distance: 1500,
      },
      flightDistance: 1500,
      travelClass: { value: 'economy', label: 'Economy' },
      roundTrip: false,
      travellers: 1,
      kilosCo2: 0,
      flightPax: 1,
      footprintId: '',
    };

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers: item.travellers,
        distance: item.flightDistance,
        travelClass: 'economy' as TravelClass,
        roundTrip: item.roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;
      item.kilosCo2 = kilosCo2;
      item.footprintId = result.id;

      dispatch(setByDistanceTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
      dispatch(addByDistanceFlightRow(item));
      onSuccess && onSuccess(item);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const updateByDistanceFlight =
  (props: { index: number; value: number | Option | boolean; name: string }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { index, value, name } = props;
    dispatch(setLoading(true));
    const context = appContextSelector(getState());
    const flightOffsetApi = createFlightApi(context);
    const currentUser = currentUserSelector(getState());
    const { byDistanceFlights, customer } = getState().flightFootprint;
    const item = byDistanceFlights[index];

    const travellers = name === 'travellers' ? (value as number) : item.travellers;
    const roundTrip = name === 'roundTrip' ? (value as boolean) : item.roundTrip;
    const flightDistance = name === 'flightDistance' ? (value as number) : item.flightDistance;
    const travelClass = name === 'travelClass' ? (value as Option) : item.travelClass;

    try {
      const result = await flightOffsetApi.getFlightFootprint({
        travellers,
        distance: flightDistance,
        travelClass: travelClass.value as TravelClass,
        roundTrip,
        customerId: currentUser?.customerId,
        calculateForCustomer: customer?.value,
      });

      const kilosCo2 = result.kilosCo2e ?? result.kilosCo2;
      const flightPax = Number(travellers ?? 1) * (roundTrip ? 2 : 1);

      dispatch(setByDistanceTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2, index }));
      dispatch(
        setByDistanceFlight({
          index,
          value: { travellers, flightDistance, travelClass, roundTrip, kilosCo2, flightPax },
        }),
      );
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const processFile =
  (file: File, onError: () => void): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setFileSuccess(false));
    dispatch(setFileProcessing(true));
    dispatch(setUploadTotalAmount({ amount: 0, kilosCo2: 0 }));
    dispatch(resetUploadingFileCancelled());
    dispatch(setDeductions(undefined));
    dispatch(setUndeductedTotalKilosCo2(undefined));
    const app = getState().app;
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const isConnectContext = context === 'connect';
    const flightAPI = createFootprintFlightApi(context);
    const partnershipId = isConnectContext ? currentUser?.partnershipId ?? '' : app.partnershipId ?? '';
    const customerId = currentUser?.customerId ?? '';

    try {
      source = CancelToken.source();
      const batchResponse = isConnectContext
        ? await flightAPI.uploadFootprintBatchForPartner(file, source.token, partnershipId)
        : await flightAPI.uploadFootprintBatchForCustomer(file, source.token, customerId);

      if (batchResponse.partnershipId && batchResponse.jobId) {
        const response = await checkJob<FlightsFootprintProcessingFileSummary>({
          partnershipId: batchResponse.partnershipId,
          jobId: batchResponse.jobId,
          context,
          module: 'flightFootprint',
          setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
          setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
          setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
          setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
        });

        const summary = response?.summary?.footprints;
        const footprintsCount = summary?.count;
        const storageBlobName = summary?.storageBlobName;
        const kilosCo2 = summary?.totalCo2Kilos;
        const deductions = summary?.deductions;
        if (storageBlobName && footprintsCount && (kilosCo2 || deductions?.every(({ type }) => type === 'AlreadyOffset'))) {
          dispatch(setFootprintsCount(footprintsCount));
          dispatch(setStorageBlobName(storageBlobName));
          dispatch(setFileErrors([]));
          dispatch(setFileSuccess(true));
          const kilosCo2 = summary?.totalCo2Kilos;
          dispatch(setUploadTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
          if (Array.isArray(summary?.deductions) && summary?.deductions.length > 0) {
            dispatch(setDeductions(summary?.deductions));
            dispatch(setUndeductedTotalKilosCo2(summary?.undeductedFootprint?.kilosCo2e ?? summary?.undeductedFootprint?.kilosCo2));
          }
        } else {
          if (Array.isArray(response.messages) && response.messages.length > 0) {
            const textMessages = response.messages.map(({ message }) => message);
            dispatch(setFileErrors(textMessages));
          } else if (response.progressPercentage === 100) {
            onError();
            dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
          }
        }
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.data) {
        // @ts-ignore
        const { data } = err.data;
        if (data && Array.isArray(data)) {
          dispatch(setFileErrors(data));
          // @ts-ignore
        }
      } else {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
      }
      onError();
    }
  };

export const flightAmountSelector = createSelector(
  (state: RootState) => state.flightFootprint,
  state => {
    const { amount } = state;

    return fixFloat(amount ?? 0);
  },
);
