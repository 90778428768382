import { sanityInstance } from '../instance';

interface SanityResponse<T = unknown> {
  ms: number;
  query: string;
  result: T;
}

async function fetch<T>(query: string): Promise<T> {
  const { data } = await sanityInstance.get<SanityResponse<T>>('', {
    params: {
      query,
    },
  });

  return data.result;
}

const client = {
  fetch,
};

export default client;
