import { RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { ActionParams } from './types';

export const useAuthAction = (baseRequest: RedirectRequest) => {
  const { instance, inProgress } = useMsal();
  const isLoading = inProgress !== 'none';

  const action = (params?: ActionParams) => {
    if (isLoading) {
      return;
    }

    const redirectStartPage = params?.redirectAfterAction ?? baseRequest.redirectStartPage;

    instance.loginRedirect({
      ...baseRequest,
      redirectStartPage,
      extraQueryParameters: { ...baseRequest.extraQueryParameters, ...params?.additionalQuery },
    });
  };

  return { action, isLoading };
};
