import { GenericAbortSignal } from 'axios';
import { ParsedQs } from 'qs';
import { defaultInstance } from '@api/instance';
import { Paginated } from '@api/shared/types';

import {
  ConfirmOrderParams,
  ConfirmOrderResponse,
  EmissionsResponse,
  FootprintsCreatedQuote,
  FootprintsOrderParams,
  GetAllOrdersRequest,
  GetFootprintsForOrderRequest,
  Job,
  Order,
  OrderExpandParams,
  OrderResponse,
  OrdersParams,
  PerCapitaDetails,
  PerCapitaFootprint,
  Portfolio,
  Quote,
  ReserveQuoteOrderParams,
} from './types';

export const getOrder = async (orderId: string, customerId: string, expandParamsFlags?: OrderExpandParams) => {
  const expandParams: string[] = [];

  if (expandParamsFlags?.includeStats) {
    expandParams.push('facilitated.statistics');
  }

  if (expandParamsFlags?.includeVirtualPortfolio) {
    expandParams.push('virtualportfolio');
  }

  if (expandParamsFlags?.includeAttachments) {
    expandParams.push('attachments');
  }

  const expand = expandParams.join(',');

  const { data } = await defaultInstance.get<Order>(`customers/${customerId}/orders/${orderId}`, {
    params: { expand },
  });

  return data;
};

export const getOrders = async (params: OrdersParams, customerId: string) => {
  const { data } = await defaultInstance.get<Paginated<Order>>(`customers/${customerId}/orders`, {
    params,
  });

  return data;
};

export const createReserveQuoteForCustomer = async (order: ReserveQuoteOrderParams, customerId: string): Promise<OrderResponse> => {
  const { data } = await defaultInstance.post<OrderResponse>(`/customers/${customerId}/orders`, order);

  return data;
};

export const getFootprintsForEmissionsOrder = async (
  params: GetFootprintsForOrderRequest,
  customerId: string,
): Promise<EmissionsResponse<FootprintsItem>> => {
  const { data } = await defaultInstance.get(`/customers/${customerId}/footprints`, {
    params: {
      ...params,
    },
  });

  return data;
};

export const getAll = async (params: GetAllOrdersRequest, customerId: string): Promise<Paginated<Order>> => {
  const { data } = await defaultInstance.get<Paginated<Order>>(`customers/${customerId}/orders`, {
    params,
  });

  return data;
};

export const getFootprint = async (orderId: string, customerId: string): Promise<Footprint[]> => {
  const { data } = await defaultInstance.get<Footprint[]>(`customers/${customerId}/orders/${orderId}/footprints`);

  return data;
};

export type EmissionsFiltersGetParams = ParsedQs;
export const getEmissionsFilters = async (customerId: string, params?: EmissionsFiltersGetParams) => {
  const { data } = await defaultInstance.get<{ name: string; type: 'Date' | 'List' }[]>(`customers/${customerId}/footprints/filters`, { params });

  return data;
};

export type EmissionsFilterGetParams = PaginationParams;
export const getEmissionsFilter = async (propertyName: string, customerId: string, signal: GenericAbortSignal, params?: EmissionsFilterGetParams) => {
  const { data } = await defaultInstance.get<Paginated<string>>(`customers/${customerId}/footprints/filters/${propertyName}`, { params, signal });

  return data;
};

export const getLegacyEmissionsFilter = async (propertyName: string, customerId: string, signal: AbortSignal) => {
  const { data } = await defaultInstance.get<string[]>(`customers/${customerId}/incompletefootprints/filters/${propertyName}`, {
    signal,
  });

  return data;
};

export const getPortfolioFilters = async (propertyName: string, portfolioId: string, customerId: string): Promise<string[]> => {
  const { data } = await defaultInstance.get<string[]>(`customers/${customerId}/portfolios/${portfolioId}/filters/${propertyName}`);

  return data;
};

export const archiveFootprint = async (id: string, customerId: string): Promise<void> => {
  await defaultInstance.delete(`/customers/${customerId}/footprints/${id}`);
};

export const getQuoteForFootprints = async (order: FootprintsOrderParams, customerId: string): Promise<Quote> => {
  const { data } = await defaultInstance.post<Quote>(`/customers/${customerId}/footprints/quote`, { ...order, disableSupplyAlerts: true });

  return data;
};

export const createQuoteForFootprints = async (order: FootprintsOrderParams, customerId: string): Promise<FootprintsCreatedQuote> => {
  const { data } = await defaultInstance.post<FootprintsCreatedQuote>(`/customers/${customerId}/footprints/quote/create`, {
    ...order,
    disableSupplyAlerts: true,
  });

  return data;
};

export const createReserveQuoteForFootprints = async (order: ReserveQuoteOrderParams, customerId: string): Promise<OrderResponse> => {
  const { data } = await defaultInstance.post<OrderResponse>(`/customers/${customerId}/footprints/compensate`, order);

  return data;
};

export const confirmOrder = async (order: ConfirmOrderParams, orderId: string, customerId: string): Promise<ConfirmOrderResponse> => {
  const { data } = await defaultInstance.post<ConfirmOrderResponse>(`/customers/${customerId}/orders/${orderId}/confirm`, order);

  return data;
};

export const requestSupply = async (portfolioId: string, customerId: string): Promise<void> => {
  await defaultInstance.post(`customers/${customerId}/portfolios/${portfolioId}/supply/request`);
};

export const getQuoteForCustomer = async (quote: Partial<Quote>, customerId: string) => {
  const { data } = await defaultInstance.post<Quote>(`/customers/${customerId}/quote`, quote);

  return data;
};

export const createQuoteForCustomer = async (quote: Partial<Quote>, customerId: string) => {
  const { data } = await defaultInstance.post<FootprintsCreatedQuote>(`/customers/${customerId}/quote/create`, quote);

  return data;
};

export const processPassengerFlightFile = async (file: File, customerId: string, currency: string, signal: AbortSignal) => {
  const { data } = await defaultInstance.post<BatchJobResponseOk | BatchJobResponseError>(
    `/customers/${customerId}/attachments/processflightbatchjob?currency=${currency}`,
    file,
    {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      signal,
    },
  );

  return data;
};

export const getJob = async (partnershipId: string, jobId: string, signal?: AbortSignal) => {
  const { data } = await defaultInstance.get<Job>(`/partnerships/${partnershipId}/jobs/${jobId}`, { signal: signal });

  return data;
};

export const getCustomerPortfolio = async (customerId: string, id: string, search?: string) => {
  const { data } = await defaultInstance.get<Portfolio>(`/customers/${customerId}/portfolios/${id}${search ? `?${search}` : ''}`);

  return data;
};

export const getPerCapitaFootprint = async (percapitas: PerCapitaDetails[]) => {
  const { data } = await defaultInstance.post<PerCapitaFootprint[]>(`/footprint/percapita/bulk`, {
    percapitas,
  });

  return data;
};
