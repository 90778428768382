import { defineStyleConfig } from '@chakra-ui/react';

const Tooltip = defineStyleConfig({
  baseStyle: {
    borderRadius: 'base',
    zIndex: 9999,
    fontSize: 'md',
    py: '10px',
    px: '10px',
  },
});

export default Tooltip;
