const breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
  xxl: '1470px',
  xxxl: '1600px',
  '4xl': '1900px',
};

const mq = {
  sm: `@media (min-width: ${breakpoints.sm})`,
  smMax: `@media (max-width: ${parseInt(breakpoints.sm) - 1}px)`,
  md: `@media (min-width: ${breakpoints.md})`,
  mdMax: `@media (max-width: ${parseInt(breakpoints.md) - 1}px)`,
  mdMinMax: `@media (min-width: ${breakpoints.md}) and (max-width: ${parseInt(breakpoints.lg) - 1}px)`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  lgMax: `@media (max-width: ${parseInt(breakpoints.lg) - 1}px)`,
  lgMinMax: `@media (min-width: ${breakpoints.lg}) and (max-width: ${parseInt(breakpoints.xl) - 1}px)`,
  xl: `@media (min-width: ${breakpoints.xl})`,
  xlMax: `@media (max-width: ${parseInt(breakpoints.xl) - 1}px)`,
  xxlMax: `@media (max-width: ${parseInt(breakpoints.xxl) - 1}px)`,
  xlMinMax: `@media (min-width: ${breakpoints.xl}) and (max-width: ${parseInt(breakpoints.xxl) - 1}px)`,
  xxl: `@media (min-width: ${breakpoints.xxl})`,
  xxxl: `@media (min-width: ${breakpoints.xxxl})`,
  mobileLandscape: `@media (max-width: ${parseInt(breakpoints.md) - 1}px) and (orientation: landscape)`,
  mobilePortrait: `@media (max-width: ${parseInt(breakpoints.md) - 1}px) and (orientation: portrait)`,
};

export { breakpoints, mq };
