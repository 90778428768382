import { usePartnershipSettings } from '@api/partnership/queries';

import { getTheme } from '@theme';

export const useAppTheme = () => {
  const { data: settings, isLoading } = usePartnershipSettings();

  const customColors = settings?.colors;
  const customFontSize = settings?.styles?.fontSize;
  const customButtonBorderRadius = settings?.styles?.buttonBorderRadius;
  const theme = getTheme(customColors, customFontSize, customButtonBorderRadius);

  return {
    isLoading: [isLoading, theme === undefined].some(e => !!e),
    theme,
  };
};
