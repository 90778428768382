import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'control', 'label', 'icon']);
const Checkbox = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      border: '2px solid',
      borderRadius: 'base',
      borderColor: 'gray.300',
      '&[data-checked],&[data-indeterminate]': {
        borderColor: 'primary.400',
        bg: 'primary.400',
        color: 'other.secondaryBgText',
        '&:hover': {
          bg: 'primary.400',
          color: 'other.secondaryBgText',
          borderColor: 'primary.400',
        },
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'primary.400',
      },
    },
    label: {
      ml: '14px',
      color: 'secondary.700',
      fontFamily: 'body',
      fontSize: 'md',
    },
  },
  defaultProps: {
    size: 'lg',
  },
});

export default Checkbox;
