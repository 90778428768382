import { useEffect } from 'react';
import { EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { handleAuthError, isAuthenticationResult } from '.';
import { useAuthHandlerStore } from './authHandlerStore';

const FAILURE_EVENTS: EventType[] = [
  EventType.LOGIN_FAILURE,
  EventType.ACQUIRE_TOKEN_FAILURE,
  EventType.LOGOUT_FAILURE,
  EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
  EventType.SSO_SILENT_FAILURE,
];

export const AuthWatcher = () => {
  const { instance, inProgress } = useMsal();
  const authHandler = useAuthHandlerStore(state => state.authHandler);
  const setAuthHandler = useAuthHandlerStore(state => state.setAuthHandler);

  useEffect(() => {
    if (!authHandler || inProgress !== 'none') {
      return;
    }

    switch (authHandler.type) {
      case 'LoginRedirect':
        instance.loginRedirect(authHandler.request);
        break;
      case 'AcquireTokenRedirect':
        instance.acquireTokenRedirect(authHandler.request);
        break;
      case 'LogoutRedirect':
        instance.logoutRedirect(authHandler.request);
        break;
      default:
        break;
    }
  }, [inProgress, authHandler]);

  useEffect(() => {
    const id = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && isAuthenticationResult(event.payload)) {
        const payload = event.payload;
        instance.setActiveAccount(payload.account);

        return;
      }

      if (FAILURE_EVENTS.includes(event.eventType)) {
        handleAuthError(event.error, {
          tags: {
            eventType: event.eventType,
          },
        });

        return;
      }

      if (event.eventType === EventType.ACCOUNT_REMOVED) {
        instance.setActiveAccount(null);
        setAuthHandler({
          type: 'LogoutRedirect',
          request: { postLogoutRedirectUri: '/' },
        });

        return;
      }
    });

    return () => {
      if (id) {
        instance.removeEventCallback(id);
      }
    };
  }, []);

  return null;
};
