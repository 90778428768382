import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  variant?: 'success' | 'error';
  titleI18nKey?: string;
  descriptionI18nKey?: string;
}

const initialState: State = {};

const slice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    showToast: (_, action: PayloadAction<State>): State => {
      return action.payload;
    },
    resetToast: (): State => {
      return { ...initialState };
    },
  },
});

const { showToast, resetToast } = slice.actions;
export { resetToast, showToast };

export default slice.reducer;
