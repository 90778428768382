import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { usePartnerSettings } from '@api/partnership/queries';
import { useCurrentAccount } from '@api/user/queries';
import { isCustomerAccount, isPartnerAccount, isTransactAccount } from '@utils/contextChecker';

export const useAnalyticsWatcher = () => {
  const posthog = usePostHog();
  const { account } = useCurrentAccount();
  const { data: settings } = usePartnerSettings();

  const params: AnalyticsParams = {
    email: account?.email || '',
    currentPortalPartnershipId: settings.partnershipId || '',
    accountId: account?.id || '',
  };

  if (account && (isCustomerAccount(account) || isTransactAccount(account))) {
    params.customerId = account.customerId;
    params.accountId = account.id;
  }

  if (account && isPartnerAccount(account)) {
    params.partnershipId = account.partnershipId;
    params.accountId = account.id;
  }

  useEffect(() => {
    if (!posthog || !account?.id) {
      return;
    }
    posthog.identify(account.id, params);
  }, [posthog, params, account?.id]);
};

interface AnalyticsParams {
  email: string;
  accountId: string;
  partnershipId?: string;
  customerId?: string;
  currentPortalPartnershipId: string;
}
