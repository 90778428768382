import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import qs from 'qs';
import { useUser } from '@api/user/queries';
import { CustomerAccount } from '@api/user/types';
import { LoaderFullPage } from '@components/Loader';
import { getTypes, hasType } from '@utils/accountType';

const LoginSetup: FC<React.PropsWithChildren> = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const ready = inProgress === 'none';
  const { data: user, isLoading } = useUser();
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    async function checkCustomer() {
      if (!ready || isLoading) {
        return;
      }

      if (!isAuthenticated || !user) {
        history.replace('/');

        return;
      }
      const { inviteId, claimCode, context } = search;

      if (hasType(getTypes(user.accounts), ['WeChooose'])) {
        const account = user.accounts.find((u): u is CustomerAccount => u.type === 'WeChooose');
        const newSearch = qs.stringify({ invite: inviteId, code: claimCode, customerId: account?.customerId, context });
        history.replace({ pathname: '/invite/claim', search: newSearch });

        return;
      }

      const newSearch = qs.stringify({ inviteId, claimCode });
      if (search.context === 'wechooose') {
        history.replace({ pathname: '/sign-up/chooose', search: newSearch });

        return;
      }

      history.replace('/');
    }

    checkCustomer();
  }, [ready, isAuthenticated, history, search, user, isLoading]);

  return <LoaderFullPage />;
};

export default LoginSetup;
