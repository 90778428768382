import { ReactElement } from 'react';
import { getI18n } from 'react-i18next';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as api from '@api/project/api';
import * as projectSanityApi from '@api/sanity/project';
import { AppThunk, RootState } from '@store';
import { appContextSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { selectGuestCountry } from '@store/signUp/signUpSlice';
import { currentUserCountryCodeSelector, userCurrencySelector } from '@store/user/userSlice';

function translateProjectDetails(p: SanityProject, lang: string) {
  return {
    title: p.title?.[lang] ?? p.title?.en ?? '',
    subtitle: p.subtitle?.[lang] ?? p.subtitle?.en ?? '',
    country: p.country?.[lang] ?? p.country?.en ?? '',
    city: p.city?.[lang] ?? p.city?.en ?? '',
    description: p.description?.[lang] ?? p.description?.en ?? [],
    howItWorks: p.howItWorks?.[lang] ?? p.howItWorks?.en ?? [],
    benefits: p.benefits?.[lang] ?? p.benefits?.en ?? [],
    whyThisProject: p.whyThisProject?.[lang] ?? p.whyThisProject?.en ?? [],
    portalDescription: p.portalDescription?.[lang] ?? p.portalDescription?.en ?? [],
    projectOwner: p.projectOwner?.[lang] ?? p.projectOwner?.en ?? '',
    sdgs:
      p.sdgs?.map(s => ({
        id: s.sdg?._id ?? '',
        logoUrl: s.sdg?.icon?.asset?.url ?? '',
        title: s.sdg?.title?.[lang] ?? s.sdg?.title?.en ?? '',
        number: s.sdg?.sdgNumber ?? '',
        subtitle: s.sdg?.subtitle?.[lang] ?? s.sdg?.subtitle?.en ?? '',
        color: s.sdg?.sdgColor?.hex ?? '#000000',
        affect: s.affect?.[lang] ?? s.affect?.en ?? [],
        backgroundUrl: s.sdg?.backgroundImage?.asset?.url ?? '',
        description: s.sdg?.description?.[lang] ?? s.sdg?.description?.en ?? [],
        localizedIcon: s.sdg?.localizedIcon?.[lang]?.asset?.url ?? '',
        localizedAlt: s.sdg?.localizedIcon?.[lang]?.alt ?? '',
      })) ?? [],
    theme: p.projectTheme?.title?.[lang] ?? '',
    themeCategory: {
      title: p.projectTheme?.category?.title?.[lang] ?? '',
      slug: p.projectTheme?.category?.slug?.current ?? '',
    },
  };
}

function toProject(p: SanityProject, lang = 'en', cp?: Project): Project {
  return {
    id: cp?.id ?? '',
    sanitySlug: p.slug.current,
    sanityId: p._id ?? '',
    imageUrl: p.mainImage?.asset?.url ?? '',
    imageAlt: p.mainImage?.alt ?? '',
    additionalImages:
      p.additionalImages?.map(img => ({
        alt: img?.alt ?? '',
        url: img?.asset?.url ?? '',
      })) ?? [],
    totalCo2: p.totalCo2eReduction,
    status: p.status,
    slug: p.slug.current,
    certifiedBy: (p.certifiedBy?.map(c => ({
      logoUrl: c.logo?.asset?.url ?? '',
      description: c.description?.textContent?.[lang] ? c.description?.textContent?.[lang] : c.description?.textContent?.['en'],
      title: c.title?.textContent?.[lang] ? c.title?.textContent?.[lang] : c.title?.textContent?.['en'],
    })) ?? []) as PortfolioCertificate[],
    factUrl: p.projectFactSheet ?? '',
    location: {
      lat: p.exactLocation?.lat ?? '',
      lng: p.exactLocation?.lng ?? '',
    },
    supplyType: p.supplyType,
    lcfType: p.lcfType,
    supplySummary: cp?.supplySummary,
    ...translateProjectDetails(p, lang),
  };
}

function calculatePriceWithVat(price = 0, countryCode?: string, vats?: SanityPortfolioVatByCountry[]): number {
  if (!vats || !countryCode) {
    return price;
  }

  const vat = vats.find(v => v.countryCode.toLowerCase() === countryCode.toLowerCase());
  if (!vat) {
    return price;
  }

  return (1 + vat.percentage / 100) * price;
}

function toSettingsPortfolio(sanityPortfolio?: SanityPortfolio, currentPortfolio?: Portfolio, countryCode?: string): SettingsPortfolio {
  const lang = getI18n().language as Language;

  const portfolioData: PortfolioData = {
    id: currentPortfolio?.id ?? '',
    sanityId: sanityPortfolio?._id ?? '',
    title: sanityPortfolio?.title?.textContent?.[lang ?? 'en'] ?? '',
    segment: sanityPortfolio?.portfolioSegment?.title ?? '',
    subtitle: sanityPortfolio?.subtitle?.textContent?.[lang ?? 'en'] ?? '',
    pricePerImpactUnit: calculatePriceWithVat(
      currentPortfolio?.estimatedCustomerPricePerTonneXTax ?? currentPortfolio?.baseCustomerPricePerTonne ?? currentPortfolio?.pricePerTonneCo2 ?? 0,
      countryCode,
      sanityPortfolio?.vatByCountry,
    ),
    currency: currentPortfolio?.estimatedCustomerPriceCurrency ?? currentPortfolio?.baseCustomerPriceCurrency ?? currentPortfolio?.currency ?? '',
    description: sanityPortfolio?.description?.textContent?.[lang ?? 'en'] ?? '',
    mainImage: {
      alt: sanityPortfolio?.mainImage?.alt ?? '',
      url: sanityPortfolio?.mainImage?.asset.url ?? '',
    },
    theme: sanityPortfolio?.portfolioTheme?.title ?? '',
    totalTonnesCo2Available: currentPortfolio?.totalTonnesCo2Available ?? 0,
    sanitySlug: currentPortfolio?.sanitySlug ?? '',
    containsSaf: currentPortfolio?.containsSaf,
    projects:
      sanityPortfolio?.projects?.map(({ supplyType, lcfType, _id }) => ({
        supplyType: supplyType,
        lcfType: lcfType,
        sanityID: _id,
      })) ?? [],
  };
  const currentPortfoliosProjects = currentPortfolio?.projects ?? [];
  const items: Project[] =
    sanityPortfolio?.projects.map(p => toProject(p, lang, currentPortfoliosProjects.find(pp => pp.project?.sanityId === p._id)?.project)) ?? [];

  return {
    portfolioData,
    items,
    default: currentPortfolio?.default,
  };
}

interface State {
  portfolioData?: PortfolioData;
  viewableItems: Project[];
  portfolioItems: Project[];
  loading: boolean;
  isSwitchingError: boolean;
  allPortfolios?: SettingsPortfolio[];
  allProjects?: Project[];
  fetchingPortfolios: boolean;
  fetchingProjectImages: boolean;
  generatingPDF: boolean;
  switchingDefaultPortfolio: boolean;
  facilitatedOrderData: {
    basePortfolio?: SettingsPortfolio[];
    customPortfolio?: SettingsPortfolio;
    customPrice?: number;
  };
  orderViewPortfolios?: SettingsPortfolio[];
  allProjectsWithAllTranslations?: SanityProject[];
}

const initialState: State = {
  viewableItems: [],
  portfolioItems: [],
  loading: false,
  isSwitchingError: false,
  fetchingPortfolios: false,
  fetchingProjectImages: false,
  generatingPDF: false,
  switchingDefaultPortfolio: false,
  facilitatedOrderData: {},
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state: State, action: PayloadAction<Project[]>): State => {
      state.viewableItems = action.payload;
      state.portfolioItems = action.payload;

      return state;
    },

    setLoading: (state: State, action: PayloadAction<boolean>): State => {
      state.loading = action.payload;

      return state;
    },
    setPortfolioData: (state: State, action: PayloadAction<PortfolioData>): State => {
      state.portfolioData = { ...action.payload };

      return state;
    },
    setAllPortfolios: (state: State, action: PayloadAction<SettingsPortfolio[]>): State => {
      state.allPortfolios = [...action.payload];

      return state;
    },
    setAllProjects: (state: State, action: PayloadAction<Project[]>): State => {
      state.allProjects = [...action.payload];

      return state;
    },
    setAllProjectsWithAllTranslations: (state: State, action: PayloadAction<SanityProject[]>): State => {
      state.allProjectsWithAllTranslations = [...action.payload];

      return state;
    },
    setIsError: (state: State, action: PayloadAction<boolean>): State => {
      state.isSwitchingError = action.payload;

      return state;
    },
    setFetchingPortfolios: (state: State, action: PayloadAction<boolean>): State => {
      state.fetchingPortfolios = action.payload;

      return state;
    },
    setFetchingProjectImages: (state: State, action: PayloadAction<boolean>): State => {
      state.fetchingProjectImages = action.payload;

      return state;
    },
    setSwitchingDefaultPortfolio: (state: State, action: PayloadAction<boolean>): State => {
      state.switchingDefaultPortfolio = action.payload;

      return state;
    },
    setGeneratingPDF: (state: State, action: PayloadAction<boolean>): State => {
      state.generatingPDF = action.payload;

      return state;
    },
    setAvailableOffset: (state: State, action: PayloadAction<{ id: string; amount: number }>): State => {
      const portfolio = state.allPortfolios?.find(p => p.portfolioData.id === action.payload.id);
      if (portfolio) {
        portfolio.portfolioData.totalTonnesCo2Available = action.payload.amount;
      }

      return state;
    },
    setOrderViewPortfolios: (state: State, action: PayloadAction<SettingsPortfolio[]>): State => {
      state.orderViewPortfolios = action.payload;

      return state;
    },
    setDefaultPortfolio: (state: State, action: PayloadAction<string>): State => {
      const targetPortfolio = state.allPortfolios?.find(p => p.portfolioData.sanityId === action.payload);
      if (targetPortfolio) {
        state.allPortfolios?.forEach(p => {
          p.default = false;
        });
        targetPortfolio.default = true;
      }

      return state;
    },
    resetFacilitatedOrderData: (state: State): State => {
      state.facilitatedOrderData = {};

      return state;
    },
    setFacilitatedOrderPortfolio: (state: State, action: PayloadAction<SettingsPortfolio[]>): State => {
      state.facilitatedOrderData.basePortfolio = action.payload;

      return state;
    },
    setFacilitatedOrderCustomPortfolio: (state: State, action: PayloadAction<SettingsPortfolio>): State => {
      state.facilitatedOrderData.customPortfolio = action.payload;

      return state;
    },
  },
});

const {
  setProjects,
  setLoading,
  setPortfolioData,
  setAllPortfolios,
  setAllProjects,
  setIsError,
  setFetchingPortfolios,
  setFetchingProjectImages,
  setGeneratingPDF,
  setAvailableOffset,
  setDefaultPortfolio,
  setSwitchingDefaultPortfolio,
  setFacilitatedOrderPortfolio,
  setFacilitatedOrderCustomPortfolio,
  setAllProjectsWithAllTranslations,
} = slice.actions;

export { setFacilitatedOrderCustomPortfolio };

export default slice.reducer;

function getCo2PerPortfolio(state: RootState): AttributionStatisticsPerPortfolio[] {
  const context = state.app.context;
  const statistics = state.attribution?.statistics;

  switch (context) {
    case 'wechooose':
      return statistics?.owned?.co2PerPortfolio ?? [];

    case 'connect':
      return statistics?.facilitated?.co2PerPortfolio ?? [];

    default:
      return [];
  }
}

export const loadProjects =
  (currentUser?: AccountLegacy, quiet?: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentAccount = currentUser ?? getState().user.currentAccount;
    const context = appContextSelector(getState());
    const customerCountry = currentUserCountryCodeSelector(getState());
    const isConnectContext = context === 'connect';
    const currency = userCurrencySelector(getState());
    const isNeededInfoForConnectContextMissing = isConnectContext && !currentAccount?.partnershipId;
    const isNeededInfoForOtherContextsMissing = !isConnectContext && !currentAccount?.customerId;
    const isNeededInfoMissing = !currentAccount || isNeededInfoForConnectContextMissing || isNeededInfoForOtherContextsMissing;

    if (isNeededInfoMissing) {
      return;
    }
    if (!quiet) {
      dispatch(setLoading(true));
    }

    try {
      let portfolios: Portfolio[];
      if (isConnectContext) {
        portfolios = (await api.getPortfolioForPartnership(currentAccount?.partnershipId ?? '', currency)) as unknown as Portfolio[];
      } else {
        portfolios = (await api.getPortfolioForCustomer(currentAccount?.customerId ?? '')) as unknown as Portfolio[];
      }

      const defaultPortfolio = portfolios.find(p => p.default);

      const sanityId = defaultPortfolio?.sanityId ?? '';
      const id = defaultPortfolio?.id ?? '';
      const portfolioIds = portfolios.map(pf => pf.sanityId);
      const sanityPortfolios = await projectSanityApi.getPortfoliosFromIds(portfolioIds);

      const matchingSanityPortfolio = sanityPortfolios.find(p => p._id === sanityId) as SanityPortfolio;
      const matchingPortfolios = portfolios.find(p => p.sanityId === sanityId);

      const sanityProjects = matchingSanityPortfolio?.projects ?? [];
      const portfolioProjects = matchingPortfolios?.projects ?? [];

      const lang = getI18n().language as Language;

      const projects: Project[] = sanityProjects.map(p => toProject(p, lang, portfolioProjects.find(pp => pp.project?.sanityId === p._id)?.project));

      const portfolioData: PortfolioData = {
        id,
        sanityId,
        title: matchingSanityPortfolio?.title?.textContent?.[lang ?? 'en'] ?? '',
        description: matchingSanityPortfolio?.description?.textContent?.[lang ?? 'en'] ?? '',
        segment: matchingSanityPortfolio?.portfolioSegment?.title ?? '',
        mainImage: {
          alt: matchingSanityPortfolio?.mainImage?.alt ?? '',
          url: matchingSanityPortfolio?.mainImage?.asset.url ?? '',
        },
        theme: matchingSanityPortfolio?.portfolioTheme?.title ?? '',
        currency: defaultPortfolio?.estimatedCustomerPriceCurrency ?? defaultPortfolio?.baseCustomerPriceCurrency ?? defaultPortfolio?.currency ?? '',
        pricePerTonneCo2:
          matchingSanityPortfolio && defaultPortfolio
            ? calculatePriceWithVat(
                defaultPortfolio?.estimatedCustomerPricePerTonneXTax ??
                  defaultPortfolio?.baseCustomerPricePerTonne ??
                  defaultPortfolio?.pricePerTonneCo2 ??
                  0,
                customerCountry,
                matchingSanityPortfolio.vatByCountry,
              )
            : undefined,
        totalTonnesCo2Available: defaultPortfolio?.totalTonnesCo2Available ?? 0,
      };

      const portfoliosToSave = sanityPortfolios
        .filter(sanityPortfolio => portfolios.find(p => p.sanityId === sanityPortfolio._id))
        .map(sanityPortfolio => {
          const currentPortfolio = portfolios.find(portfolio => portfolio.sanityId === sanityPortfolio._id);

          return toSettingsPortfolio(sanityPortfolio as SanityPortfolio, currentPortfolio, customerCountry);
        });

      dispatch(setProjects(projects));
      dispatch(setPortfolioData(portfolioData));
      dispatch(setAllPortfolios(portfoliosToSave));

      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const getFacilitatedOrderPortfolio =
  (order: OrderLegacy<OrderFootprint>, customerId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const customerCountry = currentUserCountryCodeSelector(getState());
    const guestCountry = selectGuestCountry(getState());

    const countryCode = customerCountry || guestCountry;

    dispatch(setLoading(true));
    dispatch(setFetchingPortfolios(true));

    try {
      const allPortfolios = (await api.getPortfolioForCustomer(customerId)) as unknown as Portfolio[];
      const portfolioIds = allPortfolios.map(pf => pf.sanityId);

      const allSanityPortfolios = await projectSanityApi.getPortfoliosFromIds(portfolioIds);
      if (order.virtualPortfolio) {
        const virtualPortfoliosTotalWeight = (order.virtualPortfolio.childPortfolios || []).reduce((prev, { weight }) => prev + weight, 0);
        const orderTotalCo2InTons = order.totalKilosCo2 / 1000;
        const isTotalCo2Matching = virtualPortfoliosTotalWeight <= orderTotalCo2InTons;
        let portfoliosPercent = 1;
        if (!isTotalCo2Matching) {
          portfoliosPercent = orderTotalCo2InTons / virtualPortfoliosTotalWeight;
        }
        const virtualPortfolios = await Promise.all(
          (order.virtualPortfolio.childPortfolios || []).map(async childPortfolio => {
            const matchingPortfolio = allPortfolios.find(p => p.id === childPortfolio.id);
            const matchingSanityPortfolio = allSanityPortfolios.find(p => p._id === matchingPortfolio?.sanityId);

            return {
              ...toSettingsPortfolio(matchingSanityPortfolio as SanityPortfolio, matchingPortfolio, countryCode),
              weight: childPortfolio.weight * portfoliosPercent,
            };
          }),
        );
        dispatch(setFacilitatedOrderPortfolio(virtualPortfolios));
      } else {
        const facilitatedOrderPortfolio = allPortfolios.find(p => p.id === order?.portfolioId);
        const matchingSanityPortfolio = allSanityPortfolios.find(p => p._id === facilitatedOrderPortfolio?.sanityId);
        dispatch(
          setFacilitatedOrderPortfolio([toSettingsPortfolio(matchingSanityPortfolio as SanityPortfolio, facilitatedOrderPortfolio, countryCode)]),
        );
      }
    } catch (err) {
      // err
    } finally {
      dispatch(setLoading(false));
      dispatch(setFetchingPortfolios(false));
    }
  };

export const loadAllProjects =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const sanityProjects = await projectSanityApi.getAllProjects();
      const projects = await api.getAllProjects();

      const isSanityProjectInProjects = (sanityProject: SanityProject): boolean => !!projects.find(project => project.sanityId === sanityProject._id);

      const filteredProjects = sanityProjects.filter(isSanityProjectInProjects);

      const addOwnId = (formattedProject: Project): Project => ({
        ...formattedProject,
        id: projects.find(project => project.sanityId === formattedProject.sanityId)?.id ?? '',
      });

      const addAvailability = (formattedProject: Project): Project => ({
        ...formattedProject,
        available: projects.find(project => project.sanityId === formattedProject.sanityId)?.totalTonnesCo2Available,
      });
      const lang = getI18n().language as Language;
      dispatch(
        setAllProjects(
          filteredProjects
            .map(p => toProject(p, lang))
            .map(addOwnId)
            .map(addAvailability),
        ),
      );
      dispatch(setAllProjectsWithAllTranslations(filteredProjects));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const translateProjectsAndPortfolios =
  (lang: string): AppThunk =>
  (dispatch, getState): void => {
    const { allProjectsWithAllTranslations, allPortfolios, viewableItems } = getState().projects;
    const newPortfolios =
      allPortfolios?.map(portfolio => ({
        ...portfolio,
        items: portfolio.items.map(item => {
          const newProject = allProjectsWithAllTranslations?.find(project => project._id === item.sanityId);

          return {
            ...item,
            ...(newProject && translateProjectDetails(newProject, lang)),
          };
        }),
      })) || [];

    const newProjects = viewableItems?.map(item => {
      const newProject = allProjectsWithAllTranslations?.find(project => project._id === item.sanityId);

      return {
        ...item,
        ...(newProject && translateProjectDetails(newProject, lang)),
      };
    });

    dispatch(setAllPortfolios(newPortfolios));
    dispatch(setProjects(newProjects));
  };

export const switchPortfolio =
  (selectedId: string, cb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const { currentAccount } = getState().user;
    dispatch(setSwitchingDefaultPortfolio(true));

    try {
      if (context === 'connect') {
        await api.switchPortfolioForPartnershipQuery(currentAccount?.partnershipId ?? '', selectedId);
      } else {
        await api.switchPortfolioForCustomerQuery(currentAccount?.customerId ?? '', selectedId);
      }
      dispatch(setDefaultPortfolio(selectedId));
      cb && cb();
      dispatch(loadProjects());
      dispatch(setSwitchingDefaultPortfolio(false));
    } catch (err) {
      dispatch(setSwitchingDefaultPortfolio(false));
      dispatch(setIsError(true));
    }
  };

export const downloadImages =
  (urls: string[], zipFilename = 'images.zip', onFinish?: CallableFunction): AppThunk =>
  async (dispatch): Promise<void> => {
    let fetchingError = false;

    if (urls.length) {
      const uniqueUrls = Array.from(new Set(urls));
      dispatch(setFetchingProjectImages(true));

      if (uniqueUrls.length > 1) {
        const zip = new JSZip();
        const imageFolder = zip.folder('images');
        let index = 0;

        for (const url of uniqueUrls) {
          if (!fetchingError) {
            const filename = `image-${index + 1}.png`;
            try {
              const file = await api.downloadFromUrl(url);
              imageFolder?.file(filename, file, { binary: true });
              if (index === uniqueUrls.length - 1) {
                try {
                  const content = await zip.generateAsync({ type: 'blob' });
                  saveAs(content, zipFilename);
                } catch {
                  dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
                } finally {
                  dispatch(setFetchingProjectImages(false));
                  onFinish && onFinish();
                }
              }
              index += 1;
            } catch (err) {
              fetchingError = true;
              dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
              dispatch(setFetchingProjectImages(false));
              onFinish && onFinish();
            }
          }
        }
      } else {
        try {
          const file = await api.downloadFromUrl(uniqueUrls[0]);
          saveAs(file, 'image.png');
        } catch {
          dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
        } finally {
          dispatch(setFetchingProjectImages(false));
          onFinish && onFinish();
        }
      }
    }
  };

export const downloadPDF =
  (templates: ReactElement[], filename = 'data', onFinish?: CallableFunction): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setGeneratingPDF(true));

    const reactPdf = await import('@react-pdf/renderer');
    if (templates.length === 1) {
      try {
        const blob = await reactPdf.pdf(templates[0]).toBlob();
        saveAs(blob, `${filename.trim()}.pdf`);
      } catch {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'projects:pdfErrorDescription' }));
      } finally {
        dispatch(setGeneratingPDF(false));
        onFinish && onFinish();
      }
    } else {
      const zip = new JSZip();
      const pdfFolder = zip.folder('PDF');
      let generatingError = false;
      let index = 0;

      for (const template of templates) {
        if (!generatingError) {
          const finalName = `${filename}-${index + 1}.pdf`;
          try {
            const blob = await reactPdf.pdf(template).toBlob();
            pdfFolder?.file(finalName, blob, { binary: true });
            if (index === templates.length - 1) {
              try {
                const content = await zip.generateAsync({ type: 'blob' });
                saveAs(content, filename.trim());
              } catch {
                dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'projects:pdfErrorDescription' }));
              } finally {
                dispatch(setGeneratingPDF(false));
                onFinish && onFinish();
              }
            }
            index += 1;
          } catch (err) {
            generatingError = true;
            dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'projects:pdfErrorDescription' }));
            dispatch(setGeneratingPDF(false));
            onFinish && onFinish();
          }
        }
      }
    }
  };

export const refreshAvailability =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { currentAccount } = getState().user;
    const context = appContextSelector(getState());
    const currency = userCurrencySelector(getState());

    if (!currentAccount || (context !== 'connect' && !currentAccount.customerId)) {
      return;
    }

    try {
      let portfolios: Portfolio[];
      if (context === 'connect') {
        portfolios = (await api.getPortfolioForPartnership(currentAccount?.partnershipId ?? '', currency)) as unknown as Portfolio[];
      } else {
        portfolios = (await api.getPortfolioForCustomer(currentAccount?.customerId ?? '')) as unknown as Portfolio[];
      }

      portfolios.forEach(p => dispatch(setAvailableOffset({ id: p.id, amount: p.totalTonnesCo2Available })));
    } catch (err) {
      // err
    }
  };

export const co2PerPortfolio = createSelector(getCo2PerPortfolio, portfolioList => portfolioList);

export const allPortfoliosSortedSelector = createSelector(
  (state: RootState) => state.projects,
  projectsState => {
    const { allPortfolios } = projectsState;

    return allPortfolios ? [...allPortfolios].sort(p => (p.default ? -1 : 0)) : [];
  },
);

export const allAvailableProjectsSelector = createSelector(
  (state: RootState) => state.projects,
  projectsState => {
    const { allPortfolios } = projectsState;

    return allPortfolios?.flatMap(p => p.items);
  },
);

export const defaultPortfolioSelector = createSelector(
  (state: RootState) => state.projects,
  projectsState => {
    const { allPortfolios } = projectsState;

    return allPortfolios?.find(p => p.default);
  },
);
