import { FC } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { usePartnershipSettings } from '@api/partnership/queries';

import LogoImage from './LogoImage';

type PartnershipLogoProps = {
  shouldUseLightLogo?: boolean;
};

export const PartnershipLogo: FC<PartnershipLogoProps> = ({ shouldUseLightLogo = false }) => {
  const { data: partnershipSettings } = usePartnershipSettings();
  const { lightLogoUrl } = partnershipSettings;
  // TODO: move to settings
  const isWideLogo = import.meta.env.REACT_APP_WIDE_LOGO ?? false;

  return (
    <Box borderRadius='portfolio' maxW={isWideLogo ? '200px' : '140px'} w='100%' minW='100px'>
      {shouldUseLightLogo && lightLogoUrl ? <Image src={lightLogoUrl} alt='' objectFit='contain' /> : <LogoImage />}
    </Box>
  );
};
