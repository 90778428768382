import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useAccountIdStore } from '@store/context';
import { ONE_HOUR } from '@utils/constants';
import { isCustomerAccount, isPartnerAccount, isTransactAccount } from '@utils/contextChecker';

import * as api from './api';
import { CustomerAccount, PartnerAccount, TransactAccount } from './types';

export const userFetcher = {
  queryKey: ['user'],
  queryFn: api.getUser,
};

export const useUser = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  return useQuery({
    ...userFetcher,
    staleTime: ONE_HOUR,
    enabled: isAuthenticated && inProgress === 'none',
  });
};

export const useCurrentCustomerAccount = () => {
  const { data, ...props } = useUser();
  const accountId = useAccountIdStore(state => state.accountId);
  const customerAccount = data?.accounts.find((account): account is CustomerAccount => isCustomerAccount(account) && account.id === accountId);
  const transactAccount = data?.accounts.find((account): account is TransactAccount => isTransactAccount(account) && account.id === accountId);
  const account = customerAccount ?? transactAccount;

  return {
    account,
    ...props,
  };
};

export const useCurrentPartnerAccount = () => {
  const { data, ...props } = useUser();
  const accountId = useAccountIdStore(state => state.accountId);

  const account = data?.accounts.find((account): account is PartnerAccount => isPartnerAccount(account) && account.id === accountId);

  return {
    account,
    ...props,
  };
};

export const useCurrentAccount = () => {
  const { data, ...props } = useUser();
  const accountId = useAccountIdStore(state => state.accountId);

  const account = data?.accounts.find((account): account is CustomerAccount | PartnerAccount => account.id === accountId);

  return {
    account,
    ...props,
  };
};

export const usePartnerCustomers = (partnershipId: string, query: string, limit: number, offset: number) => {
  return useQuery({
    queryKey: ['user', 'partner', 'customers', query, limit, offset],
    queryFn: () => api.getPartnersCustomers(partnershipId, query, limit, offset),
    staleTime: ONE_HOUR,
    enabled: !!partnershipId,
  });
};
