import { getI18n } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ONE_DAY } from '@utils/constants';

import { getPortfolioBySlug, getPortfoliosFromIds, getProjectsFromIds } from './project';
import type { ISanityPortfolio, ISanityProject, LocalizedSanityPortfolio, LocalizedSanityProject } from './project.types';

export const useSanityProjects = (ids: string[]) => {
  const lang = getI18n().language;

  return useQuery({
    queryKey: ['sanityProjects', ids],
    queryFn: () => getProjectsFromIds(ids),
    staleTime: ONE_DAY,
    enabled: !!ids.length,
    select: data => data.map(project => toLocalizedSanityProject(project, lang)),
  });
};

export const useSanityPortfolios = (ids: string[]) => {
  const lang = getI18n().language;

  return useQuery({
    queryKey: ['sanityPortfolios', ids],
    queryFn: () => getPortfoliosFromIds(ids),
    staleTime: ONE_DAY,
    enabled: !!ids.length,
    select: data => data.map(portfolio => toLocalizedSanityPortfolio(portfolio, lang)),
  });
};

export const useSanityPortfolio = (slug: string) => {
  const lang = getI18n().language;

  return useQuery({
    queryKey: ['sanityPortfolios', slug],
    queryFn: () => getPortfolioBySlug(slug),
    staleTime: ONE_DAY,
    select: data => toLocalizedSanityPortfolio(data, lang),
  });
};

const toLocalizedSanityPortfolio = (portfolio: ISanityPortfolio, lang: string): LocalizedSanityPortfolio => ({
  _id: portfolio._id,
  slug: portfolio.slug.current,
  title: portfolio.title?.textContent?.[lang],
  subtitle: portfolio.subtitle?.textContent?.[lang],
  description: portfolio.description?.textContent?.[lang],
  mainImage: portfolio.mainImage,
  projects: portfolio.projects.map(p => toLocalizedSanityProject(p, lang)),
});

const toLocalizedSanityProject = (project: ISanityProject, lang: string): LocalizedSanityProject => ({
  _id: project._id,
  supplyType: project.supplyType!,
  lcfType: project.lcfType,
  projectTheme: {
    title: project.projectTheme?.title?.[lang] ?? '',
    category: {
      title: project.projectTheme?.category?.title?.[lang] ?? '',
      slug: project.projectTheme?.category?.slug?.current ?? '',
    },
  },
  title: project.title[lang],
  slug: project.slug.current,
  subtitle: project.subtitle?.[lang],
  portalDescription: project.portalDescription?.[lang] ?? [],
  description: project.description?.[lang] ?? [],
  benefits: project.benefits?.[lang] ?? [],
  howItWorks: project.howItWorks?.[lang] ?? [],
  whyThisProject: project.whyThisProject?.[lang] ?? [],
  city: project.city?.[lang],
  country: project.country?.[lang],
  exactLocation: project.exactLocation,
  projectFactSheet: project.projectFactSheet,
  mainImage: project.mainImage,
  additionalImages: project.additionalImages ?? [],
  projectOwner: project.projectOwner?.[lang],
  certifiedBy:
    project.certifiedBy?.map(certification => ({
      title: certification.title?.textContent?.[lang] ?? '',
      description: certification.description?.textContent?.[lang],
      logo: certification.logo,
    })) ?? [],
  sdgs:
    project?.sdgs?.map(sdg => ({
      _id: sdg.sdg._id,
      sdgNumber: sdg.sdg.sdgNumber,
      title: sdg.sdg.title?.[lang],
      subtitle: sdg.sdg.subtitle?.[lang],
      description: sdg.sdg.description?.[lang],
      backgroundImage: sdg.sdg.backgroundImage,
      sdgColor: sdg.sdg.sdgColor,
      icon: sdg.sdg.icon,
      localizedIcon: sdg.sdg.localizedIcon?.[lang],
      slug: sdg.sdg.slug?.current,
    })) ?? [],
});
