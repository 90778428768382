import { defineStyleConfig } from '@chakra-ui/react';

const Avatar = defineStyleConfig({
  baseStyle: {
    container: {
      bg: 'gray.100',
      color: 'primary.800',
    },
  },
});

export default Avatar;
