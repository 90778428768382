import { Account } from '@api/user/types';
import { isCustomerAccount, isPartnerAccount } from '@utils/contextChecker';

import { NavigationMenuItem } from '..';
import { mapToReactIcon } from './IconMapper';

export const getNavigationLinks = (
  t: (key: string) => string,
  account: Account,
  isNewEmissionsTableEnabled: boolean,
  isTablet: boolean,
  canViewOffset: boolean,
  canViewFootprintsUpload: boolean,
  canViewFootprintsCompensation: boolean,
  canViewOrders: boolean,
  isCORSIAPageEnabled: boolean,
): NavigationMenuItem[] => {
  return [
    {
      id: 'dashboard',
      text: t('menu.dashboard'),
      link: isPartnerAccount(account) ? '/connect' : '/',
      icon: mapToReactIcon('dashboard'),
      showInNavbar: !isTablet,
      hideFor: ['Transact'],
    },
    {
      id: 'emissions',
      text: t('menu.emissions'),
      link: '/emissions',
      icon: mapToReactIcon('emissions'),
      showInNavbar: !isTablet,
      hide: !canViewFootprintsUpload || (isCustomerAccount(account) && isNewEmissionsTableEnabled),
      hideFor: ['Transact'],
    },
    {
      id: 'addressEmissions',
      text: t('menu.addressEmissions'),
      link: '/address-emissions',
      icon: mapToReactIcon('addressEmissions'),
      showInNavbar: !isTablet,
      hide: !canViewFootprintsCompensation || !isNewEmissionsTableEnabled,
      hideFor: ['Transact', 'WeChoooseCommmunity'],
    },
    {
      id: 'offset',
      text: t('menu.offset'),
      link: '/offset',
      icon: mapToReactIcon('addressEmissions'),
      showInNavbar: !isTablet,
      hide: !canViewOffset,
      hideFor: ['Transact', 'WeChooose'],
    },
    {
      id: 'corsia',
      text: t('menu.corsia'),
      link: '/CORSIA',
      icon: mapToReactIcon('dashboard'),
      hide: !isCORSIAPageEnabled,
      showInNavbar: !isTablet,
      hideFor: ['WeChooose', 'WeChoooseCommmunity', 'MyChooose', 'Supplier'],
    },
    {
      id: 'orders',
      text: t('menu.orders'),
      link: '/orders',
      icon: mapToReactIcon('orders'),
      showInNavbar: !isTablet,
      hideFor: ['WeChoooseCommmunity'],
      hide: !canViewOrders,
    },
    {
      id: 'analytics',
      text: t('communityMenu.analytics'),
      link: '/analytics',
      icon: mapToReactIcon('analytics'),
      showInNavbar: !isTablet,
    },
    {
      id: 'projects',
      text: t('menu.projects'),
      link: '/portfolios',
      icon: mapToReactIcon('projects'),
      showInNavbar: !isTablet,
    },

    {
      id: 'billingHistory',
      text: t('profileMenu.billingHistory'),
      link: '/billing-history',
      icon: mapToReactIcon('files'),
      showInNavbar: false,
    },
  ];
};

export const filterOutBillingHistory = (condition: boolean) => (item: { id: string }) => (item.id === 'billingHistory' ? condition : true);
