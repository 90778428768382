// eslint-disable-next-line simple-import-sort/imports
import { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { msalApp } from '@lib/msal';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// eslint-disable-next-line no-restricted-imports
import { BaseLoaderFullPage } from 'components/Loader';
import { PostHogProvider } from 'posthog-js/react';
import store from '@store';
import { client as posthogClient } from '@lib/posthog';
import { client as reactQueryClient } from '@utils/reactQuery';

import App from './App';
import { withAppInsights } from '@lib/appInsights/UseAppInsights';
import { AuthWatcher } from '@lib/msal/AuthWatcher';
import { AppInsightsWatcher } from '@lib/appInsights/AppInsightsWatcher';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
async function enableMocking() {
  if (import.meta.env.REACT_APP_ENABLE_MOCKING !== 'true') {
    return;
  }

  const { worker } = await import('./api/mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() => {
  root.render(
    withAppInsights(
      <MsalProvider instance={msalApp}>
        <AppInsightsWatcher />
        <AuthWatcher />
        <Provider store={store}>
          <QueryClientProvider client={reactQueryClient}>
            <CookiesProvider>
              <PostHogProvider client={posthogClient}>
                <Router>
                  <Suspense fallback={<BaseLoaderFullPage />}>
                    <App />
                  </Suspense>
                </Router>
              </PostHogProvider>
            </CookiesProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Provider>
      </MsalProvider>,
    ),
  );
});
