/* eslint-disable no-console */
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const connectionString = import.meta.env.REACT_APP_INSIGHTS_CONNECTION_STRING;
let appInsights: ApplicationInsights | undefined;
const reactPlugin = new ReactPlugin();

if (!connectionString) {
  console.error('App Insights connection string is missing');
} else {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: [
        '*.blob.core.windows.net',
        'analysis.chooose.today',
        'location.chooose.today',
        'tags.chooose.today',
        'telemetry.chooose.today',
      ],
    },
  });

  appInsights.addTelemetryInitializer(item => {
    if (!item) {
      return;
    }

    item.tags = item.tags || [];
    item.tags.push({ 'ai.cloud.role': 'crp' });
    item.tags.push({ 'ai.cloud.roleInstance': import.meta.env.REACT_APP_PIPELINE_NAME || 'portal-dev' });
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { appInsights, reactPlugin };
