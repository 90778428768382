export const GET_QUOTE_FOR_FOOTPRINTS_QUERY_KEY = ['order', 'useGetQuoteForFootprints'];
export const CREATE_QUOTE_FOR_FOOTPRINTS_MUTATION_KEY = ['order', 'useCreateQuoteForFootprints'];

export const VENDORS = {
  bp: 'Air BP',
  exxonMobil: 'ExxonMobil',
  gevo: 'Gevo',
  neste: 'Neste',
  omv: 'OMV',
  philips66: 'Philips 66',
  repsol: 'Repsol',
  shell: 'Shell',
  skyNrg: 'SkyNRG',
  worldEnergy: 'World Energy',
} as const;

export const PRODUCERS = {
  bp: 'Air BP',
  exxonMobil: 'ExxonMobil',
  gevo: 'Gevo',
  neste: 'Neste',
  omv: 'OMV',
  philips66: 'Philips 66',
  repsol: 'Repsol',
  shell: 'Shell',
  skyNrg: 'SkyNRG',
  worldEnergy: 'World Energy',
} as const;

export const FEEDSTOCK_TYPES = {
  FOGS: 'Fats, oils, and greases',
  ETHL: 'Corn grain',
  OSED: 'Oil seeds',
  ALGE: ' Algae',
  AGRS: 'Agricultural residues',
  FRRS: 'Forestry residues',
  WDWS: 'Wood mill waste',
  WDBM: 'Woody biomass',
  MSWS: 'Municipal solid waste streams',
  WTWS: 'Wet wastes',
} as const;

export const CERTIFICATE_SCHEMES = {
  ISCC: ['International Sustainability Carbon Certification', ['PLUS', 'CORSIA', 'EU']],
  TRSB: ['The Roundtable on Sustainable Biomaterials', ['Global Fuels', 'EU RED', 'ICAO CORSIA']],
  OTHR: 'Other',
} as const;
