import { AxiosInstance } from 'axios';

import { createContextAwareHandlers } from './instance';

interface EmployeeBenefitParams {
  interval?: string;
  category?: string;
  type?: string;
  description?: string;
  items?: SubscriptionItem[];
  attachments?: SubscriptionAttachment[];
  cancelAtPeriodEnd?: boolean;
  newEmployeeOffsets?: NewEmployeeOffset[];
  newEmployeeInvites?: UserPayload[];
}

interface UserInviteData {
  employeeCountryCodeIso2: string;
  employeeOffset: boolean;
  employeeOffsetFromDate: string;
  employeeOffsetToDate: string;
  inviteId: string;
  status: string;
  userAccountId: string;
  userId: string;
  userAccount: {
    email: string;
  };
  role: {
    id: string;
    name: string;
  };
}

interface EmployeeBenefitResponse {
  subscription: SubscriptionObject;
  invites: UserInviteData[];
  orderId: string;
}

const create =
  (instance: AxiosInstance) =>
  async (benefit: EmployeeBenefitParams, customerId: string): Promise<EmployeeBenefitResponse> => {
    const { data } = await instance.post<EmployeeBenefitResponse>(`/customers/${customerId}/subscriptions`, benefit);

    return data;
  };

const update =
  (instance: AxiosInstance) =>
  async (subscriptionId: string, benefit: EmployeeBenefitParams, customerId: string): Promise<EmployeeBenefitResponse> => {
    const { data } = await instance.put<EmployeeBenefitResponse>(`/customers/${customerId}/subscriptions/${subscriptionId}`, benefit);

    return data;
  };

const remove =
  (instance: AxiosInstance) =>
  async (subscriptionId: string, customerId: string): Promise<EmployeeBenefitResponse> => {
    const { data } = await instance.put<EmployeeBenefitResponse>(`/customers/${customerId}/subscriptions/${subscriptionId}`, {
      cancelAtPeriodEnd: true,
    });

    return data;
  };

const getPerCapitaFootprint =
  (instance: AxiosInstance) =>
  async (countryCode: string, people: number): Promise<PerCapitaOffset> => {
    const { data } = await instance.get<PerCapitaOffset>(`/footprint/percapita/pik/${countryCode}`, {
      params: {
        years: 0,
        months: 1,
        days: 0,
        persons: people,
      },
    });

    return data;
  };

const handlers = {
  create,
  update,
  remove,
  getPerCapitaFootprint,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createEmployeeBenefitApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createEmployeeBenefitApi;
