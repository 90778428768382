import { Account } from '@api/user/types';

type AccountType = Account['type'];

export function hasType(has: AccountType[], want: AccountType[]): boolean {
  for (const role of want) {
    if (has.includes(role)) {
      return true;
    }
  }

  return false;
}

export function getTypes(accounts?: Account[]): AccountType[] {
  if (!accounts) {
    return [];
  }

  return accounts.map(account => account.type);
}
