import axios, { CancelTokenSource } from 'axios';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createFootprintAirFreightApi from '@api/airFreightFootprint';
import { AppThunk, RootState } from '@store';
import { appContextSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { currentUserSelector, customMetadataSelector } from '@store/user/userSlice';
import checkJob from '@utils/checkJob';
import { fixFloat } from '@utils/numberFormatter';
import { getSessionStorageObject, setSessionStorageObject } from '@utils/sessionStorage';

import { DataProps } from './types';

const CancelToken = axios.CancelToken;
let source: CancelTokenSource = CancelToken.source();

interface FootprintInfo {
  emissionName?: string;
  description?: string;
  [key: string]: string | Option | Date | undefined | boolean | number;
}

export interface CustomMetadata {
  [key: string]: string | Option | number | undefined | boolean | string[] | Date | Deduction[];
}

interface AirFreightFootprintState extends CustomMetadata {
  amount?: number;
  totalKilosCo2?: number;
  isLoading: boolean;
  emissionName?: string;
  country?: Option;
  department?: string;
  description?: string;
  isFootprintInfoInvalid: boolean;
  selectedCustomMetadata?: string[];
  fileUploading?: boolean;
  fileError?: boolean;
  fileErrors?: string[];
  fileSuccess?: boolean;
  footprintsCount?: number;
  fileProcessing?: boolean;
  progressPercentage?: number;
  isUploadingFileCancelled?: boolean;
  storageBlobName?: string;
  deductions?: Deduction[];
  undeductedTotalKilosCo2?: number;
  isCreatingFootprint?: boolean;
}

const initialState: AirFreightFootprintState = {
  mode: 'upload',
  isLoading: false,
  isFootprintInfoInvalid: true,
  emissionName: '',
  description: '',
};

const tonnesFactor = 1000;

const slice = createSlice({
  name: 'airFreightFootprint',
  initialState,
  reducers: {
    reset: (): AirFreightFootprintState => {
      return initialState;
    },
    setLoading: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.isLoading = action.payload;

      return state;
    },
    setFootprintInfo: (state: AirFreightFootprintState, action: PayloadAction<FootprintInfo>): AirFreightFootprintState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setFootprintInfoInvalid: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.isFootprintInfoInvalid = action.payload;

      return state;
    },
    setSelectedCustomMetadata: (state: AirFreightFootprintState, action: PayloadAction<string[]>): AirFreightFootprintState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    resetUploadState: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.fileUploading = action.payload;
      state.fileProcessing = action.payload;
      state.fileError = action.payload;
      state.progressPercentage = 0;
      state.isUploadingFileCancelled = true;
      state.fileErrors = [];
      state.fileSuccess = false;
      state.amount = undefined;
      state.totalKilosCo2 = undefined;
      state.footprintsCount = undefined;
      state.deductions = undefined;
      state.undeductedTotalKilosCo2 = undefined;

      return state;
    },
    setFileUploading: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.fileUploading = action.payload;

      return state;
    },
    setFileError: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.fileError = action.payload;

      return state;
    },
    setFootprintsCount: (state: AirFreightFootprintState, action: PayloadAction<number>): AirFreightFootprintState => {
      state.footprintsCount = action.payload;

      return state;
    },
    setFileErrors: (state: AirFreightFootprintState, action: PayloadAction<string[]>): AirFreightFootprintState => {
      state.fileErrors = action.payload;

      return state;
    },
    setFileSuccess: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.fileSuccess = action.payload;

      return state;
    },
    setUploadTotalAmount: (
      state: AirFreightFootprintState,
      action: PayloadAction<{ amount: number; kilosCo2: number }>,
    ): AirFreightFootprintState => {
      state.amount = action.payload.amount;
      state.totalKilosCo2 = action.payload.kilosCo2;

      return state;
    },
    setProgressPercentage: (state: AirFreightFootprintState, action: PayloadAction<number>): AirFreightFootprintState => {
      state.progressPercentage = action.payload;

      return state;
    },
    setFileProcessing: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.fileProcessing = action.payload;

      return state;
    },
    resetUploadingFileCancelled: (state: AirFreightFootprintState): AirFreightFootprintState => {
      state.isUploadingFileCancelled = false;

      return state;
    },
    setStorageBlobName: (state: AirFreightFootprintState, action: PayloadAction<string>): AirFreightFootprintState => {
      state.storageBlobName = action.payload;

      return state;
    },
    setUndeductedTotalKilosCo2: (state: AirFreightFootprintState, action: PayloadAction<number | undefined>): AirFreightFootprintState => {
      state.undeductedTotalKilosCo2 = action.payload;

      return state;
    },
    setDeductions: (state: AirFreightFootprintState, action: PayloadAction<Deduction[] | undefined>): AirFreightFootprintState => {
      state.deductions = action.payload;

      return state;
    },
    setIsCreatingFootprint: (state: AirFreightFootprintState, action: PayloadAction<boolean>): AirFreightFootprintState => {
      state.isCreatingFootprint = action.payload;

      return state;
    },
  },
});

const {
  reset,
  setFootprintInfo,
  setLoading,
  setFootprintInfoInvalid,
  setSelectedCustomMetadata,
  resetUploadState,
  setFileUploading,
  setFileError,
  setFileErrors,
  setFileSuccess,
  setUploadTotalAmount,
  setFootprintsCount,
  setFileProcessing,
  setProgressPercentage,
  resetUploadingFileCancelled,
  setStorageBlobName,
  setUndeductedTotalKilosCo2,
  setDeductions,
  setIsCreatingFootprint,
} = slice.actions;

export { reset, resetUploadState, setFootprintInfo, setFootprintInfoInvalid, setSelectedCustomMetadata };
export default slice.reducer;

const checkFootprint =
  (jobId: string, partnershipId: string, errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const { mode, storageBlobName, emissionName, description } = getState().airFreightFootprint;
    const isUploadMode = mode === 'upload';

    dispatch(setLoading(true));
    if (isUploadMode) {
      try {
        dispatch(setIsCreatingFootprint(true));
        if (storageBlobName && emissionName && description) {
          const result = await checkJob<FlightsFootprintProcessingFileSummary>({
            partnershipId,
            jobId,
            context,
            module: 'airFreightFootprint',
            setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
            setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
            setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
            setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
          });
          const errorMessages = result?.messages?.filter(({ type }) => type.toLowerCase() !== 'information')?.map(m => m.message);
          if (result.status === 'Succeeded' && errorMessages.length === 0) {
            successCb && successCb();
          } else {
            dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
            if (Array.isArray(errorMessages)) {
              dispatch(setFileErrors(errorMessages));
            }
            errorCb && errorCb();
          }
        }
        dispatch(setIsCreatingFootprint(false));
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setIsCreatingFootprint(false));
        dispatch(setLoading(false));
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
        errorCb && errorCb();
      }
    }
  };

export const handleCheckJob =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = getSessionStorageObject<DataProps>('airFreightFootprint');

    if (data) {
      // NOTE(@pkgacek): `mode` is `upload` by default
      if (data.storageBlobName && data.totalKilosCo2 && data.amount && data.footprintsCount) {
        dispatch(setFootprintInfo({ emissionName: data.emissionName, description: data.description }));
        dispatch(resetUploadingFileCancelled());
        dispatch(setFootprintsCount(data.footprintsCount));
        dispatch(setStorageBlobName(data.storageBlobName));
        dispatch(setFileErrors([]));
        dispatch(setFileSuccess(true));
        dispatch(setFileUploading(true));
        dispatch(setUploadTotalAmount({ amount: data.amount, kilosCo2: data.totalKilosCo2 }));
        if (Array.isArray(data.deductions) && data.deductions.length > 0) {
          dispatch(setDeductions(data.deductions));
          dispatch(setUndeductedTotalKilosCo2(data.undeductedTotalKilosCo2));
        }
      }
      try {
        dispatch(checkFootprint(data.jobId, data.partnershipId, errorCb, successCb));
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    }
  };

export const createAirFreightFootprint =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const airFreightAPI = createFootprintAirFreightApi(context);
    const app = getState().app;
    const currentUser = currentUserSelector(getState());
    const customMetadata = customMetadataSelector(getState()) ?? [];
    const { storageBlobName, emissionName, description, ...restProps } = getState().airFreightFootprint;
    const isConnectContext = context === 'connect';
    const partnershipId = isConnectContext ? currentUser?.partnershipId ?? '' : app.partnershipId ?? '';
    const customerId = currentUser?.customerId ?? '';

    const customMetadataPayload: CustomMetadata = {};
    customMetadata?.map(
      ({ internalName }) =>
        // @ts-ignore
        (customMetadataPayload[internalName] = restProps[internalName]?.value ? restProps[internalName]?.value : restProps[internalName]),
    );
    dispatch(setLoading(true));
    dispatch(setIsCreatingFootprint(true));
    try {
      source = CancelToken.source();
      if (storageBlobName && emissionName && description) {
        const payload = {
          storageBlobName,
          emissionName,
          description,
          ...customMetadataPayload,
        };
        const response = isConnectContext
          ? await airFreightAPI.createBatchOrderForPartner(payload, source.token, partnershipId)
          : await airFreightAPI.createBatchOrderForCustomer(payload, source.token, customerId);

        setSessionStorageObject('airFreightFootprint', {
          storageBlobName,
          jobId: response.jobId,
          partnershipId: response.partnershipId,
          footprintsCount: restProps.footprintsCount,
          amount: restProps.amount,
          totalKilosCo2: restProps.totalKilosCo2,
          deductions: restProps.deductions,
          undeductedTotalKilosCo2: restProps.undeductedTotalKilosCo2,
          emissionName,
          description,
        });
        dispatch(checkFootprint(response.jobId, response.partnershipId, errorCb, successCb));
      }
      dispatch(setIsCreatingFootprint(true));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setIsCreatingFootprint(true));
      dispatch(setLoading(false));
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
      errorCb && errorCb();
    }
  };

export const processFile =
  (file: File, onError: () => void): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setFileSuccess(false));
    dispatch(setFileProcessing(true));
    dispatch(setUploadTotalAmount({ amount: 0, kilosCo2: 0 }));
    dispatch(resetUploadingFileCancelled());
    dispatch(setDeductions(undefined));
    dispatch(setUndeductedTotalKilosCo2(undefined));
    const app = getState().app;
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const isConnectContext = context === 'connect';
    const airFreightAPI = createFootprintAirFreightApi(context);
    const partnershipId = isConnectContext ? currentUser?.partnershipId ?? '' : app.partnershipId ?? '';
    const customerId = currentUser?.customerId ?? '';

    try {
      source = CancelToken.source();

      const batchResponse = isConnectContext
        ? await airFreightAPI.uploadFootprintBatchForPartner(file, source.token, partnershipId)
        : await airFreightAPI.uploadFootprintBatchForCustomer(file, source.token, customerId);

      if (batchResponse.partnershipId && batchResponse.jobId) {
        const response = await checkJob<FlightsFootprintProcessingFileSummary>({
          partnershipId: batchResponse.partnershipId,
          jobId: batchResponse.jobId,
          context,
          module: 'airFreightFootprint',
          setFileProcessingCb: (state: boolean) => dispatch(setFileProcessing(state)),
          setFileUploadingCb: (state: boolean) => dispatch(setFileUploading(state)),
          setFileErrorCb: (state: boolean) => dispatch(setFileError(state)),
          setProgressPercentageCb: (percentage: number) => dispatch(setProgressPercentage(percentage)),
        });

        const summary = response?.summary?.footprints;
        const footprintsCount = summary?.count;
        const storageBlobName = summary?.storageBlobName;
        const kilosCo2 = summary?.totalCo2Kilos;
        if (storageBlobName && footprintsCount && kilosCo2) {
          dispatch(setFootprintsCount(footprintsCount));
          dispatch(setStorageBlobName(storageBlobName));
          dispatch(setFileErrors([]));
          dispatch(setFileSuccess(true));
          dispatch(setUploadTotalAmount({ amount: kilosCo2 / tonnesFactor, kilosCo2 }));
          if (Array.isArray(summary?.deductions) && summary?.deductions.length > 0) {
            dispatch(setDeductions(summary?.deductions));
            dispatch(setUndeductedTotalKilosCo2(summary?.undeductedFootprint?.kilosCo2e ?? summary?.undeductedFootprint?.kilosCo2));
          }
        } else {
          if (Array.isArray(response.messages) && response.messages.length > 0) {
            const textMessages = response.messages.map(({ message }) => message);
            dispatch(setFileErrors(textMessages));
          } else if (response.progressPercentage === 100) {
            onError();
            dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
          }
        }
      }
    } catch (err) {
      // @ts-ignore
      if (err && err.data) {
        // @ts-ignore
        const { data } = err.data;
        if (data && Array.isArray(data)) {
          dispatch(setFileErrors(data));
          // @ts-ignore
        }
      } else {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'footprints:createFootprintError' }));
      }
      onError();
    }
  };

export const flightAmountSelector = createSelector(
  (state: RootState) => state.airFreightFootprint,
  state => {
    const { amount } = state;

    return fixFloat(amount ?? 0);
  },
);
