import { extendTheme } from '@chakra-ui/react';

import { appMaxWidth, colors, CTA, layerStyles, radii, shadows, textStyles } from './common';
import { fontSizes, setFontFamily } from './common/fonts';
import GlobalThemeStyles from './common/GlobalStyles';
import components from './components';

import { breakpoints } from '@styles';

const fontFamily = setFontFamily();

const base = (customColors?: CorporateColors, customFontSize?: number, customButtonBorderRadius?: number) => ({
  radii,
  textStyles,
  layerStyles: layerStyles(customFontSize),
  fonts: {
    body: fontFamily,
    heading: fontFamily,
  },
  fontSizes: fontSizes(customFontSize),
  breakpoints,
  colors: colors(customColors),
  shadows,
  components: components(customButtonBorderRadius),
});

type AppTheme = ReturnType<typeof base>;

const getTheme = (customColors?: CorporateColors, customFontSize?: number, customButtonBorderRadius?: number) =>
  extendTheme(base(customColors, customFontSize, customButtonBorderRadius)) as AppTheme;

export { appMaxWidth, colors, CTA, fontSizes, getTheme, GlobalThemeStyles, radii };
export type { AppTheme };
