import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { Flex } from '@chakra-ui/react';
import { DropdownMenu, DropdownMenuItem, IconButton, Menu, MenuItem, MenuSection, MenuWrapper } from '@chooose/ui';
import { useTheme } from 'styled-components';
import { usePartnershipSettings } from '@api/partnership/queries';
import { useCurrentAccount } from '@api/user/queries';
import { Account } from '@api/user/types';
import { ContextModal } from '@components/ContextModal';
import { PerUserOnboarding } from '@components/types';
import { useFeature } from '@hooks/partnershipFeatureFlags/useFeature';
import { useIsFootprintsCreationEnabled } from '@hooks/partnershipFeatureFlags/useIsFootprintsCreationEnabled';
import { useIsOffsetEnabled } from '@hooks/partnershipFeatureFlags/useIsOffsetEnabled';
import { useAnalytics } from '@hooks/useAnalytics';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useLoadedFeatureFlag } from '@hooks/useLoadedFeatureFlag';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { List, Steps, User, X } from '@icons';
import { isPartnerAccount, isTransactAccount } from '@utils/contextChecker';
import { ONBOARDING_FLOW_LOCAL_STORAGE_KEY } from '@utils/onboardingData';
import { AbilityContext } from '@utils/permissions/Can';

import { Overlay } from '../Overlay';
import { GuestCertificateItems, LogoOnlyNavbar, MiscNavbarItems } from './CommonNavbarItems';
import ContextSwitcher from './ContextSwitcher';
import { PartnershipLogo } from './PartnershipLogo';
import { filterOutBillingHistory, getNavigationLinks } from './utils/getNavigationLinks';

import * as s from './styles';

export type NavigationMenuItem = {
  hideFor?: string[];
  icon: JSX.Element;
  id: string;
  link: string;
  showInNavbar: boolean;
  text: string;
  hide?: boolean;
};

const getPortalContext = (accountType: Account['type']) => {
  const accountTypeToContext: { [x: string]: PortalContext } = {
    WeChooose: 'wechooose',
    WeChoooseCommmunity: 'connect',
  };

  return accountTypeToContext[accountType];
};

export const Navbar = () => {
  const { ready, t } = useTranslation('common');
  const { isTablet } = useBreakpoint();
  const { colors } = useTheme();
  const { account } = useCurrentAccount();

  const location = useLocation();
  const { track } = useAnalytics();

  const isGuestCertificate = location.pathname.startsWith('/guest/certificate');
  const isTermsView = location.pathname.startsWith('/terms');
  const [isModalShown, setIsModalShown] = useState(false);

  const isTransact = isTransactAccount(account);

  const isFootprintsUploadEnabled = useIsFootprintsCreationEnabled();
  const isEmissionCompensateFlights = useFeature('EmissionCompensate_Flights');
  const isEmissionCompensateAirFreight = useFeature('EmissionCompensate_AirFreight');
  const isFootprintsCompensationEnabled = isEmissionCompensateFlights || isEmissionCompensateAirFreight;
  const isOffsetEnabled = useIsOffsetEnabled();

  const [isOpen, setIsOpen] = useState(false);

  const ability = useAbility(AbilityContext);
  const canCreateOrders = ability.can('create', 'Orders') || isPartnerAccount(account);
  const canViewOrders = ability.can('view', 'Orders');

  const canViewOffset = canCreateOrders && isOffsetEnabled;
  const canViewFootprintsUpload = canCreateOrders && isFootprintsUploadEnabled;
  const canViewFootprintsCompensation = ability.can('create', 'Orders') && isFootprintsCompensationEnabled;

  const profileName = account?.profile?.name ?? account?.profile?.preferredName ?? '';
  const { data: partnershipSettings } = usePartnershipSettings();
  const colorScheme = partnershipSettings?.colors?.navbarColorScheme?.toLowerCase() === 'dark' ? 'dark' : 'light';

  const { isFeatureFlagEnabled: isNewEmissionsTableEnabled } = useLoadedFeatureFlag(
    'new_emissions_table',
    import.meta.env.REACT_APP_NEW_EMISSIONS_TABLE,
  );
  const { isFeatureFlagEnabled: isNewBillingHistoryEnabled } = useLoadedFeatureFlag(
    'new_billing_history',
    import.meta.env.REACT_APP_NEW_BILLING_HISTORY,
  );

  const isCORSIAPageEnabled = useFeature('Corsia');

  const navigationLinks: NavigationMenuItem[] = account
    ? getNavigationLinks(
        t,
        account,
        isNewEmissionsTableEnabled,
        isTablet,
        canViewOffset,
        canViewFootprintsUpload,
        canViewFootprintsCompensation,
        canViewOrders,
        isCORSIAPageEnabled,
      )
        ?.filter(({ hide, hideFor }) => !hide && !hideFor?.includes(account?.type ?? ''))
        .filter(filterOutBillingHistory(isNewBillingHistoryEnabled))
    : [];

  const userId = account?.id ?? 'user';
  const [onboardingStatus, setOnboardingStatus] = useLocalStorage<PerUserOnboarding>(ONBOARDING_FLOW_LOCAL_STORAGE_KEY);
  const handleOnboardingClick = () => {
    if (!account) {
      return;
    }
    const context = getPortalContext(account.type);

    return setOnboardingStatus({
      ...onboardingStatus,
      [userId]: {
        settings: {
          isModalOpen: true,
          isEnabled: true,
        },
        data: {
          [context]: {
            status: 'active',
            completedTasks: [] as string[],
          },
        },
      },
    } as PerUserOnboarding);
  };

  const trackLinkClick = (route: string) => {
    track('navlink_clicked', { page: route });
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  if (!ready) {
    return <Overlay overlayHeight='100%' type='header' />;
  }

  if (isTermsView) {
    return (
      <s.DesktopNavbarContainer>
        <MenuWrapper theme={colorScheme}>
          <s.DesktopNavbarInnerWrapper>
            <LogoOnlyNavbar shouldUseLightLogo={colorScheme === 'dark'} />
          </s.DesktopNavbarInnerWrapper>
        </MenuWrapper>
      </s.DesktopNavbarContainer>
    );
  }

  return (
    <>
      <ContextModal isOpen={isModalShown} onClose={(): void => setIsModalShown(false)} />

      {isTablet ? (
        <s.MobileNavbarWrapper>
          <MenuWrapper theme={colorScheme}>
            {!isGuestCertificate ? (
              <Flex w='100%' flexDir='column'>
                <s.MobileMenu>
                  <PartnershipLogo shouldUseLightLogo={colorScheme === 'dark'} />
                  <IconButton size='sm' onClick={() => setIsOpen(!isOpen)} variant='icon'>
                    {isOpen ? (
                      <X weight='bold' color={colors.text.secondary} size={24} />
                    ) : (
                      <List color={colors.text.secondary} size={24} weight='bold' />
                    )}
                  </IconButton>
                </s.MobileMenu>
                <s.MobileMenuDropdown $isOpen={isOpen}>
                  <ContextSwitcher />

                  {!!navigationLinks.length && (
                    <MenuSection title={t('menu.navigation')}>
                      {navigationLinks.map(({ text, link, id, icon, showInNavbar }) => {
                        const isActive = (link === '/' ? matchPath(location.pathname, link)?.isExact : location.pathname.includes(link)) || false;
                        if (!showInNavbar) {
                          return (
                            <NavLink key={id} to={link}>
                              <DropdownMenuItem
                                isActive={isActive}
                                icon={icon}
                                key={id}
                                title={text}
                                variant='secondary'
                                onClick={() => trackLinkClick(text)}
                              />
                            </NavLink>
                          );
                        }
                      })}

                      {!isTransact && (
                        <DropdownMenuItem
                          icon={<Steps weight='bold' />}
                          key='tutorial'
                          title={t('routes.onboarding', { ns: 'settings' })}
                          variant='secondary'
                          onClick={handleOnboardingClick}
                        />
                      )}
                    </MenuSection>
                  )}

                  <MiscNavbarItems />
                </s.MobileMenuDropdown>
              </Flex>
            ) : (
              <GuestCertificateItems setIsModalShown={setIsModalShown} shouldUseLightLogo={colorScheme === 'dark'} />
            )}
          </MenuWrapper>
        </s.MobileNavbarWrapper>
      ) : (
        <s.DesktopNavbarContainer>
          <MenuWrapper theme={colorScheme}>
            <s.DesktopNavbarInnerWrapper>
              {!isGuestCertificate ? (
                <>
                  <Menu startAdornment={<PartnershipLogo shouldUseLightLogo={colorScheme === 'dark'} />} align='right'>
                    {!!navigationLinks.length &&
                      navigationLinks
                        .filter(({ showInNavbar }) => showInNavbar)
                        .map(({ text, link, id }) => {
                          const isActive = (link === '/' ? matchPath(location.pathname, link)?.isExact : location.pathname.includes(link)) || false;

                          return (
                            <NavLink key={id} to={link}>
                              <MenuItem isActive={isActive} key={id} title={text} variant='secondary' onClick={() => trackLinkClick(text)} />
                            </NavLink>
                          );
                        })}
                  </Menu>

                  <Menu align='right' data-testid='profile-dropdown-menu-button'>
                    <DropdownMenu icon={<User weight='bold' />} title={profileName}>
                      <ContextSwitcher />

                      {!!navigationLinks.length && (
                        <MenuSection title={t('menu.navigation')}>
                          {navigationLinks.map(({ text, link, id, icon, showInNavbar }) => {
                            if (!showInNavbar) {
                              return (
                                <NavLink key={id} to={link}>
                                  <DropdownMenuItem icon={icon} key={id} title={text} variant='secondary' onClick={() => trackLinkClick(text)} />
                                </NavLink>
                              );
                            }
                          })}

                          {!isTransact && (
                            <DropdownMenuItem
                              icon={<Steps weight='bold' />}
                              key='tutorial'
                              title={t('routes.onboarding', { ns: 'settings' })}
                              variant='secondary'
                              onClick={handleOnboardingClick}
                            />
                          )}
                        </MenuSection>
                      )}

                      <MiscNavbarItems />
                    </DropdownMenu>
                  </Menu>
                </>
              ) : (
                <GuestCertificateItems setIsModalShown={setIsModalShown} shouldUseLightLogo={colorScheme === 'dark'} />
              )}
            </s.DesktopNavbarInnerWrapper>
          </MenuWrapper>
        </s.DesktopNavbarContainer>
      )}
    </>
  );
};
