import { theme } from '@chakra-ui/react';
export default {
  ...theme.radii,
  base: '3px',
  medium: '5px',
  portfolio: '12px',
  large: '18px',
  button: '25px',
  round: '50%',
  table: '8px',
};
