import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createOrderApi, { OrderItem, OrderParams } from '@api/order';
import createShippingApi, { ShippingParams } from '@api/shippingOffset';
import { getOrderStatus, validateItemPortfolio } from '@pages/shared/CorporateOffset/utils';
import { AppThunk, RootState } from '@store';
import { appContextSelector, isEmissionsDashboardEnabledSelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { syncAttribution } from '@store/user/attributionSlice';
import { currentUserSelector, metadataSelector, userCurrencySelector } from '@store/user/userSlice';
import { calculateNauticalMilesToKm } from '@utils/calculateMiles';
import { getDisplayName } from '@utils/metadata';
import { fixFloat, roundNumberToTwo } from '@utils/numberFormatter';
import { client } from '@utils/reactQuery';
import { trackPurchaseEvent } from '@utils/tags';

import { singlePortfolioSanityIdSelector, virtualPortfoliosSelector } from './portfolioSelectSlice';

interface OffsetInfo {
  orderName?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  poNumber?: string;
  costCenter?: string;
  [key: string]: string | Option | Date | undefined | boolean | number;
}

interface OrderPayload {
  number: string;
  date: string;
  name?: string;
  id: string;
  claimCode?: string;
  inviteId?: string;
  billId?: string;
  orderStatus?: string;
}

export interface ShipSize {
  label: string;
  value: number;
}

interface ShipData {
  shipActivity: string;
  shipType: string;
  shipSize: ShipSize;
}

export interface ShippingOffsetState {
  amount?: number;
  totalKilosCo2?: number;
  totalKilosCo2Avoided?: number;
  totalKilosCo2Offset?: number;
  vat?: number;
  customerVat?: number;
  excise?: number;
  customerExcise?: number;
  customerPrice?: number;
  price?: number;
  currency?: string;
  isLoading: string[];
  metadataFetching: boolean;
  selectedPortfolioId?: string;
  orderName?: string;
  orderNumber?: string;
  orderDate?: string;
  orderId?: string;
  country?: Option;
  department?: string;
  description?: string;
  emissionScope?: Option;
  timeFrameFrom?: Date;
  timeFrameTo?: Date;
  miles?: boolean;
  wttEmissions: boolean;
  preCalculatedVoyage?: boolean;
  shippingMetadata: ShippingMetadata;
  shipActivity?: string;
  shipType?: string;
  shipSize?: ShipSize;
  distance?: number;
  cargoWeight?: number;
  isOrderInfoInvalid?: boolean;
  selectedCustomMetadata?: string[];
  isPaidByPartner?: boolean;
  customer?: PartnersCustomerOption;
  inviteId?: string;
  claimCode?: string;
  isMultiplierActive?: boolean;
  billId?: string;
  orderStatus?: string;
  attachmentTempUrl?: undefined;
  fullOffsetRows?: undefined;
  costCenter?: string;
  poNumber?: string;
  footprintId?: string;
  [key: string]: string | Option | Date | number | undefined | boolean | ShippingMetadata | ShipSize | string[];
}

const initialState: ShippingOffsetState = {
  isLoading: [],
  metadataFetching: false,
  wttEmissions: true,
  shippingMetadata: {},
  isOrderInfoInvalid: true,
};

const slice = createSlice({
  name: 'offsetShipping',
  initialState,
  reducers: {
    reset: (): ShippingOffsetState => {
      return { ...initialState };
    },
    resetWithoutPortfolioAndMetadata: (state: ShippingOffsetState): ShippingOffsetState => {
      return {
        ...initialState,
        selectedPortfolioId: state.selectedPortfolioId,
        shippingMetadata: state.shippingMetadata,
        isMultiplierActive: state.isMultiplierActive,
      };
    },
    resetAmountAndPrice: (state: ShippingOffsetState): ShippingOffsetState => {
      return { ...state, amount: 0, price: 0, customerPrice: 0 };
    },
    setLoading: (state: ShippingOffsetState, action: PayloadAction<{ value: string; state: boolean }>): ShippingOffsetState => {
      if (action.payload.state) {
        state.isLoading = [...state.isLoading, action.payload.value];
      } else {
        state.isLoading = state.isLoading.filter(item => item !== action.payload.value);
      }

      return state;
    },
    setMetadataFetching: (state: ShippingOffsetState, action: PayloadAction<boolean>): ShippingOffsetState => {
      state.metadataFetching = action.payload;

      return state;
    },
    setOrderInfo: (state: ShippingOffsetState, action: PayloadAction<OffsetInfo>): ShippingOffsetState => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key];
      });

      return state;
    },
    setOrderInfoInvalid: (state: ShippingOffsetState, action: PayloadAction<boolean>): ShippingOffsetState => {
      state.isOrderInfoInvalid = action.payload;

      return state;
    },
    setAmount: (state: ShippingOffsetState, action: PayloadAction<number>): ShippingOffsetState => {
      state.amount = action.payload;

      return state;
    },
    setMiles: (state: ShippingOffsetState, action: PayloadAction<boolean>): ShippingOffsetState => {
      state.miles = action.payload;

      return state;
    },
    setOrder: (state: ShippingOffsetState, action: PayloadAction<OrderPayload>): ShippingOffsetState => {
      state.orderNumber = action.payload.number;
      state.orderDate = action.payload.date;
      state.orderName = action.payload.name ?? '';
      state.orderId = action.payload.id;
      state.inviteId = action.payload.inviteId;
      state.claimCode = action.payload.claimCode;
      state.billId = action.payload.billId;
      state.orderStatus = action.payload.orderStatus;

      return state;
    },
    setWttEmissions: (state: ShippingOffsetState, action: PayloadAction<boolean>): ShippingOffsetState => {
      state.wttEmissions = action.payload;

      return state;
    },
    setPreCalculatedVoyage: (state: ShippingOffsetState, action: PayloadAction<boolean>): ShippingOffsetState => {
      state.preCalculatedVoyage = action.payload;

      return state;
    },
    setShippingMetadata: (state: ShippingOffsetState, action: PayloadAction<ShippingMetadata>): ShippingOffsetState => {
      state.shippingMetadata = action.payload;

      return state;
    },
    setShipData: (state: ShippingOffsetState, action: PayloadAction<ShipData>): ShippingOffsetState => {
      state.shipActivity = action.payload.shipActivity;
      state.shipSize = action.payload.shipSize;
      state.shipType = action.payload.shipType;

      return state;
    },
    setShipSize: (state: ShippingOffsetState, action: PayloadAction<ShipSize>): ShippingOffsetState => {
      state.shipSize = action.payload;

      return state;
    },
    setDistance: (state: ShippingOffsetState, action: PayloadAction<number>): ShippingOffsetState => {
      state.distance = action.payload;

      return state;
    },
    setCargoWeight: (state: ShippingOffsetState, action: PayloadAction<number>): ShippingOffsetState => {
      state.cargoWeight = action.payload;

      return state;
    },
    setSelectedCustomMetadata: (state: ShippingOffsetState, action: PayloadAction<string[]>): ShippingOffsetState => {
      state.selectedCustomMetadata = action.payload;

      return state;
    },
    setIsMultiplierActive: (state: ShippingOffsetState, action: PayloadAction<boolean>): ShippingOffsetState => {
      state.isMultiplierActive = action.payload;

      return state;
    },
    setOffsetPrice: (
      state: ShippingOffsetState,
      action: PayloadAction<{ price: number; vat: number; customerPrice: number; customerVat: number; excise: number; customerExcise: number }>,
    ): ShippingOffsetState => {
      state.price = action.payload.price;
      state.vat = action.payload.vat;
      state.excise = action.payload.excise;
      state.customerPrice = action.payload.customerPrice;
      state.customerVat = action.payload.customerVat;
      state.customerExcise = action.payload.customerExcise;

      return state;
    },
    setEmissionsAvoided: (state: ShippingOffsetState, action: PayloadAction<number>): ShippingOffsetState => {
      state.totalKilosCo2Avoided = action.payload;

      return state;
    },
    setEmissionsOffset: (state: ShippingOffsetState, action: PayloadAction<number>): ShippingOffsetState => {
      state.totalKilosCo2Offset = action.payload;

      return state;
    },
    setFootprintId: (state: ShippingOffsetState, action: PayloadAction<string>): ShippingOffsetState => {
      state.footprintId = action.payload;

      return state;
    },
  },
});

const {
  reset,
  resetWithoutPortfolioAndMetadata,
  setOrderInfo,
  setLoading,
  setAmount,
  setMiles,
  setOrder,
  setWttEmissions,
  setPreCalculatedVoyage,
  setMetadataFetching,
  setShippingMetadata,
  setShipData,
  setShipSize,
  setDistance,
  setCargoWeight,
  resetAmountAndPrice,
  setOrderInfoInvalid,
  setSelectedCustomMetadata,
  setIsMultiplierActive,
  setOffsetPrice,
  setEmissionsAvoided,
  setEmissionsOffset,
  setFootprintId,
} = slice.actions;
export {
  reset,
  resetAmountAndPrice,
  resetWithoutPortfolioAndMetadata,
  setAmount,
  setCargoWeight,
  setDistance,
  setIsMultiplierActive,
  setMiles,
  setOrderInfo,
  setOrderInfoInvalid,
  setPreCalculatedVoyage,
  setSelectedCustomMetadata,
  setShipData,
  setShipSize,
  setWttEmissions,
};
export default slice.reducer;

export const getShippingOrderObject = ({
  offsetProps,
  currentUser,
  virtualPortfolios,
  mainPortfolioId,
  userCurrency,
  customer,
}: {
  offsetProps: ShippingOffsetState;
  currentUser: AccountLegacy | undefined;
  virtualPortfolios: {
    portfolioId: string;
    weight: number;
  }[];
  mainPortfolioId: string | undefined;
  userCurrency: string;
  customer: PartnersCustomerOption | undefined;
}): OrderParams => {
  const { isMultiplierActive, amount, preCalculatedVoyage, shipActivity, shipType, shipSize, cargoWeight, wttEmissions, distance, miles } =
    offsetProps;
  const tonnesFactor = 1000;

  const totalKilosCo2 = Number(amount ?? 0) * tonnesFactor;
  const portfolioId = mainPortfolioId ?? null;

  const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
  const isDiscountMode = multiplierMode === 'Discount';
  const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
  const discountValue = multiplierValue / (1 + multiplierValue);
  const discountedOffset = isMultiplierActive && multiplierValue ? (totalKilosCo2 ?? 0) * discountValue : 0;
  const mappedVirtualPortfolios = virtualPortfolios.map(({ portfolioId, weight }) => ({
    id: portfolioId,
    weight: isMultiplierActive && isDiscountMode ? weight * discountValue : weight,
  }));

  const item: OrderItem = {
    type: 'impact',
    impact: 'Co2',
    kilosCo2: isDiscountMode ? totalKilosCo2 - discountedOffset : totalKilosCo2,
    portfolio: mainPortfolioId
      ? undefined
      : {
          type: 'Virtual',
          impact: 'co2',
          currency: userCurrency,
          childPortfolios: mappedVirtualPortfolios,
        },
    portfolioId,
    feeFactors: {
      shipping: true,
      bulkShipping: true,
      shippingPercentage: true,
    },
    taxFactors: {
      customerCountry: customer?.country,
      customerCountrySubdivision: customer?.countrySubdivision,
    },
  };

  if (!preCalculatedVoyage) {
    item.details = {
      shipping: {
        activity: shipActivity,
        type: shipType,
        size: shipSize?.label,
        weight: cargoWeight,
        lca: wttEmissions ? 'WTW' : 'TTW',
        distanceKm: miles ? calculateNauticalMilesToKm(distance ?? 0) : distance ?? 0,
      },
    };
  }

  const order = {
    currency: userCurrency,
    category: 'Sea freight',
    items: [item],
  };

  return order;
};

export const getPriceForOrder =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const shippingOffset = getState().shippingOffset;
    const { customer, currency } = shippingOffset;
    const currentUser = currentUserSelector(getState());
    const context = appContextSelector(getState());
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const currentUserCurrency = userCurrencySelector(getState());
    const orderApi = createOrderApi(context);
    const userCurrency = currency ?? currentUserCurrency;
    const customerCurrency = customer?.currency ?? userCurrency;
    const customerId = currentUser?.customerId ?? '';
    const partnershipId = currentUser?.partnershipId ?? '';
    const isConnectContext = context === 'connect';

    dispatch(setLoading({ value: 'getPriceForOrder', state: true }));
    try {
      const order = getShippingOrderObject({ offsetProps: shippingOffset, currentUser, virtualPortfolios, mainPortfolioId, userCurrency, customer });

      validateItemPortfolio(order.items?.[0]);

      const response =
        context === 'connect' ? await orderApi.getQuoteForPartner(order, partnershipId) : await orderApi.getQuoteForCustomer(order, customerId);

      let customerPrice = response.totalPrice;
      let customerVat = response.totalVat;
      let customerExcise = response.totalExcise;
      let emissionsAvoided = response.totalKilosCo2Avoided;
      let emissionsOffset = response.totalKilosCo2Offset;

      if (isConnectContext && customer && customerCurrency !== userCurrency) {
        const customerOrder = getShippingOrderObject({
          offsetProps: shippingOffset,
          currentUser,
          virtualPortfolios,
          mainPortfolioId,
          userCurrency: customerCurrency,
          customer,
        });
        const customerResponse = await orderApi.getQuoteForPartner(customerOrder, partnershipId);
        customerPrice = customerResponse.totalPrice;
        customerVat = customerResponse.totalVat;
        customerExcise = customerResponse.totalExcise;
        emissionsAvoided = customerResponse.totalKilosCo2Avoided;
        emissionsOffset = customerResponse.totalKilosCo2Offset;
      }

      dispatch(
        setOffsetPrice({
          price: response.totalPrice,
          vat: response.totalVat,
          excise: response.totalExcise,
          customerPrice,
          customerVat,
          customerExcise,
        }),
      );

      dispatch(setEmissionsAvoided(emissionsAvoided ?? 0));
      dispatch(setEmissionsOffset(emissionsOffset ?? 0));
    } catch (err) {
      // @ts-ignore
      if (err.data?.includes('Insufficient supply')) {
        dispatch(
          showToast({
            variant: 'error',
            titleI18nKey: 'offset:corporate.portfolios.errorTitle',
            descriptionI18nKey: 'offset:corporate.portfolios.insufficientSupplyErrorMessage',
          }),
        );
        // @ts-ignore
      } else if (err.data) {
        dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchPriceError' }));
      }
    } finally {
      dispatch(setLoading({ value: 'getPriceForOrder', state: false }));
    }
  };

export const calculateAmountOfCO2e =
  (cb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'calculateAmountOfCO2e', state: true }));
    const context = appContextSelector(getState());
    const { shipActivity, shipType, shipSize, distance, cargoWeight, miles, wttEmissions, customer, amount } = getState().shippingOffset;
    const shippingApi = createShippingApi(context);
    const currentUser = currentUserSelector(getState());
    const tonnesFactor = 1000;

    const params: ShippingParams = {
      activity: shipActivity ?? '',
      type: shipType ?? '',
      size: shipSize?.value ?? 0,
      km: miles ? calculateNauticalMilesToKm(distance ?? 0) : distance ?? 0,
      weight: cargoWeight ?? 0,
      lca: wttEmissions ? 'WTW' : 'TTW',
      customerId: currentUser?.customerId,
      calculateForCustomer: customer?.value,
    };

    try {
      const { kilosCo2e, id } = await shippingApi.getShippingFootprint(params);
      const tonnes = kilosCo2e / tonnesFactor;
      dispatch(setAmount(tonnes));
      dispatch(setFootprintId(id));
      cb && cb(tonnes, amount);
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:fetchAmountError' }));
    } finally {
      dispatch(setLoading({ value: 'calculateAmountOfCO2e', state: false }));
    }
  };

export const createCarbonOrder =
  (errorCb?: CallableFunction, successCb?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setLoading({ value: 'createCarbonOrder', state: true }));
    const context = appContextSelector(getState());
    const currentUser = currentUserSelector(getState());
    const {
      preCalculatedVoyage,
      shipActivity,
      shipType,
      shipSize,
      cargoWeight,
      wttEmissions,
      distance,
      miles,
      isPaidByPartner,
      customer,
      currency,
      isMultiplierActive,
      price,
      customerPrice,
      footprintId,
      ...restProps
    } = getState().shippingOffset;
    const { costCenter, poNumber } = restProps;
    const virtualPortfolios = virtualPortfoliosSelector(getState());
    const mainPortfolioId = singlePortfolioSanityIdSelector(getState());
    const metadata = metadataSelector(getState()) ?? [];
    const isEmissionsDashboardEnabled = isEmissionsDashboardEnabledSelector(getState()) ?? false;
    const portfolioId = mainPortfolioId ?? null;
    const orderCurrency = !isPaidByPartner && customer?.currency ? customer.currency ?? '' : currency ?? '';

    const orderApi = createOrderApi(context);
    const tonnesFactor = 1000;
    const { shippingOffset, user } = getState();
    const paymentMethodType = context === 'connect' ? 'Invoice' : user?.currentAccount?.customer?.payment?.defaultPaymentMethod?.type;
    const totalKilosCo2 = Number(shippingOffset.amount ?? 0) * tonnesFactor;

    const multiplierValue = currentUser?.partnership?.settings?.portal?.multiplier?.value ?? 0;
    const multiplierMode = currentUser?.partnership?.settings?.portal?.multiplier?.mode;
    const isDiscountMode = multiplierMode === 'Discount';
    const discountValue = multiplierValue / (1 + multiplierValue);
    const discountedOffset = isMultiplierActive && multiplierValue ? (totalKilosCo2 ?? 0) * discountValue : 0;

    const customSingleMetadata: {
      internalName: string;
      displayName: string;
      value: string | Date;
    }[] = [];

    Object.keys(restProps)
      .filter(key => !!metadata.find(({ internalName }) => internalName === key))
      .map(key => {
        let value: string | Date = '';

        if ((shippingOffset[key] as Option)?.value) {
          value = (shippingOffset[key] as Option)?.value;
        } else {
          value = shippingOffset[key] as string | Date;
        }
        if (value) {
          customSingleMetadata.push({
            internalName: key,
            value: value,
            displayName: getDisplayName(metadata, key),
          });
        }
      });

    const item: OrderItem = {
      type: 'impact',
      impact: 'Co2',
      kilosCo2: isDiscountMode ? totalKilosCo2 - discountedOffset : totalKilosCo2,
      price: roundNumberToTwo(isPaidByPartner || !customer ? price ?? 0 : customerPrice ?? 0),
      ...(mainPortfolioId ? {} : { virtualPortfolios }),
      portfolioId,
      feeFactors: {
        shipping: true,
        bulkShipping: true,
        shippingPercentage: true,
      },
      taxFactors: {
        customerCountry: customer?.country,
        customerCountrySubdivision: customer?.countrySubdivision,
      },
    };

    if (!preCalculatedVoyage) {
      item.details = {
        shipping: {
          activity: shipActivity,
          type: shipType,
          size: shipSize?.label,
          weight: cargoWeight,
          lca: wttEmissions ? 'WTW' : 'TTW',
          distanceKm: miles ? calculateNauticalMilesToKm(distance ?? 0) : distance ?? 0,
        },
      };
    }

    const order = {
      currency: orderCurrency,
      description: shippingOffset.orderName ?? '',
      comment: shippingOffset.description ?? '',
      category: 'Sea freight',
      items: [item],
      metadata: {
        order: {
          channel: 'portal-fulloffset',
        },
        custom: customSingleMetadata,
      },
      costCenter: costCenter ?? '',
      poNumber: poNumber ?? '',
      multiplier: isMultiplierActive && multiplierMode ? { value: multiplierValue, mode: multiplierMode } : undefined,
      footprints: footprintId ? [{ calculatedFootprintId: footprintId }] : undefined,
      applyDeductions: true,
    };

    try {
      const orderResult =
        context === 'connect'
          ? await orderApi.createForPartner(
              {
                ...order,
                facilitated: { isFacilitated: true, isPaidByPartner: isPaidByPartner ?? true },
                customer: {
                  customerId: customer?.value ?? '',
                },
              },
              user.currentAccount?.partnershipId ?? '',
            )
          : await orderApi.create(order, user.currentAccount?.customerId ?? '');
      if (context !== 'connect' || isPaidByPartner) {
        trackPurchaseEvent(orderResult.order, 'Portal offset', paymentMethodType, orderResult.footprintIds);
      }
      dispatch(
        setOrder({
          number: orderResult.order.choooseId,
          date: orderResult.order.createdDate,
          name: shippingOffset.orderName ?? '',
          id: orderResult.orderId,
          claimCode: orderResult.claimCode,
          inviteId: orderResult.inviteId,
          billId: orderResult.billId,
          orderStatus: getOrderStatus(orderResult.order),
        }),
      );
      if (context === 'wechooose' && isEmissionsDashboardEnabled) {
        await client.refetchQueries({ queryKey: ['impact', 'customer', currentUser?.customerId ?? '', { year: new Date().getFullYear() }] });
      }
      dispatch(syncAttribution());
      client.invalidateQueries({ queryKey: ['orders', 'history'] });
      successCb && successCb();
    } catch (err) {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'offset:errorTitle', descriptionI18nKey: 'offset:createOrderError' }));
      errorCb && errorCb();
    } finally {
      dispatch(setLoading({ value: 'createCarbonOrder', state: false }));
    }
  };

export const loadShippingMetadata =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const shippingApi = createShippingApi(context);
    dispatch(setMetadataFetching(true));

    try {
      const shippingMetadata = await shippingApi.getShippingMetadata();
      dispatch(setShippingMetadata(shippingMetadata));
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
    } finally {
      dispatch(setMetadataFetching(false));
    }
  };

export const shippingAmountSelector = createSelector(
  (state: RootState) => state.shippingOffset,
  state => {
    const { amount } = state;

    return fixFloat(amount ?? 0);
  },
);
