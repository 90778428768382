import { AxiosInstance } from 'axios';

import { createContextAwareHandlers } from './instance';
import { OrderValues } from './transaction/types';

interface PaginationResponse {
  totalCount: number;
  offset: number;
  limit: number;
  count: number;
}

interface TransactionResponse extends PaginationResponse {
  items: Transaction[];
}

interface Filters extends PaginationParams {
  category: string;
  fromCreatedDate: string;
  toCreatedDate: string;
  'metadata.customer.residentCountry': string;
  'metadata.order.channel': string;
  'payment.status': string;
  referenceid: string;
}

const getAll =
  (instance: AxiosInstance) =>
  async (filters: Partial<Filters>, partnershipId: string): Promise<TransactionResponse> => {
    const { data } = await instance.get<TransactionResponse>(`/partnerships/${partnershipId}/orders`, {
      params: {
        ...filters,
      },
    });

    return data;
  };

const getAllSummary =
  (instance: AxiosInstance) =>
  async (filters: Partial<Filters>, partnershipId: string): Promise<TransactionSummary[]> => {
    const { data } = await instance.get<TransactionSummary[]>(`/partnerships/${partnershipId}/ordersummaries`, {
      params: {
        ...filters,
      },
    });

    return data;
  };

const getOrderValuesForPartnership =
  (instance: AxiosInstance) =>
  async (partnershipId: string): Promise<OrderValues> => {
    const { data } = await instance.get<OrderValues>(`/partnerships/${partnershipId}/ordervalues`);

    return data;
  };

const getOrderValuesForCustomer =
  (instance: AxiosInstance) =>
  async (customerId: string): Promise<OrderValues> => {
    const { data } = await instance.get<OrderValues>(`/customers/${customerId}/ordervalues`);

    return data;
  };

const handlers = {
  getAll,
  getAllSummary,
  getOrderValuesForPartnership,
  getOrderValuesForCustomer,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createTransactionApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createTransactionApi;
