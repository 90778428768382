import { FC, useEffect } from 'react';
import { usePartnershipSettings } from '@api/partnership/queries';

const FaviconInjector: FC<React.PropsWithChildren> = () => {
  const { data } = usePartnershipSettings();

  useEffect(() => {
    const element = (document.getElementById('favicon') as HTMLLinkElement) || (document.getElementById('favicon_default') as HTMLLinkElement);
    if (!element) {
      return;
    }
    if (data?.faviconUrl) {
      element.href = data.faviconUrl;
    } else if (data?.logoUrl) {
      element.href = data.logoUrl;
    }
  }, [data?.logoUrl, data?.faviconUrl]);

  return null;
};

export default FaviconInjector;
