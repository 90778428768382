import React, { FC } from 'react';
import styled from '@emotion/styled/macro';
import pinkLogo from '@assets/logos/chooose.svg';
import blackLogo from '@assets/logos/chooose-black.svg';
import blueLogo from '@assets/logos/chooose-blue.svg';
import blushLogo from '@assets/logos/chooose-blush.svg';
import whiteLogo from '@assets/logos/chooose-white.svg';

import { mq } from '@styles';

const LogoImage = styled.img`
  width: 114px;
  object-fit: contain;

  ${mq.sm} {
    width: 120px;
  }
`;

interface Props {
  color?: string;
}

export const MainLogo: FC<React.PropsWithChildren<Props>> = ({ color = 'pink', ...props }) => {
  let logoColor = pinkLogo;

  switch (color) {
    case 'white':
      logoColor = whiteLogo;
      break;
    case 'black':
      logoColor = blackLogo;
      break;
    case 'blush':
      logoColor = blushLogo;
      break;
    case 'blue':
      logoColor = blueLogo;
      break;
    default:
      logoColor = pinkLogo;
  }

  return <LogoImage src={logoColor} alt='Chooose logo' {...props} />;
};
