import { rem as originalRem } from 'polished';

const defaultBaseFontSize = window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/);
const parsedDefaultBaseFontSize = defaultBaseFontSize && defaultBaseFontSize.length ? Number(defaultBaseFontSize[0]) : 16;
const baseFontSize = 14;

const rem = (pxVal: string | number): string => originalRem(pxVal, parsedDefaultBaseFontSize);

const setFontSize = (value: number, customFontSize?: number): string => {
  if (!customFontSize) {
    return rem(value);
  }

  return rem(value + (customFontSize - baseFontSize));
};

export { rem, setFontSize };
