import styled, { css } from 'styled-components';

export const MobileMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileMenuDropdown = styled.div<{ $isOpen?: boolean }>`
  max-height: 0;
  overflow: hidden;
  display: grid;
  gap: ${props => props.theme.spacing.xs};
  transition: 0.2s all ease;
  > a {
    display: grid;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      padding-top: ${props => props.theme.spacing.xxs};
      max-height: 100vh;
    `};
`;

export const MobileNavbarWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
`;

export const DesktopNavbarContainer = styled.div`
  margin: 0 auto;
`;

export const DesktopNavbarInnerWrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;
