import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useCustomer } from '@api/customer/queries';
import { useCurrentAccount, useCurrentPartnerAccount } from '@api/user/queries';
import { ONE_HOUR, ONE_MINUTE } from '@utils/constants';
import { isPartnerAccount, isTransactAccount } from '@utils/contextChecker';

import * as api from './api';
import { partnerOffsetFeatureFlags } from './featureFlags';
import { PartnerSettings, Settings } from './types';

export const usePartnershipCustomerUsers = (customerId: string, partnershipId?: string) => {
  const { account } = useCurrentPartnerAccount();
  const pid = partnershipId ?? account?.partnershipId ?? 'default';

  return useQuery({
    // This queryKey is important, as we later invalidate this query
    // and `['user', 'partner', 'customers']` query
    queryKey: ['user', 'partner', 'customers', 'users', pid, customerId],
    queryFn: () => api.getPartnershipCustomerUsers(pid, customerId),
    enabled: pid !== 'default',
    staleTime: ONE_MINUTE * 10,
  });
};

export const usePartnership = (partnershipId?: string) => {
  const { account } = useCurrentPartnerAccount();
  const pid = partnershipId ?? account?.partnershipId ?? 'default';

  return useQuery({
    queryKey: ['partnerships', pid],
    queryFn: () => api.getPartnership(pid),
    enabled: pid !== 'default',
    staleTime: ONE_MINUTE * 10,
  });
};

const usePartnershipSettingsSuspenseQuery = <T>(select: ((data: Settings) => T) | undefined, partnershipId?: string) => {
  const { account } = useCurrentPartnerAccount();
  const params: {
    partnershipId?: string;
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const pid = partnershipId ?? params.partnershipId ?? account?.partnershipId ?? 'default';

  return useSuspenseQuery({
    queryKey: ['settings', pid],
    queryFn: () => api.getSettings(pid === 'default' ? '' : pid),
    staleTime: ONE_HOUR,
    select,
  });
};

export const usePartnershipSettings = (partnershipId?: string) => usePartnershipSettingsSuspenseQuery(settings => settings, partnershipId);
export const usePartnerSettings = (partnershipId?: string) =>
  usePartnershipSettingsSuspenseQuery(
    settings =>
      ({
        ...settings,
        logoUrl: settings?.logoUrl ?? '',
        lightLogoUrl: settings?.lightLogoUrl ?? '',
        colors: settings?.colors,
        faviconUrl: settings?.faviconUrl ?? '',
        partnershipId: settings?.partnershipId ?? '',
        currencies: settings?.currencies || [],
        disableSignupLink: (settings?.features || []).includes('DisableSignUp'),
      }) as PartnerSettings,
    partnershipId,
  );

export const useIsHideContextSwitcherEnabled = (partnershipId?: string) =>
  usePartnershipSettingsSuspenseQuery(settings => settings.features?.includes('HideContextSwitcher'), partnershipId);
export const useIsHidePortfolioSupplyEnabled = (partnershipId?: string) =>
  usePartnershipSettingsSuspenseQuery(settings => settings.features?.includes('HidePortfolioSupply'), partnershipId);

export const useIsFacilitatedOffsetEnabled = (partnershipId?: string) =>
  usePartnershipSettingsSuspenseQuery(settings => partnerOffsetFeatureFlags.some(i => settings?.connectFeatures?.includes(i)), partnershipId);

export const usePortalFeatures = (partnershipId?: string) => {
  const { account } = useCurrentAccount();
  const { data: customer } = useCustomer();

  return usePartnershipSettingsSuspenseQuery(settings => {
    if (isTransactAccount(account)) {
      return customer?.settings?.portal?.transactFeatures ?? settings.transactFeatures ?? [];
    }
    if (isPartnerAccount(account)) {
      return settings.connectFeatures ?? [];
    }

    return settings.features ?? [];
  }, partnershipId);
};
