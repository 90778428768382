import { FC } from 'react';
import { css, Global } from '@emotion/react/macro';

import { setFontFamily } from './fonts';

const GlobalStyles: FC<React.PropsWithChildren> = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'airnz';
          src:
            url('/fonts/airnz/AirNewZealandSans-Bold.woff2') format('woff2'),
            url('/fonts/airnz/AirNewZealandSans-Bold.woff') format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'airnz';
          src:
            url('/fonts/airnz/AirNewZealandSans-Medium.woff2') format('woff2'),
            url('/fonts/airnz/AirNewZealandSans-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'airnz';
          src:
            url('/fonts/airnz/AirNewZealandSans-Regular.woff2') format('woff2'),
            url('/airnz/AirNewZealandSans-Regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'cofo';
          src:
            url('/fonts/cofo/CoFo_Sans-Bold.woff2') format('woff2'),
            url('/fonts/cofo/CoFo_Sans-Bold.woff') format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'cofo';
          src:
            url('/fonts/cofo/CoFo_Sans-Medium.woff2') format('woff2'),
            url('/fonts/cofo/CoFo_Sans-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'cofo';
          src:
            url('/fonts/cofo/CoFo_Sans-Regular.woff2') format('woff2'),
            url('/fonts/cofo/CoFo_Sans-Regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        html {
          height: 100%;
          scroll-behavior: smooth;
        }

        body {
          font-family: ${setFontFamily()};
          font-size: var(--chakra-fontSizes-lgMin);
          background: var(--chakra-colors-white);
          padding-bottom: 60px;
          min-height: 100%;
          margin: 0 !important;

          &::after {
            display: block;
            content: '';
            position: absolute;
            z-index: -1;
            /* This will reload after theme is setup properly */
            background: var(--chakra-colors-secondary-100, var(--chakra-colors-white));
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
