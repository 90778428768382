import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentAccount } from '@api/user/queries';
import { useAccountIdStore } from '@store/context';

interface UseContextSwitcher {
  switchContext: (id: string) => void;
}

export const useCorporateContextSwitcher = (): UseContextSwitcher => {
  const { account } = useCurrentAccount();
  const history = useHistory();
  const location = useLocation();
  const setAccountId = useAccountIdStore(state => state.setAccountId);

  const switchContext = (id: string): void => {
    if (id === account?.id) {
      return;
    }

    setAccountId(id);

    if (location.pathname === '/') {
      history.replace('/');
      window.location.reload();
    } else {
      history.replace('/');
    }
  };

  return {
    switchContext,
  };
};
