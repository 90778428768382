import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chooose/ui';
import { RootState } from '@store';
import { resetToast } from '@store/app/toasterSlice';

// Use useToaster hook instead of this component
export const Toaster: FC<React.PropsWithChildren> = () => {
  const { variant, titleI18nKey, descriptionI18nKey } = useSelector((state: RootState) => state.toaster);
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (variant && titleI18nKey) {
      toast.addToast({
        title: titleI18nKey ? t(titleI18nKey) : '',
        description: descriptionI18nKey ? t(descriptionI18nKey) : '',
        status: variant,
        duration: 3000,
        isClosable: true,
        onOpenChange: isOpen => !isOpen && dispatch(resetToast()),
      });
    }
  }, [variant, titleI18nKey, descriptionI18nKey]);

  return null;
};
