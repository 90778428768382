/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* @typescript-eslint/ban-ts-ignore */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as api from '@api/certificates/api';
import { AppThunk } from '@store';
import { showToast } from '@store/app/toasterSlice';

import { currentUserSelector } from './userSlice';

interface State {
  loading: boolean;
  giftLoading: boolean;
  socialMediaLoading: boolean;
  instagramLoading: boolean;
  webLoading: boolean;
  posterLoading: boolean;
  totalImpact?: Certificate;
  giftedImpact?: Certificate;
  giftGiven?: Certificate;
  receivedGifts?: Certificate[];
  certificate?: Certificate;
}

const initialState: State = {
  loading: false,
  giftLoading: false,
  posterLoading: false,
  webLoading: false,
  socialMediaLoading: false,
  instagramLoading: false,
};

const slice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    setGiftGivenCertificate(state: State, action: PayloadAction<Certificate>): State {
      state.giftGiven = action.payload;

      return state;
    },
    setLoading(state: State, action: PayloadAction<boolean>): State {
      state.loading = action.payload;

      return state;
    },
    setPosterLoading(state: State, action: PayloadAction<boolean>): State {
      state.posterLoading = action.payload;

      return state;
    },
    setCertificate(state: State, action: PayloadAction<Certificate>): State {
      state.certificate = action.payload;

      return state;
    },
  },
});

const { setPosterLoading, setCertificate } = slice.actions;

export default slice.reducer;

const downloadCertificate =
  (certificateUrl: string, fileName: string, pdf?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const blob = await api.downloadFromUrl(certificateUrl);
      saveAs(blob, `${fileName}.${pdf ? 'pdf' : 'png'}`);
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
    }
  };

export const generateAndDownloadFacilitatedOrderCertificate =
  (orderId: string, customerId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentAccount = currentUserSelector(getState());

    dispatch(setPosterLoading(true));
    try {
      const certificate = await api.getCertificatesOrderForPartner({
        id: currentAccount?.partnershipId ?? '',
        customerId,
        orderId: orderId,
        type: 'Order',
        variants: ['PosterLandscape'],
      });
      dispatch(downloadCertificate(certificate.posterLandscapeUrl ?? '', `order-${orderId}`, true));
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:impact.certificateError' }));
    } finally {
      dispatch(setPosterLoading(false));
    }
  };

const downloadAsZIP =
  (folderName: string, certificatesUrl: string[], setLoader = true): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const zip = new JSZip();
    const certificatesFolder = zip.folder(folderName);
    if (!certificatesUrl.length) {
      return;
    }

    setLoader && dispatch(setPosterLoading(true));
    try {
      await Promise.all(
        certificatesUrl.map(async (url, idx) => {
          const filename = `certificate-${idx + 1}.pdf`;
          const file = await api.downloadFromUrl(url);
          certificatesFolder?.file(filename, file, { binary: true });
        }),
      );
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${folderName}.zip`);
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:impact.certificateError' }));
    } finally {
      setLoader && dispatch(setPosterLoading(false));
    }
  };

export const downloadFacilitatedOrderCertificatesForMultipleCustomers =
  (orderName: string, attachmentName: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentAccount = currentUserSelector(getState());

    dispatch(setPosterLoading(true));
    try {
      const certificates = await api.getMultiCertificatesOrderForPartner({
        id: currentAccount?.partnershipId ?? '',
        type: 'Order',
        variants: ['PosterLandscape'],
        attachmentName,
      });
      const certificatesUrl = certificates
        .filter(({ posterLandscapeUrl }) => !!posterLandscapeUrl)
        .map(({ posterLandscapeUrl }) => posterLandscapeUrl) as string[];
      await dispatch(downloadAsZIP(`${orderName} - certificates`, certificatesUrl, false));
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:impact.certificateError' }));
    } finally {
      dispatch(setPosterLoading(false));
    }
  };
