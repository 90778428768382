import { AxiosInstance } from 'axios';

import { createContextAwareHandlers } from './instance';
import { TransformedPortfolio, VirtualPortfolio } from './order/types';

interface PersonDetails {
  category?: string;
  countryCode?: string;
  countryName?: string;
  plan?: string;
  days?: number;
  months?: number;
  years?: number;
  personCount?: number;
  type?: string;
}
interface EventDetails {
  countryCode?: string;
  countryName?: string;
  plan?: string;
  days?: number;
  personCount?: number;
  participantType?: string;
}

interface ShippingDetails {
  activity?: string;
  type?: string;
  size?: string;
  weight?: number;
  lca?: string;
  distanceKm?: number;
}

export interface FlightDetails {
  travelClass?: TravelClass;
  flights?: number;
  passengers?: number;
  roundTrip?: boolean;
  routeLegs?: RouteLegs[];
}

interface Portfolio {
  type: string;
  impact: string;
  currency: string;
  childPortfolios: VirtualPortfolio[];
}

export interface OrderItem {
  type: string;
  portfolioId: string | null;
  portfolio?: Portfolio;
  impact: string;
  price?: number;
  vat?: number;
  tax?: number;
  kilosCo2?: number;
  details?: {
    person?: PersonDetails;
    flight?: FlightDetails;
    shipping?: ShippingDetails;
    event?: EventDetails;
  };
  feeFactors?: {
    flightPax?: number;
    eventFlightPax?: number;
    eventPersons?: number;
    bulkCo2?: boolean;
    percentageBulkCo2?: boolean;
    shipping?: boolean;
    bulkShipping?: boolean;
    shippingPercentage?: boolean;
    airFreight?: boolean;
    flightDistanceKm?: number;
    flight?: boolean;
    employeeBenefitMonthlyPersons?: number;
  };
  taxFactors?: {
    airports?: string[];
    customerCountry?: string;
    customerCountrySubdivision?: string;
  };
}

export interface Metadata {
  flight?: {
    fromAirport?: string;
    toAirport?: string;
    fromCity?: string;
    toCity?: string;
    fromCountry?: string;
    toCountry?: string;
    travelersCount: number;
    distanceKm?: number;
    roundtrip: boolean;
    flights: number;
    passengers: number;
    travelClass: TravelClass;
  };
  order?: {
    channel: string;
  };
  event?: {
    personCount: number;
    country?: string;
    days: number;
    flightLength?: string;
    flightTravelersCount?: number;
    flightRoundTrip?: boolean;
  };
  custom?: {
    internalName: string;
    value: string | Date;
  }[];
  gift?: {
    country: string;
    startDate: string;
    endDate: string;
    plan: string;
  };
}
export interface OrderParams {
  currency: string;
  description?: string;
  comment?: string;
  category: string;
  payment?: {
    type: string;
  };
  items: OrderItem[];
  metadata?: Metadata;
  gift?: boolean;
  quoteFootprints?: CompensatedFootprint[];
  multiplier?: Pick<Multiplier, 'value' | 'mode'>;
}

type FootprintsOrderItem = {
  year: number;
  month: number;
  footprintId: string;
};

interface FootprintsBaseOrderParams {
  currency: string;
  portfolioId: string | null;
  portfolio: TransformedPortfolio | Portfolio | undefined;
  multiplier?: Pick<Multiplier, 'value' | 'mode'>;
}

export interface FootprintsOrderSelectedSomeParams extends FootprintsBaseOrderParams {
  footprints: FootprintsOrderItem[];
}

export type FootprintsOrderFilters = Omit<EmissionsMetadata, 'status'> & { status: 'Incomplete' };

export interface FootprintsOrderSelectedAllParams extends FootprintsBaseOrderParams {
  footprints: null;
  filter: FootprintsOrderFilters;
}

type FootprintsOrderParams = FootprintsOrderSelectedAllParams | FootprintsOrderSelectedSomeParams;

interface PartnerOrderParams extends OrderParams {
  facilitated: {
    isFacilitated: boolean;
    isPaidByPartner: boolean;
  };
  customer: {
    customerId: string | null;
  };
  costCenter: string;
  poNumber: string;
}

interface BatchOrderParams extends OrderParams {
  facilitated?: {
    isFacilitated: boolean;
    isPaidByPartner: boolean;
  };
  customer?: {
    customerId: string | null;
  };
  costCenter?: string;
  poNumber?: string;
  attachments?: { storageBlobName: string; type: string }[];
}

export interface OrderResponse {
  orderId: string;
  order: OrderLegacy<OrderFootprint>;
  stripePaymentIntentId?: string;
  stripeClientSecret?: string;
  stripePaymentMethodId?: string;
  claimCode?: string;
  inviteId?: string;
  fileUrl?: string;
  billId?: string;
  orderIds?: string[];
  footprintIds?: string[];
  orders?: Partial<MultipleOrdersResponse[]>;
  jobId?: string;
}

export interface MultipleOrdersResponse extends OrderResponse {
  currency: string;
  passengers?: number;
  totalKilosCo2: number;
  totalPrice: number;
}

interface BatchOrderResponse {
  orderIds: string[];
  orders: Partial<MultipleOrdersResponse[]>;
  order: OrderResponse;
  attachment?: {
    storageBlobName: string;
    type: number;
  };
  fileUrl?: string;
}

export interface PlaceOrderSummary {
  facilitatedBatchOrderResult: BatchOrderResponse;
  orders: {
    footprintYears: number[] | null;
  };
}

export interface ApproveParamsItem {
  portfolioId?: string;
  price: number;
  kilosCo2?: number;
  virtualPortfolios?: {
    weight: number;
    portfolioId: string;
  }[];
}

export interface AdditionalApproveParams {
  costCenter?: string;
  poNumber?: string;
}

export interface ApproveParams extends AdditionalApproveParams {
  currency: string;
  items: ApproveParamsItem[];
}

export type SortKey =
  | 'userName' // These are currently unavailable due to the way order
  | 'userName_desc' // database is structured. We'll see after the MVP
  | 'createdDate'
  | 'createdDate_desc'
  | 'orderType'
  | 'orderType_desc'
  | 'orderName'
  | 'orderName_desc';

interface GetAllOrdersRequest extends PaginationParams {
  category?: string;
  fromCreatedDate?: string;
  toCreatedDate?: string;
  'metadata.custom.department'?: string;
  'metadata.custom.country'?: string;
  sortKey?: SortKey;
  'payment.status'?: string;
  isfacilitated?: boolean;
}

interface GetAllFacilitatedOrdersRequest extends PaginationParams {
  isfacilitated: boolean;
  excludeMulticustomer: boolean;
}

interface GetAllOrdersResponse<T> {
  totalCount: number;
  offset: number;
  limit: number;
  count: number;
  items: T[];
}

export type EmissionsResponse<T> = GetAllOrdersResponse<T> & EmissionsMetadata;

interface GetFootprintsForOrderRequest extends PaginationParams {
  grouped?: boolean;
  fromDate?: string;
  toDate?: string;
  type?: string;
}

export interface PortfolioPrice {
  portfolioId: string;
  pricePerTonne: number;
}

const create =
  (instance: AxiosInstance) =>
  async (order: OrderParams, customerId: string): Promise<OrderResponse> => {
    const { data } = await instance.post<OrderResponse>(`/customers/${customerId}/orders`, order);

    return data;
  };

const createForPartner =
  (instance: AxiosInstance) =>
  async (order: PartnerOrderParams, partnershipId: string): Promise<OrderResponse> => {
    const { data } = await instance.post<OrderResponse>(`/partnerships/${partnershipId}/orders`, order);

    return data;
  };

const createBatchOrderJobForPartner =
  (instance: AxiosInstance) =>
  async (order: BatchOrderParams, partnershipId: string): Promise<BatchJobResponseOk & BatchJobResponseError> => {
    const { data } = await instance.post<BatchJobResponseOk & BatchJobResponseError>(`/partnerships/${partnershipId}/orders/batchjob`, order);

    return data;
  };

const createBatchOrderJobForCustomer =
  (instance: AxiosInstance) =>
  async (order: BatchOrderParams, customerId: string): Promise<BatchJobResponseOk & BatchJobResponseError> => {
    const { data } = await instance.post<BatchJobResponseOk & BatchJobResponseError>(`/customers/${customerId}/orders/batchjob`, order);

    return data;
  };

const getAll =
  (instance: AxiosInstance) =>
  async (params: GetAllOrdersRequest, customerId: string): Promise<GetAllOrdersResponse<OrderLegacy<OrderFootprint>>> => {
    const { data } = await instance.get<GetAllOrdersResponse<OrderLegacy<OrderFootprint>>>(`customers/${customerId}/orders`, {
      params,
    });

    return data;
  };

interface OrderExpandParams {
  includeVirtualPortfolio?: boolean;
  includeStats?: boolean;
  includeAttachments?: boolean;
}

const getOne =
  (instance: AxiosInstance) =>
  async (orderId: string, customerId: string, expandParamsFlags?: OrderExpandParams): Promise<OrderLegacy<OrderFootprint>> => {
    const expandParams: string[] = [];

    if (expandParamsFlags?.includeStats) {
      expandParams.push('facilitated.statistics');
    }

    if (expandParamsFlags?.includeVirtualPortfolio) {
      expandParams.push('virtualportfolio');
    }

    if (expandParamsFlags?.includeAttachments) {
      expandParams.push('attachments');
    }

    const expand = expandParams.join(',');

    const { data } = await instance.get<OrderLegacy<OrderFootprint>>(`customers/${customerId}/orders/${orderId}`, {
      params: { expand, includeVirtualPortfolio: expandParamsFlags?.includeVirtualPortfolio },
    });

    return data;
  };

const getFootprint =
  (instance: AxiosInstance) =>
  async (orderId: string, customerId: string): Promise<Footprint[]> => {
    const { data } = await instance.get<Footprint[]>(`customers/${customerId}/orders/${orderId}/footprints`);

    return data;
  };

const approveOrder =
  (instance: AxiosInstance) =>
  async (orderId: string, customerId: string, approveParams?: ApproveParams | {}): Promise<OrderResponse> => {
    const { data } = await instance.post<OrderResponse>(`customers/${customerId}/orders/${orderId}/approve`, approveParams);

    return data;
  };

const rejectOrder =
  (instance: AxiosInstance) =>
  async (orderId: string, customerId: string): Promise<void> => {
    await instance.post(`customers/${customerId}/orders/${orderId}/reject`);
  };

const getQuoteForPartner =
  (instance: AxiosInstance) =>
  async (order: OrderParams, partnershipId: string): Promise<OrderLegacy<OrderFootprint>> => {
    const { data } = await instance.post<OrderLegacy<OrderFootprint>>(`/partnerships/${partnershipId}/quote`, {
      ...order,
      items: order.items.map(item => ({ ...item, taxFactors: { ...item.taxFactors, customerType: 'enterprise' } })),
    });

    return data;
  };

const getPricePerTonneForAllPortfoliosForCustomer =
  (instance: AxiosInstance) =>
  async (order: OrderParams, customerId: string): Promise<PortfolioPrice[]> => {
    const { data } = await instance.post<PortfolioPrice[]>(`/customers/${customerId}/quote-all-portfolios`, order);

    return data;
  };

const getPricePerTonneForAllPortfoliosForPartner =
  (instance: AxiosInstance) =>
  async (order: OrderParams, partnershipId: string): Promise<PortfolioPrice[]> => {
    const { data } = await instance.post<PortfolioPrice[]>(`/partnerships/${partnershipId}/quote-all-portfolios`, {
      ...order,
      items: order.items.map(item => ({ ...item, taxFactors: { ...item.taxFactors, customerType: 'enterprise' } })),
    });

    return data;
  };

const getQuoteForCustomer =
  (instance: AxiosInstance) =>
  async (order: OrderParams, customerId: string): Promise<OrderLegacy<OrderFootprint>> => {
    const { data } = await instance.post<OrderLegacy<OrderFootprint>>(`/customers/${customerId}/quote`, order);

    return data;
  };

const getFacilitatedOrdersForSingleCustomers =
  (instance: AxiosInstance) =>
  async (params: Partial<GetAllFacilitatedOrdersRequest>, partnershipId: string): Promise<GetAllOrdersResponse<OrderLegacy<OrderFootprint>>> => {
    const { data } = await instance.get<GetAllOrdersResponse<OrderLegacy<OrderFootprint>>>(`/partnerships/${partnershipId}/orders`, {
      params: {
        ...params,
        isfacilitated: true,
        excludeMulticustomer: true,
      },
    });

    return data;
  };

const getFacilitatedOrdersForMultipleCustomers =
  (instance: AxiosInstance) =>
  async (params: Partial<GetAllFacilitatedOrdersRequest>, partnershipId: string): Promise<GetAllOrdersResponse<MultiCustomersOrder>> => {
    const { data } = await instance.get(`/partnerships/${partnershipId}/facilitatedordersummaries`, {
      params: {
        ...params,
      },
    });

    return data;
  };

const getFootprintsForEmissionsOrder =
  (instance: AxiosInstance) =>
  async (params: GetFootprintsForOrderRequest, customerId: string): Promise<EmissionsResponse<FootprintsItem>> => {
    const { data } = await instance.get(`/customers/${customerId}/footprints`, {
      params: {
        ...params,
      },
    });

    return data;
  };

const getQuoteForFootprints = (instance: AxiosInstance) => async (order: FootprintsOrderParams, customerId: string) => {
  const { data } = await instance.post<OrderLegacy<CompensatedFootprint[]>>(`/customers/${customerId}/footprints/quote`, {
    ...order,
    disableSupplyAlerts: true,
  });

  return data;
};

const getPricePerTonneForFootprints =
  (instance: AxiosInstance) =>
  async (order: FootprintsOrderParams, customerId: string): Promise<PortfolioPrice[]> => {
    const { data } = await instance.post<PortfolioPrice[]>(`/customers/${customerId}/footprints/quote-all-portfolios`, order);

    return data;
  };

const createOrderFromFootprints =
  (instance: AxiosInstance) =>
  async (order: OrderParams, customerId: string): Promise<OrderResponse> => {
    const { data } = await instance.post<OrderResponse>(`/customers/${customerId}/footprints/compensate`, order);

    return data;
  };

const handlers = {
  create,
  getAll,
  getOne,
  getFootprint,
  createForPartner,
  approveOrder,
  rejectOrder,
  getQuoteForPartner,
  getQuoteForCustomer,
  createBatchOrderJobForPartner,
  getFacilitatedOrdersForSingleCustomers,
  getFacilitatedOrdersForMultipleCustomers,
  getFootprintsForEmissionsOrder,
  getQuoteForFootprints,
  createOrderFromFootprints,
  createBatchOrderJobForCustomer,
  getPricePerTonneForAllPortfoliosForPartner,
  getPricePerTonneForAllPortfoliosForCustomer,
  getPricePerTonneForFootprints,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createOrderApi = (context: PortalContext) => {
  return createContextAwareHandlers(context, handlers);
};

export default createOrderApi;
